import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import LoadMask from '../../../components/LoadMask';
import Header from '../../../components/Header';
import RankingForm from '../shared/RankingForm';

// redux
import { addNewRanking } from '../../../redux/ranking-detail';

class AddNewRanking extends Component {
  _onRankingSubmit = (values) => {
    const { dispatch, history } = this.props;

    dispatch(addNewRanking(values))
      .then(
        // Success
        () => {
          toast('Ranking cadastrado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/ranking`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const {
      addNewRankingUI,
    } = this.props;

    return (
      <Fragment>
        { addNewRankingUI.isFetching && <LoadMask /> }
        <Header title="Adicionar Novo Ranking" />
        <div className="AddNewRanking PageContainer">
          <RankingForm onSubmit={this._onRankingSubmit} />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    addNewRankingUI: {
      isFetching: state.rankingDetail.addNewRanking.isFetching,
      error: state.rankingDetail.addNewRanking.error,
    },
  };
}

export default withRouter(connect(mapStateToProps)(AddNewRanking));
