import React, { useState, Fragment } from 'react';

// 3rd party components
import { TextField } from 'redux-form-material-ui';
import { Col, Row } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FontAwesome from 'react-fontawesome';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import SelectFormik from '../../../components/SelectFormik';

// Utils
import { required } from '../../../utils/form-validators';
import countries from '../../../utils/countries';
import moment from 'moment';

// css
import { S3_BASE_URL } from '../../../utils/constants';
import styled from 'styled-components';

const PlayerForm = ({ initialData, onSubmit }) => {
  const [newFile, setNewFile] = useState(null);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        playerNickname: initialData ? initialData.playerNickname : '',
        playerFirstName: initialData ? initialData.playerFirstName : '',
        playerLastName: initialData ? initialData.playerLastName : '',
        playerCountry: initialData ? initialData.playerCountry : '',
        birthdate: initialData && initialData.birthdate
          ? moment(initialData.birthdate, 'X').format('YYYY-MM-DD')
          : '',
        steamId: initialData ? initialData.steamId : '',
        playerPhoto: initialData ? initialData.playerPhoto : '',
        photoCredits: initialData ? initialData.photoCredits : '',
      }}
      validationSchema={Yup.object().shape({
        playerNickname: Yup.string()
          .max(50, 'Nickname muito longo')
          .required('Campo obrigatório'),
        playerFirstName: Yup.string()
          .max(50, 'Primeiro nome muito longo'),
        playerLastName: Yup.string()
          .max(50, 'Sobrenome muito longo'),
        playerCountry: Yup.string().required('Campo Obrigatório'),
      })}
      className="PlayerForm"
      onSubmit={(values) => {
        let birthdate = null;

        if (values.birthdate.toString()) {
          birthdate = new Date(values.birthdate);
          birthdate.setHours(birthdate.getHours() + ((moment(values.birthdate).utcOffset() / 60) * -1));
        }

        Object.assign(values, { birthdate });

        onSubmit(values, newFile)
      }}
    >
      {({ values, handleChange, errors, touched, isValid }) => (
        <Form>
          <Row>
            <Col lg={6}>
              <Card>
                <CustomFormInput
                  name="playerNickname"
                  value={values.playerNickname}
                  fullWidth
                  floatingLabelText="Nick do Jogador"
                  onChange={handleChange}
                  validate={required}
                  floatingLabelFixed
                  className="PlayerForm__input"
                  error={errors.playerNickname && touched.playerNickname}
                  helperText={errors.playerNickname}
                />

                <CustomFormInput
                  name="playerFirstName"
                  value={values.playerFirstName}
                  error={errors.playerFirstName && touched.playerFirstName}
                  helperText={errors.playerFirstName}
                  fullWidth
                  floatingLabelText="Nome do Jogador"
                  onChange={handleChange}
                  validate={required}
                  floatingLabelFixed
                  className="PlayerForm__input"
                />

                <CustomFormInput
                  name="playerLastName"
                  value={values.playerLastName}
                  error={errors.playerLastName && touched.playerLastName}
                  helperText={errors.playerLastName}
                  fullWidth
                  floatingLabelText="Sobrenome do Jogador"
                  onChange={handleChange}
                  validate={required}
                  floatingLabelFixed
                  className="PlayerForm__input"
                />

                <SelectFormik
                  name="playerCountry"
                  label="País"
                  onChange={handleChange}
                  value={values.playerCountry}
                  className="NewMatchForm__text-field"
                >
                  <option value="" selected />
                  {
                    countries.map(country => (
                      <option key={country.label} value={country.label}>{country.fullName}</option>
                    ))
                  }
                </SelectFormik>

                <CustomFormInput
                  floatingLabelText="Data de Nascimento"
                  floatingLabelFixed
                  className="PlayerForm__input"
                  name="birthdate"
                  type="date"
                  fullWidth
                  value={values.birthdate}
                  onChange={handleChange}
                  error={errors.birthdate && touched.birthdate}
                  DateTimeFormat={Intl.DateTimeFormat}
                />

                <FormLabel
                  htmlFor="steamId"
                >
                  Steam ID 64&nbsp;
                  <LinkHelper href="https://steamid.xyz/" target="_blank" rel="noreferrer">
                    <FontAwesome name="question" />
                  </LinkHelper>
                </FormLabel>
                <CustomFormInput
                  name="steamId"
                  value={values.steamId}
                  error={errors.steamId && touched.steamId}
                  helperText={errors.steamId}
                  onChange={handleChange}
                  fullWidth
                  className="PlayerForm__input"
                />

                <FormLabel
                  htmlFor="playerPhoto"
                >
                  Foto do Jogador
                </FormLabel>

                <div>
                  <Field
                    type="file"
                    name="newPlayerPhoto"
                    onChange={(event) => {
                      setNewFile(event.target.files[0])
                    }}
                  />
                </div>

                {/* ========================== */}
                {/* ======== OLD LOGO ======== */}
                {/* ========================== */}
                {values.playerPhoto && (
                  <Fragment>
                    <span>Imagem atual:</span> <br />
                    <img
                      src={`${S3_BASE_URL}/${values.playerPhoto}`}
                      alt={values.playerNickname}
                      width="60%"
                    />
                  </Fragment>
                )}

                <CustomFormInput
                  name="photoCredits"
                  value={values.photoCredits}
                  error={errors.photoCredits && touched.photoCredits}
                  helperText={errors.photoCredits}
                  onChange={handleChange}
                  fullWidth
                  floatingLabelText="Créditos da foto"
                  floatingLabelFixed
                  className="PlayerForm__input"
                />

                <FormSubmitButton
                  size="small"
                  className="PlayerForm__submit"

                  text="Enviar"
                  disabled={!isValid}
                  block
                  skin="accent"
                  type="submit"
                />
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

}

export default PlayerForm;

const CustomFormInput = styled(TextField)`
  margin-bottom: 12px;
  width: 100% !important;
`
const FormLabel = styled.label`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.3);
  display: block;
  margin-top: 24px;
`

const FormSubmitButton = styled(Button)`
  margin-top: 16px;
`

const LinkHelper = styled.a`
  display:inline-block;
  background-color:gray;
  color:#d3d3d3;
  border-radius 50px;
  width:15px;
  height:15px;
  text-align:center;
`
