const baseMatch = (
  substageId,
  index,
  isLowerBracket = false,
  fatherLower = false
) => ({
  substageId,
  binaryTreeIndex: index,
  isLowerBracket,
  fatherLower,
  isHidden: true,
  winnerAdvances: false,
  loserEliminated: false,
  seedDefiner: false,
  isFinished: false,
  bestOf: null,
  matchId: null,
  matchDate: null,
  matchScores: { matchScoreA: null, matchScoreB: null },
  series: { scoreA: null, scoreB: null },
  teamA: { id: null, name: null, country: null, logo: null },
  teamB: { id: null, name: null, country: null, logo: null },
});

const totalMatchesNumber = levelCount => {
  return 2 ** levelCount - 1;
};

const fillPreset = (substageId, levelTitles, lowerStatus = []) => {
  const levelCount = levelTitles.length;

  const totalMatches = totalMatchesNumber(levelCount);

  const matches = [];
  for (let count = 0; count < totalMatches; count += 1) {
    // if match is lower bracket
    const match = lowerStatus.includes(count)
      ? baseMatch(substageId, count, true)
      : baseMatch(substageId, count);
    matches.push(match);
  }

  return {
    matches,
    levelCount,
    levelTitles,
  };
};

export const semiFinals = substageId => {
  const levelTitles = [
    {
      binaryTreeLevel: 0,
      upperBracketTitle: 'Grande final',
      lowerBracketTitle: '',
    },
    {
      binaryTreeLevel: 1,
      upperBracketTitle: 'Semifinais',
      lowerBracketTitle: '',
    },
  ];

  return fillPreset(substageId, levelTitles);
};

export const upperLower = substageId => {
  const levelTitles = [
    {
      binaryTreeLevel: 0,
      upperBracketTitle: 'Grande final',
      lowerBracketTitle: '',
    },
    {
      binaryTreeLevel: 1,
      upperBracketTitle: 'Upper Bracket Final',
      lowerBracketTitle: 'Lower Bracket Final',
    },
    {
      binaryTreeLevel: 2,
      upperBracketTitle: 'Upper Bracket SF',
      lowerBracketTitle: 'Lower Round 3',
    },
    {
      binaryTreeLevel: 3,
      upperBracketTitle: 'Upper Bracket QF',
      lowerBracketTitle: 'Lower Bracket 2',
    },
    {
      binaryTreeLevel: 4,
      upperBracketTitle: '',
      lowerBracketTitle: 'Lower Bracket 1',
    },
  ];

  const lowerStatus = [2, 5, 6, 11, 12, 13, 14, 23, 24, 25, 26, 27, 28, 29, 30];
  return fillPreset(substageId, levelTitles, lowerStatus);
};
