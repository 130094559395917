import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import LoadingIndicator from '../LoadingIndicator';

import './styles.css';

export default class LoadMask extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
  }

  render() {
    return (
      <CSSTransition
        classNames="LoadMask"
        in
        appear
        timeout={100}
      >
        <div className="LoadMask__backdrop">
          <LoadingIndicator />
          {this.props.text || 'Carregando...'}
        </div>
      </CSSTransition>
    );
  }
}
