import React, { Component } from 'react';
import PlayerItem from './PlayerItem';

export default class ListItem extends Component {
  render() {
    const { value, removePlayer } = this.props;
    return (
      <div>
        {value &&
          value.length > 0 &&
          value.map(player => {
            const {
              playerNickname,
              playerFirstName,
              playerLastName,
              playerId,
            } = player;
            return (
              <PlayerItem
                key={playerId}
                label={`${playerFirstName} "${playerNickname}" ${playerLastName}`}
                onClick={(event) => {event.preventDefault()}}
                onRemove={ev => {
                  ev.preventDefault();

                  removePlayer(playerId);
                }}
              />
            );
          })}
      </div>
    );
  }
}
