import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button';

const PositionEditor = props => {
  const [availableTeams, setAvailableTeams] = useState(
    props.teams.filter(team => {
      const teamId = team.value;

      const found = props.field.value.find(pos => pos.value === teamId);

      return !found;
    })
  );
  const [positions, setPositions] = useState(props.field.value);
  const [teamSelectValue, setTeamSelectValue] = useState('');

  const pushTeamToPosition = teamId => {
    if (!teamId) return;
    const teamObj = availableTeams.find(
      team => team.value === parseInt(teamId, 10)
    );

    if (!teamObj) return;

    const newPositions = Array.from(positions);
    newPositions.push(teamObj);
    setPositions(newPositions);

    const newAvailableTeams = availableTeams.filter(
      team => team.value !== parseInt(teamId, 10)
    );
    setAvailableTeams(newAvailableTeams);

    props.form.setFieldValue(props.field.name, newPositions);
  };

  const removeTeamFromPosition = teamId => {
    if (!teamId) return;

    const teamObj = positions.find(team => team.value === parseInt(teamId, 10));

    if (!teamObj) return;

    const newAvailableTeams = Array.from(availableTeams);
    newAvailableTeams.push(teamObj);
    setAvailableTeams(newAvailableTeams);

    const newPositions = positions.filter(
      team => team.value !== parseInt(teamId, 10)
    );
    setPositions(newPositions);

    props.form.setFieldValue(props.field.name, newPositions);
  };

  return (
    <div>
      {positions.map((position, index) => (
        <Position>
          <Label>
            {index + 1} - {position.key}
          </Label>
          <ButtonGroup>
            <Button
              size="micro"
              skin="error"
              icon="times"
              onClick={ev => {
                ev.preventDefault();
                removeTeamFromPosition(position.value);
              }}
            />
          </ButtonGroup>
        </Position>
      ))}

      <select
        onChange={ev => {
          setTeamSelectValue(ev.target.value);
        }}
        value={teamSelectValue}
      >
        <option value="" />
        {availableTeams.map(team => (
          <option value={team.value}>{team.key}</option>
        ))}
      </select>

      <Button
        onClick={ev => {
          ev.preventDefault();
          pushTeamToPosition(teamSelectValue);
          setTeamSelectValue('');
        }}
        size="small"
        skin="accent"
        text="Adicionar Posição"
      />
    </div>
  );
};

const Position = styled.div`
  border: 1px solid var(--gray-lightest);
  padding: 24px;
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  gap: 0 12px;
  align-items: center;
  margin-bottom: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0 12px;
`;

const Label = styled.div`
  flex: 1;
`;

export default PositionEditor;
