import React, { Fragment, Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Typography, AppBar, Button, Toolbar } from '@material-ui/core';

import TournamentsService from '../../../services/tournaments';
import TournamentTable from '../TournamentList/TournamentTable';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import DeleteTournamentModal from '../TournamentList/DeleteTournamentModal';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';

class TournamentListFinished extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      deleteTournamentDetail: null,
      nextTournaments: {
        error: null,
        isLoading: true,
        count: 10,
        page: 1,
        data: [],
      },
      pastTournaments: {
        error: null,
        isLoading: true,
        count: 50,
        page: 1,
        data: [],
      },
    };
  }

  componentDidMount() {
    this.getTournaments(true);
    this.getTournaments(false);
  }

  getTournaments = (isFinished = false) => {
    const stateKey = isFinished ? 'pastTournaments' : 'nextTournaments';

    const {
      [stateKey]: { page, count },
    } = this.state;

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        isLoading: true,
        error: null,
      },
    });

    TournamentsService.getTournaments(page, count, isFinished).then(
      response => {
        this.setState({
          [stateKey]: {
            ...this.state[stateKey],
            isLoading: false,
            data: response.data.data,
          },
        });
      },
      error => {
        this.setState({
          [stateKey]: {
            ...this.state[stateKey],
            isLoading: false,
            error: error.response.data.message || 'Ocorreu um erro.',
          },
        });
      }
    );
  };

  onChangePage = (isNextTournaments, page) => {
    const stateKey = isNextTournaments ? 'nextTournaments' : 'pastTournaments';
    this.setState(
      {
        [stateKey]: {
          ...this.state[stateKey],
          // @mui/core TablePagination component supports 0 index pagination, so we add 1
          page: page + 1,
        },
      },
      () => {
        this.getTournaments(!isNextTournaments);
      }
    );
  };

  onChangeRowsPerPage = (isNextTournaments, count) => {
    const stateKey = isNextTournaments ? 'nextTournaments' : 'pastTournaments';

    this.setState(
      {
        [stateKey]: {
          ...this.state[stateKey],
          count,
        },
      },
      () => {
        this.getTournaments(!isNextTournaments);
      }
    );
  };

  onDeleteRequest = tournamentDetail => {
    this.setState({
      deleteTournamentDetail: tournamentDetail,
      deleteModalOpen: true,
    });
  };

  toggleModal = () => {
    const { deleteModalOpen } = this.state;
    this.setState({
      deleteModalOpen: !deleteModalOpen,
    });
  };

  onDeleteTournament = tournamentId => {
    TournamentsService.deleteTournament(tournamentId).then(
      // Success
      () => {
        toast(`Campeonato excluído com sucesso`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.toggleModal();
        this.getTournaments(false);
        this.getTournaments(true);
      },
      // Catch
      error => {
        toast(error.response.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.toggleModal();
      }
    );
  };

  render() {
    const {
      nextTournaments,
      pastTournaments,
      deleteModalOpen,
      deleteTournamentDetail,
    } = this.state;

    return (
      <Fragment>
        <Header title="Campeonatos Finalizados" />
        <div className="PageContainer">
          <Row>
            <Col lg={12} xs={12}>
              <TournamentTable
                data={pastTournaments.data}
                count={pastTournaments.count}
                page={pastTournaments.page}
                isLoading={pastTournaments.isLoading}
                error={pastTournaments.error}
                onChangePage={(ev, page) => {
                  this.onChangePage(false, page);
                }}
                onChangeRowsPerPage={ev => {
                  this.onChangeRowsPerPage(false, ev.target.value);
                }}
                onDeleteRequest={this.onDeleteRequest}
              />
            </Col>
          </Row>
        </div>

        <DeleteTournamentModal
          open={deleteModalOpen}
          deleteTournamentDetail={deleteTournamentDetail}
          onModalClose={this.toggleModal}
          onDeleteTournament={this.onDeleteTournament}
        />
      </Fragment>
    );
  }
}

export default TournamentListFinished;
