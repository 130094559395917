import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

// 3rd
import { Col, Row } from 'reactstrap';
import { submit, isValid, formValueSelector } from 'redux-form';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

// components
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import ArticleForm from './ArticleForm';
import ArticlePreview from './ArticlePreview';
import PlayByPlayService from '../../../../services/playByPlay';
import LoadMask from '../../../../components/LoadMask';
import MatchImageForm from '../components/MatchImageForm';
import ArticleChecklist from '../components/ArticleChecklist';

class BroadcastArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPublished: false,
      articleData: null,
      isLoading: true,
      shouldSendNotification: true,
      shouldTweet: true,
      tweetText: '',
      hasChanged: false,
    };
    window.onbeforeunload = event => {
      if (this.state.hasChanged) {
        const message = 'Your confirmation message goes here.';
        const targetEvent = event || window.event;
        // For IE and Firefox
        if (targetEvent) {
          targetEvent.returnValue = message;
        }
        // For Safari
        return message;
      }
    };
  }

  componentDidMount() {
    this.getArticleData();
  }

  componentWillUnmount() {
    clearInterval(this.draftSavingInterval);
  }

  getArticleData = () => {
    const { matchId } = this.props;

    PlayByPlayService.getArticle(matchId).then(({ data }) => {
      this.setState({
        articleData: data.data,
        isLoading: false,
        shouldSendNotification: data.data ? !data.data.isPublished : true,
        shouldTweet: data.data ? !data.data.isPublished : true,
      });

      if (data.data && !data.data.isPublished) {
        this.setDraftSaving();
      }
    });
  };

  pageHasChanged = (newValue = '') => {
    const hasChanged = newValue !== '' ? newValue : !this.stage.hasChanged;
    this.setState({ hasChanged });
  };

  setDraftSaving = () => {
    if (this.draftSavingInterval) return;

    this.draftSavingInterval = setInterval(() => {
      this.setState(
        {
          isPublished: false,
        },
        () => {
          if (this.props.articleFormIsValid) {
            this.props.dispatch(submit('articleForm'));
          }
        }
      );
    }, 2 * 60 * 1000);
  };

  submitArticle = values => {
    const { matchId } = this.props;
    const {
      isPublished,
      shouldSendNotification,
      shouldTweet,
      tweetText,
    } = this.state;

    if (!isPublished) {
      toast('Salvando rascunho', {
        position: 'top-right',
        autoClose: 2500,
        type: toast.TYPE.INFO,
      });
    } else {
      clearInterval(this.draftSavingInterval);
    }

    PlayByPlayService.addArticle(
      values,
      matchId,
      isPublished,
      shouldSendNotification,
      shouldTweet,
      tweetText
    ).then(
      // Success
      () => {
        toast(`${isPublished ? 'Artigo' : 'Rascunho'} salvo com sucesso`, {
          position: 'top-right',
          autoClose: 2500,
          type: toast.TYPE.SUCCESS,
        });
        this.pageHasChanged(false);

        this.getArticleData();
      },
      // Error
      error => {
        toast(error.message, {
          position: 'top-right',
          autoClose: 2500,
          type: toast.TYPE.ERROR,
        });
      }
    );
  };

  shouldSendNotification = ev => {
    this.setState({
      shouldSendNotification: ev.target.checked,
    });
  };

  shouldTweet = ev => {
    this.setState({
      shouldTweet: ev.target.checked,
    });
  };

  updateTweetText = ev => {
    this.setState({
      tweetText: ev.target.value,
    });
  };

  copyArticleTitleToTweetText = () => {
    this.setState({
      tweetText: this.props.articleTitle,
    });
  };

  render() {
    const {
      articleData,
      isLoading,
      shouldSendNotification,
      shouldTweet,
      tweetText,
    } = this.state;

    const {
      articleFormIsValid,
      matchData,
      articleTitle,
      articleExcerpt,
      hasChanged,
    } = this.props;

    return (
      <div className="PageContainer">
        <Prompt
          when={hasChanged}
          message={() =>
            `É possível que as alterações feitas não sejam salvas.`
          }
        />

        {isLoading && <LoadMask />}

        <Row>
          <Col lg={5} sm={12} xs={12}>
            <ArticleForm
              pageHasChanged={() => this.pageHasChanged(true)}
              onSubmit={this.submitArticle}
              articleData={articleData}
            />
          </Col>
          <Col lg={5} sm={12} xs={12}>
            <ArticlePreview />
          </Col>
          <Col lg={2}>
            <Card>
              <small>
                <strong>Status do Artigo: </strong>
                {articleData && articleData.isPublished
                  ? 'Publicado'
                  : 'Rascunho'}
              </small>

              <ArticleChecklist
                shouldTweet={shouldTweet}
                tweetText={tweetText}
                articleTitle={articleTitle}
                articleExcerpt={articleExcerpt}
                image={matchData.image}
              />
              <br />

              <Button
                size="small"
                block
                skin="accent"
                text="Salvar Rascunho"
                disabled={articleData && articleData.isPublished}
                onClick={() => {
                  this.setState(
                    {
                      isPublished: false,
                    },
                    () => {
                      this.props.dispatch(submit('articleForm'));
                    }
                  );
                }}
                className="AddBottom AddTop"
              />
              <Button
                size="small"
                block
                skin="success"
                disabled={!articleFormIsValid || (shouldTweet && !tweetText)}
                onClick={() => {
                  this.setState(
                    {
                      isPublished: true,
                    },
                    () => {
                      this.props.dispatch(submit('articleForm'));
                    }
                  );
                }}
                text={
                  articleData && articleData.isPublished
                    ? 'Atualizar'
                    : 'Publicar'
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldSendNotification}
                    onChange={this.shouldSendNotification}
                    color="primary"
                  />
                }
                label="Enviar Notificação"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldTweet}
                    onChange={this.shouldTweet}
                    color="primary"
                  />
                }
                label="Postar no Twitter"
              />
            </Card>

            {shouldTweet && (
              <Card title="Twitter" className="AddTop">
                <TextField
                  multiline
                  label="Texto do Tweet"
                  value={tweetText}
                  onChange={this.updateTweetText}
                  rows={12}
                  inputProps={{
                    maxLength: 235,
                  }}
                />

                {this.props.articleTitle !== tweetText && (
                  <Button
                    size="small"
                    className="AddTop"
                    text="Copiar Título do Artigo"
                    skin="accent"
                    block
                    onClick={this.copyArticleTitleToTweetText}
                  />
                )}
              </Card>
            )}

            <Card title="Imagem de Destaque" className="AddTop">
              <small>Será exibida no destaque, lista de notícias e tweet</small>
              <MatchImageForm enableReinitialize matchData={matchData} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articleFormIsValid: isValid('articleForm')(state),
    articleTitle: formValueSelector('articleForm')(state, 'articleTitle'),
    articleExcerpt: formValueSelector('articleForm')(state, 'articleExcerpt'),
  };
}

export default connect(mapStateToProps)(BroadcastArticle);
