import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

// css
import './styles.css';

export default class AlertBox extends Component {
    render(){
  return (
    <div className="Alert Alert__Warning">
        <FontAwesome name="warning" className="Alert__Icon" />
        <b>ATENÇÃO SCOUT:</b> Lembre-se de revisar as <b>partidas</b>, os <b>campeonatos</b> e as <b>streams</b> do dia que vão acontecer na <b>D5</b> e também no <b>VZone</b>.
    </div>
  );
}
}