function formatSubstageBody(values) {
  let substageData = {};
  switch (values.substageType) {
    case 'Groups':
      substageData.lineData = values.lineData.map((team, index) => ({
        teamId: team.teamData.teamId,
        teamPosition: index + 1,
        totalScore: team.totalScore ? parseInt(team.totalScore, 10) : 0,
      }));
      substageData.ranges = values.ranges;
      substageData.extraPoints = values.extraPoints;
      substageData.matches = values.matches.map(match => ({
        matchId: match.matchId,
      }));
      break;
    case 'Brackets':
      substageData.matches = values.matches.map(match => ({
        matchId: match.matchId,
        binaryTreeIndex: match.binaryTreeIndex,
        isLowerBracket: match.isLowerBracket,
        isHidden: match.isHidden,
        winnerAdvances: match.winnerAdvances,
        loserEliminated: match.loserEliminated,
        seedDefiner: match.seedDefiner,
      }));
      substageData.levelTitles = values.levelTitles.map((title, index) => ({
        ...title,
        binaryTreeLevel: index,
      }));
      break;
    default:
      substageData = {};
      break;
  }
  return {
    substageName: values.substageName,
    substageType: values.substageType,
    substagePosition: values.substagePosition,
    automaticPoints: values.automaticPoints ? 1 : 0,
    substageData,
  };
}

export default formatSubstageBody;
