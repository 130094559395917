import React, { Component, Fragment } from 'react';

import { InputLabel, Select } from '@material-ui/core';
import styled from 'styled-components';

class SelectField extends Component {
  render() {
    const { name = '', disabled, onChange, value, label, onBlur } = this.props;

    return (
      <Container>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          name={name}
          fullWidth
          label={label || 'aaaa'}
          native
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
        >
          {this.props.children}
        </Select>
      </Container>
    );
  }
}

const Container = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export default SelectField;
