import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import Bracket from '../../../../components/Icons/bracket'
import Group from '../../../../components/Icons/group'

const Substage = props => {
  const { selectedSubstage, substage, onSubstageSelect, onDeleteSubstage } = props
  const isSelected =
    selectedSubstage &&
    substage.substageId === selectedSubstage.substageId;

  return (
    <ListItem
      button
      onClick={() => {
        onSubstageSelect(substage);
      }}
      selected={isSelected}
    >
      <ListItemAvatar>
        <Avatar>
          {
            substage.substageType === "Brackets"
              ? <Bracket />
              : ""
          }
          {
            substage.substageType === "Groups"
              ? <Group />
              : ""
          }
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={substage.substageName}
        secondary={`${substage.substageType} - posição ${substage.substagePosition}`}
      />
      <ListItemSecondaryAction>
        <Button
          onClick={() => {
            onDeleteSubstage(substage);
          }}
        >
          <FontAwesome name="trash" />
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default Substage;
