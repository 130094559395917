import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const teamListSelector = state => state.teams;

// =============
// Selector
// =============
export const getTeamListUi = createSelector(
  teamListSelector,
  teamList => extractRequestUI(teamList),
);
