import { useEffect, useState } from 'react';
import RankingsService from '../../../services/rankings';

function useRankingEditions() {
  const [rankings, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    RankingsService.getRankings()
      .then(response => {
        setData(response.data.data);
      })
      .catch(err => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return [rankings, isLoading, error];
}

export default useRankingEditions;
