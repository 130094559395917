import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';
import BetHouseForm from './form/BetHouseForm';
import BetHousesService from '../../services/bet-houses';

class AddBetHouse extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
  }
  onSubmit = (values) => {
    this.setState({ loading: true })

    BetHousesService.create(values).then(
      // Success
      (resp) => {
        const { betHouseId } = resp.data.data
        toast('Casa de apostas criada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        // Ver forma de utilizar o router
        window.location.replace(`${process.env.PUBLIC_URL}/u/bet-houses/editar/${betHouseId}`);
      },
      // Error
      (error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
        this.setState({ loading: false })
      },
    );
  }

  render() {
    const { loading } = this.state
    return (
      <Fragment>
        <Header title="Adicionar Nova Casa de Apostas" />
        {
          loading ?
            (<LoadIndicator />) :
            (
              <div className="AddBetHouse PageContainer">
                <BetHouseForm onSubmit={this.onSubmit} />
              </div>
            )
        }

      </Fragment>
    );
  }
}

export default AddBetHouse
