import React, { Component } from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import EmptyBracketMatch from './EmptyBracketMatch';
import BracketMatch from './BracketMatch';
import Button from '../../../../components/Button';

export default class BracketEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodesPerLevel: props.form.values.levelCount,
    };
  }

  getNodeIndexesPerLevel = (levelCount) => {
    // for every level, calculate total items on it and sum to total
    const totalItems = Array.from(Array(levelCount).keys()).reduce(
      (prev, curr) => prev + 2 ** curr,
      0
    );

    // create array with every binary tree key
    const indexesArray = Array.from(Array(totalItems).keys());

    /**
     * create array with the same length as the total levelCount
     * For every key, add 1 to find the level number and cut the
     * indexesArray according to how many node indexes belong to that level
     *
     * E.g.:
     * on "array for each level" map
     * Key: 0 = Level: 1
     * How many nodes does level 1 have? 1
     * Cut 1 from indexesArray starting at index 0
     * index 0 at indexesArray now is the first key to level 2 binary indexes
     * Binary Tree Index 0 belongs to Level 1, add to resulting array "nodesPerLevel"
     * Repeat process for Key: 1 = Level: 2
     *
     * Resulting array
     * [[0], [<level 2 binary indexes>], [<level 3 binary indexes>], ...]
     */
    const nodesPerLevel = Array.from(Array(levelCount).keys()).map(level => {
      const currentLevel = level + 1;

      const nodeCountOnLevel = 2 ** (currentLevel - 1);

      /**
       * splice modifies the original "indexesArray", deleting the currentLevel
       * keys and moving the first binary key for the next level to array index 0,
       * so we can splice again on the next map iteration
       */
      const slicedIndexes = indexesArray.splice(0, nodeCountOnLevel);

      /**
       * the resulting array gives us easy access to each binary tree key
       * on each level by just querying for the index
       * Index 0 on resulting array = Level 1, etc...
       */
      return slicedIndexes;
    });

    return nodesPerLevel;
  };

  componentDidUpdate(oldProps) {
    if (oldProps.selectedSubstageId !== this.props.selectedSubstageId) {
      this.setState({
        nodesPerLevel: 
          this.props.form.values.levelCount,
      });
    }
  }

  maxMatches = () => 2 ** (this.props.form.values.levelCount - 1);
  getMatchesPerLevel = index =>
    2 ** (this.props.form.values.levelCount - index - 1);
  maxHeight = () => this.maxMatches() * 82;

  addLevel = levelCount => {
    const matches = [...this.props.field.value];
    const newLevel = levelCount + 1;
    const totalNodes = this.props.matchesByLevel(newLevel);
    const indexesPerLevel = [];
    let count = matches.length;
    for (; count < totalNodes; count += 1) {
      const hiddenMatch = this.props.baseHiddenMatch(
        this.props.selectedSubstageId,
        count,
        matches
      );
      indexesPerLevel.push(count);
      matches.push(hiddenMatch);
    }

    this.props.form.setFieldValue('levelCount', newLevel);
    this.props.form.setFieldValue('matches', matches);
  };

  removeLevel = levelCount => {
    const nodesPerLevel = this.getNodeIndexesPerLevel(levelCount);
    const newLevel = levelCount - 1
    const { value } = this.props.field;

    // Remove matches on excluded level
    // ======================================
    const nodesToRemove = nodesPerLevel[newLevel];

    const fieldValue = value.filter(
      match => !nodesToRemove.includes(match.binaryTreeIndex)
    );

    this.props.form.setFieldValue(
      'matches',
      fieldValue.sort((a, b) =>
        a.binaryTreeIndex > b.binaryTreeIndex ? 1 : -1
      )
    );

    // Remove titles for excluded level
    // =====================================
    const { levelTitles } = this.props.form.values;

    const newLevelTitlesFieldValue = levelTitles.filter(
      title => title.binaryTreeLevel !== newLevel
    );

    this.props.form.setFieldValue('levelTitles', newLevelTitlesFieldValue);

    // Set level count
    // ======================================
    this.props.form.setFieldValue('levelCount', newLevel);

    // Revalidate form
    // ======================================
    this.props.form.validateForm();
  };

  renderMatch = (selectedMatch, binaryTreeIndex, arrayIndex) => {
    const { activeMatch } = this.props;

    if (selectedMatch.length > 0) {
      return (
        <BracketMatch
          onEdit={this.props.onMatchSelect}
          key={`binaryTreeIndex-${binaryTreeIndex}`}
          index={binaryTreeIndex}
          matchData={selectedMatch[0]}
          isOdd={binaryTreeIndex % 2 === 1}
          isActive={activeMatch === binaryTreeIndex}
          containerHeight={
            this.maxHeight() / this.getMatchesPerLevel(arrayIndex)
          }
        />
      );
    }

    return (
      <EmptyBracketMatch
        key={`lowerIndex-${binaryTreeIndex}`}
        maxHeight={this.maxHeight()}
        matchesPerLevel={this.getMatchesPerLevel(arrayIndex)}
        isActive={activeMatch === binaryTreeIndex}
        onClick={() => {
          this.props.onMatchSelect(binaryTreeIndex);
        }}
      />
    );
  };

  render() {
    const { levelCount } = this.props.form.values;
    const nodesPerLevel = this.getNodeIndexesPerLevel(levelCount);
    const { activeMatch } = this.props;
    const levels = Array.from({ length: levelCount }) || []
    return (
      <BracketContainer>
        {levels.map((level, index) => (
          <BracketLevel key={`level-${level || Math.random()}`}>
            <UpperContainer>
              {nodesPerLevel[levelCount - index - 1] && nodesPerLevel[levelCount - index - 1].map(binaryTreeIndex => {
                const { value } = this.props.field;

                const selectedMatch = value.filter(
                  match => match.binaryTreeIndex === binaryTreeIndex
                );

                if (selectedMatch[0] && !selectedMatch[0].isLowerBracket) {
                  if (!selectedMatch[0].isHidden) {
                    return this.renderMatch(
                      selectedMatch,
                      binaryTreeIndex,
                      index
                    );
                  }
                  return (
                    <EmptyBracketMatch
                      key={`empty-${binaryTreeIndex}`}
                      maxHeight={this.maxHeight()}
                      matchesPerLevel={this.getMatchesPerLevel(index)}
                      isActive={activeMatch === binaryTreeIndex}
                      onClick={() => {
                        this.props.onMatchSelect(binaryTreeIndex);
                      }}
                    />
                  );
                }

                return '';
              })}
            </UpperContainer>

            <hr />
            {nodesPerLevel[levelCount - index - 1] && nodesPerLevel[levelCount - index - 1].map(binaryTreeIndex => {
              const { value } = this.props.field;

              const selectedMatch = value
                .filter(match => match.isLowerBracket)
                .filter(match => match.binaryTreeIndex === binaryTreeIndex);
              if (selectedMatch[0] && selectedMatch[0].isLowerBracket) {
                if (!selectedMatch[0].isHidden) {
                  return this.renderMatch(
                    selectedMatch,
                    binaryTreeIndex,
                    index
                  );
                }
                return (
                  <EmptyBracketMatch
                    key={`empty-${binaryTreeIndex}`}
                    maxHeight={this.maxHeight()}
                    matchesPerLevel={this.getMatchesPerLevel(index)}
                    isActive={activeMatch === binaryTreeIndex}
                    onClick={() => {
                      this.props.onMatchSelect(binaryTreeIndex);
                    }}
                  />
                );
              }
              return '';
            })}

            {levelCount === levelCount - index && (
              <Button
                type="button"
                text="Excluir nível"
                size="small"
                block
                skin="accent"
                onClick={() => {
                  this.removeLevel(levelCount);
                }}
              />
            )}
          </BracketLevel>
        ))}

        {levelCount < 7 && (
          <AddLevel
            onClick={() => {
              this.addLevel(levelCount);
            }}
          >
            <FontAwesome name="plus-circle" />
            <small>Adicionar coluna</small>
          </AddLevel>
        )}
      </BracketContainer>
    );
  }
}

const BracketContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

const BracketLevel = styled.div`
  margin-right: 16px;
  width: 250px;
  min-width: 250px;
`;

const UpperContainer = styled.div``;

const AddLevel = styled.div`
  background: var(--gray-lightest);
  width: 250px;
  min-width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  font-size: 50px;
  color: var(--gray);
  border-radius: 12px;

  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid var(--gray-lighter);

  small {
    font-size: 14px;
    margin-top: 8px;
  }

  &:hover {
    color: var(--accent);
    background: white;
    border: 1px solid var(--accent);
  }
`;
