import { TextField } from '@material-ui/core';
import React from 'react';
import Card from '../../../components/Card';
import SelectFormik from '../../../components/SelectFormik';

const BonusAndPenaltiesFieldArray = ({ arrayHelpers, handleChange }) => {
  const { push, form } = arrayHelpers;
  const fieldValue = form.values.bonusAndPenalties;

  return (
    <div>
      {fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.map((bNp, index) => (
          <Card>
            <SelectFormik
              name={`bonusAndPenalties.${index}.type`}
              label="Tipo"
              onChange={handleChange}
              value={form.values.bonusAndPenalties[index].type}
            >
              <option value="bonus">Bônus</option>
              <option value="penalty" selected>
                Penalidade
              </option>
            </SelectFormik>

            <br />

            <TextField
              name={`bonusAndPenalties.${index}.percent`}
              label="Porcentagem"
              type="number"
              min="0"
              max="100"
              props
              fullWidth
              value={form.values.bonusAndPenalties[index].percent}
              onChange={handleChange}
            />

            <br />

            <TextField
              name={`bonusAndPenalties.${index}.description`}
              label="Descrição"
              type="text"
              props
              fullWidth
              value={form.values.bonusAndPenalties[index].description}
              onChange={handleChange}
            />

            <br />

            <TextField
              name={`bonusAndPenalties.${index}.teamId`}
              label="ID do Time"
              type="number"
              props
              fullWidth
              value={form.values.bonusAndPenalties[index].teamId}
              onChange={handleChange}
            />

            <button type="button" onClick={() => arrayHelpers.remove(index)}>
              -
            </button>
          </Card>
        ))}

      <button
        type="button"
        onClick={() =>
          push({
            type: 'penalty',
            percent: 20,
          })
        }
      >
        +
      </button>
    </div>
  );
};

export default BonusAndPenaltiesFieldArray;
