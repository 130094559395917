import React, { Component, Fragment } from 'react';
import GroupForm from './GroupForm';
import MatchesService from '../../../services/matches';
import TournamentsService from '../../../services/tournaments';
import { Container } from 'reactstrap';
import BracketForm from './BracketForm';
import { toast } from 'react-toastify';

import StageSelector from '../components/StageSelector';

export default class StagesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matches: [],
      teams: [],
      stages: [],
      selectedSubstage: null,
    };
  }

  componentDidMount() {
    this.getTournamentData();
    this.getTournamentStages();
  }

  getTournamentStages = () => {
    const { tournamentId } = this.props;

    TournamentsService.getTournamentStages(tournamentId).then(response => {
      this.setState({
        stages: response.data.data,
      });
    });
  };

  getTournamentData = async () => {
    const { tournamentId } = this.props;

    const matches = await MatchesService.getMatchList(
      1,
      9999,
      0,
      null,
      null,
      tournamentId
    );

    const pastMatches = await MatchesService.getMatchList(
      1,
      9999,
      1,
      null,
      null,
      tournamentId
    );

    const tournament = await TournamentsService.getTournamentDetail(
      tournamentId
    );

    this.setState({
      matches: [...matches.data.data.list, ...pastMatches.data.data.list],
      teams: tournament.data.data.teams,
    });
  };

  selectSubstage = selectedSubstage => {
    this.setState({
      selectedSubstage,
    });
  };

  addSubstage = (substageType, stageId) => {
    this.setState({
      selectedSubstage: {
        substageType,
        substageId: null,
        stageId,
      },
    });
  };

  resetSelectedSubstage = () => {
    this.setState({
      selectedSubstage: null,
    });
  };

  substageAddRequest = (data, tournamentId, stageId) => {
    TournamentsService.addSubstage(data, tournamentId, stageId)
      .then(() => {
        toast(`Substage adicionado com sucesso.`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        this.getTournamentStages();
        this.resetSelectedSubstage();
      })
      .catch(err => {
        toast(`Erro ao adicionar substage: ${err}`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });
  };

  substageEditRequest = (data, tournamentId, stageId) => {
    TournamentsService.editSubstage(data, tournamentId, stageId)
      .then(() => {
        toast(`Substage editado com sucesso`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        this.getTournamentStages();
      })
      .catch(err => {
        toast(`Erro ao editar substage: ${err}`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });
  };

  submitSubstage = (values, substageType, substageId) => {
    const { tournamentId } = this.props;
    const { stageId } = this.state.selectedSubstage;
    const data = { 
      ...values,
      substageType,
      substageId
    };

    if (!substageId) {
      this.substageAddRequest(data, tournamentId, stageId);
    } else {
      this.substageEditRequest(data, tournamentId, stageId);
    }
  };

  render() {
    const { matches, teams, stages, selectedSubstage } = this.state;
    const { tournamentId } = this.props;

    return (
      <Fragment>
        <Container fluid>
          <StageSelector
            tournamentId={tournamentId}
            stages={stages}
            selectedSubstage={selectedSubstage}
            onStageUpdate={this.getTournamentStages}
            onSubstageSelect={this.selectSubstage}
            onSubstageAdd={this.addSubstage}
          />
        </Container>

        {selectedSubstage && selectedSubstage.substageType === 'Brackets' && (
          <BracketForm 
            matches={matches}
            selectedSubstage={selectedSubstage}
            substageId={selectedSubstage.substageId}
            tournamentId={tournamentId}
            onSubmit={this.submitSubstage}
          />
        )}
        {selectedSubstage && selectedSubstage.substageType === 'Groups' && (
          <GroupForm
            matches={matches}
            availableTeams={teams}
            selectedSubstage={selectedSubstage}
            substageId={selectedSubstage.substageId}
            tournamentId={tournamentId}
            onSubmit={this.submitSubstage}
          />
        )}
      </Fragment>
    );
  }
}
