import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const streamListSelector = state => state.streams;

// =============
// Selector
// =============
export const getStreamListUi = createSelector(
  streamListSelector,
  streamList => extractRequestUI(streamList),
);
