import React, { Component, Fragment } from 'react';

// 3rd party
import { toast } from 'react-toastify';

// Components
import MatchForm from '../MatchForm';
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';

import MatchServices from '../../../services/matches';

class EditMatch extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      matchDetail: {},
    };
  }

  componentDidMount() {
    const urlParams = window.location.href.split('/');
    const matchId = urlParams[urlParams.length - 1];

    MatchServices.getMatchDetail(matchId)
      .then(response => {
        this.setState({
          isLoading: false,
          matchDetail: response.data.data,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        toast(error.response.data.error || error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      });
  }

  onSubmit = values => {
    const {
      history,
      match: {
        params: { matchId },
      },
    } = this.props;

    MatchServices.editMatch(values, matchId).then(
      // Success
      () => {
        toast('Partida editada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        history.push(`${process.env.PUBLIC_URL}/u/partidas`);
      },
      // Error
      error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      }
    );
    MatchServices.clearCache(matchId);
  };

  render() {
    const { matchDetail, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Editar Partida" />
        <div className="EditMatch PageContainer">
          <MatchForm
            data={matchDetail}
            enableReinitialize
            onSubmit={this.onSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

export default EditMatch;
