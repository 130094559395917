import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';

import HubsService from '../../services/hubs';
import HubForm from './form/HubForm';

class EditHub extends Component {
  constructor() {
    super()
    this.state = {
      hubId: 0,
      hub: null,
      loading: true,
    }
  }
  componentDidMount() {
    const id = +this.props.match.params.id
    this.loadData(id)
  }

  loadData(id) {
    HubsService.getById(id)
      .then((response) => {
        const hub = response.data.data
        this.setState({ hubId: id, hub, loading: false })
      }).catch(error => {
        console.error(error)
      });
  }

  saveFuncion = async (values) => {
    return HubsService.edit(this.state.hubId, values);
  }

  onSubmitSuccess = () => {
    toast('Hub editado com sucesso', {
      position: 'bottom-center',
      autoClose: 5000,
      type: toast.TYPE.SUCCESS,
      hideProgressBar: true,
    });
    // Ver forma de utilizar o router
    window.location.replace(`${process.env.PUBLIC_URL}/u/hubs`);
  }

  render() {
    const { hub, loading } = this.state;
    return (
      <Fragment>
        <Header title="Editar Casa de Apostas" />
        {
          loading && <LoadIndicator />
        }
        {
          !loading && !!hub &&
          (
            <div className="EditHub PageContainer">
              <HubForm
                initialData={hub}
                saveFuncion={this.saveFuncion}
                onSubmitSuccess={this.onSubmitSuccess}
              />
            </div>
          )
        }
      </Fragment >
    );
  }
}

export default EditHub