import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

// ========================
// Actions
// ========================
const ADD_NEW_STREAM = 'draft5-admin/streams/ADD_NEW_STREAM';
const ADD_NEW_STREAM_SUCCESS = 'draft5-admin/streams/ADD_NEW_STREAM_SUCCESS';
const ADD_NEW_STREAM_FAILURE = 'draft5-admin/streams/ADD_NEW_STREAM_FAILURE';

const DELETE_STREAM = 'draft5-admin/streams/DELETE_STREAM';
const DELETE_STREAM_SUCCESS = 'draft5-admin/streams/DELETE_STREAM_SUCCESS';
const DELETE_STREAM_FAILURE = 'draft5-admin/streams/DELETE_STREAM_FAILURE';

const GET_STREAM_DETAIL = 'draft5-admin/streams/GET_STREAM_DETAIL';
const GET_STREAM_DETAIL_SUCCESS = 'draft5-admin/streams/GET_STREAM_DETAIL_SUCCESS';
const GET_STREAM_DETAIL_FAILURE = 'draft5-admin/streams/GET_STREAM_DETAIL_FAILURE';

const EDIT_STREAM = 'draft5-admin/streams/EDIT_STREAM';
const EDIT_STREAM_SUCCESS = 'draft5-admin/streams/EDIT_STREAM_SUCCESS';
const EDIT_STREAM_FAILURE = 'draft5-admin/streams/EDIT_STREAM_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  addNewStream: {
    isFetching: false,
    error: '',
  },
  deleteStream: {
    isFetching: false,
    error: '',
  },
  streamDetail: {
    isFetching: false,
    error: '',
    data: {},
  },
  editStream: {
    isFetching: false,
    error: '',
  },
};

// ========================
// reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD NEW STREAM
    case ADD_NEW_STREAM: {
      return {
        ...state,
        addNewStream: {
          ...state.addNewStream,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_STREAM_SUCCESS: {
      return {
        ...state,
        addNewStream: {
          ...state.addNewStream,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_STREAM_FAILURE: {
      return {
        ...state,
        addNewStream: {
          ...state.addNewStream,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // DELETE STREAM
    case DELETE_STREAM: {
      return {
        ...state,
        deleteStream: {
          ...state.deleteStream,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_STREAM_SUCCESS: {
      return {
        ...state,
        deleteStream: {
          ...state.deleteStream,
          isFetching: false,
        },
      };
    }

    case DELETE_STREAM_FAILURE: {
      return {
        ...state,
        deleteStream: {
          ...state.deleteStream,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // GET STREAM DETAIL
    case GET_STREAM_DETAIL: {
      return {
        ...state,
        streamDetail: {
          ...state.streamDetail,
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_STREAM_DETAIL_SUCCESS: {
      return {
        ...state,
        streamDetail: {
          ...state.streamDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_STREAM_DETAIL_FAILURE: {
      return {
        ...state,
        streamDetail: {
          ...state.streamDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT STREAM
    case EDIT_STREAM: {
      return {
        ...state,
        editStream: {
          ...state.editStream,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_STREAM_SUCCESS: {
      return {
        ...state,
        editStream: {
          ...state.editStream,
          isFetching: false,
        },
      };
    }

    case EDIT_STREAM_FAILURE: {
      return {
        ...state,
        editStream: {
          ...state.editStream,
          isFetching: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// action creators
// ========================
function _addNewStream() { return { type: ADD_NEW_STREAM }; }
function _addNewStreamSuccess() { return { type: ADD_NEW_STREAM_SUCCESS }; }
function _addNewStreamFailure(error) {
  return {
    type: ADD_NEW_STREAM_FAILURE,
    error,
  };
}

function _deleteStream() { return { type: DELETE_STREAM }; }
function _deleteStreamSuccess() { return { type: DELETE_STREAM_SUCCESS }; }
function _deleteStreamFailure(error) {
  return {
    type: DELETE_STREAM_FAILURE,
    error,
  };
}

function _getStreamDetail() { return { type: GET_STREAM_DETAIL }; }
function _getStreamDetailSuccess(data) { return { type: GET_STREAM_DETAIL_SUCCESS, data }; }
function _getStreamDetailFailure(error) {
  return {
    type: GET_STREAM_DETAIL_FAILURE,
    error,
  };
}

function _editStream() { return { type: EDIT_STREAM }; }
function _editStreamSuccess() { return { type: EDIT_STREAM_SUCCESS }; }
function _editStreamFailure(error) {
  return {
    type: EDIT_STREAM_FAILURE,
    error,
  };
}

// ========================
// thunks
// ========================
export function addNewStream(values) {
  return function (dispatch) {
    return (async () => {
      dispatch(_addNewStream());

      try {
        const url = `${API_BASE_URL}/streams`;
        await axios.post(url, values, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_addNewStreamSuccess());
        return true;
      } catch (error) {
        dispatch(_addNewStreamFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function editStream(values, streamId) {
  return async function (dispatch) {
    dispatch(_editStream());

    try {
      const url = `${API_BASE_URL}/streams/${streamId}`;
      await axios.put(url, values, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_editStreamSuccess());
      return true;
    } catch (error) {
      dispatch(_editStreamFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}

export function deleteStream(streamId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_deleteStream());

      try {
        const url = `${API_BASE_URL}/streams/${streamId}`;
        await axios.delete(url, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_deleteStreamSuccess());
        return true;
      } catch (error) {
        dispatch(_deleteStreamFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function getStreamDetail(streamId) {
  return async function (dispatch) {
    dispatch(_getStreamDetail());

    try {
      const url = `${API_BASE_URL}/streams/${streamId}`;
      const response = await axios.get(url);

      dispatch(_getStreamDetailSuccess(response.data.data));
    } catch (error) {
      dispatch(_getStreamDetailFailure(error.response.data.message));
    }
  };
}
