import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party components
import FontAwesome from 'react-fontawesome';
import AlertBox from '../AlertBox'

// css
import './styles.css';

// redux
import { toggleSidebar } from '../../redux/ui';
import AuthService from '../../services/auth';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: AuthService.getCookies().displayName,
      userPhoto: AuthService.getCookies().userPhoto,
    };
  }

  onLogout = () => {
    AuthService.logout();

    window.location.href = '/';
  }

  render() {
    const {
      username,
      userPhoto,
    } = this.state;

    return (
      <Fragment>
      <header className="Header">
        <FontAwesome
          name="bars"
          className="Header__toggle-menu"
          onClick={() => {
            this.props.dispatch(toggleSidebar());
          }}
        />

        <h3 className="Header__page-name">{this.props.title}</h3>

        <div className="Header__actions">
          {this.props.children}
        </div>

        <div className="Header__login-info">
          <img src={userPhoto} className="Header__login-info-avatar" alt={username} />
          <div className="Header__login-info-username">
            {username || 'username'}
          </div>

          <FontAwesome
            onClick={this.onLogout}
            name="sign-out"
            className="Header__logout-icon"
          />
        </div>
      </header>
        <AlertBox />
      </Fragment>
      
    );
  }
}


export default connect(null)(Header);
