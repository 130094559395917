import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

// Components
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import PageNavigator from '../../../components/PageNavigator';
import Flag from '../../../components/Flag';
import Search from '../../../components/Search';
import Modal from '../../../components/Modal';
import LoadMask from '../../../components/LoadMask';

// Thunks + Selectors
import { getStreamList } from '../../../redux/stream-list';
import { deleteStream } from '../../../redux/stream-detail';
import { getStreamListUi } from '../../../redux/selectors/stream-list';
import { getDeleteStreamUi } from '../../../redux/selectors/stream-detail';

// css
import './styles.css';

class StreamList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Nome da Stream' },
        { title: 'Nome Interno da Stream' },
        { title: 'Plataforma' },
        { title: 'Canal' },
        { title: 'Idioma' },
        { title: 'Ações' },
        { title: '' },
      ],
      currentPage: 1,
      currentAmount: 50,
      deleteModalIsVisible: false,
      deleteModalStreamObject: {},
    };
  }

  componentDidMount() {
    this._getStreamList();
  }

  _getStreamList = () => {
    this.props.dispatch(
      getStreamList(this.state.currentPage, this.state.currentAmount)
    );
  };

  _formatStreamListArray = () =>
    this.props.streamList.map(stream => [
      stream.streamName,
      stream.streamInternalName || '-',
      stream.streamPlatform,
      stream.streamChannel,
      <Flag country={stream.streamLanguage} size={15} />,
      <NavLink
        to={`${process.env.PUBLIC_URL}/u/streams/editar/${stream.streamId}`}
      >
        <Button size="small" text="Editar" block skin="accent" />
      </NavLink>,
      <Button
        size="small"
        text="Excluir"
        onClick={() => {
          this._promptDeleteStream(stream);
        }}
        block
        skin="error"
      />,
    ]);

  _onAmountChange = newAmount => {
    const { currentPage } = this.state;

    this.props.dispatch(getStreamList(currentPage, newAmount));

    this.setState({
      currentAmount: newAmount,
    });
  };

  _onPageChange = newPage => {
    const { currentAmount } = this.state;

    if (newPage < 1) return;

    this.props.dispatch(getStreamList(newPage, currentAmount));

    this.setState({
      currentPage: newPage,
    });
  };

  _onSearch = searchQuery => {
    const { currentAmount } = this.state;

    this.setState({
      currentPage: 1,
    });

    this.props.dispatch(getStreamList(1, currentAmount, searchQuery));
  };

  _promptDeleteStream = stream => {
    this.setState({
      deleteModalStreamObject: stream,
      deleteModalIsVisible: true,
    });
  };

  _closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteModalStreamObject: {},
    });
  };

  _deleteStream = (streamId, streamName) => {
    const { dispatch } = this.props;

    dispatch(deleteStream(streamId)).then(
      // Success
      () => {
        toast(`Stream ${streamName} excluída com sucesso`, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this._closeModal();
        this._getStreamList();
      },
      // Error
      error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this._closeModal();
      }
    );
  };

  render() {
    const { deleteModalIsVisible, deleteModalStreamObject } = this.state;
    const { ui, deleteStreamUi } = this.props;

    return (
      <Fragment>
        <Header title="Streams" />

        {deleteStreamUi.isFetching && <LoadMask />}
        {ui.isFetching && <LoadMask />}

        {/* =================================== */}
        {/* ========== DELETE STREAM ========== */}
        {/* =================================== */}
        {deleteModalIsVisible && (
          <Modal
            title="Excluir Equipe"
            onBackdropClick={this._closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeModal}
                />
                <Button
                  size="small"
                  text={`Excluir ${deleteModalStreamObject.streamName}`}
                  skin="error"
                  onClick={() => {
                    this._deleteStream(
                      deleteModalStreamObject.streamId,
                      deleteModalStreamObject.streamName
                    );
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir a stream{' '}
            <strong>{deleteModalStreamObject.streamName}</strong>? <br />
            <strong>Plataforma/Canal:</strong>
            {deleteModalStreamObject.streamPlatform}/
            {deleteModalStreamObject.streamChannel}
          </Modal>
        )}

        {/* =================================== */}
        {/* =========== STREAM LIST =========== */}
        {/* =================================== */}

        <div className="StreamList PageContainer">
          <div className="StreamList__SearchBar">
            <Search onSearch={this._onSearch} />
          </div>

          <div className="TableContainer">
            <Table
              headers={this.state.headers}
              isFetching={false}
              data={this._formatStreamListArray()}
            />
          </div>

          {
            // !ui.isFetching &&
            <PageNavigator
              currentPage={this.state.currentPage}
              currentAmount={this.state.currentAmount}
              onAmountChange={this._onAmountChange}
              onPageChange={this._onPageChange}
            />
          }
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ui: getStreamListUi(state),
    streamList: state.streams.data,
    deleteStreamUi: getDeleteStreamUi(state),
  };
}

export default connect(mapStateToProps)(StreamList);
