import React from 'react';
import './style.css';

const Bracket = () => {
  return (
    <svg id="Agrupar_2" className="substage-icon" data-name="Agrupar 2" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Retângulo_2" data-name="Retângulo 2" className="cls-1" width="36" height="20" />
      <rect id="Retângulo_2_copiar" data-name="Retângulo 2 copiar" className="cls-1" y="28" width="36" height="20" />
      <rect id="Retângulo_3" data-name="Retângulo 3" className="cls-1" x="36" y="8" width="7" height="4" />
      <rect id="Retângulo_3_copiar_3" data-name="Retângulo 3 copiar 3" className="cls-1" x="43" y="22" width="7" height="4" />
      <path id="Retângulo_3_copiar_2" data-name="Retângulo 3 copiar 2" className="cls-2" d="M39,8.76h4V37.219H39V8.76Z" />
      <rect id="Retângulo_3_copiar" data-name="Retângulo 3 copiar" className="cls-1" x="36" y="36" width="7" height="4" />
    </svg>
  );
}

export default Bracket;