import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

// ========================
// Actions
// ========================
const ADD_NEW_TEAM = 'draft5-admin/teams/ADD_NEW_TEAM';
const ADD_NEW_TEAM_SUCCESS = 'draft5-admin/teams/ADD_NEW_TEAM_SUCCESS';
const ADD_NEW_TEAM_FAILURE = 'draft5-admin/teams/ADD_NEW_TEAM_FAILURE';

const EDIT_TEAM = 'draft5-admin/teams/EDIT_TEAM';
const EDIT_TEAM_SUCCESS = 'draft5-admin/teams/EDIT_TEAM_SUCCESS';
const EDIT_TEAM_FAILURE = 'draft5-admin/teams/EDIT_TEAM_FAILURE';

const DELETE_TEAM = 'draft5-admin/teams/DELETE_TEAM';
const DELETE_TEAM_SUCCESS = 'draft5-admin/teams/DELETE_TEAM_SUCCESS';
const DELETE_TEAM_FAILURE = 'draft5-admin/teams/DELETE_TEAM_FAILURE';

const GET_TEAM_DETAIL = 'draft5-admin/teams/GET_TEAM_DETAIL';
const GET_TEAM_DETAIL_SUCCESS = 'draft5-admin/teams/GET_TEAM_DETAIL_SUCCESS';
const GET_TEAM_DETAIL_FAILURE = 'draft5-admin/teams/GET_TEAM_DETAIL_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  addNewTeam: {
    isFetching: false,
    error: '',
  },
  editTeam: {
    isFetching: false,
    error: '',
  },
  deleteTeam: {
    isFetching: false,
    error: '',
  },
  teamDetail: {
    isFetching: false,
    error: '',
    data: {},
  },
};

// ========================
// reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD NEW TEAM
    case ADD_NEW_TEAM: {
      return {
        ...state,
        addNewTeam: {
          ...state.addNewTeam,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_TEAM_SUCCESS: {
      return {
        ...state,
        addNewTeam: {
          ...state.addNewTeam,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_TEAM_FAILURE: {
      return {
        ...state,
        addNewTeam: {
          ...state.addNewTeam,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT TEAM
    case EDIT_TEAM: {
      return {
        ...state,
        editTeam: {
          ...state.editTeam,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_TEAM_SUCCESS: {
      return {
        ...state,
        editTeam: {
          ...state.editTeam,
          isFetching: false,
        },
      };
    }

    case EDIT_TEAM_FAILURE: {
      return {
        ...state,
        editTeam: {
          ...state.editTeam,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // DELETE TEAM
    case DELETE_TEAM: {
      return {
        ...state,
        deleteTeam: {
          ...state.deleteTeam,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        deleteTeam: {
          ...state.deleteTeam,
          isFetching: false,
        },
      };
    }

    case DELETE_TEAM_FAILURE: {
      return {
        ...state,
        deleteTeam: {
          ...state.deleteTeam,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // GET TEAM DETAIL
    case GET_TEAM_DETAIL: {
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          isFetching: true,
        },
      };
    }

    case GET_TEAM_DETAIL_SUCCESS: {
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_TEAM_DETAIL_FAILURE: {
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// action creators
// ========================
const _addNewTeam = () => ({ type: ADD_NEW_TEAM });
const _addNewTeamSuccess = () => ({ type: ADD_NEW_TEAM_SUCCESS });
const _addNewTeamFailure = error => ({
  type: ADD_NEW_TEAM_FAILURE,
  error,
});

const _editTeam = () => ({ type: EDIT_TEAM });
const _editTeamSuccess = () => ({ type: EDIT_TEAM_SUCCESS });
const _editTeamFailure = error => ({
  type: EDIT_TEAM_FAILURE,
  error,
});

function _deleteTeam() { return { type: DELETE_TEAM }; }
function _deleteTeamSuccess() { return { type: DELETE_TEAM_SUCCESS }; }
function _deleteTeamFailure(error) {
  return {
    type: DELETE_TEAM_FAILURE,
    error,
  };
}

function _getTeamDetail() { return { type: GET_TEAM_DETAIL }; }
function _getTeamDetailSuccess(data) {
  return {
    type: GET_TEAM_DETAIL_SUCCESS,
    data,
  };
}
function _getTeamDetailFailure(error) {
  return {
    type: GET_TEAM_DETAIL_FAILURE,
    error,
  };
}

// ========================
// thunks
// ========================
export function addNewTeam({ teamName, teamCountry, teamLogo, isRecommended = false }) {
  return function (dispatch) {
    return (async () => {
      dispatch(_addNewTeam());

      const data = new FormData();
      data.append('teamName', teamName);
      data.append('teamCountry', teamCountry);
      data.append('isRecommended', isRecommended);
      if (teamLogo) {
        data.append('teamLogo', teamLogo);
      }

      const config = {
        onUploadProgress: (progEv) => {
          console.log(progEv);
        },
        headers: {
          Authorization: getAuthToken(),
        },
      };

      try {
        const url = `${API_BASE_URL}/teams`;
        await axios.post(url, data, config);

        dispatch(_addNewTeamSuccess());
        return true;
      } catch (error) {
        dispatch(_addNewTeamFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function editTeam({ teamName, teamCountry, teamLogo, isRecommended = false }, teamId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_editTeam());

      const data = new FormData();
      data.append('teamName', teamName);
      data.append('teamCountry', teamCountry);
      data.append('isRecommended', isRecommended);
      if (teamLogo) {
        data.append('teamLogo', teamLogo);
      }

      const config = {
        onUploadProgress: (progEv) => {
          console.log(progEv);
        },
        headers: {
          Authorization: getAuthToken(),
        },
      };

      try {
        const url = `${API_BASE_URL}/teams/${teamId}`;
        await axios.put(url, data, config);

        dispatch(_editTeamSuccess());
        return true;
      } catch (error) {
        dispatch(_editTeamFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function deleteTeam(teamId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_deleteTeam());

      try {
        const url = `${API_BASE_URL}/teams/${teamId}`;
        await axios.delete(url, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_deleteTeamSuccess());
        return true;
      } catch (error) {
        dispatch(_deleteTeamFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function getTeamDetail(teamId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_getTeamDetail());

      try {
        const url = `${API_BASE_URL}/teams/${teamId}`;
        const response = await axios.get(url);

        dispatch(_getTeamDetailSuccess(response.data.data));
        return true;
      } catch (error) {
        dispatch(_getTeamDetailFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}
