import React, { Fragment } from 'react';
import Button from '../../components/Button';
import Header from '../../components/Header';
import AdminToolsService from '../../services/admin-tools';
import { toast } from 'react-toastify';
import FontAwesome from 'react-fontawesome';

import '../../components/Table/styles.css';

function resetRedisCache() {
  AdminToolsService.resetRedisCache()
    .then(() => {
      toast('Cache do redis resetado com sucesso.', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });
    })
    .catch(e => {
      console.error('Falha ao resetar o cache do redis. Erro:', e);

      toast('Falha ao resetar o cache do redis.', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    });
}

function createAdminToolsItems(
  option: string, description: string, action: string
  ) {
  return {
    option, description, action 
  };
}

const adminToolsItems = [
  createAdminToolsItems(
    "RedisRR",
    "Reinicia o sistema Redis e limpa todo o cache do site",
    <Button text="Reiniciar" size="small" skin="accent" onClick={() => { resetRedisCache(); }}> </Button>
  ),
];

export default function AdminToolsPage() {
  return (
  <Fragment>
  <Header title="Ferramentas Administrativas" />
  <div className="PageContainer">
  <div className="Alert Alert__Error">
        <FontAwesome name="ban" className="Alert__Icon" />
        <b>Aviso:</b> As opções aqui listadas são administrativas e com grande impacto no funcionamento do site. <b>Não utilize nenhuma</b> delas sem ter certeza do que está fazendo!
    </div>
  <table className="Table">
  <thead className="Table__thead">
    <tr>
      <td>Opção</td>
      <td>Descrição</td>
      <td>Ações</td>
    </tr>
    </thead>
    <tbody className="Table__tbody">
    {adminToolsItems.map((row) => (
    <tr key={row.option}>
      <td>{row.option}</td>
      <td>{row.description}</td>
      <td>{row.action}</td>
    </tr>
    ))}
    </tbody>
  </table>
  </div>
  </Fragment>
  );
}
