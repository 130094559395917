import { combineReducers } from 'redux';

// Reducers
import { reducer as form } from 'redux-form';
import matches from './match-list';
import matchDetail from './match-detail';

import tournaments from './tournaments';
import tournamentDetail from './tournament-detail';

import maps from './map-list';
import mapDetail from './map-detail';

import teams from './team-list';
import teamDetail from './team-detail';

import streams from './stream-list';
import streamDetail from './stream-detail';

import rankings from './ranking-list';
import rankingDetail from './ranking-detail';

import ui from './ui';

const rootReducer = combineReducers({
  form,
  maps,
  mapDetail,
  matches,
  matchDetail,
  tournaments,
  tournamentDetail,
  teams,
  teamDetail,
  streams,
  streamDetail,
  rankings,
  rankingDetail,
  ui,
});

export default rootReducer;
