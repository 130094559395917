import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import CampaignsService from '../../services/campaigns';
import SpotsForm from './SpotsForm';

class AddNewSpot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onSubmit = values => {
    const { history } = this.props;

    this.setState({ isLoading: true });
    CampaignsService.addNewSpot(values)
      .then(() => {
        toast.success('Spot cadastrado com sucesso');
        this.setState({ isLoading: false });

        history.push(`${process.env.PUBLIC_URL}/u/spots`);
      })
      .catch(err => {
        toast.error(err.response.data.message || err.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Adicionar Novo Spot" />
        <div className="PageContainer">
          <SpotsForm onSubmit={this.onSubmit} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddNewSpot);
