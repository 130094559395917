import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const mapDetailSelector = state => state.mapDetail;

// =============
// Selector
// =============
export const getAddNewMapUi = createSelector(
  mapDetailSelector,
  mapDetail => extractRequestUI(mapDetail.addNewMap),
);

export const getDeleteMapUi = createSelector(
  mapDetailSelector,
  mapDetail => extractRequestUI(mapDetail.deleteMap),
);

export const getMapDetailUi = createSelector(
  mapDetailSelector,
  mapDetail => extractRequestUI(mapDetail.mapDetail),
);
