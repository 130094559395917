import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import TeamForm from '../shared/TeamForm';
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';

// redux
import { getTeamDetail, editTeam } from '../../../redux/team-detail';
import { getEditTeamUi, getTeamDetailUi } from '../../../redux/selectors/team-detail';

class EditTeam extends Component {
  componentDidMount() {
    const { dispatch, match: { params: { teamId } } } = this.props;

    dispatch(getTeamDetail(teamId));
  }

  _onTeamSubmit = (res) => {
    const { dispatch, history, match: { params: { teamId } } } = this.props;

    dispatch(editTeam(res, teamId))
      .then(
        // Success
        () => {
          toast('Equipe editada com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/equipes`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const { teamDetailUi, editTeamUi, teamDetail } = this.props;

    return (
      <Fragment>
        {
          (editTeamUi.isFetching || teamDetailUi.isFetching) &&
          <LoadMask />
        }
        <Header title="Editar Equipe" />
        <div className="EditTeam PageContainer">
          <TeamForm
            data={teamDetail}
            enableReinitialize
            onSubmit={this._onTeamSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamDetailUi: getTeamDetailUi(state),
    editTeamUi: getEditTeamUi(state),
    teamDetail: state.teamDetail.teamDetail.data,
  };
}

export default withRouter(connect(mapStateToProps)(EditTeam));
