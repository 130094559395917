import React, { Component } from 'react';

// 3rd
import FontAwesome from 'react-fontawesome';

// css
import './styles.css';

export default class Button extends Component {
  render() {
    const {
      text,
      block,
      outline,
      skin,
      size,
      disabled,
      icon,
      spin
    } = this.props;

    return (
      <button
        className={
          `Button
          ${block ? ' Button--block' : ''}
          ${outline ? ' Button--outline' : ''}
          ${skin ? ` Button--${skin}` : ''}
          ${size ? ` Button--${size}` : ''}
          ${this.props.className || ''}
          ${disabled ? ' Button--disabled' : ''}
          `
        }
        disabled={disabled}
        onClick={this.props.onClick}
        type={this.props.type}
      >
        {icon && <FontAwesome name={icon} spin={spin} style={!text ? { marginRight: 0 } : {}} className="Button__icon" />}
        {text}
      </button>
    );
  }
}
