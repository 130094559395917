export default function getTeamNameById(teamId, slotName, teamArray) {
  if (!teamId || !teamArray) return `Equipe ${slotName}`;

  const selectedTeam = teamArray
    .filter(team => team.teamId === parseInt(teamId, 10));

  if (selectedTeam[0] && selectedTeam[0].teamName) return selectedTeam[0].teamName;

  return `Equipe ${slotName}`;
}
