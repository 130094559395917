import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class AdminToolsService {
  static resetRedisCache() {
    const url = `${API_TS_BASE_URL}/redisrr`;

    return axios.post(url, null, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
