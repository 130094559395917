import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';

export default class MapsService {
  static getMaps() {
    const url = `${API_TS_BASE_URL}/maps`;

    return axios.get(
      url,
    );
  }
}
