import React, { Component } from 'react';

// 3rd Party
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

// Components
import LoadMask from '../../components/LoadMask';

// css
import './styles.css';

// Utils
import getRandomSentence from '../../utils/sentences';

// images
import logo from '../../assets/logo.png';
import steamLoginBtn from '../../assets/steam-login-btn.png';

// Services
import AuthService from '../../services/auth';
import { API_BASE_URL } from '../../utils/constants';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sentence: getRandomSentence(),
      isValidToken: false,
      isLoading: true,
    };
  }

  componentWillMount() {
    const cookies = AuthService.getCookies();

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('notAuthorized')) {
      toast('Usuário não autorizado', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });

      window.history.replaceState({}, document.title, '/');
    }

    // validate
    AuthService.validateToken(cookies.d5Session)
      .then(() => this.setState({ isLoading: false, isValidToken: true }))
      .catch(() => this.setState({ isLoading: false, isValidToken: false }));
  }

  render() {
    const { isValidToken, isLoading } = this.state;

    return (
      <div className="Login">
        {isValidToken && <Redirect to="/u/partidas" />}

        {isLoading && <LoadMask />}

        <ToastContainer />

        <div className="Login__box">
          <div className="Login__form">
            <div className="Dashboard__title">Eatclean - Dashboard</div>
            <div className="Login__form-logo">
              <img src={logo} width="250" alt="" />
            </div>

            <a
              className="Login__steam-btn"
              href={`${API_BASE_URL}/users/steam?returnTo=eatclean`}
            >
              <img src={steamLoginBtn} alt="" />
            </a>

            {/* <small className="Login__form-sentence">
              &quot;{this.state.sentence}&quot;
            </small> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
