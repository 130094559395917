import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const DeleteTournamentModal = props => {
  return (
    <Dialog open={props.open} onClose={props.onModalClose}>
      <DialogTitle>Deletar Campeonato</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deseja deletar o campeonato{' '}
          {props.deleteTournamentDetail &&
            props.deleteTournamentDetail.tournamentName}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onModalClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onDeleteTournament(props.deleteTournamentDetail.tournamentId);
          }}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTournamentModal;
