import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';
import BetHousesService from '../../services/bet-houses';
import BetHouseForm from './form/BetHouseForm';

class EditBetHouse extends Component {
  constructor() {
    super()
    this.state = {
      betHouseId: 0,
      betHouse: null,
      loading: true,
    }
  }
  componentDidMount() {
    const betHouseId = +this.props.match.params.id
    this.loadBetHouse(betHouseId)
  }

  loadBetHouse(betHouseId) {
    BetHousesService.getById(betHouseId)
      .then((response) => {
        const betHouse = response.data.data
        this.setState({ betHouseId, betHouse, loading: false })
      }).catch(error => {
        console.log(error)
      });
  }

  onPlayerEdit = async (values) => {
    this.setState({ loading: true })
    try {
      const response = await BetHousesService.edit(this.state.betHouseId, values);

      this.setState({ betHouse: response.data.data })

      toast('casa de apostas editada com sucesso', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });

    } catch (error) {
      toast(error.message, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }

    this.setState({ loading: false })
  }

  render() {
    const { betHouse, loading } = this.state;
    return (
      <Fragment>
        <Header title="Editar Casa de Apostas" />
        {
          loading && <LoadIndicator />
        }
        {
          !loading && !!betHouse &&
          (
            <div className="EditBetHouse PageContainer">
              <BetHouseForm
                initialData={betHouse}
                onSubmit={this.onPlayerEdit}
              />
            </div>
          )
        }
      </Fragment >
    );
  }
}

export default EditBetHouse