import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_MAP_LIST = 'draft5-admin/matches/GET_MAP_LIST';
const GET_MAP_LIST_SUCCESS = 'draft5-admin/matches/GET_MAP_LIST_SUCCESS';
const GET_MAP_LIST_FAILURE = 'draft5-admin/matches/GET_MAP_LIST_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  isFetching: false,
  error: '',
  data: [],
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MAP_LIST: {
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    }

    case GET_MAP_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    }

    case GET_MAP_LIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _getMapList() {
  return {
    type: GET_MAP_LIST,
  };
}

function _getMapListSuccess(data) {
  return {
    type: GET_MAP_LIST_SUCCESS,
    data,
  };
}

function _getMapListFailure(error) {
  return {
    type: GET_MAP_LIST_FAILURE,
    error,
  };
}

// ========================
// Thunks
// ========================
export function getMapList() {
  return function (dispatch) {
    dispatch(_getMapList());

    (async () => {
      try {
        const url = `${API_BASE_URL}/maps`;
        const response = await axios.get(url);

        dispatch(_getMapListSuccess(response.data.data));
      } catch (error) {
        dispatch(_getMapListFailure(error.response.data.message));
      }
    })();
  };
}
