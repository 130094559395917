import React, { Component, Fragment } from 'react';

// 3rd party
import { toast } from 'react-toastify';

// Components
import MatchForm from '../MatchForm';
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';
import MatchServices from '../../../services/matches';

class AddNewMatch extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }

  _onSubmit = baseValues => {
    this.setState({ isLoading: true });
    const { history } = this.props;
    const values = baseValues;

    if (!values.streamId) {
      values.streams = [];
    }
    MatchServices.addMatch(baseValues).then(
      // Success
      () => {
        toast('Partida editada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        this.setState({ isLoading: false });
        history.push(`${process.env.PUBLIC_URL}/u/partidas`);
      },
      // Error
      error => {
        this.setState({ isLoading: false });
        toast(error.response.data.error || error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      }
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Adicionar Nova Partida" />
        <div className="AddNewMatch PageContainer">
          <MatchForm onSubmit={this._onSubmit} />
        </div>
      </Fragment>
    );
  }
}

export default AddNewMatch;
