import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const DeleteStageModal = props => {
  return (
    <Dialog open={props.open} onClose={props.onModalClose}>
      <DialogTitle>Deletar Stage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deseja deletar o stage {props.stageData && props.stageData.stageName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onModalClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onDeleteStage(props.stageData.stageId);
          }}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStageModal;
