import React from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { S3_BASE_URL } from '../../../utils/constants';
import Button from '../../../components/Button';

const HistoryList = ({list, changeForm, deleteHistory}) => {
  const formatDate = (date) => {
    if (!date) return "Presente"
    const dateArray = date.split("-")
    return dateArray.reverse().join("/")
  }
  return ( 
    <Col lg={6}>
      {
        !!list && list.length ? (
          list.sort((a,b)=> new Date(b.startDate) - new Date(a.startDate)).map(history => {

            return (
              <HistoryItem>
                <TeamLogo src={`${S3_BASE_URL}/${history.team.teamLogo || "assets/team-logo-placeholder.png"}`} alt={`Logo do(a) ${history.team.teamName}`} />
                <HistoryInfo>
                  <MainInfo>{history.team.teamName} - <span>{history.playerHistoryType.status}</span></MainInfo>
                  <InfoDate>{formatDate(history.startDate)} - {formatDate(history.endDate)}</InfoDate>
                </HistoryInfo>
                <Actions>
                  <Button 
                    skin="accent" 
                    size="small" 
                    text="Editar" 
                    onClick={()=>{changeForm(true, history)}}
                  />
                  <Button 
                    skin="error" 
                    size="small" 
                    text="Deletar" 
                    onClick={()=>{deleteHistory(history.id)}}
                  />
                </Actions>
              </HistoryItem>
            )
          })
        ) :  (
          <EmptyCard>
            Player sem historico
          </EmptyCard>
        )
      }
    </Col>
  );
}

export default HistoryList

const HistoryItem = styled.div`
  display:flex;
  justify-content:space-between;
  width: 100%;
  margin: 10px 0;
`

const TeamLogo = styled.img`
  height:50px;
  width:50px;
`

const HistoryInfo = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-left: 20px;
  width:100%;
  &&::first-child {
    font-weight: bold;

  }
`
const MainInfo = styled.span`
  font-weight: bold;
  span {
    text-transform: capitalize
  }
`

const InfoDate = styled.span`
  color:var(--gray);
`

const Actions = styled.div`
  display:flex;
  justify-content:center;
  aling-items:center;
  margin-left: 20px;

  button {
    margin: 5px 10px;
  }
`

const EmptyCard = styled.span`
  display:flex;
  justify-content:center;
  aling-items:center;
  width: 100%;
`