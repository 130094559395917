import React, { Component } from 'react';

// css
import './styles.css';

export default class LoadingIndicator extends Component {
  render() {
    return (
      <div className="LoaderContainer">
        <div className="LoaderContainer__loader" />
      </div>
    )
  }
}