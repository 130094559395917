import React, { useState } from 'react';

// 3rd party components
import { TextField } from 'redux-form-material-ui';
import { Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import SelectFormik from '../../../components/SelectFormik';
import Checkbox from '@material-ui/core/Checkbox';

// css
import styled from 'styled-components';

const PlayerHistoryForm = ({
    teams,
    initialData, 
    onPlayerHistoryAdd, 
    onPlayerHistoryEdit, 
  }) => {
  const [hasendDate, setHasendDate] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={{ 
        status: initialData ? initialData.status : '',
        startDate: initialData ? initialData.startDate : '',
        endDate: initialData ? initialData.endDate : '',
        teamId: initialData ? initialData.teamId : '',
        description: initialData ? initialData.description : '',
        leaveReason: initialData ? initialData.leaveReason : '',
      }}
      validationSchema={Yup.object().shape({
        status: Yup.string()
          .required('Campo obrigatório'),
        startDate: Yup.date()
          .required('Campo obrigatório'),
        teamId: Yup.string()
          .required('Campo obrigatório'),
      })}
      className="PlayerForm"
      onSubmit={(values)=>
        initialData.id ? 
          onPlayerHistoryEdit(values, initialData.id) :
          onPlayerHistoryAdd(values)
      }
    >
      {({ values, handleChange, setFieldValue, errors, touched, isValid }) => (
        <Form>
          <Row>
            <Col lg={6}>
              <Card>
                <SelectFormik
                  name="status"
                  label="Status"
                  onChange={handleChange}
                  value={values.status}
                  className="NewMatchForm__text-field"
                >
                  <option key="empty" value="" />
                  <option key="titular" value={1}>Titular</option>
                  <option key="coach" value={2}>Coach</option>
                  <option key="inativo" value={3}>Inativo</option>
                  <option key="reserva" value={4}>Reserva</option>
                  <option key="emprestimo" value={5}>Emprestimo</option>
                  <option key="standin" value={6}>Standin</option>
                </SelectFormik>

                <CustomFormInput
                  name="description"
                  value={values.description}
                  fullWidth
                  floatingLabelText="Descricao do status"
                  onChange={handleChange}
                  floatingLabelFixed
                  className="PlayerForm__input"
                  error={errors.description && touched.description}
                  helperText={errors.description}
                />

                <SelectFormik
                  name="teamId"
                  label="Time"
                  onChange={handleChange}
                  value={values.teamId}
                  className="NewMatchForm__text-field"
                >
                  <option value="" selected />
                  {
                    teams.map(team => (
                      <option key={team.teamId} value={team.teamId}>{team.teamName}</option>
                    ))
                  }
                </SelectFormik>

                <CustomFormInput
                  floatingLabelText="Data de Inicio"
                  floatingLabelFixed
                  className="PlayerForm__input"
                  name="startDate"
                  type="date"
                  fullWidth
                  value={values.startDate}
                  onChange={handleChange}
                  error={errors.startDate && touched.startDate}
                />
                {
                  hasendDate && (
                    <CustomFormInput
                      floatingLabelText="Data de Termino"
                      floatingLabelFixed
                      className="PlayerForm__input"
                      name="endDate"
                      type="date"
                      fullWidth
                      value={values.endDate}
                      onChange={handleChange}
                      error={errors.endDate && touched.endDate}
                    />
                  )
                }

                <label>
                  Status presente
                  <Checkbox
                    onChange={(value)=>{
                      setHasendDate(!value.target.checked)
                      if(value.target.checked){
                        setFieldValue("endDate", null)
                      }
                    }}
                    color="primary"
                  />
                </label>

                <CustomFormInput
                  name="leaveReason"
                  value={values.leaveReason}
                  fullWidth
                  floatingLabelText="Motivo de saida"
                  onChange={handleChange}
                  floatingLabelFixed
                  className="PlayerForm__input"
                  error={errors.leaveReason && touched.leaveReason}
                  helperText={errors.leaveReason}
                />

                <FormSubmitButton
                  size="small"
                  className="PlayerForm__submit"
                  text="Enviar"
                  disabled={!isValid}
                  block
                  skin="accent"
                  type="submit"
                />
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
 
}

export default PlayerHistoryForm;

const CustomFormInput = styled(TextField)`
  margin-bottom: 12px;
  width: 100% !important;
`
const FormSubmitButton = styled(Button)`
  margin-top: 16px;
`

