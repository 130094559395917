import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FontAwesome from 'react-fontawesome';

export default class ExtraPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormHidden: true,
      editElement: {
        index: null,
        data: null,
      },
    };
  }

  onDeleteExtraPoints = index => {
    const list = Array.from(this.props.field.value);
    list.splice(index, 1);
    this.props.form.setFieldValue('extraPoints', list);
  };

  onEditExtraPoints = (data, index) => {
    this.setState({
      isFormHidden: false,
      editElement: {
        index,
        data,
      },
    });
  };

  getTeamNameById = (teamId, teams) => {
    const [team] = teams.filter(({teamData}) => teamData.teamId === teamId)
    return team.teamData.teamName
  }

  renderMatchForm = () => (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          teamId: this.state.editElement.data ? this.state.editElement.data.teamId : '',
          points: this.state.editElement.data ? this.state.editElement.data.points : '',
          reason: this.state.editElement.data ? this.state.editElement.data.reason : '',
        }}
        validationSchema={Yup.object().shape({
          teamId: Yup.number().required('Campo Obrigatório'),
          points: Yup.number().required('Campo Obrigatório'),
          reason: Yup.string().required('Campo Obrigatório'),
        })}
        onSubmit={(values, { setSubmitting })=>{
          const extraPoints = Array.from(this.props.field.value);
          if(typeof this.state.editElement.index === "number") {
            extraPoints.splice(this.state.editElement.index, 1)
          }
          extraPoints.push(values)
          this.setState({isFormHidden: true, editElement:{}},
            () => {
              setSubmitting(false);
              this.props.form.setFieldValue('extraPoints', extraPoints);
            }
          );
         }}
      >
        {({ values, handleChange, errors, touched, isSubmitting, isValid }) => (
          <ExtraPointsForm>
            <Form>
              <FieldContainer>
                <FormControl
                  variant="outlined"
                  size="small"
                  error={errors.teamId && touched.teamId}
                >
                  <InputLabel>Time</InputLabel>
                  <Select
                    id="teamId"
                    name="teamId"
                    value={values.teamId}
                    onChange={handleChange}
                    label="Partida"
                    fullWidth
                  >
                    {
                      this.props.teams && this.props.teams.map(team => (
                        <MenuItem key={team.teamData.teamId} value={team.teamData.teamId}>
                          <span>{team.teamData.teamId} - {team.teamData.teamName}</span>
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{errors.teamId}</FormHelperText>
                </FormControl>

                <TextField
                  name="reason"
                  placeholder="Motivo"
                  fullWidth
                  value={values.reason}
                  onChange={handleChange}
                  error={errors.reason && touched.reason}
                />
              
                <TextField
                  name="points"
                  placeholder="Pontos"
                  value={values.points}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  error={errors.points && touched.points}
                />
                
              </FieldContainer>
              <FormActions>
                <Button
                  text="Cancelar"
                  block
                  outline
                  size="small"
                  skin="error"
                  onClick={() => this.setState({isFormHidden:true})}
                />
                <Button
                  type="submit"
                  text="Salvar"
                  block
                  size="small"
                  skin="success"
                  disabled={!isValid || isSubmitting}
                />
              </FormActions>
            </Form>
          </ExtraPointsForm>
        )}
      </Formik>
    </Fragment>
  );

  render() {
    const {isFormHidden} = this.state
    const {field, teams} = this.props
    return (
      <Fragment>
        {
          field && field.value.map((extraPoint, index) => {
            return (
              <ExtraPointsContainer>
                <ExtraPointsCard>
                  <ExtraPointInfo>Time {this.getTeamNameById(extraPoint.teamId, teams)} :</ExtraPointInfo>
                  <ExtraPointInfo>{extraPoint.points > 0 ? `Recebeu +${extraPoint.points} Pontos` : `Perdeu ${extraPoint.points} Pontos`}</ExtraPointInfo>
                  <ExtraPointInfo>por {extraPoint.reason}</ExtraPointInfo>
                </ExtraPointsCard>
                <MatchActions>
                  <EditAction
                    name="pencil"
                    skin="accent"
                    size="small"
                    onClick={() => this.onEditExtraPoints(extraPoint, index)}
                  />
                  <DeleteAction
                    name="trash"
                    skin="accent"
                    size="small"
                    onClick={() => this.onDeleteExtraPoints(index)}
                  />
                </MatchActions>
              </ExtraPointsContainer>
            )
          })

        }

        {
          isFormHidden ? (
            <Button
              text="Associar nova partida"
              block
              size="small"
              skin="accent"
              onClick={() => this.setState({isFormHidden:false})}
            />
          ) : this.renderMatchForm()
        }
      </Fragment>
    );
  }
}

const ExtraPointsForm = styled(Card)`
  margin: 10px 0;
  border: 1px solid var(--accent);
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  & > * {
    flex-basis: 100%;
    margin:10px;
  }
`;

const ExtraPointsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  small {
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
    color: var(--gray);
  }

  span {
    font-size: 14px;
  }
`;

const ExtraPointsCard = styled(Card)`
  flex: 1;
  text-align: center;
  display:flex;
`;

const ExtraPointInfo = styled.span`
  display:inline-flex;
  flex:1;
`;

const MatchActions = styled.div`
  width:50px;
  text-align: right;
  opacity: 1;
  transition: all 0.2s;
`;

const EditAction = styled(FontAwesome)`
  color: var(--accent);
  padding-left: 12px;
  cursor: pointer;
`;

const DeleteAction = styled(FontAwesome)`
  color: var(--error);
  padding-left: 12px;
  cursor: pointer;
`;

const FormActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin: 16px 0;
`;
