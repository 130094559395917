import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';



class HubsServiceCls {
  serviceUrl = `${API_BASE_URL}/hubs`;

  getAll({ page, amount, search }) {

    const params = {
      page: page || 1,
      amount: amount || 10,
    };

    if (search) {
      params.search = search;
    }

    return axios.get(this.serviceUrl, { params });
  }


  getById(id) {
    return axios.get(`${this.serviceUrl}/${id}`);
  }

  delete(id) {
    return axios.delete(`${this.serviceUrl}/${id}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  create(body) {
    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(this.serviceUrl, body, config);
  }

  edit(id, body) {
    const url = `${this.serviceUrl}/${id}`;

    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.put(url, body, config);
  }
}

const HubsService = new HubsServiceCls();

export default HubsService;