import React, { Component } from 'react';

import './styles.css';

export default class Card extends Component {
  render() {
    return (
      <div className={`Card ${this.props.className ? this.props.className : ''} ${this.props.noPadding ? 'Card--no-padding' : ''}`}>
        {
          this.props.title &&
          <h2 className="Card__title">{this.props.title}</h2>
        }
        {this.props.children}
      </div>
    );
  }
}
