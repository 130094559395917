
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_TS_BASE_URL = process.env.REACT_APP_API_TS_BASE_URL;
export const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

export const BYPASS = process.env.REACT_APP_BYPASS;


export const VETO_ACTIONS = {
  // eslint-disable-next-line radix
  PICK: Number.parseInt(process.env.REACT_APP_VETO_ACTIONS_PICK),
  // eslint-disable-next-line radix
  BAN: Number.parseInt(process.env.REACT_APP_VETO_ACTIONS_BAN),
  // eslint-disable-next-line radix
  LEFT: Number.parseInt(process.env.REACT_APP_VETO_ACTIONS_LEFT),
  // eslint-disable-next-line radix
  RANDOM: Number.parseInt(process.env.REACT_APP_VETO_ACTIONS_RANDOM)
};
