import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import { NavLink, Route } from 'react-router-dom';
import StagesForm from './Forms/StagesForm';
import TournamentForm from './Forms/TournamentForm';
import TournamentsService from '../../services/tournaments';
import { toast } from 'react-toastify';

export default class EditTournament extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentData: null,
    };

    this.getTournamentDetail();
  }

  getTournamentDetail = () => {
    const {
      match: { params },
    } = this.props;

    TournamentsService.getTournamentDetail(params.tournamentId).then(
      response => {
        this.setState({
          tournamentData: response.data.data,
        });
      }
    );
  };

  onSubmit = (values, newFile) => {
    const {
      match: { params },
    } = this.props;

    TournamentsService.editTournament(values, newFile, params.tournamentId).then(
      response => {
        toast.success('Campeonato editado com sucesso');
      },
      e => {
        toast.error(e.response.data.message);
      }
    );
  };

  render() {
    const {
      match: { url, params },
    } = this.props;

    const { tournamentData } = this.state;

    return (
      <Fragment>
        <Header title="Editar Campeonato" />
        <Tabs>
          <NavLink
            exact
            to={url}
            activeClassName="Tabs__tab-item--active"
            className="Tabs__tab-item"
          >
            Campeonato
          </NavLink>
          <NavLink
            exact
            to={`${url}/fases`}
            activeClassName="Tabs__tab-item--active"
            className="Tabs__tab-item"
          >
            Fases
          </NavLink>
        </Tabs>

        <div className="PageContainer">
          {tournamentData && (
            <Route
              exact
              path={`${url}`}
              render={props => (
                <TournamentForm
                  {...props}
                  onFormSubmit={this.onSubmit}
                  initialData={tournamentData}
                />
              )}
            />
          )}
          <Route
            exact
            path={`${url}/fases`}
            render={props => (
              <StagesForm {...props} tournamentId={params.tournamentId} />
            )}
          />
        </div>
      </Fragment>
    );
  }
}
