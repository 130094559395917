import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

// ========================
// Actions
// ========================
const ADD_NEW_RANKING = 'draft5-admin/ranking/ADD_NEW_RANKING';
const ADD_NEW_RANKING_SUCCESS = 'draft5-admin/ranking/ADD_NEW_RANKING_SUCCESS';
const ADD_NEW_RANKING_FAILURE = 'draft5-admin/ranking/ADD_NEW_RANKING_FAILURE';

const DELETE_RANKING = 'draft5-admin/ranking/DELETE_RANKING';
const DELETE_RANKING_SUCCESS = 'draft5-admin/ranking/DELETE_RANKING_SUCCESS';
const DELETE_RANKING_FAILURE = 'draft5-admin/ranking/DELETE_RANKING_FAILURE';

const EDIT_RANKING = 'draft5-admin/ranking/EDIT_RANKING';
const EDIT_RANKING_SUCCESS = 'draft5-admin/ranking/EDIT_RANKING_SUCCESS';
const EDIT_RANKING_FAILURE = 'draft5-admin/ranking/EDIT_RANKING_FAILURE';

const GET_RANKING_DETAIL = 'draft5-admin/ranking/GET_RANKING_DETAIL';
const GET_RANKING_DETAIL_SUCCESS = 'draft5-admin/ranking/GET_RANKING_DETAIL_SUCCESS';
const GET_RANKING_DETAIL_FAILURE = 'draft5-admin/ranking/GET_RANKING_DETAIL_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  addNewRanking: {
    isFetching: false,
    error: '',
  },
  deleteRanking: {
    isFetching: false,
    error: '',
  },
  editRanking: {
    isFetching: false,
    error: '',
  },
  rankingDetail: {
    isFetching: false,
    error: '',
    data: {}
  },
};

// ========================
// reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD NEW RANKING
    case ADD_NEW_RANKING: {
      return {
        ...state,
        addNewRanking: {
          ...state.addNewRanking,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_RANKING_SUCCESS: {
      return {
        ...state,
        addNewRanking: {
          ...state.addNewRanking,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_RANKING_FAILURE: {
      return {
        ...state,
        addNewRanking: {
          ...state.addNewRanking,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // DELETE RANKING
    case DELETE_RANKING: {
      return {
        ...state,
        deleteRanking: {
          ...state.deleteRanking,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_RANKING_SUCCESS: {
      return {
        ...state,
        deleteRanking: {
          ...state.deleteRanking,
          isFetching: false,
        },
      };
    }

    case DELETE_RANKING_FAILURE: {
      return {
        ...state,
        deleteRanking: {
          ...state.deleteRanking,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT RANKING
    case EDIT_RANKING: {
      return {
        ...state,
        editRanking: {
          ...state.editRanking,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_RANKING_SUCCESS: {
      return {
        ...state,
        editRanking: {
          ...state.editRanking,
          isFetching: false,
        },
      };
    }

    case EDIT_RANKING_FAILURE: {
      return {
        ...state,
        editRanking: {
          ...state.editRanking,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT RANKING
    case GET_RANKING_DETAIL: {
      return {
        ...state,
        rankingDetail: {
          ...state.rankingDetail,
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_RANKING_DETAIL_SUCCESS: {
      return {
        ...state,
        rankingDetail: {
          ...state.rankingDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_RANKING_DETAIL_FAILURE: {
      return {
        ...state,
        rankingDetail: {
          ...state.rankingDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// action creators
// ========================
function _addNewRanking() { return { type: ADD_NEW_RANKING }; }
function _addNewRankingSuccess() { return { type: ADD_NEW_RANKING_SUCCESS }; }
function _addNewRankingFailure(error) {
  return {
    type: ADD_NEW_RANKING_FAILURE,
    error,
  };
}

function _deleteRanking() { return { type: ADD_NEW_RANKING }; }
function _deleteRankingSuccess() { return { type: ADD_NEW_RANKING_SUCCESS }; }
function _deleteRankingFailure(error) {
  return {
    type: ADD_NEW_RANKING_FAILURE,
    error,
  };
}

function _editRanking() { return { type: EDIT_RANKING }; }
function _editRankingSuccess() { return { type: EDIT_RANKING_SUCCESS }; }
function _editRankingFailure(error) {
  return {
    type: EDIT_RANKING_FAILURE,
    error,
  };
}

function _getRankingDetail() { return { type: GET_RANKING_DETAIL }; }
function _getRankingDetailSuccess(data) { return { type: GET_RANKING_DETAIL_SUCCESS, data }; }
function _getRankingDetailFailure(error) {
  return {
    type: GET_RANKING_DETAIL_FAILURE,
    error,
  };
}

// ========================
// thunks
// ========================
export function addNewRanking(values) {
  return function (dispatch) {
    return (async () => {
      dispatch(_addNewRanking());

      const postObject = {
        ...values,
        positions: values.positions.map((position, index) => (
          {
            ...position,
            isNew: position.isNew || false,
            position: index + 1,
            team: parseInt(position.team, 10),
            points: parseInt(position.points, 10),
            previousPosition: parseInt(position.previousPosition, 10),
          }
        )),
      };

      try {
        const url = `${API_BASE_URL}/rankings`;
        await axios.post(url, postObject, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_addNewRankingSuccess());
        return true;
      } catch (error) {
        dispatch(_addNewRankingFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function editRanking(values, rankingId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_editRanking());

      const postObject = {
        ...values,
        positions: values.positions.map((position, index) => (
          {
            ...position,
            isNew: position.isNew || false,
            position: index + 1,
            team: parseInt(position.team, 10),
            points: parseInt(position.points, 10),
            previousPosition: parseInt(position.previousPosition, 10),
          }
        )),
      };

      try {
        const url = `${API_BASE_URL}/rankings/${rankingId}`;
        await axios.put(url, postObject, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_editRankingSuccess());
        return true;
      } catch (error) {
        dispatch(_editRankingFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function deleteRanking(rankingId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_deleteRanking());

      try {
        const url = `${API_BASE_URL}/rankings/${rankingId}`;
        await axios.delete(url, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_deleteRankingSuccess());
        return true;
      } catch (error) {
        dispatch(_deleteRankingFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function getRankingDetail(rankingId) {
  return function (dispatch) {
    dispatch(_getRankingDetail());

    (async () => {
      try {
        const url = `${API_BASE_URL}/rankings/${rankingId}`;
        const response = await axios.get(url);

        dispatch(_getRankingDetailSuccess(response.data.data));
      } catch (error) {
        dispatch(_getRankingDetailFailure(error.response.data.message));
      }
    })();
  };
}
