import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const matchListSelector = state => state.matches;

// =============
// Selector
// =============
const getMatchListUi = matchType => (
  createSelector(
    matchListSelector,
    matchList => extractRequestUI(matchList[matchType]),
  )
);

export default getMatchListUi;
