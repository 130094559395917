import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// 3rd party components
import { TextField } from 'redux-form-material-ui';
import { Col, Row } from 'reactstrap';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';

// Utils
import { required } from '../../../utils/form-validators';

class MapForm extends Component {
  render() {
    const {
      handleSubmit,
      valid,
    } = this.props;

    return (
      <form className="MapForm" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>
            <Card>
              <Field
                name="mapName"
                fullWidth
                floatingLabelText="Nome do Mapa"
                validate={required}
                component={TextField}
                floatingLabelFixed
              />

              <Button
                text="Enviar"
                skin="accent"
                size="small"
                block
                className="NewTeamForm__submit"
                disabled={!valid}
              />
            </Card>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { data } = ownProps;

  return data ?
    {
      initialValues: {
        mapName: data.mapName,
      },
    } :
    {
      initialValues: {
        mapName: '',
      },
    };
}

export default connect(mapStateToProps)(reduxForm({ form: 'mapForm' })(MapForm));
