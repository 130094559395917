import React, { Component, Fragment } from 'react';
// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BetHousesService from '../../services/bet-houses';
import BetHouseTableOrder from './components/BetHouseTableOrder';

class OrderBetHouses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getBetHouses();
  }

  getBetHouses = (search = "") => {
    this.setState({ isLoading: true });

    BetHousesService.getAll({ page: 1, amount: 500, search })
      .then(({ data }) => {
        this.setState({
          data: data.data,
          isLoading: false,
        });
      });
  }


  updateOrder(newList) {
    let index = 1;
    newList.map((row) => {
      row.betHouseViewOrder = index;
      index += 1;
      return row;
    })
    this.setState({ data: newList });
  }

  async saveNewOrder() {
    this.setState({ isLoading: true });
    try {
      await BetHousesService.editAllOrders(this.state.data.map(({ betHouseId, betHouseViewOrder }) => ({ betHouseId, betHouseViewOrder })))

      toast('Casas de Apostas reordenadas com sucesso', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });

      window.location.replace(`${process.env.PUBLIC_URL}/u/bet-houses`);
    } catch (error) {
      toast(error.message, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      data,
      isLoading,
    } = this.state;

    return (
      <Fragment>
        <Header title="Ordenação das Casas de Apostas" />

        {isLoading && <LoadMask />}

        <div className="BetHouseList PageContainer">
          <Menu>
            <div>
              <Button size="small" text="Salvar Ordenação" block skin="success" onClick={() => { this.saveNewOrder() }} />
            </div>
          </Menu>


          {!isLoading &&

            <BetHouseTableOrder
              data={data}
              updateOrder={(newList) => {
                this.updateOrder(newList)
              }}
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default OrderBetHouses;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`
