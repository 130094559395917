import React, { Component } from 'react';

// 3rd party
import { Select } from '@material-ui/core/';

// css
import './styles.css';

class SelectPool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemList: this.props.data,
      selectedItems: this.props.field.value,
    };
  }

  componentDidUpdate(oldProps) {
    const {
      field: { value },
      data,
    } = this.props;

    if (oldProps.data.length !== data.length) {
      this.setState({
        itemList: this.props.data,
      });
    }

    if (oldProps.field.value.length !== value.length) {
      this.setState({
        selectedItems: value,
      });
    }
  }

  addValueToPool = event => {
    const { itemList, selectedItems } = this.state;
    const { field } = this.props;

    const selectedValue = parseInt(event.target.value, 10);

    // update internal state
    this.setState({
      itemList: itemList.filter(item => item.value !== selectedValue),
      selectedItems: [
        ...selectedItems,
        ...itemList.filter(item => item.value === selectedValue),
      ],
    });

    // Update field value
    this.props.form.setFieldValue(field.name, [
      ...selectedItems,
      ...itemList.filter(item => item.value === selectedValue),
    ]);
  };

  removeValueFromPool = itemIndex => {
    const { itemList, selectedItems } = this.state;
    const { field } = this.props;

    this.setState({
      itemList: [...itemList, selectedItems[itemIndex]],
      selectedItems: selectedItems.filter((item, index) => index !== itemIndex),
    });

    // update redux form connection
    this.props.form.setFieldValue(field.name, [
      ...selectedItems.filter((item, index) => index !== itemIndex),
    ]);
  };

  render() {
    return (
      <div>
        {this.state.selectedItems.map((item, index) => (
          <div key={item.value} className="SelectPool__list-item">
            <span className="SelectPool__list-item-label">{item.key}</span>
            <button
              className="SelectPool__list-item-button"
              onClick={() => {
                this.removeValueFromPool(index);
              }}
            >
              Excluir
            </button>
          </div>
        ))}

        {this.state.selectedItems.length === 0 && (
          <div className="SelectPool__empty-state">
            Selecione um item no select abaixo.
          </div>
        )}

        <Select
          fullWidth
          onChange={this.addValueToPool}
          native
          className="SelectPool__select"
        >
          <option value="" placeholder="true">
            Selecione
          </option>
          {this.state.itemList
            .sort((a, b) => {
              if (a.key > b.key) return 1;
              else if (a.key < b.key) return -1;
              return 0;
            })
            .map(item => (
              <option key={item.value} value={item.value}>
                {item.key}
              </option>
            ))}
        </Select>
      </div>
    );
  }
}

export default SelectPool;
