import React from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';

const SubMenu = (props) => {
  return (
    <Col lg={6}>
      <Menu>
        <MenuItem
          active={props.active === 1}
          onClick={()=>props.changeForm(1)}
        >
          Jogador
        </MenuItem>
        <MenuItem
          active={props.active === 2}
          onClick={()=>props.changeForm(2)}
        >
          Historico
        </MenuItem>
      </Menu>
    </Col>
  );
}

export default SubMenu

const Menu = styled.ul`
  display:flex;
  margin-top: 20px;
  padding:0;
  background-color:var(--main-lighter);
  width: 100%;
`

const MenuItem = styled.li`
  color: var(--gray-lightest);
  list-style-type:none;
  padding: 15px 20px;
  border-bottom: 5px solid var(--main-lighter);
  cursor: pointer;
  ${props =>
    props.active &&
    `
    font-weight: bold;
    border-bottom: 5px solid var(--accent);
  `}
  &:hover{
    font-weight: bold;
    border-bottom: 5px solid var(--accent);
  }
  
`
