import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class FeatureFlagsService {
  static getFeatureFlags() {
    const url = `${API_TS_BASE_URL}/feature-flags`;

    return axios.get(url);
  }

  static getSingleFeatureFlag(featureFlagId) {
    const url = `${API_TS_BASE_URL}/feature-flags/${featureFlagId}`;

    return axios.get(url);
  }

  static updateFeatureFlag(featureFlagId, featureFlag) {
    const url = `${API_TS_BASE_URL}/feature-flags/${featureFlagId}`;

    return axios.put(url, featureFlag, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
