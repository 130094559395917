import axios from 'axios';
import { change } from 'redux-form';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';
import moment from 'moment';

// ========================
// Actions
// ========================
const GET_TOURNAMENT_DETAIL = 'draft5/tournaments/GET_TOURNAMENT_DETAIL';
const GET_TOURNAMENT_DETAIL_SUCCESS = 'draft5/tournaments/GET_TOURNAMENT_DETAIL_SUCCESS';
const GET_TOURNAMENT_DETAIL_FAILURE = 'draft5/tournaments/GET_TOURNAMENT_DETAIL_FAILURE';

const DELETE_TOURNAMENT = 'draft5/tournaments/DELETE_TOURNAMENT';
const DELETE_TOURNAMENT_SUCCESS = 'draft5/tournaments/DELETE_TOURNAMENT_SUCCESS';
const DELETE_TOURNAMENT_FAILURE = 'draft5/tournaments/DELETE_TOURNAMENT_FAILURE';

const ADD_NEW_TOURNAMENT = 'draft5/tournaments/ADD_NEW_TOURNAMENT';
const ADD_NEW_TOURNAMENT_SUCCESS = 'draft5/tournaments/ADD_NEW_TOURNAMENT_SUCCESS';
const ADD_NEW_TOURNAMENT_FAILURE = 'draft5/tournaments/ADD_NEW_TOURNAMENT_FAILURE';

const EDIT_TOURNAMENT = 'draft5/tournaments/EDIT_TOURNAMENT';
const EDIT_TOURNAMENT_SUCCESS = 'draft5/tournaments/EDIT_TOURNAMENT_SUCCESS';
const EDIT_TOURNAMENT_FAILURE = 'draft5/tournaments/EDIT_TOURNAMENT_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  tournamentDetail: {
    isFetching: false,
    error: '',
    data: {},
  },
  addNewTournament: {
    isFetching: false,
    error: '',
  },
  deleteTournament: {
    isFetching: false,
    error: '',
  },
  editTournament: {
    isFetching: false,
    error: '',
  },
};

// ========================
// REDUCER
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ==========================
    // Tournament Detail
    // ==========================
    case GET_TOURNAMENT_DETAIL: {
      return {
        ...state,
        tournamentDetail: {
          ...state.tournamentDetail,
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_TOURNAMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        tournamentDetail: {
          ...state.tournamentDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_TOURNAMENT_DETAIL_FAILURE: {
      return {
        ...state,
        tournamentDetail: {
          ...state.tournamentDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // ==========================
    // Delete Tournament
    // ==========================
    case DELETE_TOURNAMENT: {
      return {
        ...state,
        deleteTournament: {
          ...state.deleteTournament,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_TOURNAMENT_SUCCESS: {
      return {
        ...state,
        deleteTournament: {
          ...state.deleteTournament,
          isFetching: false,
        },
      };
    }

    case DELETE_TOURNAMENT_FAILURE: {
      return {
        ...state,
        deleteTournament: {
          ...state.deleteTournament,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // ==========================
    // Add New Tournament
    // ==========================
    case ADD_NEW_TOURNAMENT: {
      return {
        ...state,
        addNewTournament: {
          ...state.addNewTournament,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_TOURNAMENT_SUCCESS: {
      return {
        ...state,
        addNewTournament: {
          ...state.addNewTournament,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_TOURNAMENT_FAILURE: {
      return {
        ...state,
        addNewTournament: {
          ...state.addNewTournament,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // ==========================
    // Edit Tournament
    // ==========================
    case EDIT_TOURNAMENT: {
      return {
        ...state,
        editTournament: {
          ...state.editTournament,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_TOURNAMENT_SUCCESS: {
      return {
        ...state,
        editTournament: {
          ...state.editTournament,
          isFetching: false,
        },
      };
    }

    case EDIT_TOURNAMENT_FAILURE: {
      return {
        ...state,
        editTournament: {
          ...state.editTournament,
          isFetching: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// ACTION CREATORS
// ========================
// Tournament Detail
function _getTournamentDetail() { return { type: GET_TOURNAMENT_DETAIL }; }
function _getTournamentDetailSuccess(data) {
  return {
    type: GET_TOURNAMENT_DETAIL_SUCCESS,
    data,
  };
}
function _getTournamentDetailFailure(error) {
  return {
    type: GET_TOURNAMENT_DETAIL_FAILURE,
    error,
  };
}

// Delete tournament
function _deleteTournament() { return { type: DELETE_TOURNAMENT }; }
function _deleteTournamentSuccess() { return { type: DELETE_TOURNAMENT_SUCCESS }; }
function _deleteTournamentFailure(error) {
  return {
    type: DELETE_TOURNAMENT_FAILURE,
    error,
  };
}

// add new tournament
function _addNewTournament() { return { type: ADD_NEW_TOURNAMENT }; }
function _addNewTournamentSuccess() { return { type: ADD_NEW_TOURNAMENT_SUCCESS }; }
function _addNewTournamentFailure(error) {
  return {
    type: ADD_NEW_TOURNAMENT_FAILURE,
    error,
  };
}

// edit tournament
function _editTournament() { return { type: EDIT_TOURNAMENT }; }
function _editTournamentSuccess() { return { type: EDIT_TOURNAMENT_SUCCESS }; }
function _editTournamentFailure(error) {
  return {
    type: EDIT_TOURNAMENT_FAILURE,
    error,
  };
}

// ========================
// THUNKS
// ========================
export function getTournamentDetail(tournamentId) {
  return async function (dispatch) {
    dispatch(_getTournamentDetail());

    try {
      const url = `${API_BASE_URL}/tournaments/${tournamentId}`;
      const response = await axios.get(url);
      const tournamentDetail = response.data.data
      dispatch(_getTournamentDetailSuccess(tournamentDetail));
    } catch (error) {
      dispatch(_getTournamentDetailFailure(error.response.data.message));
    }
  };
}

export function deleteTournament(tournamentId) {
  return async function (dispatch) {
    dispatch(_deleteTournament());

    try {
      const url = `${API_BASE_URL}/tournaments/${tournamentId}`;
      await axios.delete(url, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_deleteTournamentSuccess());
      return true;
    } catch (error) {
      dispatch(_deleteTournamentFailure(error.response.data.message));
      throw new Error(error.response.data.message)
    }
  };
}

export function addNewTournament({
  tournamentName,
  tournamentImageNew,
  coverageName,
  coverageUrl,
  generalInfo,
  tournamentStart,
  tournamentEnd,
  isFinished = false,
  isFeatured = false,
  teams = [],
  streams = [],
}) {
  return async function (dispatch) {
    dispatch(_addNewTournament());

    const data = new FormData();
    data.append('tournamentName', tournamentName);
    data.append('coverageName', coverageName);
    data.append('coverageUrl', coverageUrl);
    data.append('generalInfo', generalInfo);
    data.append('tournamentStart', moment(tournamentStart).format('X'));
    data.append('tournamentEnd', moment(tournamentEnd).format('X'));
    data.append('isFinished', isFinished ? 1 : 0);
    data.append('isFeatured', isFeatured ? 1 : 0);
    data.append('teams', teams.map(team => team.value).join(','));
    data.append('streams', streams.map(stream => stream.value).join(','));
    if (tournamentImageNew) {
      data.append('tournamentImage', tournamentImageNew);
    }

    const config = {
      onUploadProgress: (progEv) => {
        console.log(progEv);
      },
      headers: {
        Authorization: getAuthToken(),
      },
    };

    try {
      const url = `${API_BASE_URL}/tournaments`;
      await axios.post(url, data, config);

      dispatch(_addNewTournamentSuccess());
      return true;
    } catch (error) {
      dispatch(_addNewTournamentFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}

export function editTournament(tournamentData, tournamentId) {
  return async function (dispatch) {
    dispatch(_editTournament());

    const {
      tournamentName,
      coverageName,
      coverageUrl,
      generalInfo,
      tournamentStart,
      tournamentEnd,
      isFinished,
      isFeatured,
      teams,
      streams,
      tournamentImage,
      tournamentImageNew,
    } = tournamentData;

    const data = new FormData();
    data.append('tournamentName', tournamentName);
    data.append('coverageName', coverageName);
    data.append('coverageUrl', coverageUrl);
    data.append('generalInfo', generalInfo);
    data.append('tournamentStart', moment(tournamentStart).format('X'));
    data.append('tournamentEnd', moment(tournamentEnd).format('X'));
    data.append('isFinished', isFinished ? 1 : 0);
    data.append('isFeatured', isFeatured ? 1 : 0);
    data.append('teams', teams.map(team => team.value).join(','));
    data.append('streams', streams.map(stream => stream.value).join(','));
    if (tournamentImageNew) {
      data.append('tournamentImage', tournamentImageNew);
    } else {
      data.append('tournamentImage', tournamentImage);
    }

    const config = {
      onUploadProgress: (progEv) => {
        console.log(progEv);
      },
      headers: {
        Authorization: getAuthToken(),
      },
    };

    try {
      const url = `${API_BASE_URL}/tournaments/${tournamentId}`;
      await axios.put(url, data, config);

      dispatch(_editTournamentSuccess());
      return true;
    } catch (error) {
      dispatch(_editTournamentFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}
