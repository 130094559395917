import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
// 3rd
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

// componentFromStream
import BroadcastEventForm from './components/EventForm';
import BroadcastFeed from './components/BroadcastFeed';

// Services
import PlayByPlayService from '../../../services/playByPlay';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import MatchImageForm from './components/MatchImageForm';

class Broadcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasChanged: false,
      activeStream: props.matchData.mainStream,
    };
    window.onbeforeunload = event => {
      if (this.state.hasChanged) {
        const message = 'Your confirmation message goes here.';
        const targetEvent = event || window.event;
        // For IE and Firefox
        if (targetEvent) {
          targetEvent.returnValue = message;
        }
        // For Safari
        return message;
      }
      return '';
    };
  }

  pageHasChanged = (newValue = '') => {
    const hasChanged = newValue !== '' ? newValue : !this.stage.hasChanged;
    this.setState({ hasChanged });
  };

  submitEvent = values => {
    const { matchData, matchId } = this.props;

    PlayByPlayService.addEvent(matchId, values, matchData).then(
      // Success
      () => {
        toast('Evento enviado com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        this.pageHasChanged(false);
      },
      // Error
      error => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
      }
    );
  };

  render() {
    const { matchData, matchId, socket } = this.props;

    const { activeStream, hasChanged } = this.state;

    return (
      <div className="PageContainer">
        <Prompt
          when={hasChanged}
          message={() =>
            `É possível que as alterações feitas não sejam salvas.`
          }
        />
        <Row>
          <Col lg={6}>
            <h3>Lances</h3>
            <BroadcastEventForm
              onSubmit={this.submitEvent}
              enableReinitialize
              matchData={matchData}
              pageHasChanged={newValue => this.pageHasChanged(newValue)}
            />

            <h3>Imagem de Destaque</h3>
            <Card>
              <small>Será exibida no destaque e lista de notícias</small>
              <MatchImageForm
                enableReinitialize
                matchData={matchData}
                pageHasChanged={newValue => this.pageHasChanged(newValue)}
              />
            </Card>
          </Col>
          <Col lg={6}>
            <h3>Stream</h3>
            <Card noPadding>
              <iframe
                src={`https://player.twitch.tv/?channel=${activeStream.streamChannel}&autoplay=false&parent=eatclean.draft5.gg`}
                frameBorder="0"
                allowFullScreen="true"
                scrolling="no"
                height="420"
                width="100%"
                title="Stream"
              />
            </Card>

            <Card>
              {matchData.streams.map(({ stream }) => (
                <Button
                  disabled={stream.streamId === activeStream.streamId}
                  skin="accent"
                  size="small"
                  onClick={() => {
                    this.pageHasChanged(false);
                    this.setState({
                      activeStream: stream,
                    });
                  }}
                  text={`${stream.streamPlatform} - ${stream.streamName}`}
                />
              ))}
            </Card>

            <h3>Feed</h3>
            <BroadcastFeed
              matchId={matchId}
              socket={socket}
              matchData={matchData}
              pageHasChanged={newValue => this.pageHasChanged(newValue)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Broadcast;
