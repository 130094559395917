import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

// ========================
// Actions
// ========================
const ADD_NEW_MAP = 'draft5-admin/maps/ADD_NEW_MAP';
const ADD_NEW_MAP_SUCCESS = 'draft5-admin/maps/ADD_NEW_MAP_SUCCESS';
const ADD_NEW_MAP_FAILURE = 'draft5-admin/maps/ADD_NEW_MAP_FAILURE';

const DELETE_MAP = 'draft5-admin/maps/DELETE_MAP';
const DELETE_MAP_SUCCESS = 'draft5-admin/maps/DELETE_MAP_SUCCESS';
const DELETE_MAP_FAILURE = 'draft5-admin/maps/DELETE_MAP_FAILURE';

const GET_MAP_DETAIL = 'draft5-admin/maps/GET_MAP_DETAIL';
const GET_MAP_DETAIL_SUCCESS = 'draft5-admin/maps/GET_MAP_DETAIL_SUCCESS';
const GET_MAP_DETAIL_FAILURE = 'draft5-admin/maps/GET_MAP_DETAIL_FAILURE';

const EDIT_MAP = 'draft5-admin/maps/EDIT_MAP';
const EDIT_MAP_SUCCESS = 'draft5-admin/maps/EDIT_MAP_SUCCESS';
const EDIT_MAP_FAILURE = 'draft5-admin/maps/EDIT_MAP_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  addNewMap: {
    isFetching: false,
    error: '',
  },
  editMap: {
    isFetching: false,
    error: '',
  },
  deleteMap: {
    isFetching: false,
    error: '',
  },
  mapDetail: {
    isFetching: false,
    error: '',
    data: {},
  },
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_MAP: {
      return {
        ...state,
        addNewMap: {
          ...state.addNewMap,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_MAP_SUCCESS: {
      return {
        ...state,
        addNewMap: {
          ...state.addNewMap,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_MAP_FAILURE: {
      return {
        ...state,
        addNewMap: {
          ...state.addNewMap,
          isFetching: false,
          error: action.error,
        },
      };
    }

    case DELETE_MAP: {
      return {
        ...state,
        deleteMap: {
          ...state.deleteMap,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_MAP_SUCCESS: {
      return {
        ...state,
        deleteMap: {
          ...state.deleteMap,
          isFetching: false,
        },
      };
    }

    case DELETE_MAP_FAILURE: {
      return {
        ...state,
        deleteMap: {
          ...state.deleteMap,
          isFetching: false,
          error: action.error,
        },
      };
    }

    case GET_MAP_DETAIL: {
      return {
        ...state,
        mapDetail: {
          ...state.mapDetail,
          isFetching: true,
          error: '',
          data: {},
        },
      };
    }

    case GET_MAP_DETAIL_SUCCESS: {
      return {
        ...state,
        mapDetail: {
          ...state.mapDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_MAP_DETAIL_FAILURE: {
      return {
        ...state,
        mapDetail: {
          ...state.mapDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    case EDIT_MAP: {
      return {
        ...state,
        editMap: {
          ...state.editMap,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_MAP_SUCCESS: {
      return {
        ...state,
        editMap: {
          ...state.editMap,
          isFetching: false,
        },
      };
    }

    case EDIT_MAP_FAILURE: {
      return {
        ...state,
        editMap: {
          ...state.editMap,
          isFetching: false,
          error: action.error,
        },
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _addNewMap() { return { type: ADD_NEW_MAP }; }
function _addNewMapSuccess() { return { type: ADD_NEW_MAP_SUCCESS }; }
function _addNewMapFailure(error) {
  return {
    type: ADD_NEW_MAP_FAILURE,
    error,
  };
}

function _deleteMap() { return { type: DELETE_MAP }; }
function _deleteMapSuccess() { return { type: DELETE_MAP_SUCCESS }; }
function _deleteMapFailure(error) {
  return {
    type: DELETE_MAP_FAILURE,
    error,
  };
}

function _getMapDetail() { return { type: GET_MAP_DETAIL }; }
function _getMapDetailSuccess(data) { return { type: GET_MAP_DETAIL_SUCCESS, data }; }
function _getMapDetailFailure(error) {
  return {
    type: GET_MAP_DETAIL_FAILURE,
    error,
  };
}

function _editMap() { return { type: EDIT_MAP }; }
function _editMapSuccess() { return { type: EDIT_MAP_SUCCESS }; }
function _editMapFailure(error) {
  return {
    type: EDIT_MAP_FAILURE,
    error,
  };
}

// ========================
// Thunks
// ========================
export function addNewMap(values) {
  return async function (dispatch) {
    dispatch(_addNewMap());

    try {
      const url = `${API_BASE_URL}/maps`;
      await axios.post(url, values, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_addNewMapSuccess());
      return true;
    } catch (error) {
      dispatch(_addNewMapFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}

export function deleteMap(mapId) {
  return async function (dispatch) {
    dispatch(_deleteMap());

    try {
      const url = `${API_BASE_URL}/maps/${mapId}`;
      await axios.delete(url, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_deleteMapSuccess());
      return true;
    } catch (error) {
      dispatch(_deleteMapFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}

export function getMapDetail(mapId) {
  return async function (dispatch) {
    dispatch(_getMapDetail());

    try {
      const url = `${API_BASE_URL}/maps/${mapId}`;
      const response = await axios.get(url);

      dispatch(_getMapDetailSuccess(response.data.data));
    } catch (error) {
      dispatch(_getMapDetailFailure(error.response.data.message));
    }
  };
}

export function editMap(values, mapId) {
  return async function (dispatch) {
    dispatch(_editMap());

    try {
      const url = `${API_BASE_URL}/maps/${mapId}`;
      await axios.put(url, values, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_editMapSuccess());
      return true;
    } catch (error) {
      dispatch(_editMapFailure(error.response.data.message));
      throw new Error(error.response.data.message);
    }
  };
}
