import React, { Component } from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'react-toastify/dist/ReactToastify.css';

// Pages
import Login from './pages/Login';
import Logged from './pages/Logged';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router basename="/">
        <Switch>
          <Route path="/u" component={Logged} />
          <Route exact path="/" component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default App;
