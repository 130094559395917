import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';
import { Route } from 'react-router-dom';
import TournamentForm from './Forms/TournamentForm';
import TournamentsService from '../../services/tournaments';
import { toast } from 'react-toastify';

export default class AddNewTournament extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    const onSubmit = (values, newFile = null) => {
      TournamentsService.addTournament(values, newFile).then(
        response => {
          toast.success('Campeonato editado com sucesso');
        },
        e => {
          toast.error(e.response.data.message);
        }
      );
    };

    return (
      <Fragment>
        <Header title="Adicionar Novo Campeonato" />

        <div className="PageContainer">
          <Route
            exact
            path={`${url}`}
            render={props => (
              <TournamentForm
                {...props}
                onFormSubmit={onSubmit}
              />
            )}
          />
        </div>
      </Fragment>
    );
  }
}
