import React, { Fragment } from 'react';
import styled from 'styled-components';

// 3rd
import { Col, Row } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { TextField } from '@material-ui/core';

// Components
import SelectFormik from '../../../../components/SelectFormik';
import Button from '../../../../components/Button';

const MapsFieldArray = props => {
  const {
    form: { setFieldValue, values, handleChange, handleBlur, touched, errors },
    push,
    remove,
    teamA,
    teamB,
    maps,
  } = props;
  const defaultMap = { scoreA: 0, scoreB: 0, map: 9 };

  const presetScores = rounds => {
    const presetScore = [];
    for (let i = rounds; i > 0; i -= 1) {
      presetScore.push(defaultMap);
    }
    setFieldValue('scores', presetScore);
  };

  const checkError = (index, key) => {
    return (
      Boolean(
        touched.scores && touched.scores[index] && touched.scores[index][key]
      ) &&
      Boolean(
        errors.scores && errors.scores[index] && errors.scores[index][key]
      )
    );
  };

  const returnErrorMessage = (index, key) => {
    return (
      Boolean(
        touched.scores && touched.scores[index] && touched.scores[index][key]
      ) &&
      Boolean(
        errors.scores && errors.scores[index] && errors.scores[index][key]
      ) &&
      errors.scores[index][key]
    );
  };

  return (
    <Fragment>
      <Button
        skin="accent"
        size="micro"
        className="NewMatchForm__preset-btn"
        type="button"
        text="MD1"
        onClick={() => presetScores(1)}
      />
      <Button
        skin="accent"
        size="micro"
        className="NewMatchForm__preset-btn"
        type="button"
        text="MD2"
        onClick={() => presetScores(2)}
      />
      <Button
        skin="accent"
        size="micro"
        className="NewMatchForm__preset-btn"
        type="button"
        text="MD3"
        onClick={() => presetScores(3)}
      />
      <Button
        skin="accent"
        size="micro"
        className="NewMatchForm__preset-btn"
        type="button"
        text="MD5"
        onClick={() => presetScores(5)}
      />

      <hr className="NewMatchForm__divider" />

      {values.scores.length === 0 && (
        <small>Escolha um preset ou adicione um mapa no botão abaixo.</small>
      )}

      {values &&
        values.scores.map((member, index) => (
          // formik requires index as key to prevent focus errors onChange
          // using JSON.stringify(member) changes value after input change, causing map errors
          <MapContainer>
            <Row key={index}>
              <Col lg={1}>
                <div className="NewMatchForm__label NewMatchForm__label--dark">
                  <span>Map {index + 1}</span>
                </div>
              </Col>

              <Col lg={3}>
                <TextField
                  value={member.scoreA}
                  name={`scores.${index}.scoreA`}
                  fullWidth
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={teamA}
                  error={checkError(index, 'scoreA')}
                  helperText={returnErrorMessage(index, 'scoreA')}
                />
              </Col>

              <Col lg={1}>
                <div className="NewMatchForm__label">
                  <span>vs.</span>
                </div>
              </Col>

              <Col lg={3}>
                <TextField
                  value={member.scoreB}
                  name={`scores.${index}.scoreB`}
                  label={teamB}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  error={checkError(index, 'scoreB')}
                  helperText={returnErrorMessage(index, 'scoreB')}
                />
              </Col>

              <Col lg={3}>
                <SelectFormik
                  name={`scores.${index}.map`}
                  value={member.map}
                  label="Mapa"
                  onChange={handleChange}
                >
                  {maps.map(map => (
                    <option key={map.mapId} value={map.mapId}>
                      {map.mapName}
                    </option>
                  ))}
                </SelectFormik>
              </Col>

              <Col lg={1}>
                <a
                  className="NewMatchForm__label NewMatchForm__label--button"
                  onClick={() => remove(index)}
                  role="button"
                >
                  <FontAwesome name="times" />
                </a>
              </Col>
            </Row>
          </MapContainer>
        ))}
      <hr className="NewMatchForm__divider" />

      <Button
        skin="accent"
        size="small"
        block
        type="button"
        text="Adicionar mapa"
        onClick={() => push(defaultMap)}
      />
    </Fragment>
  );
};

export default MapsFieldArray;

const MapContainer = styled.div`
  margin-bottom: 16px;
`;
