import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const mapListSelector = state => state.maps;
// =============
// Selector
// =============
export const getMapListUi = createSelector(
  mapListSelector,
  mapList => extractRequestUI(mapList),
);
