import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import TeamForm from '../shared/TeamForm';
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';

// redux
import { addNewTeam } from '../../../redux/team-detail';
import { getAddNewTeamUi } from '../../../redux/selectors/team-detail';

class AddNewTeam extends Component {
  _onTeamSubmit = (res) => {
    const { dispatch, history } = this.props;

    dispatch(addNewTeam(res))
      .then(
        // Success
        () => {
          toast('Equipe cadastrada com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/equipes`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const { addNewTeamUI } = this.props;

    return (
      <Fragment>
        { addNewTeamUI.isFetching && <LoadMask /> }
        <Header title="Adicionar Nova Equipe" />
        <div className="AddNewTeam PageContainer">
          <TeamForm onSubmit={this._onTeamSubmit} />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    addNewTeamUI: getAddNewTeamUi(state),
  };
}

export default withRouter(connect(mapStateToProps)(AddNewTeam));
