import React, { Fragment } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Button from '../../../../components/Button';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

const EditBracketSlotForm = props => {
  const { matches } = props.form.values;
  const parentNode = Math.floor((props.activeBracketSlot - 1) / 2);
  const [parentMatch] = matches.filter(
    match => match.binaryTreeIndex === parentNode
  );
  const shouldBeLowerBracket = parentMatch ? parentMatch.isLowerBracket : false;

  const getMatchById = id => {
    const [result] = props.availableMatches.filter(
      element => element.matchId === id
    );
    return result || {};
  };

  return (
    <Formik
      initialValues={{
        match: props.selectedMatch ? props.selectedMatch.matchId : null,
        isLowerBracket:
          shouldBeLowerBracket ||
          (props.selectedMatch ? props.selectedMatch.isLowerBracket : false),
        winnerAdvances: props.selectedMatch
          ? props.selectedMatch.winnerAdvances
          : false,
        loserEliminated: props.selectedMatch
          ? props.selectedMatch.loserEliminated
          : false,
        seedDefiner: props.selectedMatch
          ? props.selectedMatch.seedDefiner
          : false,
        isHidden: props.selectedMatch ? props.selectedMatch.isHidden : false,
      }}
      validationSchema={Yup.object().shape({
        match: Yup.number().when('isHidden', {
          is: true,
          then: Yup.number().nullable(),
          otherwise: Yup.number()
            .notOneOf(
              props.form.values.matches
                .filter(match => {
                  const matchId = props.selectedMatch
                    ? props.selectedMatch.matchId
                    : null;
                  return match.matchId !== matchId;
                })
                .map(match => match.matchId),
              'Partida já existente no bracket'
            )
            .required('Por favor, preencha uma partida válida'),
        }),
        isLowerBracket: Yup.boolean().required(),
        winnerAdvances: Yup.boolean().required(),
        loserEliminated: Yup.boolean().required(),
        seedDefiner: Yup.boolean().required(),
        isHidden: Yup.boolean().required(),
      })}
      onSubmit={values => {
        const {
          isLowerBracket,
          winnerAdvances,
          loserEliminated,
          seedDefiner,
          isHidden,
        } = values;
        const { availableMatches } = props;

        let matchData;

        if (!isHidden) {
          [matchData] = availableMatches.filter(
            match => match.matchId === values.match
          );
        }

        const newMatch = {
          ...matchData,
          binaryTreeIndex: props.activeBracketSlot,
          isLowerBracket,
          winnerAdvances,
          loserEliminated,
          seedDefiner,
          isHidden,
        };

        const fieldValue = [
          ...props.form.values.matches.filter(
            match => match.binaryTreeIndex !== props.activeBracketSlot
          ),
          newMatch,
        ];

        props.form.setFieldValue(
          'matches',
          fieldValue.sort((a, b) =>
            a.binaryTreeIndex > b.binaryTreeIndex ? 1 : -1
          )
        );

        props.onMatchSubmit();
      }}
      enableReinitialize
    >
      {({ errors, touched, values, handleChange, handleSubmit, isValid }) => (
        <Fragment>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            error={errors.color && touched.color}
          >
            <InputLabel>Partida</InputLabel>
            <Select
              id="match"
              name="match"
              value={values.match}
              onChange={e => {
                values.isHidden = false;
                return handleChange(e);
              }}
              label="Partida"
            >
              {props.availableMatches.map(match => (
                <MenuItem key={match.matchId} value={match.matchId}>
                  {match.matchId} | {match.teamA.teamName} vs{' '}
                  {match.teamB.teamName} |{' '}
                  {match.matchParticularity.substring(0, 30)}{' '}
                  {match.isFinished ? ' | Finalizada' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <MatchDetais>
            <InputLabel>
              {values.match
                ? getMatchById(values.match).matchParticularity
                : ''}
            </InputLabel>
          </MatchDetais>

          <FormControlLabel
            control={
              <Checkbox
                checked={values.isLowerBracket}
                onChange={handleChange}
                name="isLowerBracket"
                disabled={shouldBeLowerBracket}
                color="primary"
              />
            }
            label="Lower Bracket"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isHidden}
                onChange={handleChange}
                name="isHidden"
                color="primary"
              />
            }
            label="Espaço Vazio"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={values.winnerAdvances}
                onChange={handleChange}
                name="winnerAdvances"
                color="primary"
              />
            }
            label="Vencedor classifica"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={values.loserEliminated}
                onChange={handleChange}
                name="loserEliminated"
                color="primary"
              />
            }
            label="Partida eliminatória"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={values.seedDefiner}
                onChange={handleChange}
                name="seedDefiner"
                color="primary"
              />
            }
            label="Definição de seed"
          />

          <FormErrorText>{errors.match}</FormErrorText>

          <FormFooter>
            <Button
              text="Cancelar"
              skin="gray"
              type="button"
              onClick={props.onMatchEditCancel}
              outline
              size="small"
            />
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!isValid}
              text="Salvar"
              skin="accent"
              size="small"
            />
          </FormFooter>
        </Fragment>
      )}
    </Formik>
  );
};

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--gray-lightest);
  padding-top: 16px;
`;

const FormErrorText = styled.div`
  font-size: 13px;
  color: var(--error);
  margin: 12px 0;
`;

const MatchDetais = styled.div`
  margin: 10px 0;
`;

export default EditBracketSlotForm;
