import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const LevelTitlesEditor = props => {
  const { levelCount } = props.form.values;

  return (
    <FieldsContainer>
      {Array.from({ length: levelCount }).map((level, index, array) => (
        <FieldGroupContainer>
          <TextField
            name={`levelTitles[${array.length - index - 1}].upperBracketTitle`}
            label="Título Upper Bracket"
            variant="outlined"
            size="small"
            fullWidth
            value={
              props.field.value[array.length - index - 1]
                ? props.field.value[array.length - index - 1].upperBracketTitle
                : ''
            }
            onChange={props.field.onChange}
          />
          <TextField
            name={`levelTitles[${array.length - index - 1}].lowerBracketTitle`}
            label="Título Lower Bracket"
            variant="outlined"
            size="small"
            fullWidth
            value={
              props.field.value[array.length - index - 1]
                ? props.field.value[array.length - index - 1].lowerBracketTitle
                : ''
            }
            onChange={props.field.onChange}
          />
        </FieldGroupContainer>
      ))}
    </FieldsContainer>
  );
};

const FieldsContainer = styled.div`
  display: flex;
  padding: 8px 0;
`;

const FieldGroupContainer = styled.div`
  width: 250px;
  min-width: 250px;
  margin-right: 16px;

  & > div {
    margin-bottom: 12px;
  }
`;

export default LevelTitlesEditor;
