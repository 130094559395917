import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import SelectField from '../../../../components/SelectFormik';
import orderAlphabetically from '../../../../utils/order-alphabetically';
import PlayerList from './PlayerList';

export default class PlayerSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availablePlayers: props.players.filter(
        player =>
          props.field.value.playersTeamA.every(
            teamAPlayer => teamAPlayer.playerId !== player.playerId
          ) &&
          props.field.value.playersTeamB.every(
            teamBPlayer => teamBPlayer.playerId !== player.playerId
          )
      ),
    };
  }

  componentDidUpdate(prevProps) {
    const prevPlayersTeamA = prevProps.field.value.playersTeamA || [];
    const propsPlayersTeamA = this.props.field.value.playersTeamA || [];
    const prevPlayersTeamB = prevProps.field.value.playersTeamB || [];
    const propsPlayersTeamB = this.props.field.value.playersTeamB || [];
    if (
      prevPlayersTeamA.length !== propsPlayersTeamA.length ||
      prevPlayersTeamB.length !== propsPlayersTeamB.length
    ) {
      this.filterAvailablePlayers();
    }
  }

  addPlayerToTeam = (value, teamKey) => {
    const playerId = parseInt(value, 10);

    const { availablePlayers } = this.state;
    const { form, field } = this.props;

    const selectedPlayer = availablePlayers.filter(
      player => player.playerId === playerId
    );

    if (teamKey === 'A') {
      const playersTeamA = field.value.playersTeamA || [];
      const aggregatedAPlayers = [...playersTeamA, ...selectedPlayer];
      form.setFieldValue(field.name, {
        ...field.value,
        playersTeamA: aggregatedAPlayers,
      });
    }

    if (teamKey === 'B') {
      const playersTeamB = field.value.playersTeamB || [];
      const aggregatedBPlayers = [...playersTeamB, ...selectedPlayer];

      form.setFieldValue(field.name, {
        ...field.value,
        playersTeamB: aggregatedBPlayers,
      });
    }

    this.filterAvailablePlayers();
  };

  removePlayerFromTeam = (value, teamKey) => {
    const playerId = parseInt(value, 10);

    const { form, field } = this.props;

    if (teamKey === 'A') {
      form.setFieldValue(field.name, {
        ...field.value,
        playersTeamA: field.value.playersTeamA.filter(
          player => player.playerId !== playerId
        ),
      });
    }

    if (teamKey === 'B') {
      form.setFieldValue(field.name, {
        ...field.value,
        playersTeamB: field.value.playersTeamB.filter(
          player => player.playerId !== playerId
        ),
      });
    }

    this.filterAvailablePlayers();
  };

  filterAvailablePlayers = () => {
    const playerTeamA = this.props.field.value.playersTeamA || [];
    const playerTeamB = this.props.field.value.playersTeamB || [];
    this.setState({
      availablePlayers: this.props.players.filter(
        player =>
          playerTeamA.every(
            teamAPlayer => teamAPlayer.playerId !== player.playerId
          ) &&
          playerTeamB.every(
            teamBPlayer => teamBPlayer.playerId !== player.playerId
          )
      ),
    });
  };

  render() {
    const { availablePlayers } = this.state;
    const { field, form } = this.props;

    return (
      <Row>
        <Col lg={6}>
          <SelectField
            onChange={ev => {
              this.addPlayerToTeam(ev.target.value, 'A');

              // Reset select value
              const evTarget = ev.target;
              evTarget.value = '';
            }}
            name="players.playersTeamB"
            onBlur={form.handleBlur}
          >
            <option selected>Selecione um jogador</option>
            {availablePlayers
              .sort(orderAlphabetically('playerNickname'))
              .map(player => (
                <option key={player.playerId} value={player.playerId}>
                  {`${player.playerNickname} | ${player.playerFirstName} "${player.playerNickname}" ${player.playerLastName}`}
                </option>
              ))}
          </SelectField>
          <PlayerList
            value={field.value.playersTeamA}
            removePlayer={playerId => {
              this.removePlayerFromTeam(playerId, 'A');
            }}
          />
        </Col>

        <Col lg={6}>
          <SelectField
            onChange={ev => {
              this.addPlayerToTeam(ev.target.value, 'B');

              // Reset select value
              const evTarget = ev.target;
              evTarget.value = '';
            }}
            name="players.playersTeamB"
            onBlur={form.handleBlur}
          >
            <option selected>Selecione um jogador</option>
            {availablePlayers
              .sort(orderAlphabetically('playerNickname'))
              .map(player => (
                <option key={player.playerId} value={player.playerId}>
                  {`${player.playerNickname} | ${player.playerFirstName} "${player.playerNickname}" ${player.playerLastName}`}
                </option>
              ))}
          </SelectField>

          <PlayerList
            value={field.value.playersTeamB}
            removePlayer={playerId => {
              this.removePlayerFromTeam(playerId, 'B');
            }}
          />
        </Col>
      </Row>
    );
  }
}
