import React, { Fragment } from 'react';

// 3rd
import { Col, Row } from 'reactstrap';
import SelectFormik from '../../../../components/SelectFormik';
import FontAwesome from 'react-fontawesome';

// Components
import Button from '../../../../components/Button';

// enums
import { VETO_ACTIONS } from '../../../../redux/match-detail';
import { required } from '../../../../utils/form-validators';
import styled from 'styled-components';

const VetoesFieldArray = props => {
  const {
    value,
    teamA,
    teamAName,
    teamB,
    teamBName,
    maps,
    form: { setFieldValue, handleChange, handleBlur, touched, errors },
    push,
    remove,
  } = props;

  // MD1 presets
  const md1Preset1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md1Preset1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md1Preset2 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md1Preset2Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const ESLPresetMD1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.RANDOM, map: '' },
  ];

  const ESLPresetMD1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.RANDOM, map: '' },
  ];

  const BLASTPresetMD1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const BLASTPresetMD1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const SUMMITPresetMD1 = [
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
  ];

  const SUMMITPresetMD1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
  ];

  // MD2 presets
  const md2Preset1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
  ];

  const md2Preset1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
  ];

  // MD3 presets
  const md3Preset1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md3Preset1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md3Preset2 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md3Preset2Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const ESLPresetMD3 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.RANDOM, map: '' },
  ];

  const ESLPresetMD3Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.RANDOM, map: '' },
  ];

  // MD5 Presets
  const md5Preset1 = [
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const md5Preset1Reverse = [
    { teamId: teamB, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.BAN, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamB, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: teamA, action: VETO_ACTIONS.PICK, map: '' },
    { teamId: 'N/A', action: VETO_ACTIONS.LEFT, map: '' },
  ];

  const presetVetoes = preset => {
    setFieldValue('vetoes', preset);
  };

  return (
    <Fragment>
      <p>MD1</p>
      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Bans alternados e restante (Padrão)"
        onClick={() => {
          presetVetoes(md1Preset1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="2 bans A, 3 bans B, 1 ban A e restante"
        onClick={() => {
          presetVetoes(md1Preset2);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset ESL MD1"
        onClick={() => {
          presetVetoes(ESLPresetMD1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset BLAST MD1"
        onClick={() => {
          presetVetoes(BLASTPresetMD1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset SUMMIT MD1"
        onClick={() => {
          presetVetoes(SUMMITPresetMD1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Bans alternados e restante (Padrão) [invertido]"
        onClick={() => {
          presetVetoes(md1Preset1Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="2 bans A, 3 bans B, 1 ban A e restante [invertido]"
        onClick={() => {
          presetVetoes(md1Preset2Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset ESL MD1 [invertido]"
        onClick={() => {
          presetVetoes(ESLPresetMD1Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset BLAST MD1 [invertido]"
        onClick={() => {
          presetVetoes(BLASTPresetMD1Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset SUMMIT MD1 [invertido]"
        onClick={() => {
          presetVetoes(SUMMITPresetMD1Reverse);
        }}
      />

      <p>MD2</p>
      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Ban, ban, pick e pick"
        onClick={() => {
          presetVetoes(md2Preset1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Ban, ban, pick e pick [invertido]"
        onClick={() => {
          presetVetoes(md2Preset1Reverse);
        }}
      />

      <p>MD3</p>
      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Bans/picks alternados e restante (Padrão)"
        onClick={() => {
          presetVetoes(md3Preset1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Ban A, Ban B, Pick A, Pick B, Ban B, Ban A e restante"
        onClick={() => {
          presetVetoes(md3Preset2);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset ESL MD3"
        onClick={() => {
          presetVetoes(ESLPresetMD3);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Bans/picks alternados e restante (Padrão) [invertido]"
        onClick={() => {
          presetVetoes(md3Preset1Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Ban A, Ban B, Pick A, Pick B, Ban B, Ban A e restante [invertido]"
        onClick={() => {
          presetVetoes(md3Preset2Reverse);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset ESL MD3 [invertido]"
        onClick={() => {
          presetVetoes(ESLPresetMD3Reverse);
        }}
      />

      <p>MD5</p>

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset MD5 (Padrão)"
        onClick={() => {
          presetVetoes(md5Preset1);
        }}
      />

      <Button
        skin="accent"
        size="micro"
        type="button"
        className="NewMatchForm__preset-btn"
        text="Preset MD5 (Padrão) [invertido]"
        onClick={() => {
          presetVetoes(md5Preset1Reverse);
        }}
      />

      <hr className="NewMatchForm__divider" />

      {value.length === 0 && <p>Nenhuma regra de veto adicionada.</p>}

      {value.map((member, index) => (
        <VetoesFieldContainer>
          {/* eslint-disable-next-line react/no-array-index-key */}
          <Row key={`vetoes.${index}`}>
            <Col lg={4}>
              <SelectFormik
                name={`vetoes.${index}.teamId`}
                label="Equipe"
                onChange={handleChange}
                value={member.teamId}
              >
                <option value="" />
                <option value={teamA}>{teamAName}</option>
                <option value={teamB}>{teamBName}</option>
                <option value="N/A">N/A</option>
              </SelectFormik>
            </Col>
            <Col lg={3}>
              <SelectFormik
                name={`vetoes.${index}.action`}
                label="Ação"
                onChange={handleChange}
                validate={[required]}
                value={member.action}
              >
                <option value="" />
                <option value={VETO_ACTIONS.PICK}>Escolheu</option>
                <option value={VETO_ACTIONS.BAN}>Vetou</option>
                <option value={VETO_ACTIONS.LEFT}>Restou</option>
                <option value={VETO_ACTIONS.RANDOM}>Aleatório</option>
              </SelectFormik>
            </Col>
            <Col lg={4}>
              <SelectFormik
                name={`vetoes.${index}.map`}
                label="Mapa"
                className="NewMatchForm__text-field"
                onChange={handleChange}
                value={member.map}
              >
                <option value="" />
                {maps.map(map => (
                  <option key={map.mapId} value={map.mapId}>
                    {map.mapName}
                  </option>
                ))}
              </SelectFormik>
            </Col>
            <Col lg={1}>
              <a
                className="NewMatchForm__label NewMatchForm__label--button"
                onClick={() => remove(index)}
                role="button"
              >
                <FontAwesome name="times" />
              </a>
            </Col>
          </Row>
        </VetoesFieldContainer>
      ))}

      <hr className="NewMatchForm__divider" />

      <Button
        skin="accent"
        size="small"
        block
        type="button"
        text="Adicionar veto"
        onClick={() => push({ teamId: teamA, action: VETO_ACTIONS.BAN })}
      />
    </Fragment>
  );
};

export default VetoesFieldArray;

const VetoesFieldContainer = styled.div`
  margin-bottom: 16px;
`;
