const TOGGLE_SIDEBAR = 'draft5-admin/ui/TOGGLE_SIDEBAR';

const initialState = {
  sidebarIsVisible: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarIsVisible: !state.sidebarIsVisible,
      }
    }

    default: {
      return state;
    }
  }
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}
