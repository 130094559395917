// React
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './redux';

// Moment
import 'moment/locale/pt-br';

// Material UI
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiThemeProvider } from 'material-ui/styles';

// Store Configuration
const enhancer = compose(applyMiddleware(thunkMiddleware));
const store = createStore(rootReducer, enhancer);

// Material UI Theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0088ff',
    },
    secondary: {
      light: '#262f37',
      main: '#15171b',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
