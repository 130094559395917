import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// 3rd party components
import { TextField, Checkbox } from 'redux-form-material-ui';
import SelectField from '../../../components/SelectField';
import { Col, Row } from 'reactstrap';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import FileUploadInput from '../../../components/FileUploadInput';

// Utils
import { required } from '../../../utils/form-validators';
import countries from '../../../utils/countries';

// css
import './styles.css';
import { S3_BASE_URL } from '../../../utils/constants';

class TeamForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePreview: '',
    };
  }

  _updateImagePreview = (fileObj) => {
    const reader = new FileReader();

    reader.readAsDataURL(fileObj);

    reader.onload = (ev) => {
      this.setState({
        imagePreview: ev.target.result,
      });
    };
  };

  render() {
    const {
      handleSubmit,
      valid,
      data,
    } = this.props;

    return (
      <form className="TeamForm" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>
            <Card>
              <Field
                name="teamName"
                fullWidth
                floatingLabelText="Nome da Equipe"
                validate={required}
                component={TextField}
                floatingLabelFixed
                className="TeamForm__input"
              />

              <Field
                name="teamCountry"
                component={SelectField}
                validate={required}
                label="País da Equipe"
                className="TeamForm__input"
              >
                {
                  countries.map(country => (
                    <option value={country.label}>{country.fullName}</option>
                  ))
                }
              </Field>

              <Field
                name="isRecommended"
                label="Recomendar equipe no onboarding"
                component={Checkbox}
                className="AddTop"
              />

              <label
                className="TeamForm__label"
                htmlFor="teamLogo"
              >
                Logo da Equipe
              </label>

              {/* ========================== */}
              {/* ======== OLD LOGO ======== */}
              {/* ========================== */}
              {
                data && data.teamLogo &&
                <img
                  className="TeamForm__existing-logo"
                  src={`${S3_BASE_URL}/${data.teamLogo}`}
                  alt={data.teamName}
                  width="30%"
                />
              }

              {
                data && !data.teamLogo &&
                <p className="TeamForm__existing-logo">Nenhum logo cadastrado para esta equipe</p>
              }

              {/* ========================== */}
              {/* ====== LOGO UPLOAD ======= */}
              {/* ========================== */}
              <Field
                name="teamLogo"
                component={FileUploadInput}
                className="TeamForm__input"
                onChange={(e, field) => this._updateImagePreview(field)}
              />

              <img src={this.state.imagePreview} width="50%" alt="" />

              <Button
                text="Enviar"
                skin="accent"
                size="small"
                block
                className="TeamForm__submit"
                disabled={!valid}
              />
            </Card>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { data } = ownProps;

  return data ?
    {
      initialValues: {
        teamName: data.teamName,
        teamCountry: data.teamCountry,
        teamLogo: data.teamLogo,
        isRecommended: !!data.isRecommended,
      },
    } :
    {
      initialValues: {
        teamName: '',
        teamCountry: '',
        teamLogo: '',
        isRecommended: false,
      },
    };
}

export default connect(mapStateToProps)(reduxForm({ form: 'teamForm' })(TeamForm));
