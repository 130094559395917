import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from '../../../components/Card';
import { Formik, Form, Field } from 'formik';
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import styled from 'styled-components';
import SelectPool from '../../../components/SelectPool';
import TeamsService from '../../../services/teams';
import StreamsService from '../../../services/streams';
import Button from '../../../components/Button';
import * as Yup from 'yup';
import moment from 'moment';
import { S3_BASE_URL } from '../../../utils/constants';
import PositionEditor from '../components/PositionEditor';

const TournamentForm = ({ initialData, onFormSubmit }) => {
  const [availableTeams, setAvailableTeams] = useState([]);
  const [availableStreams, setAvailableStreams] = useState([]);
  const [newFile, setNewFile] = useState(null);

  useEffect(() => {
    const teamsPromise = TeamsService.getTeams(1, 10000);
    const streamsPromise = StreamsService.getStreams(1, 10000);

    Promise.all([teamsPromise, streamsPromise]).then(([teams, streams]) => {
      setAvailableTeams(teams.data.data);
      setAvailableStreams(streams.data.data);
    });
  }, []);

  const formatAvailableTeamsArray = teamsArray =>
    teamsArray.map(team => ({
      key: team.teamName,
      value: team.teamId,
    }));

  const formatPositionsArray = positionsArray =>
    positionsArray.map(position => ({
      key: position.teamName,
      value: position.teamId,
    }));

  const formatAvailableStreamsArray = streamsArray =>
    streamsArray.map(stream => {
      let streamKey;
      if (stream.streamInternalName) {
        streamKey = `${stream.streamInternalName} - ${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}`;
      } else {
        streamKey = `${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}`;
      }

      return {
        key: streamKey,
        value: stream.streamId,
      };
    });

  if (availableTeams.length === 0 && availableStreams.length === 0) return '';
  return (
    <Container fluid>
      <Formik
        initialValues={{
          tournamentName: initialData ? initialData.tournamentName : '',
          generalInfo: initialData ? initialData.generalInfo : '',
          tournamentStart:
            initialData && initialData.tournamentStart
              ? moment(initialData.tournamentStart, 'X').format('YYYY-MM-DD')
              : '',
          tournamentEnd:
            initialData && initialData.tournamentEnd
              ? moment(initialData.tournamentEnd, 'X').format('YYYY-MM-DD')
              : '',
          tournamentImage: initialData ? initialData.tournamentImage : '',
          coverageName: initialData ? initialData.coverageName : '',
          coverageUrl: initialData ? initialData.coverageUrl : '',
          teams: initialData
            ? formatAvailableTeamsArray(initialData.teams)
            : [],
          streams: initialData
            ? formatAvailableStreamsArray(initialData.streams)
            : [],
          isFinished: initialData ? !!initialData.isFinished : false,
          isFeatured: initialData ? !!initialData.isFeatured : false,
          isTournamentHided: initialData ? !!initialData.isTournamentHided : false,
          positions: initialData
            ? formatPositionsArray(initialData.positions)
            : [],
          isLan: initialData ? !!initialData.isLan : false,
          isInternationalSpot: initialData
            ? !!initialData.isInternationalSpot
            : false,
        }}
        validationSchema={Yup.object().shape({
          tournamentName: Yup.string().required(
            'Nome do campeonato é obrigatório'
          ),
          generalInfo: Yup.string(),
          tournamentStart: Yup.date().required(
            'Data de início do campeonato é obrigatória'
          ),
          tournamentEnd: Yup.date().required(
            'Data final de campeonato é obrigatória'
          ),
          coverageName: Yup.string(),
          coverageUrl: Yup.string(),
          teams: Yup.array(),
          streams: Yup.array(),
          isFinished: Yup.boolean(),
          isFeatured: Yup.boolean(),
          isTournamentHided: Yup.boolean(),
          isLan: Yup.boolean(),
          isInternationalSpot: Yup.boolean(),
        })}
        enableReinitialize
        onSubmit={values => {
          onFormSubmit(values, newFile);
        }}
      >
        {({ values, handleChange, errors, touched, isValid }) => (
          <Form>
            <Row>
              <Col lg={3}>
                <StyledCard title="Status">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isFinished}
                        onChange={handleChange}
                        name="isFinished"
                        color="primary"
                        value={values.isFinished}
                        error={errors.isFinished && touched.isFinished}
                      />
                    }
                    label="Campeonato Finalizado"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isFeatured}
                        onChange={handleChange}
                        name="isFeatured"
                        color="primary"
                        value={values.isFeatured}
                        error={errors.isFeatured && touched.isFeatured}
                      />
                    }
                    label="Destaque"
                  />
                <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isTournamentHided}
                        onChange={handleChange}
                        name="isTournamentHided"
                        color="primary"
                        value={values.isTournamentHided}
                        error={errors.isTournamentHided && touched.isTournamentHided}
                      />
                    }
                    label="Esconder campeonato"
                  />
                </StyledCard>

                <StyledCard title="Ranking">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isLan}
                        onChange={handleChange}
                        name="isLan"
                        color="primary"
                        value={values.isLan}
                        error={errors.isLan && touched.isLan}
                      />
                    }
                    label="LAN"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isInternationalSpot}
                        onChange={handleChange}
                        name="isInternationalSpot"
                        color="primary"
                        value={values.isInternationalSpot}
                        error={
                          errors.isInternationalSpot &&
                          touched.isInternationalSpot
                        }
                      />
                    }
                    label="Vaga pra Fora?"
                  />
                </StyledCard>
                <StyledCard title="Datas">
                  <FieldContainer>
                    <span>Data de Início</span>
                    <TextField
                      name="tournamentStart"
                      type="date"
                      defaultValue="2017-05-24"
                      fullWidth
                      value={values.tournamentStart}
                      onChange={handleChange}
                      error={errors.tournamentStart && touched.tournamentStart}
                    />
                  </FieldContainer>
                  <span>Data de Término</span>
                  <TextField
                    name="tournamentEnd"
                    type="date"
                    defaultValue="2017-05-24"
                    fullWidth
                    value={values.tournamentEnd}
                    onChange={handleChange}
                    error={errors.tournamentEnd && touched.tournamentEnd}
                  />
                </StyledCard>
                {/* <StyledCard title="Cobertura">
                  <FieldContainer>
                    <TextField
                      label="Nome do Veículo"
                      placeholder="Digite..."
                      fullWidth
                      name="coverageName"
                      value={values.coverageName}
                      onChange={handleChange}
                      error={errors.coverageName && touched.coverageName}
                    />
                  </FieldContainer>
                  <TextField
                    label="URL"
                    placeholder="Digite..."
                    fullWidth
                    name="coverageUrl"
                    value={values.coverageUrl}
                    onChange={handleChange}
                    error={errors.coverageUrl && touched.coverageUrl}
                  />
                </StyledCard> */}
              </Col>
              <Col lg={9}>
                <StyledCard title="Informações do Campeonato">
                  <FieldContainer>
                    <TextField
                      fullWidth
                      label="Nome do Campeonato"
                      name="tournamentName"
                      value={values.tournamentName}
                      onChange={handleChange}
                      error={errors.tournamentName && touched.tournamentName}
                    />
                  </FieldContainer>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Descrição do Campeonato"
                    name="generalInfo"
                    value={values.generalInfo}
                    onChange={handleChange}
                    error={errors.generalInfo && touched.generalInfo}
                  />

                  <small>
                    Suporte a markdown: <strong>**bold**</strong>,{' '}
                    <i>_itálico_</i>
                  </small>
                </StyledCard>
                <StyledCard title="Imagem do Campeonato">
                  <FieldContainer>
                    <Field
                      type="file"
                      name="tournamentImageNew"
                      onChange={event => {
                        setNewFile(event.target.files[0]);
                      }}
                    />
                  </FieldContainer>

                  {/* ========================== */}
                  {/* ======== OLD LOGO ======== */}
                  {/* ========================== */}
                  {values.tournamentImage && (
                    <Fragment>
                      <span>Imagem atual:</span> <br />
                      <img
                        src={`${S3_BASE_URL}/${values.tournamentImage}`}
                        alt={values.tournamentName}
                        width="60%"
                      />
                    </Fragment>
                  )}
                </StyledCard>
                <Row>
                  <Col lg={6}>
                    <StyledCard title="Equipes">
                      <Field
                        name="teams"
                        component={SelectPool}
                        data={formatAvailableTeamsArray(availableTeams)}
                        value={values.teams}
                      />
                    </StyledCard>
                  </Col>
                  {/* <Col lg={6}>
                    <StyledCard title="Streams">
                      <Field
                        name="streams"
                        component={SelectPool}
                        data={formatAvailableStreamsArray(availableStreams)}
                        value={values.streams}
                      />
                    </StyledCard>
                  </Col> */}
                  <Col lg={6}>
                    <StyledCard title="Posições">
                      <Field
                        name="positions"
                        component={PositionEditor}
                        teams={values.teams}
                        value={values.positions}
                      />
                    </StyledCard>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  text="Enviar"
                  disabled={!isValid}
                  block
                  skin="accent"
                  type="submit"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TournamentForm;

const FieldContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;
