import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import LoadMask from '../../../components/LoadMask';
import Header from '../../../components/Header';
import RankingForm from '../shared/RankingForm';

// redux
import { editRanking, getRankingDetail } from '../../../redux/ranking-detail';

class EditRanking extends Component {
  componentDidMount() {
    const { dispatch, match: { params: { rankingId } } } = this.props;

    dispatch(getRankingDetail(rankingId));
  }

  _onRankingSubmit = (values) => {
    const { dispatch, history, match: { params: { rankingId } } } = this.props;

    dispatch(editRanking(values, rankingId))
      .then(
        // Success
        () => {
          toast('Ranking editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/ranking`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const {
      editRankingUI,
      rankingDetailUI,
      rankingDetail,
    } = this.props;

    return (
      <Fragment>
        { (editRankingUI.isFetching || rankingDetailUI.isFetching) && <LoadMask /> }
        <Header title="Editar Ranking" />
        <div className="EditRanking PageContainer">
          <RankingForm
            data={rankingDetail}
            enableReinitialize
            onSubmit={this._onRankingSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    editRankingUI: {
      isFetching: state.rankingDetail.addNewRanking.isFetching,
      error: state.rankingDetail.addNewRanking.error,
    },
    rankingDetail: state.rankingDetail.rankingDetail.data,
    rankingDetailUI: {
      isFetching: state.rankingDetail.rankingDetail.isFetching,
      error: state.rankingDetail.rankingDetail.error,
    },
  };
}

export default withRouter(connect(mapStateToProps)(EditRanking));
