import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BetHouseTableOrderRow from './BetHouseTableOrderRow';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

export default class BetHouseTableOrder extends Component {

  onDragEnd = result => {
    const { destination, source } = result;

    // if no change or no destination
    if (!destination) return;
    if (destination.index === source.index) return;

    // reorder state
    const newList = Array.from(this.props.data);
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, this.props.data[source.index]);

    this.props.updateOrder(newList);
  };

  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <TableHeader>
          <FontAwesome name="bars" />
          <Position>#</Position>
          <THead stretch>Casa de Apostas</THead>
          <THead>Avaliação</THead>
        </TableHeader>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppableId">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {data.map((betHouse, index) => {
                  return (
                    <BetHouseTableOrderRow
                      key={`key_${betHouse.betHouseId}`}
                      betHouse={betHouse}
                      index={index}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Fragment>
    );
  }
}

const TableHeader = styled.div`
  display: flex;

  padding: 16px 16px 16px 8px;
  border-bottom: 1px solid var(--gray-lightest);
  border-left: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const THead = styled.div`
  font-weight: bold;
  flex-basis: 10%;
  text-align: center;

  ${props => (props.stretch ? 'flex: 1' : '')};
  ${props => (props.stretch ? 'text-align: left' : '')}
`;

const Position = styled.div`
  padding: 0 16px;
  color: var(--gray);
  font-size: 12px;
`;