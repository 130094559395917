import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Card from '../../../components/Card';
import useRankingEditions from '../hooks/useRankingEditions';
import SelectFormik from '../../../components/SelectFormik';
import BonusAndPenaltiesFieldArray from './BonusAndPenaltiesFieldArray';
import Button from '../../../components/Button';
import SelectedTournamentsFieldArray from './SelectedTournamentsFieldArray';
import TeamTransfersFieldArray from './TeamTransfersFieldArray';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';

const RankingForm = props => {
  const [rankings, rankingsIsLoading, rankingsError] = useRankingEditions();

  return (
    <Formik
      initialValues={{
        baseRankingId: props.initialData.baseRankingId || '',
        tournaments: props.initialData.tournaments || [],
        bonusAndPenalties: props.initialData.bonusAndPenalties || [],
        teamTransfers: props.initialData.teamTransfers || [],
      }}
      validationSchema={Yup.object().shape({
        baseRankingId: Yup.number().required('Obrigatório'),
        tournaments: Yup.array(
          Yup.object({
            id: Yup.number().required(),
            distribution: Yup.string().required(),
          })
        ).required('Obrigatório'),
        bonusAndPenalties: Yup.array(
          Yup.object({
            type: Yup.string().required(),
            percent: Yup.number().required(),
            description: Yup.string().required(),
            teamId: Yup.number().required(),
          })
        ),
        teamTransfers: Yup.array(
          Yup.object({
            from: Yup.number().required(),
            to: Yup.number().required(),
          })
        ),
      })}
      enableReinitialize
      onSubmit={values => {
        props.onSubmit(values);
      }}
    >
      {({ values, handleChange, isValid, errors, touched }) => {
        // useEffect use is valid here, since we are rendering a new component
        useEffect(() => {
          props.saveFormState(values);
        }, [values]);

        return (
          <Form>
            <Container fluid>
              <Row>
                <Col lg={3}>
                  <Card title="Ranking Base">
                    <SelectFormik
                      name="baseRankingId"
                      label="Ranking"
                      onChange={handleChange}
                      disabled={rankingsIsLoading || rankingsError}
                      value={values.baseRankingId}
                    >
                      <option value="" selected />
                      {rankings.map(ranking => (
                        <option key={ranking.id} value={ranking.id}>
                          {ranking.id} - {ranking.rankingDate}
                        </option>
                      ))}
                    </SelectFormik>
                    {errors.baseRankingId && touched.baseRankingId && (
                      <FormHelperText>{errors.baseRankingId}</FormHelperText>
                    )}
                  </Card>
                  <br />
                  <Card title="Tipos de Distribuição">
                    <strong>3=4:</strong>
                    <p>45%, 20%, 10%, 10%, 5%, 5%, 2.5%, 2.5%</p>

                    <strong>Normal:</strong>
                    <p>45%, 20%, 12.5%, 7.5%, 5%, 5%, 2.5%, 2.5%</p>

                    <strong>Qualifier:</strong>
                    <p>Top 4: 20%, 5 ao 8: 5%</p>

                    <strong>Um e Dois</strong>
                    <p>32.5%, 32.5%, 10%, 10%, 5%, 5%, 2.5%, 2.5%</p>

                    <strong>Igual</strong>
                    <p>Do 1 ao 8: 12.5%</p>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card title="Campeonatos Considerados">
                    <FieldArray
                      name="tournaments"
                      render={helpers => (
                        <SelectedTournamentsFieldArray
                          arrayHelpers={helpers}
                          handleChange={handleChange}
                        />
                      )}
                    />
                    {errors.tournaments && touched.tournaments && (
                      <FormHelperText>{errors.tournaments}</FormHelperText>
                    )}
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card title="Punições e Bônus">
                    <FieldArray
                      name="bonusAndPenalties"
                      render={helpers => (
                        <BonusAndPenaltiesFieldArray
                          arrayHelpers={helpers}
                          handleChange={handleChange}
                        />
                      )}
                    />
                  </Card>
                  <br />
                  <Card title="Transferência de org">
                    <FieldArray
                      name="teamTransfers"
                      render={helpers => (
                        <TeamTransfersFieldArray
                          arrayHelpers={helpers}
                          handleChange={handleChange}
                        />
                      )}
                    />
                  </Card>

                  <br />
                  <Button
                    text="Reiniciar Form"
                    size="small"
                    skin="main"
                    block
                    onClick={() => {
                      props.saveFormState({
                        baseRankingId: '',
                        tournaments: [],
                        bonusAndPenalties: [],
                        teamTransfers: [],
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Button
                    disabled={!isValid}
                    size="large"
                    block
                    type="submit"
                    skin="success"
                    text="Enviar"
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RankingForm;
