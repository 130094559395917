export const transferStatus = {
  CONFIRMED: 1,
  RUMOR: 2,
  DENIED: 3,
};

export const transferStatusToLabel = {
  [transferStatus.CONFIRMED]: 'Confirmado',
  [transferStatus.RUMOR]: 'Rumor',
  [transferStatus.DENIED]: 'Negado',
};
