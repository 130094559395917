import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';

export default class Home extends Component {
  render() {
    return (
      <Fragment>
        <Header title="Home" />
        <div className="PageContainer">
        </div>
      </Fragment>
    );
  }
}
