import React, { Component, Fragment } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import TournamentsService from '../../../../services/tournaments';
import Stage from './Stage';
import DeleteStageModal from './DeleteStageModal';
import DeleteSubstageModal from './DeleteSubstageModal';
import { toast } from 'react-toastify';
import StageFormModal from './StageFormModal';
import SubstageTypeSelectionModal from './SubstageTypeSelectionModal';
import Substage from './Substage';
import LoadMask from '../../../../components/LoadMask';

export default class StageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStageId: null,
      isLoading: false,
      deleteStage: {
        isModalOpen: false,
        stageData: null,
      },
      addStage: {
        isModalOpen: false,
      },
      editStage: {
        isModalOpen: false,
        stageData: null,
      },
      addSubstage: {
        isModalOpen: false,
      },
      deleteSubstage: {
        isModalOpen: false,
        substageData: null,
      },
    };
  }

  handleStageClick = selectedStageId => {
    this.setState({ selectedStageId });
  };

  // Delete Stage
  // ==============================
  handleStageDelete = stageData => {
    this.setState({
      deleteStage: {
        isModalOpen: true,
        stageData,
      },
    });
  };

  deleteStage = stageId => {
    const { tournamentId, onStageUpdate } = this.props;

    this.setState({
      isLoading: true,
    });

    TournamentsService.deleteStage(stageId, tournamentId).then(
      () => {
        toast.success('Stage deletado com sucesso');

        this.setState({
          selectedStageId: null,
          deleteStage: {
            ...this.state.deleteStage,
            isModalOpen: false,
          },
          isLoading: false,
        });

        onStageUpdate();
      },
      e => {
        toast.error(e.response.data.message);
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  // Add Stage
  // ================================
  addStage = (stage) => {
    const { tournamentId, onStageUpdate } = this.props;

    this.setState({
      isLoading: true,
    });

    TournamentsService.addStage(stage, tournamentId).then(
      () => {
        toast.success('Stage adicionado com sucesso');

        this.setState({
          addStage: {
            isModalOpen: false,
          },
          isLoading: false,
        });

        onStageUpdate();
      },
      e => {
        toast.error(e.response.data.message);
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  // Edit Stage
  // =================================
  handleStageEdit = stageData => {
    this.setState({
      editStage: {
        ...this.state.editStage,
        isModalOpen: true,
        stageData,
      },
    });
  };

  editStage = (stage, stageId) => {
    const { tournamentId, onStageUpdate } = this.props;

    this.setState({
      isLoading: true,
    });

    TournamentsService.editStage(stage, stageId, tournamentId).then(
      () => {
        toast.success('Stage editado com sucesso');

        this.setState({
          editStage: {
            isModalOpen: false,
            stageData: null,
          },
          isLoading: false,
        });

        onStageUpdate();
      },
      e => {
        toast.error(e.response.data.message);

        this.setState({
          isLoading: false,
        });
      }
    );
  };

  // Delete Substage
  // ==============================
  handleSubstageDelete = substageData => {
    this.setState({
      deleteSubstage: {
        isModalOpen: true,
        substageData,
      },
    });
  };

  deleteSubstage = (substageId, stageId) => {
    const { tournamentId, onStageUpdate } = this.props;
    TournamentsService.deleteSubstage(substageId, stageId, tournamentId)
      .then(() => {
        toast.success('Substage deletado com sucesso');

        this.setState({
          selectedStageId: null,
          deleteSubstage: {
            ...this.state.deleteSubstage,
            isModalOpen: false,
          },
        });

        onStageUpdate();
      })
      .catch(e => {
        toast.error(
          'Ocorreu um problema ao deletar o registro. Favor tentar novamente mais tarde.'
        );
        console.error(e);
      });
  };

  // Add Substage
  // =====================================
  handleSubstageAdd = () => {
    this.setState({
      addSubstage: {
        isModalOpen: true,
      },
    });
  };

  handleSubstageTypeSelect = substageType => {
    const { onSubstageAdd } = this.props;

    this.setState({
      addSubstage: {
        isModalOpen: false,
      },
    });

    onSubstageAdd(substageType, this.state.selectedStageId);
  };

  render() {
    const {
      selectedStageId,
      deleteStage,
      addStage,
      editStage,
      addSubstage,
      deleteSubstage,
      isLoading,
    } = this.state;

    const { stages, onSubstageSelect, selectedSubstage } = this.props;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Row>
          <Col lg={6}>
            <List component={Paper}>
              {stages
                .sort((a,b)=> a.stagePosition - b.stagePosition)
                .map(stage => (
                  <Stage
                    selectedStageId={selectedStageId}
                    stage={stage}
                    onStageSelect={this.handleStageClick}
                    onStageDelete={this.handleStageDelete}
                    onStageEdit={this.handleStageEdit}
                  />
                ))
              }
              <ListItem
                button
                onClick={() => {
                  this.setState({ addStage: { isModalOpen: true } });
                }}
              >
                <ListItemAvatar>
                  <Avatar>+</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Adicionar novo stage" />
              </ListItem>
            </List>
          </Col>
          <Col lg={6}>
            {selectedStageId && (
              <List component={Paper}>
                {stages
                  .filter(stage => stage.stageId === selectedStageId)[0]
                  .substageData
                  .sort((a, b) => a.substagePosition - b.substagePosition)
                  .map(substage => (
                    <Substage
                      key={substage.substageId}
                      substage={substage}
                      onSubstageSelect={onSubstageSelect}
                      onDeleteSubstage={this.handleSubstageDelete}
                      selectedSubstage={selectedSubstage}
                    />
                  ))}

                <ListItem button onClick={this.handleSubstageAdd}>
                  <ListItemAvatar>
                    <Avatar>+</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Adicionar novo substage" />
                </ListItem>
              </List>
            )}
          </Col>
        </Row>
        {/* ========================= */}
        {/* DIALOGS ================= */}
        {/* ========================= */}
        <DeleteStageModal
          open={deleteStage.isModalOpen}
          stageData={deleteStage.stageData}
          onDeleteStage={this.deleteStage}
          onModalClose={() =>
            this.setState({
              deleteStage: { ...this.state.deleteStage, isModalOpen: false },
            })
          }
        />
        {/* delete substage */}
        <DeleteSubstageModal
          open={deleteSubstage.isModalOpen}
          substageData={deleteSubstage.substageData}
          onDeleteSubstage={this.deleteSubstage}
          onModalClose={() =>
            this.setState({
              deleteSubstage: {
                ...this.state.deleteSubstage,
                isModalOpen: false,
              },
            })
          }
        />
        <StageFormModal
          open={addStage.isModalOpen || editStage.isModalOpen}
          stageData={editStage.stageData}
          onStageAdd={this.addStage}
          onStageEdit={this.editStage}
          onModalClose={() => {
            this.setState({
              addStage: {
                isModalOpen: false,
              },
              editStage: {
                isModalOpen: false,
              },
            });
          }}
        />

        <SubstageTypeSelectionModal
          open={addSubstage.isModalOpen}
          onModalClose={() => {
            this.setState({ addSubstage: { isModalOpen: false } });
          }}
          onSubstageTypeSelect={this.handleSubstageTypeSelect}
        />
      </Fragment>
    );
  }
}
