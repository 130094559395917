import React, { Component, Fragment } from 'react';

import { Select } from '@material-ui/core/';

// css
import './styles.css';

class SelectField extends Component {
  render() {
    const { onChange, value } = this.props.input;

    const { disabled } = this.props;

    return (
      <Fragment>
        <label className="SelectField__label">{this.props.label || ''}</label>
        <Select
          fullWidth
          native
          onChange={e => {
            // redux form hook
            onChange(e.target.value);
            // component prop hook
            if (this.props.onChange) this.props.onChange(e.target.value);
          }}
          value={value}
          disabled={disabled}
        >
          {this.props.children}
        </Select>
      </Fragment>
    );
  }
}

export default SelectField;
