import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

// components
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import TwitchClip from '../../../../../components/TwitchClip';

import './styles.css';

class MatchEvent extends Component {
  componentDidMount() {
    this.twitterTimer = setTimeout(() => {
      if (window.twttr) {
        window.twttr.widgets.load(
          document.getElementById(`tweet-${this.props.data.id}`)
        );
      }
    }, 500);
  }

  componentDidUpdate(oldProps) {
    const { data } = this.props;

    if (oldProps.data.eventTweet !== data.eventTweet && data.eventTweet) {
      // matches the numbers on a Twitter URL
      // https://twitter.com/natusvincere/status/<<<13367329691055636529>>>
      const tweetIdRegExp = new RegExp(/\d+$/);
      const tweetId = tweetIdRegExp.exec(this.props.data.eventTweet)[0];

      // Remove the twitter.js created element from the DOM
      const oldTweet = document
        .getElementById(`tweet-container-${data.id}`)
        .getElementsByClassName(`twitter-tweet-rendered`)[0];
      if (oldTweet) oldTweet.remove();

      // Call twtrr to render new Tweet on span container
      if (window.twttr) {
        window.twttr.widgets.createTweet(
          tweetId,
          document.getElementById(`tweet-container-${data.id}`)
        );
      }
    }
  }

  renderDefaultEvent = () => {
    const { data, onDeleteEvent, onEditEvent } = this.props;

    return (
      <Card className="MatchEvent">
        {data.hasCustomLabel ? (
          <div className="MatchEventLabel MatchEventLabel--custom">
            <p>{data.customLabel}</p>
          </div>
        ) : (
          <div className="MatchEventLabel">
            <p>
              {data.scoreA}:{data.scoreB}
            </p>
            <span className="MatchEventLabel__map">{data.map.name}</span>
          </div>
        )}

        <div className="MatchEventContent">
          {data.featuredTeam && (
            <div className="MatchEventContent__featured-team">
              <img
                src={`https://api-v3.draft5.gg/teams/${data.featuredTeam
                  .teamId || data.featuredTeam.id}/logo`}
                width="50"
                alt=""
              />
              <h3>{data.featuredTeam.teamName || data.featuredTeam.name}</h3>
            </div>
          )}

          {data.title && <h3>{data.title}</h3>}
          {data.description && <ReactMarkdown source={data.description} />}

          {data.eventHighlight && (
            <TwitchClip
              height="320"
              width="100%"
              clipName={data.eventHighlight}
              title={data.title}
              thumb={data.eventHighlightThumb}
            />
          )}

          {data.eventTweet && (
            <span id={`tweet-container-${data.id}`}>
              <blockquote
                data-width="100%"
                id={`tweet-${data.id}`}
                className="twitter-tweet"
              >
                <p lang="pt" dir="ltr">
                  <a href={data.eventTweet}>&nbsp;</a>
                </p>
              </blockquote>
            </span>
          )}
        </div>

        <div className="MatchEventActions">
          {onDeleteEvent && (
            <Button
              icon="trash"
              skin="error"
              size="small"
              onClick={() => {
                onDeleteEvent(data);
              }}
            />
          )}
          {onEditEvent && (
            <Button
              icon="edit"
              skin="main"
              className="AddTop"
              size="small"
              onClick={() => {
                onEditEvent(data);
              }}
            />
          )}
        </div>
      </Card>
    );
  };

  render() {
    return this.renderDefaultEvent();
  }
}

export default MatchEvent;
