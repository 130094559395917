const countries = [
  { label: 'TBA', fullName: 'TBA' },
  { label: 'EU', fullName: 'Europe' },
  { label: 'BR', fullName: 'Brazil' },
  { label: 'FR', fullName: 'France' },
  { label: 'SE', fullName: 'Sweden' },
  { label: 'DK', fullName: 'Denmark' },
  { label: 'US', fullName: 'United States' },
  { label: 'CIS', fullName: 'CIS' },
  { label: 'PL', fullName: 'Poland' },
  { label: 'UA', fullName: 'Ukraine' },
  { label: 'RU', fullName: 'Russian Federation' },
  { label: 'CA', fullName: 'Canada' },
  { label: 'XK', fullName: 'Kosovo' },
  { label: 'GLOBAL', fullName: 'Global' },

  { label: 'AF', fullName: 'Afghanistan' },
  { label: 'AX', fullName: 'Åland Islands' },
  { label: 'AL', fullName: 'Albania' },
  { label: 'DZ', fullName: 'Algeria' },
  { label: 'AS', fullName: 'American Samoa' },
  { label: 'AD', fullName: 'Andorra' },
  { label: 'AO', fullName: 'Angola' },
  { label: 'AI', fullName: 'Anguilla' },
  { label: 'AQ', fullName: 'Antarctica' },
  { label: 'AG', fullName: 'Antigua and Barbuda' },
  { label: 'AR', fullName: 'Argentina' },
  { label: 'AM', fullName: 'Armenia' },
  { label: 'AW', fullName: 'Aruba' },
  { label: 'AU', fullName: 'Australia' },
  { label: 'AT', fullName: 'Austria' },
  { label: 'AZ', fullName: 'Azerbaijan' },
  { label: 'BS', fullName: 'Bahamas' },
  { label: 'BH', fullName: 'Bahrain' },
  { label: 'BD', fullName: 'Bangladesh' },
  { label: 'BB', fullName: 'Barbados' },
  { label: 'BY', fullName: 'Belarus' },
  { label: 'BE', fullName: 'Belgium' },
  { label: 'BZ', fullName: 'Belize' },
  { label: 'BJ', fullName: 'Benin' },
  { label: 'BM', fullName: 'Bermuda' },
  { label: 'BT', fullName: 'Bhutan' },
  { label: 'BO', fullName: 'Bolivia, Plurinational State of' },
  { label: 'BQ', fullName: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'BA', fullName: 'Bosnia and Herzegovina' },
  { label: 'BW', fullName: 'Botswana' },
  { label: 'BV', fullName: 'Bouvet Island' },
  { label: 'IO', fullName: 'British Indian Ocean Territory' },
  { label: 'BN', fullName: 'Brunei Darussalam' },
  { label: 'BG', fullName: 'Bulgaria' },
  { label: 'BF', fullName: 'Burkina Faso' },
  { label: 'BI', fullName: 'Burundi' },
  { label: 'KH', fullName: 'Cambodia' },
  { label: 'CM', fullName: 'Cameroon' },
  { label: 'CV', fullName: 'Cape Verde' },
  { label: 'KY', fullName: 'Cayman Islands' },
  { label: 'CF', fullName: 'Central African Republic' },
  { label: 'TD', fullName: 'Chad' },
  { label: 'CL', fullName: 'Chile' },
  { label: 'CN', fullName: 'China' },
  { label: 'CX', fullName: 'Christmas Island' },
  { label: 'CC', fullName: 'Cocos (Keeling) Islands' },
  { label: 'CO', fullName: 'Colombia' },
  { label: 'KM', fullName: 'Comoros' },
  { label: 'CG', fullName: 'Congo' },
  { label: 'CD', fullName: 'Congo, the Democratic Republic of the' },
  { label: 'CK', fullName: 'Cook Islands' },
  { label: 'CR', fullName: 'Costa Rica' },
  { label: 'CI', fullName: 'Côte d\'Ivoire' },
  { label: 'HR', fullName: 'Croatia' },
  { label: 'CU', fullName: 'Cuba' },
  { label: 'CW', fullName: 'Curaçao' },
  { label: 'CY', fullName: 'Cyprus' },
  { label: 'CZ', fullName: 'Czech Republic' },
  { label: 'DJ', fullName: 'Djibouti' },
  { label: 'DM', fullName: 'Dominica' },
  { label: 'DO', fullName: 'Dominican Republic' },
  { label: 'EC', fullName: 'Ecuador' },
  { label: 'EG', fullName: 'Egypt' },
  { label: 'SV', fullName: 'El Salvador' },
  { label: 'GQ', fullName: 'Equatorial Guinea' },
  { label: 'ER', fullName: 'Eritrea' },
  { label: 'EE', fullName: 'Estonia' },
  { label: 'ET', fullName: 'Ethiopia' },
  { label: 'FK', fullName: 'Falkland Islands (Malvinas)' },
  { label: 'FO', fullName: 'Faroe Islands' },
  { label: 'FJ', fullName: 'Fiji' },
  { label: 'FI', fullName: 'Finland' },
  { label: 'GF', fullName: 'French Guiana' },
  { label: 'PF', fullName: 'French Polynesia' },
  { label: 'TF', fullName: 'French Southern Territories' },
  { label: 'GA', fullName: 'Gabon' },
  { label: 'GM', fullName: 'Gambia' },
  { label: 'GE', fullName: 'Georgia' },
  { label: 'DE', fullName: 'Germany' },
  { label: 'GH', fullName: 'Ghana' },
  { label: 'GI', fullName: 'Gibraltar' },
  { label: 'GR', fullName: 'Greece' },
  { label: 'GL', fullName: 'Greenland' },
  { label: 'GD', fullName: 'Grenada' },
  { label: 'GP', fullName: 'Guadeloupe' },
  { label: 'GU', fullName: 'Guam' },
  { label: 'GT', fullName: 'Guatemala' },
  { label: 'GG', fullName: 'Guernsey' },
  { label: 'GN', fullName: 'Guinea' },
  { label: 'GW', fullName: 'Guinea-Bissau' },
  { label: 'GY', fullName: 'Guyana' },
  { label: 'HT', fullName: 'Haiti' },
  { label: 'HM', fullName: 'Heard Island and McDonald Islands' },
  { label: 'VA', fullName: 'Holy See (Vatican City State)' },
  { label: 'HN', fullName: 'Honduras' },
  { label: 'HK', fullName: 'Hong Kong' },
  { label: 'HU', fullName: 'Hungary' },
  { label: 'IS', fullName: 'Iceland' },
  { label: 'IN', fullName: 'India' },
  { label: 'ID', fullName: 'Indonesia' },
  { label: 'IR', fullName: 'Iran, Islamic Republic of' },
  { label: 'IQ', fullName: 'Iraq' },
  { label: 'IE', fullName: 'Ireland' },
  { label: 'IM', fullName: 'Isle of Man' },
  { label: 'IL', fullName: 'Israel' },
  { label: 'IT', fullName: 'Italy' },
  { label: 'JM', fullName: 'Jamaica' },
  { label: 'JP', fullName: 'Japan' },
  { label: 'JE', fullName: 'Jersey' },
  { label: 'JO', fullName: 'Jordan' },
  { label: 'KZ', fullName: 'Kazakhstan' },
  { label: 'KE', fullName: 'Kenya' },
  { label: 'KI', fullName: 'Kiribati' },
  { label: 'KP', fullName: 'Korea, Democratic People\'s Republic of' },
  { label: 'KR', fullName: 'Korea, Republic of' },
  { label: 'KW', fullName: 'Kuwait' },
  { label: 'KG', fullName: 'Kyrgyzstan' },
  { label: 'LA', fullName: 'Lao People\'s Democratic Republic' },
  { label: 'LV', fullName: 'Latvia' },
  { label: 'LB', fullName: 'Lebanon' },
  { label: 'LS', fullName: 'Lesotho' },
  { label: 'LR', fullName: 'Liberia' },
  { label: 'LY', fullName: 'Libya' },
  { label: 'LI', fullName: 'Liechtenstein' },
  { label: 'LT', fullName: 'Lithuania' },
  { label: 'LU', fullName: 'Luxembourg' },
  { label: 'MO', fullName: 'Macao' },
  { label: 'MK', fullName: 'Macedonia, the former Yugoslav Republic of' },
  { label: 'MG', fullName: 'Madagascar' },
  { label: 'MW', fullName: 'Malawi' },
  { label: 'MY', fullName: 'Malaysia' },
  { label: 'MV', fullName: 'Maldives' },
  { label: 'ML', fullName: 'Mali' },
  { label: 'MT', fullName: 'Malta' },
  { label: 'MH', fullName: 'Marshall Islands' },
  { label: 'MQ', fullName: 'Martinique' },
  { label: 'MR', fullName: 'Mauritania' },
  { label: 'MU', fullName: 'Mauritius' },
  { label: 'YT', fullName: 'Mayotte' },
  { label: 'MX', fullName: 'Mexico' },
  { label: 'FM', fullName: 'Micronesia, Federated States of' },
  { label: 'MD', fullName: 'Moldova, Republic of' },
  { label: 'MC', fullName: 'Monaco' },
  { label: 'MN', fullName: 'Mongolia' },
  { label: 'ME', fullName: 'Montenegro' },
  { label: 'MS', fullName: 'Montserrat' },
  { label: 'MA', fullName: 'Morocco' },
  { label: 'MZ', fullName: 'Mozambique' },
  { label: 'MM', fullName: 'Myanmar' },
  { label: 'NA', fullName: 'Namibia' },
  { label: 'NR', fullName: 'Nauru' },
  { label: 'NP', fullName: 'Nepal' },
  { label: 'NL', fullName: 'Netherlands' },
  { label: 'NC', fullName: 'New Caledonia' },
  { label: 'NZ', fullName: 'New Zealand' },
  { label: 'NI', fullName: 'Nicaragua' },
  { label: 'NE', fullName: 'Niger' },
  { label: 'NG', fullName: 'Nigeria' },
  { label: 'NU', fullName: 'Niue' },
  { label: 'NF', fullName: 'Norfolk Island' },
  { label: 'MP', fullName: 'Northern Mariana Islands' },
  { label: 'NO', fullName: 'Norway' },
  { label: 'OM', fullName: 'Oman' },
  { label: 'PK', fullName: 'Pakistan' },
  { label: 'PW', fullName: 'Palau' },
  { label: 'PS', fullName: 'Palestinian Territory, Occupied' },
  { label: 'PA', fullName: 'Panama' },
  { label: 'PG', fullName: 'Papua New Guinea' },
  { label: 'PY', fullName: 'Paraguay' },
  { label: 'PE', fullName: 'Peru' },
  { label: 'PH', fullName: 'Philippines' },
  { label: 'PN', fullName: 'Pitcairn' },
  { label: 'PT', fullName: 'Portugal' },
  { label: 'PR', fullName: 'Puerto Rico' },
  { label: 'QA', fullName: 'Qatar' },
  { label: 'RE', fullName: 'Réunion' },
  { label: 'RO', fullName: 'Romania' },
  { label: 'RW', fullName: 'Rwanda' },
  { label: 'BL', fullName: 'Saint Barthélemy' },
  { label: 'SH', fullName: 'Saint Helena, Ascension and Tristan da Cunha' },
  { label: 'KN', fullName: 'Saint Kitts and Nevis' },
  { label: 'LC', fullName: 'Saint Lucia' },
  { label: 'MF', fullName: 'Saint Martin (French part)' },
  { label: 'PM', fullName: 'Saint Pierre and Miquelon' },
  { label: 'VC', fullName: 'Saint Vincent and the Grenadines' },
  { label: 'WS', fullName: 'Samoa' },
  { label: 'SM', fullName: 'San Marino' },
  { label: 'ST', fullName: 'Sao Tome and Principe' },
  { label: 'SA', fullName: 'Saudi Arabia' },
  { label: 'SN', fullName: 'Senegal' },
  { label: 'RS', fullName: 'Serbia' },
  { label: 'SC', fullName: 'Seychelles' },
  { label: 'SL', fullName: 'Sierra Leone' },
  { label: 'SG', fullName: 'Singapore' },
  { label: 'SX', fullName: 'Sint Maarten (Dutch part)' },
  { label: 'SK', fullName: 'Slovakia' },
  { label: 'SI', fullName: 'Slovenia' },
  { label: 'SB', fullName: 'Solomon Islands' },
  { label: 'SO', fullName: 'Somalia' },
  { label: 'ZA', fullName: 'South Africa' },
  { label: 'GS', fullName: 'South Georgia and the South Sandwich Islands' },
  { label: 'SS', fullName: 'South Sudan' },
  { label: 'ES', fullName: 'Spain' },
  { label: 'LK', fullName: 'Sri Lanka' },
  { label: 'SD', fullName: 'Sudan' },
  { label: 'SR', fullName: 'Suriname' },
  { label: 'SJ', fullName: 'Svalbard and Jan Mayen' },
  { label: 'SZ', fullName: 'Swaziland' },
  { label: 'CH', fullName: 'Switzerland' },
  { label: 'SY', fullName: 'Syrian Arab Republic' },
  { label: 'TW', fullName: 'Taiwan, Province of China' },
  { label: 'TJ', fullName: 'Tajikistan' },
  { label: 'TZ', fullName: 'Tanzania, United Republic of' },
  { label: 'TH', fullName: 'Thailand' },
  { label: 'TL', fullName: 'Timor-Leste' },
  { label: 'TG', fullName: 'Togo' },
  { label: 'TK', fullName: 'Tokelau' },
  { label: 'TO', fullName: 'Tonga' },
  { label: 'TT', fullName: 'Trinidad and Tobago' },
  { label: 'TN', fullName: 'Tunisia' },
  { label: 'TR', fullName: 'Turkey' },
  { label: 'TM', fullName: 'Turkmenistan' },
  { label: 'TC', fullName: 'Turks and Caicos Islands' },
  { label: 'TV', fullName: 'Tuvalu' },
  { label: 'UG', fullName: 'Uganda' },
  { label: 'AE', fullName: 'United Arab Emirates' },
  { label: 'GB', fullName: 'United Kingdom' },
  { label: 'UM', fullName: 'United States Minor Outlying Islands' },
  { label: 'UY', fullName: 'Uruguay' },
  { label: 'UZ', fullName: 'Uzbekistan' },
  { label: 'VU', fullName: 'Vanuatu' },
  { label: 'VE', fullName: 'Venezuela, Bolivarian Republic of' },
  { label: 'VN', fullName: 'Viet Nam' },
  { label: 'VG', fullName: 'Virgin Islands, British' },
  { label: 'VI', fullName: 'Virgin Islands, U.S.' },
  { label: 'WF', fullName: 'Wallis and Futuna' },
  { label: 'EH', fullName: 'Western Sahara' },
  { label: 'YE', fullName: 'Yemen' },
  { label: 'ZM', fullName: 'Zambia' },
  { label: 'ZW', fullName: 'Zimbabwe' },
];

export default countries;
