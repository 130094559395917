import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Button from '../../components/Button';
import Card from '../../components/Card';
import * as Yup from 'yup';

const FeatureFlagForm = ({ onSubmit, initialData }) => {
  const [emptyValue, setEmptyValue] = React.useState(false);

  return (
    <Formik
      initialValues={{
        name: initialData ? initialData.name : '',
        value: initialData ? initialData.value : '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Nome da chave é obrigatório'),
        value: Yup.string().nullable(),
      })}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, isValid, setFieldValue }) => (
        <Form>
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card title="Feature Flag">
                  <TextField
                    name="name"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    label="Nome da Chave"
                    disabled
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name && errors.name}
                  />

                  <TextField
                    name="value"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    label="Valor da Chave"
                    fullWidth
                    disabled={emptyValue}
                    value={values.value}
                    onChange={handleChange}
                    error={errors.value && touched.value}
                    helperText={errors.value && touched.value && errors.value}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => {
                          if (e.target.checked) {
                            setFieldValue('value', '');
                          } else {
                            setFieldValue('value', initialData.value || '');
                          }

                          setEmptyValue(e.target.checked);
                        }}
                        name="campaignStatus"
                        color="primary"
                      />
                    }
                    label="Desligar chave (campo vazio)"
                  />
                </Card>
                <Button
                  disabled={!isValid}
                  size="large"
                  block
                  type="submit"
                  skin="success"
                  text="Enviar"
                />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default FeatureFlagForm;
