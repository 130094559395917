import React, { Component } from 'react';

// 3rd party components
import { TextField } from 'redux-form-material-ui';

// Components
import Button from '../../components/Button';

// CSS
import './styles.css';

export default class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: ''
    }
  }

  _onInputChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  _onSearch = (e) => {
    e.preventDefault();

    this.props.onSearch(this.state.inputValue);
  }

  render() {
    const { placeholder } = this.props;

    return (
      <form className="Search" onSubmit={this._onSearch}>
        <TextField
          name="SearchInput"
          className="Search__input"
          type="text"
          value={this.state.inputValue}
          onChange={this._onInputChange}
          placeholder={placeholder || 'Buscar'}
        />
        <Button
          skin="accent"
          size="small"
          text="Buscar"
        />
      </form>
    );
  }
}
