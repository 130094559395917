import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

export default class StreamsService {
  static getStreams(page, amount) {
    const url = `${API_BASE_URL}/streams`;

    return axios.get(url, {
      params: { page, amount },
    });
  }
}
