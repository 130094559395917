import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import PlayerForm from './form/PlayersForm';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import PlayersService from '../../services/players';
import { toast } from 'react-toastify';

class AddNewPlayer extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
  }
  onSubmit = (values, image) => {
    this.setState({ loading: true })

    PlayersService.addPlayer(values, image).then(
      // Success
      (resp) => {
        const { playerId } = resp.data.data
        toast('Partida editada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        window.location.replace(`${process.env.PUBLIC_URL}/u/players/editar/${playerId}`);
      },
      // Error
      (error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
        this.setState({ loading: false })
      },
    );
  }

  render() {
    const { loading } = this.state
    return (
      <Fragment>
        <Header title="Adicionar Novo Jogador" />
        {
          loading ?
            (<LoadIndicator />) :
            (
              <div className="AddPlayer PageContainer">
                <PlayerForm onSubmit={this.onSubmit} />
              </div>
            )
        }

      </Fragment>
    );
  }
}

export default AddNewPlayer
