import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';


// components
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import TransferForm from './TransferForm';
import PlayerTransfersService from '../../services/playerTransfers';
import { toast } from 'react-toastify';

class AddNewTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  onSubmit = ({
    playerName,
    playerCountry,
    originTeam,
    destinationTeam,
    transferStatus,
    transferDate,
    relatedNewsUrl,
    isFreeAgent,
    isRetiring,
    isArchived,
    isBenched,
    isInactive,
    role,
  }) => {
    const { dispatch, history } = this.props;

    this.setState({ isLoading: true });

    PlayerTransfersService.addNewTransfer(
      playerName,
      playerCountry,
      originTeam,
      destinationTeam,
      transferStatus,
      transferDate,
      relatedNewsUrl,
      isFreeAgent,
      isRetiring,
      isArchived,
      isBenched,
      isInactive,
      role,
    )
      .then(({ data }) => {
        this.setState({ isLoading: true });

        toast('Transferência cadastrada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        history.push(`${process.env.PUBLIC_URL}/u/transferencias`);
      });
  }

  render() {
    const {
      isLoading,
    } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Adicionar Nova Transferência" />
        <div className="AddNewTransfer PageContainer">
          <TransferForm onSubmit={this.onSubmit} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddNewTransfer);
