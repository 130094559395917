import styled from 'styled-components';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import CampaignsService from '../../services/campaigns';
import DeleteSpot from './DeleteSpot';
import LoadMask from '../../components/LoadMask';
import { toast } from 'react-toastify';

export default class Spots extends Component {
  headers = [
    { title: 'Código do Spot' },
    { title: 'Descrição do Spot' },
    { title: 'Spot AdManager' },
    { title: 'Spot Siprocal' },
    { title: 'Tipo' },
    { title: 'Ações' },
    { title: '' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      spots: [],
      isLoading: true,
      deleteSpot: {
        isModalOpen: false,
        deletedSpot: null,
      },
    };
  }

  componentDidMount() {
    this.getSpots();
  }

  getSpots() {
    CampaignsService.getSpots().then(response => {
      this.setState({
        spots: response.data.data,
        isLoading: false,
      });
    });
  }

  formatSpotsArray() {
    return this.state.spots.map(spot => [
      spot.campaignSpotKey,
      spot.campaignSpotDescription,
      spot.adManagerSlotName,
      spot.siprocalSlotName,
      <div>
            {spot.isSiprocal ?
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__actived-label">Siprocal</span>
              ) :
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__deactived-label">AdManager</span>
              )}
          </div>,
      <NavLink
        to={{
          pathname: `${process.env.PUBLIC_URL}/u/spots/editar/${spot.campaignSpotId}`,
          spot,
        }}
      >
        <Button size="small" text="Editar" block skin="accent" />
      </NavLink>,
      <Button
        size="small"
        text="Excluir"
        block
        skin="error"
        onClick={() => {
          this.setState({
            deleteSpot: {
              isModalOpen: true,
              deletedSpot: spot,
            },
          });
        }}
      />,
    ]);
  }

  handleModalClose = () => {
    this.setState({
      deleteSpot: {
        isModalOpen: false,
        deletedSpot: null,
      },
    });
  };

  onDelete = spotId => {
    this.setState({ isLoading: true });
    CampaignsService.deleteSpot(spotId)
      .then(() => {
        toast.success('Spot deletado com sucesso');
        this.setState({
          isLoading: false,
          deleteSpot: {
            isModalOpen: false,
            deleteSpot: null,
          },
        });

        this.getSpots();
      })
      .catch(err => {
        toast.error(err.response.data.message || err.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { deleteSpot, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Spots" />
        <div className="PageContainer">
          <Menu>
            <NavLink to={`${process.env.PUBLIC_URL}/u/spots/novo`}>
              <Button
                size="small"
                text="+ Adicionar Spot"
                block
                skin="success"
              />
            </NavLink>
          </Menu>
          <Table headers={this.headers} data={this.formatSpotsArray()} />

          <DeleteSpot
            open={deleteSpot.isModalOpen}
            deletedSpot={deleteSpot.deletedSpot}
            handleClose={this.handleModalClose}
            onDelete={this.onDelete}
          />
        </div>
      </Fragment>
    );
  }
}

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;
