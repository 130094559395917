import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  untouch,
} from 'redux-form';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton } from 'material-ui/RadioButton';
import {
  TextField,
  Toggle,
  SelectField,
  RadioButtonGroup,
} from 'redux-form-material-ui';
import { Col, Row } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

// components
import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import MarkdownInput from '../../../../../components/MarkdownInput';

// utils
import { required } from '../../../../../utils/form-validators';

// css
import './styles.css';

class BroadcastEventForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTweetFormHidden: props.data ? !props.data.eventTweet : true,
      isTwitchFormHidden: props.data ? !props.data.eventHighlight : true,
    };

    this.labelPresets = [
      'Pause tático',
      'Pause técnico',
      'Fim de jogo',
      'Pré Jogo',
      'Pós Jogo',
      'Começa o jogo!',
      'Começa o segundo mapa',
      'Começa o terceiro mapa',
      'Começa o quarto mapa',
      'Começa o quinto mapa',
      'Virada de half',
      'Intervalo de mapas',
    ];
  }

  componentDidUpdate(newProps) {
    if (this.props.activeEventType !== newProps.activeEventType) {
      this.props.reset();
    }
  }

  onFormSubmit = values => {
    const { handleSubmit } = this.props;
    handleSubmit(values);

    this.resetSomeFields('eventForm', {
      title: '',
      description: '',
      featuredTeam: '',
      eventHighlight: '',
      eventHighlightTitle: '',
      eventTweet: '',
      hasCustomLabel: false,
      customLabel: '',
    });
  };

  resetSomeFields = (formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      this.props.dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));

      this.props.dispatch(untouch(formName, fieldKey));
    });
  };

  renderDefaultEventForm = () => {
    const { hasCustomLabel, valid, matchData, onCancel } = this.props;

    const { isTweetFormHidden, isTwitchFormHidden } = this.state;

    return (
      <form onSubmit={this.onFormSubmit} className="EventForm">
        <Card className="EventForm__card" title="Detalhes">
          <Field
            name="title"
            component={TextField}
            hintText="Título (opcional)"
            floatingLabelText="Título (opcional)"
            fullWidth
            onChange={() => this.props.pageHasChanged(true)}
          />

          <Field
            name="description"
            component={MarkdownInput}
            hintText={
              isTweetFormHidden && isTwitchFormHidden
                ? 'Descrição'
                : 'Descrição (opcional)'
            }
            floatingLabelText={
              isTweetFormHidden && isTwitchFormHidden
                ? 'Descrição'
                : 'Descrição (opcional)'
            }
            multiLine
            validate={isTweetFormHidden && isTwitchFormHidden ? required : []}
            rows={3}
            fullWidth
            onChange={() => this.props.pageHasChanged(true)}
          />
        </Card>

        <Card className="EventForm__card" title="Equipe Destacada">
          <Field
            name="featuredTeam"
            component={RadioButtonGroup}
            onChange={() => this.props.pageHasChanged(true)}
          >
            <RadioButton value="" label="N/A" />
            <RadioButton
              value={matchData.teamA.teamId.toString()}
              label={matchData.teamA.teamName}
            />
            <RadioButton
              value={matchData.teamB.teamId.toString()}
              label={matchData.teamB.teamName}
            />
          </Field>
        </Card>

        <Card className="EventForm__card" title="Label">
          <Field
            name="hasCustomLabel"
            component={Toggle}
            label="Label Customizada"
            labelPosition="right"
            onChange={() => this.props.pageHasChanged(true)}
          />

          {!hasCustomLabel && (
            <Fragment>
              <Row>
                <Col lg={4}>
                  <Field
                    name="scoreA"
                    component={TextField}
                    hintText={`Placar ${matchData.teamA.teamName}`}
                    floatingLabelText={`Placar ${matchData.teamA.teamName}`}
                    validate={!hasCustomLabel ? required : []}
                    fullWidth
                    onChange={() => this.props.pageHasChanged(true)}
                  />
                </Col>
                <Col lg={4}>
                  <Field
                    name="scoreB"
                    component={TextField}
                    hintText={`Placar ${matchData.teamB.teamName}`}
                    floatingLabelText={`Placar ${matchData.teamB.teamName}`}
                    validate={!hasCustomLabel ? required : []}
                    fullWidth
                    onChange={() => this.props.pageHasChanged(true)}
                  />
                </Col>
                <Col lg={4}>
                  <Field
                    name="map"
                    component={SelectField}
                    hintText="Mapa"
                    floatingLabelText="Mapa"
                    validate={!hasCustomLabel ? required : []}
                    fullWidth
                    onChange={() => this.props.pageHasChanged(true)}
                  >
                    {matchData.scores.map(({ map }) => (
                      <MenuItem value={map.mapId} primaryText={map.mapName} />
                    ))}
                  </Field>
                </Col>
              </Row>
            </Fragment>
          )}

          {hasCustomLabel && (
            <Fragment>
              <Field
                name="customLabel"
                component={TextField}
                hintText="Label"
                floatingLabelText="Label"
                fullWidth
                validate={hasCustomLabel ? required : []}
                onChange={() => this.props.pageHasChanged(true)}
              />
              <div className="EventForm__label-presets">
                {this.labelPresets.map(preset => (
                  <Button
                    outline
                    size="micro"
                    type="button"
                    className="NewMatchForm__particularity-preset-btn"
                    text={preset}
                    onClick={() => {
                      this.props.dispatch(
                        change('eventForm', 'customLabel', preset)
                      );
                    }}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </Card>

        <Card className="EventForm__card" title="Adicionais">
          <Button
            className="EventForm__media-btn"
            outline={isTweetFormHidden}
            skin={isTweetFormHidden ? '' : 'accent'}
            size="small"
            onClick={ev => {
              ev.preventDefault();
              if (!isTweetFormHidden)
                this.props.dispatch(change('eventForm', 'eventTweet', ''));
              this.setState({ isTweetFormHidden: !isTweetFormHidden });
            }}
            text={<FontAwesome name="twitter" />}
          />

          <Button
            className="EventForm__media-btn"
            outline={isTwitchFormHidden}
            skin={isTwitchFormHidden ? '' : 'accent'}
            size="small"
            onClick={ev => {
              ev.preventDefault();
              if (!isTwitchFormHidden)
                this.props.dispatch(change('eventForm', 'eventHighlight', ''));
              this.setState({ isTwitchFormHidden: !isTwitchFormHidden });
            }}
            text={<FontAwesome name="twitch" />}
          />

          {!isTwitchFormHidden && (
            <div className="EventForm__field-icon-container">
              <FontAwesome name="twitch" />
              <Field
                name="eventHighlight"
                component={TextField}
                validate={!isTwitchFormHidden ? required : []}
                hintText="https://clips.twitch.tv/ID"
                floatingLabelText="Clip da Twitch"
                fullWidth
              />

              <Field
                name="eventHighlightTitle"
                component={TextField}
                validate={!isTwitchFormHidden ? required : []}
                hintText="5k FalleN para impedir entrada B"
                floatingLabelText="Título do Clip"
                fullWidth
              />
            </div>
          )}

          {!isTweetFormHidden && (
            <div className="EventForm__field-icon-container">
              <FontAwesome name="twitter" />
              <Field
                name="eventTweet"
                component={TextField}
                validate={!isTweetFormHidden ? required : []}
                hintText="twitter.com/USER/status/ID"
                floatingLabelText="Link do Tweet"
                fullWidth
              />
            </div>
          )}
        </Card>

        {onCancel && (
          <Button
            skin="error"
            block
            className="AddBottom"
            text="Cancelar"
            onClick={e => {
              e.preventDefault();
              onCancel();
            }}
          />
        )}

        <Button
          type="Submit"
          skin="success"
          block
          disabled={!valid}
          text="Enviar"
        />
      </form>
    );
  };

  render() {
    const { matchData } = this.props;

    if (!matchData) return '';

    return <Fragment>{this.renderDefaultEventForm()}</Fragment>;
  }
}

function mapStateToProps(state, ownProps) {
  const sharedProps = {
    hasCustomLabel: formValueSelector('eventForm')(state, 'hasCustomLabel'),
  };

  const getTeamId = (team) => {
    if(!team) return ""
    if(team.id) return team.id
    if(team.teamId) return team.teamId
    return ""
  }

  if (ownProps.data) {
    const { data } = ownProps;
    return {
      ...sharedProps,
      initialValues: {
        title: data.title,
        description: data.description,
        scoreA: data.scoreA,
        scoreB: data.scoreB,
        map: data.map ? data.map.id : '',
        featuredTeam: getTeamId(data.featuredTeam),
        eventHighlight: data.eventHighlight
          ? `https://clips.twitch.tv/${data.eventHighlight}`
          : '',
        eventHighlightTitle: data.eventHighlightTitle
          ? data.eventHighlightTitle
          : '',
        eventTweet: data.eventTweet,
        hasCustomLabel: data.hasCustomLabel,
        customLabel: data.customLabel,
      },
    };
  }

  return sharedProps;
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'eventForm' })(BroadcastEventForm)
);
