import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd
import { Field, reduxForm, FieldArray } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

// components
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';

// utils
import { required } from '../../../../utils/form-validators';
import MarkdownInput from '../../../../components/MarkdownInput';

class ArticleForm extends Component {
  renderArticleBlocksArray = ({ fields }) => (
    <Fragment>
      {
        fields.length === 0 &&
        <Card className="AddTop">
          Nenhum bloco de texto adicionado.
        </Card>
      }

      {
        fields.map((member, index) => (
          <Card className="AddTop" key={member}>
            <div className="AddBottom" style={styles.articleBlockActions}>
              #{index + 1}

              <Button
                icon="times"
                text="Remover Bloco"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.pageHasChanged();
                  fields.remove(index);
                }}
                size="micro"
                skin="error"
              />

              <Button
                icon="arrow-up"
                text="Mover Para Cima"
                size="micro"
                disabled={index === 0}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.pageHasChanged();
                  if (index > 0) fields.swap(index, index - 1);
                }}
                skin="accent"
              />

              <Button
                icon="arrow-down"
                text="Mover Para Baixo"
                disabled={index === fields.length - 1}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.pageHasChanged();
                  if (index < fields.length - 1) fields.swap(index, index + 1);
                }}
                size="micro"
                skin="accent"
              />
            </div>

            <Field
              floatingLabelFixed
              onChange={()=>this.props.pageHasChanged()}
              name={`${member}.title`}
              component={TextField}
              hintText="Título"
              floatingLabelText="Título"
              fullWidth
            />
            <Field
              floatingLabelFixed
              onChange={()=>this.props.pageHasChanged()}
              name={`${member}.text`}
              component={MarkdownInput}
              hintText="Texto"
              floatingLabelText="Texto"
              fullWidth
              multiLine
              validate={required}
              rows={5}
            />
            <Field
              onChange={()=>this.props.pageHasChanged()}
              floatingLabelFixed
              name={`${member}.highlight`}
              component={TextField}
              hintText="Highlight"
              floatingLabelText="Highlight"
              fullWidth
            />
          </Card>
        ))
      }

      <Button
        skin="accent"
        size="small"
        block
        className="AddTop"
        type="button"
        text="Adicionar bloco"
        onClick={() => fields.push({})}
      />
    </Fragment>
  );

  render() {
    const {
      handleSubmit,
      articleData,
      pageHasChanged,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="EventForm">
        <Card title="Artigo">
          <Field
            onChange={()=>pageHasChanged()}
            name="articleTitle"
            component={TextField}
            hintText="Título"
            floatingLabelText="Título"
            fullWidth
            validate={required}
          />

          <Field
            onChange={()=>pageHasChanged()}
            name="articleExcerpt"
            component={TextField}
            hintText="Subtítulo"
            floatingLabelText="Subtítulo"
            fullWidth
            validate={required}
          />
        </Card>

        <FieldArray
          name="blocks"
          component={this.renderArticleBlocksArray}
        />
      </form>
    );
  }
}

const styles = {
  articleBlockActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

function mapStateToProps(state, ownProps) {
  const { articleData } = ownProps;

  if (articleData) {
    return {
      initialValues: {
        articleTitle: articleData.articleTitle,
        articleExcerpt: articleData.articleExcerpt,
        blocks: articleData.blocks.map(block => ({
          ...block,
          highlight: (block.highlight ? `https://clips.twitch.tv/${block.highlight}` : ''),
        })),
      },
    };
  }

  return {};
}

export default connect(mapStateToProps)(reduxForm({ form: 'articleForm' })(ArticleForm));
