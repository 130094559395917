import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Chip,
  MenuItem,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CampaignsService from '../../services/campaigns';

const CampaignForm = ({ onSubmit, initialData }) => {
  const [spots, setSpots] = useState([]);

  useEffect(() => {
    CampaignsService.getSpots().then(response => {
      setSpots(response.data.data);
    });
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        campaignName: initialData ? initialData.campaignName : '',
        campaignStatus: initialData ? initialData.campaignStatus : true,
        campaignStartDate: initialData
          ? moment(initialData.campaignStartDate, 'X').format('YYYY-MM-DD')
          : '',
        campaignStartTime: initialData
          ? moment(initialData.campaignStartDate, 'X').format('HH:mm')
          : '',
        campaignEndDate: initialData
          ? moment(initialData.campaignEndDate, 'X').format('YYYY-MM-DD')
          : '',
        campaignEndTime: initialData
          ? moment(initialData.campaignEndDate, 'X').format('HH:mm')
          : '',
        campaignSpots: initialData
          ? initialData.campaignSpots.map(spot => spot.campaignSpotId)
          : [],
        campaignLink: initialData ? initialData.campaignLink : '',
        campaignImage: initialData ? initialData.campaignImage : '',
      }}
    >
      {({ values, handleChange, errors, touched, setFieldValue, isValid }) => (
        <Form>
          <Row>
            <Col lg={4}>
              <Card title="Dados da Campanha">
                <TextField
                  name="campaignName"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  label="Nome da Campanha"
                  helperText="Será usado como categoria nos eventos no Analytics"
                  fullWidth
                  value={values.campaignName}
                  onChange={handleChange}
                  error={errors.campaignName && touched.campaignName}
                />

                <Row>
                  <Col lg={6}>
                    <TextField
                      name="campaignStartDate"
                      type="date"
                      label="Data de início"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.campaignStartDate}
                      onChange={handleChange}
                      error={
                        errors.campaignStartDate && touched.campaignStartDate
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>

                  <Col lg={6}>
                    <TextField
                      name="campaignStartTime"
                      type="time"
                      label="Horário de Início"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.campaignStartTime}
                      onChange={handleChange}
                      error={
                        errors.campaignStartTime && touched.campaignStartTime
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <TextField
                      name="campaignEndDate"
                      type="date"
                      label="Data de Término"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.campaignEndDate}
                      onChange={handleChange}
                      error={errors.campaignEndDate && touched.campaignEndDate}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>

                  <Col lg={6}>
                    <TextField
                      name="campaignEndTime"
                      type="time"
                      label="Horário de Término"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.campaignEndTime}
                      onChange={handleChange}
                      error={errors.campaignEndTime && touched.campaignEndTime}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Col>
                </Row>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.campaignStatus}
                      onChange={handleChange}
                      name="campaignStatus"
                      color="primary"
                      value={values.campaignStatus}
                      error={errors.campaignStatus && touched.campaignStatus}
                    />
                  }
                  label="Campanha Ativa"
                />
              </Card>
              <br />
              {spots.length > 0 && (
                <Card title="spots">
                  <FormControl>
                    <InputLabel>Spots</InputLabel>
                    <Select
                      multiple
                      name="campaignSpots"
                      value={values.campaignSpots}
                      onChange={ev => {
                        const { value } = ev.target;

                        setFieldValue(
                          'campaignSpots',
                          typeof value === 'string' ? value.split(',') : value
                        );
                      }}
                      input={
                        <OutlinedInput style={{ width: '100%' }} label="Tag" />
                      }
                      renderValue={selected => (
                        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selected.map(value => (
                            <Chip
                              style={{ margin: '4px' }}
                              key={value}
                              label={
                                spots.filter(
                                  spot => spot.campaignSpotId === value
                                )[0].campaignSpotKey
                              }
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {spots.map(spot => (
                        <MenuItem
                          key={spot.campaignSpotId}
                          value={spot.campaignSpotId}
                          style={{
                            fontWeight:
                              values.campaignSpots.indexOf(
                                spot.campaignSpotId
                              ) >= 0
                                ? 'bold'
                                : 'normal',
                          }}
                        >
                          {spot.campaignSpotKey} -{' '}
                          {spot.campaignSpotDescription}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Card>
              )}
            </Col>

            <Col lg={8}>
              <Card title="Dados do Criativo">
                <TextField
                  name="campaignLink"
                  type="text"
                  variant="outlined"
                  label="Link do Banner"
                  helperText="Link que será usado para campanha"
                  fullWidth
                  value={values.campaignLink}
                  onChange={handleChange}
                  error={errors.campaignLink && touched.campaignLink}
                />

                <TextField
                  name="campaignImage"
                  type="text"
                  variant="outlined"
                  label="Imagem do Banner"
                  helperText="Link da S3"
                  fullWidth
                  margin="normal"
                  value={values.campaignImage}
                  onChange={handleChange}
                  error={errors.campaignImage && touched.campaignImage}
                />
              </Card>

              <Button
                disabled={!isValid}
                size="large"
                block
                type="submit"
                skin="success"
                text="Enviar"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CampaignForm;
