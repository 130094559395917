import React from 'react';

// 3rd party components
import { Col, Row } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';

// css
import styled from 'styled-components';

import ReactMde from 'react-mde';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Chip,
  MenuItem,
} from '@material-ui/core';

const custoMarkdownFormik = ({ name, values, }) =>
(
  <Field
    name={name}
    value={values.betHouseDescription}
  >
    {({
      field,
      form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    }) => (
      <ReactMde
        value={field.value}
        onChange={(textValue) => { setFieldValue(name, textValue) }}
      />
    )}
  </Field>
)

const PAYMENTS_FORMS = [
  { key: "PIX", label: "PIX" },
  { key: "BOLETO", label: "Boleto" },
  { key: "VISA", label: "Visa" },
  { key: "MASTERCAD", label: "Mastercard" },
  { key: "AMEX", label: "American Express" },
  { key: "ELO", label: "ELO" },
  { key: "BITCOIN", label: "BitCoin" },
  { key: "PAYPAL", label: "PayPal" },
  { key: "TRANFERENCIA", label: "Transferência Bancária" },
]


const BetHouseForm = ({ initialData, onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialData ? {
        betHouseSlug: initialData.betHouseSlug,
        betHouseName: initialData.betHouseName,
        betHouseIsActive: initialData.betHouseIsActive,
        betHouseViewOrder: initialData.betHouseViewOrder,
        betHouseShortCallRegister: initialData.betHouseShortCallRegister,
        betHouseRating: initialData.betHouseRating,
        betHouseSiteUrl: initialData.betHouseSiteUrl,
        betHouseRegisterUrl: initialData.betHouseRegisterUrl,
        betHouseLogoImageUrl: initialData.betHouseLogoImageUrl,
        betHouseBannerImageUrl: initialData.betHouseBannerImageUrl,
        betHouseDescription: initialData.betHouseDescription,
        betHouseSecuritySection: initialData.betHouseSecuritySection,
        betHouseAccountCreateSection: initialData.betHouseAccountCreateSection,
        betHouseCupomSection: initialData.betHouseCupomSection,
        betHousePayments: initialData.betHousePayments,
        betHouseBenefitsSection: initialData.betHouseBenefitsSection
      } : {
        betHouseSlug: "",
        betHouseName: "",
        betHouseIsActive: true,
        betHouseViewOrder: 0,
        betHouseShortCallRegister: "",
        betHouseRating: 0,
        betHouseSiteUrl: "",
        betHouseRegisterUrl: "",
        betHouseLogoImageUrl: "",
        betHouseBannerImageUrl: "",
        betHouseDescription: "",
        betHouseSecuritySection: "",
        betHouseAccountCreateSection: "",
        betHouseCupomSection: "",
        betHousePayments: [],
        betHouseBenefitsSection: "",
      }}
      validationSchema={Yup.object().shape({
        betHouseSlug: Yup.string()
          .min(3, 'Slug muito curto')
          .max(100, 'Slug muito longo')
          .required('Campo obrigatório'),
        betHouseName: Yup.string()
          .max(250, 'Nome muito longo')
          .required('Campo obrigatório'),
        betHouseShortCallRegister: Yup.string()
          .max(250, 'Chamada para registro muito longa'),
        betHouseRating: Yup.number()
          .max(5, 'A classificação não pode ser maior que 5'),
        betHouseSiteUrl: Yup.string()
          .required()
          .max(250, 'A url do site muito longa (max. 250)'),
        betHouseRegisterUrl: Yup.string()
          .required()
          .max(250, 'A url de registro muito longa (max. 250)'),
        betHouseLogoImageUrl: Yup.string()
          .max(250, 'A url do logo muito longa (max. 250)'),
        betHouseBannerImageUrl: Yup.string()
          .max(250, 'A url do banner muito longa (max. 250)'),
      })}
      className="BetHouseForm"
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ values, handleChange, setFieldValue, errors, touched, isValid }) => (
        <Form>
          <Card>
            <Row>
              <Col md={2}>
                <InputLabel>
                  <Checkbox
                    name="betHouseIsActive"
                    checked={values.betHouseIsActive}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    className="BetHouseForm__checkbox"
                  />
                  Está ativa?
                </InputLabel>
              </Col>

              <Col md={4}>
                <CustomFormInput
                  name="betHouseName"
                  value={values.betHouseName}
                  fullWidth
                  label="Nome da Casa de Apostas"
                  onChange={handleChange}

                  className="BetHouseForm__input"
                  error={errors.betHouseName && touched.betHouseName}
                  helperText={errors.betHouseName}
                />
              </Col>
              <Col md={4}>
                <CustomFormInput
                  name="betHouseSlug"
                  value={values.betHouseSlug}
                  fullWidth
                  label="Slug"
                  onChange={handleChange}

                  className="BetHouseForm__input"
                  error={errors.betHouseSlug && touched.betHouseSlug}
                  helperText={errors.betHouseSlug}
                />
              </Col>
              <Col md={2}>
                <CustomFormInput
                  type="number"
                  step="0.5"
                  max="5"
                  name="betHouseRating"
                  value={values.betHouseRating}
                  fullWidth
                  label="Avaliação"
                  onChange={handleChange}
                  className="BetHouseForm__input"
                  error={errors.betHouseRating && touched.betHouseRating}
                  helperText={errors.betHouseRating}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <CustomFormInput
                  name="betHouseShortCallRegister"
                  value={values.betHouseShortCallRegister}
                  fullWidth
                  label="Descrição curta para chamada de registro"
                  onChange={handleChange}

                  className="BetHouseForm__input"
                  error={errors.betHouseShortCallRegister && touched.betHouseShortCallRegister}
                  helperText={errors.betHouseShortCallRegister}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CustomFormInput
                  name="betHouseSiteUrl"
                  value={values.betHouseSiteUrl}
                  fullWidth
                  label="Link da Casa de Apostas"
                  onChange={handleChange}

                  className="BetHouseForm__input"
                  error={errors.betHouseSiteUrl && touched.betHouseSiteUrl}
                  helperText={errors.betHouseSiteUrl}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CustomFormInput
                  name="betHouseRegisterUrl"
                  value={values.betHouseRegisterUrl}
                  fullWidth
                  label="Link de registro da casa de apostas"
                  onChange={handleChange}
                  className="BetHouseForm__input"
                  error={errors.betHouseRegisterUrl && touched.betHouseRegisterUrl}
                  helperText={errors.betHouseRegisterUrl}
                />
              </Col>
            </Row>
          </Card>
          <div style={{ height: "10px" }} />
          <Card title="Imagens">
            <CustomFormInput
              name="betHouseLogoImageUrl"
              value={values.betHouseLogoImageUrl}
              fullWidth
              label="Link de registro da casa de apostas (64x64)"
              onChange={handleChange}
              className="BetHouseForm__input"
              error={errors.betHouseLogoImageUrl && touched.betHouseLogoImageUrl}
              helperText={errors.betHouseLogoImageUrl}
            />

            <CustomFormInput
              name="betHouseBannerImageUrl"
              value={values.betHouseBannerImageUrl}
              fullWidth
              label="Link da imagem do banner (1170x160)"
              onChange={handleChange}
              className="BetHouseForm__input"
              error={errors.betHouseBannerImageUrl && touched.betHouseBannerImageUrl}
              helperText={errors.betHouseBannerImageUrl}
            />
          </Card>
          <div style={{ height: "10px" }} />
          <Card title="Formas de Pagamento">
            <FormControl>
              <InputLabel>Formas Pagamento</InputLabel>
              <Select
                multiple
                name="betHousePayments"
                value={values.betHousePayments}
                onChange={ev => {
                  const { value } = ev.target;
                  setFieldValue(
                    'betHousePayments',
                    value
                  );
                }}
                input={
                  <OutlinedInput style={{ width: '100%' }} label="Tag" />
                }
                renderValue={selected => (
                  <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <Chip
                        style={{ margin: '4px' }}
                        key={value}
                        label={
                          PAYMENTS_FORMS.filter(
                            payment => payment.key === value
                          )[0].label
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {PAYMENTS_FORMS.map(payment => (
                  <MenuItem
                    key={payment.key}
                    value={payment.key}
                  >
                    {payment.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Card>
          <Card title="Descrição">
            {custoMarkdownFormik({ name: "betHouseDescription", values })}
          </Card>
          <Card title="Secção Segurança">
            {custoMarkdownFormik({ name: "betHouseSecuritySection", values })}
          </Card>
          <Card title="Secção de Vantagens">
            {custoMarkdownFormik({ name: "betHouseBenefitsSection", values })}
          </Card>
          <Card title="Criação de Conta">
            {custoMarkdownFormik({ name: "betHouseAccountCreateSection", values })}
          </Card>
          <Card title="Secção de Cupons">
            {custoMarkdownFormik({ name: "betHouseCupomSection", values })}
          </Card>

          <Card>
            <FormSubmitButton
              size="small"
              className="BetHouseForm__submit"
              text="Enviar"
              disabled={!isValid}
              block
              skin="accent"
              type="submit"
            />
          </Card>
        </Form>
      )}
    </Formik>
  );

}

export default BetHouseForm;

const CustomFormInput = styled(TextField)`
  margin-bottom: 12px;
  width: 100% !important;
`

const FormSubmitButton = styled(Button)`
  margin-top: 16px;
`