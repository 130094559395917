import axios from 'axios';
import moment from 'moment';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';
import formatSubstageBody from '../utils/format-substage-body';

export default class TournamentsService {
  static getTournaments(
    page = 1,
    amount = 10,
    finished = false,
    featured = false,
    team = null,
    searchQuery = null,
    ids = null
  ) {
    const url = `${API_BASE_URL}/tournaments`;

    const params = {
      page,
      amount,
      finished: finished ? 1 : 0,
      featured: featured ? 1 : 0,
      team,
      searchQuery,
      ids,
    };

    return axios.get(url, { params });
  }

  static getTournamentDetail(tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}`;

    return axios.get(url);
  }

  static deleteTournament(tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addTournament(tournamentData, newFile = null) {
    const {
      tournamentName,
      coverageName,
      coverageUrl,
      generalInfo,
      tournamentStart,
      tournamentEnd,
      isFinished,
      isFeatured,
      isTournamentHided,
      isLan,
      isInternationalSpot,
      teams,
      streams,
      tournamentImage,
      positions,
    } = tournamentData;

    const data = new FormData();
    data.append('tournamentName', tournamentName || '');
    data.append('coverageName', coverageName || '');
    data.append('coverageUrl', coverageUrl || '');
    data.append('generalInfo', generalInfo || '');
    data.append('tournamentStart', moment(tournamentStart).format('X'));
    data.append('tournamentEnd', moment(tournamentEnd).format('X'));
    data.append('isFinished', isFinished ? 1 : 0);
    data.append('isFeatured', isFeatured ? 1 : 0);
    data.append('isTournamentHided', isTournamentHided ? 1 : 0);
    data.append('isLan', isLan ? 1 : 0);
    data.append('isInternationalSpot', isInternationalSpot ? 1 : 0);
    data.append('teams', teams.map(team => team.value).join(','));
    data.append('streams', streams.map(stream => stream.value).join(','));
    data.append(
      'positions',
      positions.map(position => position.value).join(',') || ''
    );
    if (newFile) {
      data.append('tournamentImage', newFile);
    } else {
      data.append('tournamentImage', tournamentImage);
    }

    const url = `${API_BASE_URL}/tournaments`;

    const config = {
      onUploadProgress: progEv => {
        console.log(progEv);
      },
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(url, data, config);
  }

  static editTournament(tournamentData, newFile = null, tournamentId) {
    const {
      tournamentName,
      coverageName,
      coverageUrl,
      generalInfo,
      tournamentStart,
      tournamentEnd,
      isFinished,
      isFeatured,
      isTournamentHided,
      isLan,
      isInternationalSpot,
      teams,
      streams,
      tournamentImage,
      positions,
    } = tournamentData;

    const data = new FormData();
    data.append('tournamentName', tournamentName || '');
    data.append('coverageName', coverageName || '');
    data.append('coverageUrl', coverageUrl || '');
    data.append('generalInfo', generalInfo || '');
    data.append('tournamentStart', moment(tournamentStart).format('X'));
    data.append('tournamentEnd', moment(tournamentEnd).format('X'));
    data.append('isFinished', isFinished ? 1 : 0);
    data.append('isFeatured', isFeatured ? 1 : 0);
    data.append('isTournamentHided', isTournamentHided ? 1 : 0);
    data.append('isLan', isLan ? 1 : 0);
    data.append('isInternationalSpot', isInternationalSpot ? 1 : 0);
    data.append('teams', teams.map(team => team.value).join(','));
    data.append('streams', streams.map(stream => stream.value).join(','));
    data.append(
      'positions',
      positions.map(position => position.value).join(',') || ''
    );
    if (newFile) {
      data.append('tournamentImage', newFile);
    } else {
      data.append('tournamentImage', tournamentImage);
    }

    const url = `${API_BASE_URL}/tournaments/${tournamentId}`;

    const config = {
      onUploadProgress: progEv => {
        console.log(progEv);
      },
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.put(url, data, config);
  }

  static getTournamentStages(tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages`;

    return axios.get(url);
  }

  static deleteStage(stageId, tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addStage(stage, tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages`;

    const data = {
      ...stage,
    };

    return axios.post(url, data, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static editStage(stage, stageId, tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}`;
    const data = {
      ...stage,
    };

    return axios.put(url, data, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static deleteSubstage(substageId, stageId, tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/${substageId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static getGroupsSubstages(tournamentId, stageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/groups`;

    return axios.get(url);
  }

  static getSingleSubstage(tournamentId, stageId, substageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/${substageId}`;

    return axios.get(url);
  }

  static addSubstage(values, tournamentId, stageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages`;

    const data = formatSubstageBody(values);

    return axios.post(url, data, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static editSubstage(values, tournamentId, stageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/${values.substageId}`;
    const data = formatSubstageBody(values);

    return axios.put(url, data, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
