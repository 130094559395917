import { useEffect, useState } from 'react';
import TournamentsService from '../../../services/tournaments';

function useTournaments() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const tournamentsPromise = TournamentsService.getTournaments(
      1,
      99999,
      false
    );
    const finishedTournamentsPromise = TournamentsService.getTournaments(
      1,
      100,
      true
    );

    Promise.all([tournamentsPromise, finishedTournamentsPromise])
      .then(([tournaments, finishedTournaments]) => {
        setData(
          [
            ...tournaments.data.data,
            ...finishedTournaments.data.data,
          ].sort((a, b) => (a.tournamentName > b.tournamentName ? 1 : -1))
        );
      })
      .catch(err => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return [data, isLoading, error];
}

export default useTournaments;
