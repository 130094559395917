import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

export default class TeamsService {
  static getTeams(page, amount) {
    const url = `${API_BASE_URL}/teams`;

    return axios.get(
      url,
      {
        params: { page, amount },
      },
    );
  }

  static getTeamById(id, titulars) {
    const url = `${API_BASE_URL}/teams/${id}${titulars ? "?titulars=true" : ""}`;
    return axios.get(
      url
    );
  }
}
