import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const streamDetailSelector = state => state.streamDetail;

// =============
// Selector
// =============
export const getAddNewStreamUi = createSelector(
  streamDetailSelector,
  streamDetail => extractRequestUI(streamDetail.addNewStream),
);

export const getDeleteStreamUi = createSelector(
  streamDetailSelector,
  streamDetail => extractRequestUI(streamDetail.deleteStream),
);

export const getStreamDetailUi = createSelector(
  streamDetailSelector,
  streamDetail => extractRequestUI(streamDetail.streamDetail),
);
