import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

const EmptyBracketMatch = props => (
  <SlotContainer
    onClick={props.onClick}
    height={props.maxHeight / props.matchesPerLevel}
  >
    <EmptyBracketMatchContainer isActive={props.isActive}>
      <TeamSlot isActive={props.isActive}>
        <AddIcon name={props.isActive ? 'pencil' : 'plus-circle'} />
        {props.isActive ? (
          <strong>Editando Partida</strong>
        ) : (
          'Adicionar Partida'
        )}
      </TeamSlot>
    </EmptyBracketMatchContainer>
  </SlotContainer>
);

const SlotContainer = styled.div`
  height: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EmptyBracketMatchContainer = styled.div`
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: 0.2s all;
  border-color: ${props => (props.isActive ? 'var(--accent)' : 'transparent')};

  &:hover {
    border: 1px solid var(--accent);
    color: var(--accent);
  }
`;

const TeamSlot = styled.div`
  background: ${props =>
    props.isActive ? 'var(--accent)' : 'var(--gray-lightest)'};
  border-radius: 5px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${props => (props.isActive ? 'white' : 'var(--gray)')};
  height: 66px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const AddIcon = styled(FontAwesome)`
  font-size: 24px;
  color: var(--gray-lighter);
  margin-right: 4px;
`;

export default EmptyBracketMatch;
