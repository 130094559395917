import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import FeatureFlagsService from '../../services/feature-flags';
import FeatureFlagForm from './FeatureFlagForm';

class EditFeatureFlag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      initialData: props.location.flag,
    };
  }

  componentDidMount() {
    if (!this.props.location.flag) {
      this.fetchInitialData();
    }
  }

  fetchInitialData = () => {
    const { featureFlagId } = this.props.match.params;

    this.setState({ isLoading: true });

    FeatureFlagsService.getSingleFeatureFlag(featureFlagId).then(response => {
      this.setState({
        isLoading: false,
        initialData: response.data.data,
      });
    });
  };

  onSubmit = values => {
    const {
      history,
      match: {
        params: { featureFlagId },
      },
    } = this.props;
    this.setState({ isLoading: true });

    FeatureFlagsService.updateFeatureFlag(featureFlagId, values)
      .then(() => {
        this.setState({ isLoading: false });
        toast.success('Feature flag atualizada com sucesso');
        history.push(`${process.env.PUBLIC_URL}/u/feature-flags`);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        toast.error(err.response.data.error || err.message);
      });
  };

  render() {
    const { isLoading, initialData } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Editar Feature Flag" />
        <div className="PageContainer">
          <FeatureFlagForm onSubmit={this.onSubmit} initialData={initialData} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditFeatureFlag);
