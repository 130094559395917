import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

export default class BetHouseTableOrderRow extends Component {
  render() {
    const { betHouse, index } = this.props;
    return (
      <Draggable
        key={`RowKey_${betHouse.betHouseId}`}
        draggableId={`draggable-${betHouse.betHouseId}`}
        index={index}
      >
        {provided => (
          <Line
            ref={provided.innerRef}
            {...provided.draggableProps}
            rangeColor='#FFF'
          >
            <DragHandler name="bars" {...provided.dragHandleProps} />
            <Position>{index + 1}</Position>
            <TeamInfo>{betHouse.betHouseName}</TeamInfo>
            <TeamStat>{betHouse.betHouseRating}</TeamStat>
          </Line>
        )}
      </Draggable>
    );
  }
}

const DeleteIcon = styled(FontAwesome)`
  color: #dd2c2c;
  opacity: 0.1;
  transition: all 0.2s;
  cursor: pointer;
`;

const Line = styled.div`
  padding: 16px 16px 16px 8px;
  border-bottom: 1px solid var(--gray-lightest);
  border-left: 4px solid ${props => props.rangeColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.rangeColor}19;

  &:hover {
    ${DeleteIcon} {
      opacity: 1;
    }
  }
`;

const DragHandler = styled(FontAwesome)`
  color: var(--gray-lighter);
`;

const Position = styled.small`
  padding: 0 16px;
  color: var(--gray);
  font-size: 12px;
`;

const TeamStat = styled.span`
  flex-basis: 10%;
  text-align: center;
`;

const TeamInfo = styled.div`
  flex: 1;

  & img {
    margin-right: 12px;
  }
`;