import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

// 3rd
import { Col, Row } from 'reactstrap';
import { TextField, Checkbox } from 'redux-form-material-ui';

// components
import Card from '../../../components/Card';
import SelectField from '../../../components/SelectField';
import Button from '../../../components/Button';
import LoadMask from '../../../components/LoadMask';

// services
import TeamsService from '../../../services/teams';

// utils
import countries from '../../../utils/countries';
import { required } from '../../../utils/form-validators';
import { transferStatus, transferStatusToLabel } from '../enums';
import orderAlphabetically from '../../../utils/order-alphabetically';

// css
import './styles.css';

class TransferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true,
      error: '',
    };
  }

  componentDidMount() {
    TeamsService.getTeams(1, 10000).then(({ data }) => {
      this.setState({
        data: data.data,
        isLoading: false,
      });
    });
  }

  handleNoOriginTeam = ev => {
    if (ev.target.checked) {
      this.props.change('originTeam', null);
    }
  };

  render() {
    const {
      handleSubmit,
      invalid,
      isFreeAgent,
      isRetiring,
      noOriginTeam,
      isBenched,
      isInactive,
    } = this.props;

    const { isLoading, data } = this.state;

    return (
      <form className="TransferForm" onSubmit={handleSubmit}>
        {isLoading && <LoadMask />}

        <Row>
          <Col lg={4}>
            <Card className="TransferForm__card" title="Jogador">
              <Field
                name="playerName"
                fullWidth
                floatingLabelText="Nome do Jogador"
                validate={required}
                component={TextField}
                floatingLabelFixed
              />
              <Field
                name="role"
                fullWidth
                floatingLabelText="Função"
                component={TextField}
                floatingLabelFixed
              />
              <Field
                name="playerCountry"
                component={SelectField}
                validate={required}
                label="País do Jogador"
              >
                {countries.map(country => (
                  <option value={country.label}>{country.fullName}</option>
                ))}
              </Field>
            </Card>

            <Card className="TransferForm__card" title="Transferência">
              <Field
                name="transferStatus"
                component={SelectField}
                validate={required}
                label="Status da Transferência"
              >
                <option value="" />
                {Object.keys(transferStatus).map(status => (
                  <option value={transferStatus[status]}>
                    {transferStatusToLabel[transferStatus[status]]}
                  </option>
                ))}
              </Field>

              <Field
                name="transferDate"
                floatingLabelText="Data"
                fullWidth
                floatingLabelFixed
                type="date"
                component={TextField}
                validate={required}
              />

              <Field
                name="isArchived"
                label="Arquivar"
                component={Checkbox}
                className="TransferForm__checkbox"
              />
            </Card>

            <Card className="TransferForm__card" title="Notícia Relacionada">
              <Field
                name="relatedNewsUrl"
                fullWidth
                floatingLabelText="URL da Notícia Relacionada"
                component={TextField}
                floatingLabelFixed
              />
            </Card>
          </Col>

          <Col lg={4}>
            <Card title="Equipe de Origem">
              <Field
                name="originTeam"
                component={SelectField}
                disabled={noOriginTeam}
                validate={noOriginTeam ? [] : required}
                label="Equipe de Origem"
              >
                <option value="" />
                {data.sort(orderAlphabetically('teamName')).map(team => (
                  <option key={team.teamId} value={team.teamId}>
                    {team.teamName}
                  </option>
                ))}
              </Field>

              <Field
                name="noOriginTeam"
                label="Sem Equipe"
                onClick={this.handleNoOriginTeam}
                component={Checkbox}
                className="TransferForm__checkbox"
              />
            </Card>
          </Col>

          <Col lg={4}>
            <Card title="Equipe de Destino">
              <Field
                name="destinationTeam"
                component={SelectField}
                validate={(isFreeAgent || isRetiring || isBenched || isInactive) ? [] : required}
                disabled={isFreeAgent || isRetiring || isBenched || isInactive}
                label="Equipe de Destino"
              >
                <option value="" />
                {data.sort(orderAlphabetically('teamName')).map(team => (
                  <option key={team.teamId} value={team.teamId}>
                    {team.teamName}
                  </option>
                ))}
              </Field>

              <Field
                name="isRetiring"
                label="Aposentando"
                component={Checkbox}
                disabled={isFreeAgent || isBenched || isInactive}
                className="TransferForm__checkbox"
              />
              <Field
                name="isFreeAgent"
                label="Free Agent / Sem Equipe"
                disabled={isRetiring || isBenched || isInactive}
                component={Checkbox}
                className="TransferForm__checkbox"
              />
              <Field
                name="isBenched"
                label="Reserva"
                disabled={isRetiring || isFreeAgent || isInactive}
                component={Checkbox}
                className="TransferForm__checkbox"
              />
              <Field
                name="isInactive"
                label="Inativo"
                disabled={isRetiring || isFreeAgent || isBenched}
                component={Checkbox}
                className="TransferForm__checkbox"
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Button
              type="Submit"
              skin="success"
              block
              text="Enviar"
              disabled={invalid}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { data } = ownProps;

  const commonProps = {
    isRetiring: formValueSelector('transferForm')(state, 'isRetiring'),
    isFreeAgent: formValueSelector('transferForm')(state, 'isFreeAgent'),
    isBenched: formValueSelector('transferForm')(state, 'isBenched'),
    isInactive: formValueSelector('transferForm')(state, 'isInactive'),
    destinationTeam: formValueSelector('transferForm')(state, 'destinationTeam'),
    noOriginTeam: formValueSelector('transferForm')(state, 'noOriginTeam'),
  };

  return data ?
    {
      ...commonProps,
      initialValues: {
        playerName: data.playerName,
        playerCountry: data.playerCountry,
        transferStatus: data.transferStatusId,
        transferDate: moment(data.transferDate * 1000).format('YYYY-MM-DD'),
        relatedNewsUrl: data.relatedNewsUrl,
        originTeam: data.originTeamId,
        destinationTeam: data.destinationTeamId,
        isFreeAgent: data.isFreeAgent,
        isRetiring: data.isRetiring,
        isArchived: data.isArchived,
        isBenched: data.isBenched,
        isInactive: data.isInactive,
        role: data.role,
        noOriginTeam: data.originTeam === null,
      },
    } :
    {
      ...commonProps,
      initialValues: {
        playerName: '',
        playerCountry: '',
        transferStatus: '',
        relatedNewsUrl: '',
        originTeam: '',
        destinationTeam: '',
        isFreeAgent: false,
        isRetiring: false,
        isArchived: false,
        isBenched: false,
        isInactive: false,
        role: '',
      },
    };
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'transferForm' })(TransferForm)
);
