import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

// Components
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import PageNavigator from '../../../components/PageNavigator';
import Flag from '../../../components/Flag';
import Search from '../../../components/Search';
import Header from '../../../components/Header';
import Modal from '../../../components/Modal';
import LoadMask from '../../../components/LoadMask';

// Thunks
import { getTeamList } from '../../../redux/team-list';
import { deleteTeam } from '../../../redux/team-detail';
import { getTeamListUi } from '../../../redux/selectors/team-list';
import { getDeleteTeamUi } from '../../../redux/selectors/team-detail';

// css
import './styles.css';

class TeamList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Nome da Equipe' },
        { title: 'País' },
        { title: 'Recomendada no Onboarding' },
        { title: 'Ações' },
        { title: '' },
      ],
      currentPage: 1,
      currentAmount: 25,
      deleteModalIsVisible: false,
      deleteModalTeamObject: {},
    };
  }

  componentDidMount() {
    this._getTeamList();
  }

  _getTeamList = () => {
    this.props.dispatch(getTeamList(this.state.currentPage, this.state.currentAmount));
  }

  _onAmountChange = (newAmount) => {
    const { currentPage } = this.state;

    this.props.dispatch(getTeamList(currentPage, newAmount));

    this.setState({
      currentAmount: newAmount,
    });
  }

  _onPageChange = (newPage) => {
    const { currentAmount } = this.state;

    if (newPage < 1) return;

    this.props.dispatch(getTeamList(newPage, currentAmount));

    this.setState({
      currentPage: newPage,
    });
  }

  _onSearch = (searchQuery) => {
    const { currentAmount } = this.state;

    this.setState({
      currentPage: 1,
    });

    this.props.dispatch(getTeamList(1, currentAmount, searchQuery));
  }

  _formatTeamListArray = () => (
    this.props.teamList.map((team) => {
      const { teamCountry, teamId } = team;

      return [
        team.teamName,
        <div><Flag country={teamCountry} size={15} /> <span>{teamCountry}</span></div>,
        team.isRecommended ? 'Sim' : '-',
        <NavLink to={`${process.env.PUBLIC_URL}/u/equipes/editar/${teamId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
        <Button size="small" onClick={() => { this._promptDeleteTeam(team); }} text="Excluir" block skin="error" />,
      ];
    })
  );

  _promptDeleteTeam = (team) => {
    this.setState({
      deleteModalTeamObject: team,
      deleteModalIsVisible: true,
    });
  }

  _deleteTeam = (teamId, teamName) => {
    const { dispatch } = this.props;

    dispatch(deleteTeam(teamId))
      .then(
        // Success
        () => {
          toast(`Equipe ${teamName} excluída com sucesso`, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          this._closeModal();
          this._getTeamList();
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  _closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteModalTeamObject: {},
    });
  }

  render() {
    const { ui, deleteTeamUi } = this.props;
    const { deleteModalIsVisible, deleteModalTeamObject } = this.state;

    return (
      <Fragment>
        <Header title="Equipes" />

        { deleteTeamUi.isFetching && <LoadMask /> }
        { ui.isFetching && <LoadMask /> }

        {/* =================================== */}
        {/* =========== DELETE TEAM =========== */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Equipe"
            onBackdropClick={this._closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeModal}
                />
                <Button
                  size="small"
                  text={`Excluir ${deleteModalTeamObject.teamName}`}
                  skin="error"
                  onClick={() => {
                    this._deleteTeam(deleteModalTeamObject.teamId, deleteModalTeamObject.teamName);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir a equipe <strong>{deleteModalTeamObject.teamName}</strong> ?
          </Modal>
        }

        {/* =================================== */}
        {/* ============ TEAM LIST ============ */}
        {/* =================================== */}
        <div className="TeamList PageContainer">
          <div className="TeamList__SearchBar">
            <Search onSearch={this._onSearch} />
          </div>

          <Table
            headers={this.state.headers}
            isFetching={ui.isFetching}
            data={this._formatTeamListArray()}
          />

          {
            !ui.isFetching &&
            <PageNavigator
              currentPage={this.state.currentPage}
              currentAmount={this.state.currentAmount}
              onAmountChange={this._onAmountChange}
              onPageChange={this._onPageChange}
            />
          }

        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamList: state.teams.data,
    ui: getTeamListUi(state),
    deleteTeamUi: getDeleteTeamUi(state),
  };
}

export default connect(mapStateToProps)(TeamList);
