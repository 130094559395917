import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

// Components
import MatchEvent from './MatchEvent';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

// Services
import PlayByPlayService from '../../../../services/playByPlay';
import LoadMask from '../../../../components/LoadMask';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Card from '../../../../components/Card';
import EventForm from './EventForm';

class BroadcastFeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      deleteModalIsVisible: false,
      deleteEventObject: {},
      editModalIsVisible: false,
      editEventObject: {},
      isLoadingDeleteEvent: false,
      isLoadingGetPreviousEvents: false,
      eventAmount: 15,
      eventAmountIncrease: 15,
    };
  }

  componentDidMount() {
    const { socket } = this.props;

    socket.on('update', (newEvent) => {
      this.setState({
        events: [
          newEvent,
          ...this.state.events,
        ],
      });
    });

    socket.on('delete', (deletedEventId) => {
      this.setState({
        events: this.state.events.filter(event => event.id !== parseInt(deletedEventId, 10)),
      });
    });

    socket.on('edit', (eventData) => {
      this.setState({
        events: this.state.events.map((event) => {
          if (event.id === parseInt(eventData.id, 10)) {
            return eventData;
          }

          return event;
        }),
      });
    });

    this.getPreviousEvents();
  }

  getPreviousEvents = () => {
    const { matchId } = this.props;

    this.setState({ isLoadingGetPreviousEvents: true });

    PlayByPlayService.getPreviousEvents(matchId)
      .then(({ data }) => {
        this.setState({
          events: data.data,
          isLoadingGetPreviousEvents: false,
        });
      });
  }

  deleteEvent = (eventId) => {
    const { matchId } = this.props;

    this.setState({ isLoadingDeleteEvent: true });

    PlayByPlayService.deleteEvent(matchId, eventId)
      .then(
        // Success
        () => {
          toast('Evento excluído com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          this.onModalClose('delete');
          // this.getPreviousEvents();
          this.setState({ isLoadingDeleteEvent: false });
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });

          this.onModalClose('delete');
          this.setState({ isLoadingDeleteEvent: false });
        },
      );
  }

  editEvent = (values) => {
    const { matchId, matchData } = this.props;
    const { editEventObject: { id, eventDate } } = this.state;

    PlayByPlayService.editEvent(matchId, values, matchData, id, eventDate)
      .then(
        // Success
        () => {
          toast('Evento editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.props.pageHasChanged(false)

          this.onModalClose('edit');
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  promptAction = (data, type) => {
    if (type === 'delete') {
      this.setState({
        deleteModalIsVisible: true,
        deleteEventObject: data,
      });
    }

    if (type === 'edit') {
      this.setState({
        editModalIsVisible: true,
        editEventObject: data,
      });
    }
  }

  onModalClose = (type) => {
    if (type === 'delete') {
      this.setState({
        deleteModalIsVisible: false,
        deleteEventObject: {},
      });
    }

    if (type === 'edit') {
      this.setState({
        editModalIsVisible: false,
        editEventObject: {},
      });
    }
  }

  increaseEventAmount = () => {
    this.setState({
      eventAmount: this.state.eventAmount + this.state.eventAmountIncrease,
    });
  }

  render() {
    const {
      events,
      socketStatusMessage,
      deleteModalIsVisible,
      deleteEventObject,
      editModalIsVisible,
      editEventObject,
      isLoadingDeleteEvent,
      isLoadingGetPreviousEvents,
      eventAmount,
    } = this.state;

    const {
      matchData,
    } = this.props;

    return (
      <Fragment>
        <p>{socketStatusMessage}</p>

        {/* ======================== */}
        {/* Loading State            */}
        {/* ======================== */}
        {
          isLoadingGetPreviousEvents &&
          <LoadingIndicator />
        }

        {/* ======================== */}
        {/* Empty State              */}
        {/* ======================== */}
        {
          !isLoadingDeleteEvent && events.length === 0 &&
          <Card>Nenhum evento cadastrado para esta partida.</Card>
        }

        {/* ======================== */}
        {/* Normal State             */}
        {/* ======================== */}
        {
          !isLoadingGetPreviousEvents &&
          events.slice(0, eventAmount).map(event => (
            <div key={event.id}>
              <MatchEvent
                onDeleteEvent={(eventData) => { this.promptAction(eventData, 'delete'); }}
                onEditEvent={(eventData) => { this.promptAction(eventData, 'edit'); }}
                data={event}
              />
            </div>
          ))
        }


        {/* ======================== */}
        {/* delete modal             */}
        {/* ======================== */}
        {isLoadingDeleteEvent && <LoadMask text="Deletando Evento..." />}

        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Evento"
            onBackdropClick={() => { this.onModalClose('delete'); }}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="AddRight"
                  onClick={() => { this.onModalClose('delete'); }}
                />
                <Button
                  size="small"
                  text="Excluir Evento"
                  skin="error"
                  onClick={() => {
                    this.deleteEvent(deleteEventObject.id);
                    this.onModalClose('delete');
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir este evento?
            <MatchEvent data={deleteEventObject} />
          </Modal>
        }

        {/* ======================== */}
        {/* edit modal               */}
        {/* ======================== */}
        {
          editModalIsVisible &&
          <Modal
            title="Editar Evento"
            onBackdropClick={() => { this.onModalClose('edit'); }}
          >
            <EventForm
              onSubmit={this.editEvent}
              enableReinitialize
              data={editEventObject}
              matchData={matchData}
              pageHasChanged={(newValue)=>this.props.pageHasChanged(newValue)}
              onCancel={() => { this.onModalClose('edit'); }}
            />
          </Modal>
        }

        {
          eventAmount < events.length &&
          <Button
            size="small"
            block
            skin="accent"
            text="Carregar Mais"
            onClick={this.increaseEventAmount}
          />
        }
      </Fragment>
    );
  }
}

export default BroadcastFeed;
