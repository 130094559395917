import React, { Component } from "react";
import styled from "styled-components";
import Button from '../../../../components/Button';

export default class ListItem extends Component {
  render() {
    const {
      label,
      onRemove
    } = this.props;

    return (
      <Container>
        <ListItemContainer type="button">
          <div>{this.props.children}</div>

          <ListItemLabel>
            {typeof label === "function" && label()}

            {typeof label !== "function" && label}
          </ListItemLabel>
        </ListItemContainer>
        <RemoveButton size="small" onClick={onRemove} text="Excluir" block skin="error" />
      </Container>
    );
  }
}

const Container = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top:5px;
`;

const ListItemContainer = styled.button`
  background: white;
  display: block;
  font-size: 13px;
  border-bottom: 1px solid #f0f0f0;
  padding: 13px 17px;
  color: #565656;
  transition: all 0.2s;
  border: 0;
  border-left: 3px solid transparent;
  border-bottom: 1px solid #f0f0f0;
  flex:3;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right:5px;
  &:hover {
    background: #0084ff;
    color: white;
    border-left: 3px solid #171c21;
    border-bottom: 1px solid #0084ff;
  }

  ${props =>
    props.dark &&
    `
    background: #171c21;
    color: white;
  `}

  ${props =>
    props.active || props.isMain &&
    `
    background: #0084ff;
    color: white;
    border-left: 3px solid #171c21;
    border-bottom: 1px solid #0084ff;
  `}

  ${props =>
    props.small &&
    `
    padding: 8px 10px;
    font-size: 10px;
  `}

  ${props =>
    props.center &&
    `
    text-align: center;
  `}
`;

const ListItemLabel = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-align: left;
`;

const RemoveButton = styled(Button)`
  flex:1;
`