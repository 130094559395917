import React from 'react';

// 3rd party components
import { Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';

// css
import styled from 'styled-components';

import {
  Checkbox,
  InputLabel,
  TextField,
} from '@material-ui/core';



const LiveOddProviderForm = ({ initialData, onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialData ? {
        liveOddProviderSlug: initialData.liveOddProviderSlug,
        liveOddProviderName: initialData.liveOddProviderName,
        liveOddProviderIsActive: initialData.liveOddProviderIsActive,
        liveOddProviderImageUrl: initialData.liveOddProviderImageUrl,
        liveOddProviderPromotional: initialData.liveOddProviderPromotional
      } : {
        liveOddProviderSlug: "",
        liveOddProviderName: "",
        liveOddProviderIsActive: true,
        liveOddProviderImageUrl: "",
        liveOddProviderPromotional: ""
      }}
      validationSchema={Yup.object().shape({
        liveOddProviderSlug: Yup.string()
          .min(3, 'Slug muito curto')
          .max(100, 'Slug muito longo')
          .required('Campo obrigatório'),
        liveOddProviderName: Yup.string()
          .max(250, 'Nome muito longo')
          .required('Campo obrigatório'),
        liveOddProviderImageUrl: Yup.string(),
        liveOddProviderPromotional: Yup.string()
      })}
      className="LiveOddProviderForm"
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ values, handleChange, errors, touched, isValid }) => (
        <Form>
          <Card>
            <Row>
              <Col md={2}>
                <InputLabel>
                  <Checkbox
                    name="liveOddProviderIsActive"
                    checked={values.liveOddProviderIsActive}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    className="LiveOddProviderForm__checkbox"
                  />
                  Está ativo?
                </InputLabel>
              </Col>

              <Col md={4}>
                <CustomFormInput
                  name="liveOddProviderName"
                  value={values.liveOddProviderName}
                  fullWidth
                  label="Nome do Provedor"
                  onChange={handleChange}

                  className="LiveOddProviderForm__input"
                  error={errors.liveOddProviderName && touched.liveOddProviderName}
                  helperText={errors.liveOddProviderName}
                />
              </Col>
              <Col md={4}>
                <CustomFormInput
                  name="liveOddProviderSlug"
                  value={values.liveOddProviderSlug}
                  fullWidth
                  label="Slug (utilizado no servidor)"
                  onChange={handleChange}

                  className="LiveOddProviderForm__input"
                  error={errors.liveOddProviderSlug && touched.liveOddProviderSlug}
                  helperText={errors.liveOddProviderSlug}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomFormInput
                  name="liveOddProviderPromotional"
                  value={values.liveOddProviderPromotional}
                  fullWidth
                  label="Mensagem promocional"
                  onChange={handleChange}

                  className="LiveOddProviderForm__input"
                  error={errors.liveOddProviderPromotional && touched.liveOddProviderPromotional}
                  helperText={errors.liveOddProviderPromotional}
                  multiline
                  minRows={3}
                />
              </Col>
            </Row>

          </Card>
          <div style={{ height: "10px" }} />
          <Card title="Imagens">
            <CustomFormInput
              name="liveOddProviderImageUrl"
              value={values.liveOddProviderImageUrl}
              fullWidth
              label="Link da imagem do provedor de probabilidade (64x64)"
              onChange={handleChange}
              className="LiveOddProviderForm__input"
              error={errors.liveOddProviderImageUrl && touched.liveOddProviderImageUrl}
              helperText={errors.liveOddProviderImageUrl}
            />

          </Card>
          <div style={{ height: "10px" }} />
          <Card>
            <FormSubmitButton
              size="small"
              className="LiveOddProviderForm__submit"
              text="Enviar"
              disabled={!isValid}
              block
              skin="accent"
              type="submit"
            />
          </Card>
        </Form>
      )}
    </Formik>
  );

}

export default LiveOddProviderForm;

const CustomFormInput = styled(TextField)`
  margin-bottom: 12px;
  width: 100% !important;
`

const FormSubmitButton = styled(Button)`
  margin-top: 16px;
`