import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';



class BetHousesServiceCls {
  serviceUrl = `${API_BASE_URL}/bet-houses`;

  getAll({ page, amount, search }) {

    const params = {
      page: page || 1,
      amount: amount || 10,
    };

    if (search) {
      params.search = search;
    }

    return axios.get(this.serviceUrl, { params });
  }


  getById(betHouseId) {
    return axios.get(`${this.serviceUrl}/${betHouseId}`);
  }

  delete(betHouseId) {
    return axios.delete(`${this.serviceUrl}/${betHouseId}`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  create(betHouseBody) {
    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(this.serviceUrl, betHouseBody, config);
  }

  edit(betHouseId, betHouseBody) {
    const url = `${this.serviceUrl}/${betHouseId}`;

    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.put(url, betHouseBody, config);
  }


  editAllOrders(betHouses) {
    const url = `${this.serviceUrl}/update-all-orders`;

    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(url, { betHouses }, config);
  }

}

const BetHousesService = new BetHousesServiceCls();

export default BetHousesService;