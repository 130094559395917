import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_STREAM_LIST = 'draft5-admin/streams/GET_STREAM_LIST';
const GET_STREAM_LIST_SUCCESS = 'draft5-admin/streams/GET_STREAM_LIST_SUCCESS';
const GET_STREAM_LIST_FAILURE = 'draft5-admin/streams/GET_STREAM_LIST_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  isFetching: false,
  error: '',
  data: [],
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STREAM_LIST: {
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    }

    case GET_STREAM_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    }

    case GET_STREAM_LIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _getStreamList() {
  return {
    type: GET_STREAM_LIST,
  };
}

function _getStreamListSuccess(data) {
  return {
    type: GET_STREAM_LIST_SUCCESS,
    data,
  };
}

function _getStreamListFailure(error) {
  return {
    type: GET_STREAM_LIST_FAILURE,
    error,
  };
}

// ========================
// Thunks
// ========================
export function getStreamList(page = 1, amount = 50, search = null) {
  return function (dispatch) {
    dispatch(_getStreamList());

    (async () => {
      try {
        const params = {
          page,
          amount,
          search,
        };

        const url = `${API_BASE_URL}/streams`;
        const response = await axios.get(url, {
          params,
        });

        dispatch(_getStreamListSuccess(response.data.data));
      } catch (error) {
        dispatch(_getStreamListFailure(error.response.data.message));
      }
    })();
  };
}
