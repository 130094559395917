import { TextField } from '@material-ui/core';
import React from 'react';
import Card from '../../../components/Card';

const TeamTransfersFieldArray = ({ arrayHelpers, handleChange }) => {
  const { push, form } = arrayHelpers;
  const fieldValue = form.values.teamTransfers;

  return (
    <div>
      {fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.map((transfers, index) => (
          <>
            <Card>
              <TextField
                name={`teamTransfers.${index}.from`}
                label="De"
                type="text"
                props
                fullWidth
                value={form.values.teamTransfers[index].from}
                onChange={handleChange}
              />

              <br />

              <TextField
                name={`teamTransfers.${index}.to`}
                label="Para"
                type="text"
                props
                fullWidth
                value={form.values.teamTransfers[index].to}
                onChange={handleChange}
              />

              <button type="button" onClick={() => arrayHelpers.remove(index)}>
                -
              </button>
            </Card>
          </>
        ))}
      <button type="button" onClick={() => push({})}>
        +
      </button>
    </div>
  );
};

export default TeamTransfersFieldArray;
