import React, { Component } from 'react';

import FontAwesome from 'react-fontawesome';

// css
import './styles.css';

class ArticleChecklist extends Component {
  render() {
    const {
      shouldTweet,
      tweetText,
      articleTitle,
      articleExcerpt,
      image,
    } = this.props;

    return (
      <div className="ArticleChecklist">
        <div className="ArticleChecklist__item">
          <FontAwesome name={articleTitle ? 'check' : 'times'} /> Título
        </div>
        <div className="ArticleChecklist__item">
          <FontAwesome name={articleExcerpt ? 'check' : 'times'} /> Excerpt
        </div>
        {
          shouldTweet &&
          <div className="ArticleChecklist__item">
            <FontAwesome name={tweetText ? 'check' : 'times'} /> Texto do Tweet
          </div>
        }
        <div className="ArticleChecklist__item">
          <FontAwesome name={image ? 'check' : 'times'} /> Imagem de Destaque
        </div>
      </div>
    );
  }
}

export default ArticleChecklist;
