import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';

// components
import FileUploadInput from '../../../../../components/FileUploadInput';
import Button from '../../../../../components/Button';

// css
import './styles.css';

// utils
import { required } from '../../../../../utils/form-validators';
import PlayByPlayService from '../../../../../services/playByPlay';
import { S3_BASE_URL } from '../../../../../utils/constants';
import LoadMask from '../../../../../components/LoadMask';

class MatchImageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePreview: '',
      isLoading: false,
      uploadedImage: props.matchData.matchImage || props.matchData.image,
      imageTooLarge: false,
    };
  }

  updateImagePreview = fileObj => {
    const reader = new FileReader();

    this.setState({
      imageTooLarge: false,
    });

    if (fileObj.size && fileObj.size > 2000000) {
      toast(
        'Imagem muito grande, por favor selecione uma imagem com menos de 1.5mb',
        {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.INFO,
          hideProgressBar: true,
        }
      );

      this.setState({
        imagePreview: '',
        imageTooLarge: true,
      });

      return;
    }

    const fileObjType = typeof fileObj;

    if (fileObjType !== 'undefined') {
      reader.readAsDataURL(fileObj);

      reader.onload = ev => {
        this.setState({
          imagePreview: ev.target.result,
        });
      };
    } else {
      this.setState({
        imagePreview: '',
      });
    }
  };

  onSubmit = values => {
    const {
      matchData: { matchId },
    } = this.props;

    this.setState({ isLoading: true });

    PlayByPlayService.uploadMatchImage(values, matchId)
      .then(response => {
        this.setState({
          isLoading: false,
          imagePreview: '',
          uploadedImage: response.data.data,
        });
      })
      .catch(e => {
        toast.error(e.response.data.message || e.message || 'Ocorreu um erro');

        this.setState({
          isLoading: false,
        });
      });
  };

  imageFormatSelect = (imgObject) => {
    if(typeof imgObject.matchImage === "string") return imgObject.matchImage
    if(typeof imgObject.image === "string") return imgObject.image
    if(typeof imgObject === "string") return imgObject
    return ""
  }

  render() {
    const { handleSubmit, valid, matchData } = this.props;

    const {
      isLoading,
      imagePreview,
      uploadedImage,
      imageTooLarge,
    } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {isLoading && <LoadMask />}

        <div className="MatchImageForm__upload">
          <Field
            name="newMatchImage"
            component={FileUploadInput}
            validate={[required]}
            onChange={(e, field) => this.updateImagePreview(field)}
          />

          <Button
            type="Submit"
            skin="success"
            size="small"
            disabled={!valid || imageTooLarge}
            text="Enviar"
            block
            className="AddTop"
          />
        </div>

        {imagePreview && (
          <Fragment>
            <h4>Imagem a ser enviada</h4>
            <img src={imagePreview} width="100%" alt="" />
          </Fragment>
        )}

        {(this.imageFormatSelect(uploadedImage || matchData)) && (
          <Fragment>
            <h4>Imagem atual</h4>
            <img
              src={`${S3_BASE_URL}/${this.imageFormatSelect(uploadedImage || matchData)}`}
              width="100%"
              alt={this.imageFormatSelect(uploadedImage || matchData)}
            />
          </Fragment>
        )}
      </form>
    );
  }
}

export default connect(null)(
  reduxForm({ form: 'matchImageForm' })(MatchImageForm)
);
