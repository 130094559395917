import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';

// Components
import Header from '../../components/Header';
import PlayerForm from './form/PlayersForm';
import PlayerHistoryForm from './form/PlayerHistoryForm';
import PlayerHistoryList from './form/PlayerHistoryList';
import LoadIndicator from "../../components/LoadingIndicator"
import SubMenu from "./form/SubMenu";

// Services
import PlayersService from '../../services/players';
import TeamsService from '../../services/teams';
import { toast } from 'react-toastify';

class EditPlayer extends Component {
  constructor() {
    super()
    this.state = {
      teams: [],
      playerDetail: "",
      loading: true,
      activeForm: 1,
      isFormOpen: false,
      targetHistory: {}
    }
  }
  componentDidMount() {

    const urlParams = window.location.href.split("/")
    const playerId = urlParams[urlParams.length - 1]

    Promise.all([
      TeamsService.getTeams(1, 99999),
      PlayersService.getPlayer(playerId)
    ]).then((values) => {
      const teams = values[0].data.data.sort(
        (a, b) => a.teamName > b.teamName ? 1 : -1
      )
      const playerDetail = values[1].data.data
      playerDetail.birthdate = playerDetail.birthdate ? new Date(playerDetail.birthdate) : ""
      this.setState({ playerDetail, teams, loading: false })
    }).catch(error => {
      console.log(error)
    })

  }

  onPlayerEdit = (values, image) => {
    this.setState({ loading: true })
    const urlParams = window.location.href.split("/")
    const playerId = urlParams[urlParams.length - 1]

    PlayersService.editPlayer(values, playerId, image)
      .then(() => PlayersService.getPlayer(playerId))
      .then(({ data }) => {
        const playerDetail = data.data
        playerDetail.birthdate = playerDetail.birthdate ? new Date(playerDetail.birthdate) : ""
        this.setState({ playerDetail })
      })
      .then(
        // Success
        () => {
          toast('player editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.setState({ loading: false, isFormOpen: false })
        }
      ).catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
        this.setState({ loading: false })
      })
  }

  onPlayerHistoryAdd = (values) => {
    this.setState({ loading: true })
    const urlParams = window.location.href.split("/")
    const playerId = urlParams[urlParams.length - 1]

    PlayersService.addPlayerHistory(values, playerId)
      .then(() => PlayersService.getPlayer(playerId)
      ).then(
        // Success
        (player) => {
          toast('player editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.setState({ loading: false, isFormOpen: false, playerDetail: player.data.data })
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
          this.setState({ loading: false })
        },
      );
  }

  onPlayerHistoryEdit = (values, historyId) => {
    this.setState({ loading: true })
    const urlParams = window.location.href.split("/")
    const playerId = urlParams[urlParams.length - 1]

    PlayersService.editPlayerHistory(values, playerId, historyId)
      .then(() => PlayersService.getPlayer(playerId)
      ).then(
        // Success
        (player) => {
          toast('player editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.setState({ loading: false, isFormOpen: false, playerDetail: player.data.data })
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
          this.setState({ loading: false })
        },
      );
  }

  onPlayerHistoryDelete = (historyId) => {
    this.setState({ loading: true })
    const urlParams = window.location.href.split("/")
    const playerId = urlParams[urlParams.length - 1]

    PlayersService.deletePlayerHistory(playerId, historyId)
      .then(() => PlayersService.getPlayer(playerId)
      ).then(
        // Success
        (player) => {
          toast('player editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });
          this.setState({ loading: false, isFormOpen: false, playerDetail: player.data.data })
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
          this.setState({ loading: false })
        },
      );
  }

  changeForm = (isFormOpen = true, targetHistory = {}) => {
    this.setState({ isFormOpen, targetHistory })
  }

  render() {
    const { playerDetail, teams, loading, activeForm, isFormOpen, targetHistory } = this.state;

    return (
      <Fragment>
        <Header title="Editar Player" />
        <SubMenu active={activeForm} changeForm={(data) => { this.setState({ activeForm: data }) }} />
        {
          loading && <LoadIndicator />
        }
        {
          !loading && activeForm === 1 &&
          (
            <div className="EditPlayer PageContainer">
              <PlayerForm
                initialData={playerDetail}
                onSubmit={this.onPlayerEdit}
              />
            </div>
          )
        }
        {
          !loading && activeForm === 2 && isFormOpen &&
          (
            <>
              <div className="EditPlayer PageContainer">
                <PlayerHistoryForm
                  teams={teams}
                  initialData={targetHistory}
                  onPlayerHistoryAdd={this.onPlayerHistoryAdd}
                  onPlayerHistoryEdit={this.onPlayerHistoryEdit}
                />

              </div>
              <Col lg={6}>
                <ResetFormButton onClick={() => this.changeForm(false)}>
                  Fechar Formulario
                </ResetFormButton>
              </Col>
            </>
          )
        }
        {
          !loading && activeForm === 2 && !isFormOpen && (
            <Col lg={6}>
              <NewFormButton onClick={() => this.changeForm()}>
                + Adicionar Historico
              </NewFormButton>
            </Col>
          )
        }
        {
          !loading && activeForm === 2 &&
          (
            <PlayerHistoryList
              list={playerDetail.playerHistory}
              changeForm={this.changeForm}
              deleteHistory={this.onPlayerHistoryDelete}
            />
          )
        }

      </Fragment>
    );
  }
}

export default EditPlayer

const ResetFormButton = styled.button`
  display:flex;
  width: 100%;
  padding:10px;
  margin-bottom: 20px;
  margin-left: 3px;
  background-color:var(--error);
  color: #fff;
  justify-content: center;
  font-weight:bold;
  border:none;
  cursor: pointer;
`

const NewFormButton = styled.button`
  width: 100%;
  display:flex;
  padding:10px;
  margin-bottom: 20px;
  background-color:var(--gray-light);
  justify-content: center;
  font-weight:bold;
  border:none;
  cursor: pointer;
`