import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import MatchCard from './MatchCard';
import FontAwesome from 'react-fontawesome';

export default class Matches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatchFormHidden: true,
      editMatch: {
        index: null,
        data: null,
      },
    };
  }

  availableMatches = (selectedTeams, tournamentMatches, selectedMatches) => {
    const teamsId = selectedTeams.map(team => team.teamData.teamId)
    const selected = selectedMatches.map(match => match.matchId)
    return tournamentMatches.filter(match => (
      teamsId.includes(match.teamA.teamId) && teamsId.includes(match.teamB.teamId) && !selected.includes(match.matchId)
    ))
  }

  renderMatchForm = (initialValues = null, editIndex = null) => (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          match: initialValues ? initialValues.match : '',
        }}
        validationSchema={Yup.object().shape({
          match: Yup.string().required('Campo Obrigatório'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const matches = Array.from(this.props.field.value);

          if (initialValues !== null && editIndex !== null) {
            const newMatches = matches.filter(
              (match, index) => index !== editIndex
            );

            newMatches.push(values.match);
            this.setState(
              {
                isMatchFormHidden: true,
                editMatch: {
                  index: null,
                  data: null,
                },
              },
              () => {
                setSubmitting(false);

                this.props.form.setFieldValue('matches', newMatches);
              }
            );

            return;
          }

          this.setState(
            {
              matches: [...matches, values.match],
              isMatchFormHidden: true,
            },
            () => {
              setSubmitting(false);

              this.props.form.setFieldValue('matches', this.state.matches);
            }
          );
        }}
      >
        {({ values, handleChange, errors, touched, isSubmitting, isValid }) => (
          <MatchForm>
            <Form>
              <FieldContainer>
                <FormControl
                  variant="outlined"
                  size="small"
                  error={errors.match && touched.match}
                >
                  <InputLabel>Partida</InputLabel>
                  <Select
                    id="match"
                    name="match"
                    value={values.matchId}
                    onChange={handleChange}
                    label="Partida"
                    fullWidth
                  >
                    {
                      this.availableMatches(this.props.selectedTeams, this.props.availableMatches, this.props.field.value)
                      .map(match => (
                        <MenuItem key={match.matchId} value={match}>
                          <span>{match.matchId} - {match.teamA.teamName} vs {match.teamB.teamName}</span>
                        </MenuItem>
                      ))
                    }
                    
                  </Select>
                  <FormHelperText>{errors.match}</FormHelperText>
                </FormControl>
              </FieldContainer>

              <FormActions>
                <Button
                  text="Cancelar"
                  block
                  outline
                  size="small"
                  skin="error"
                  onClick={() => {
                    this.setState({
                      isMatchFormHidden: true,
                      editMatch: {
                        index: null,
                        data: null,
                      },
                    });
                  }}
                />
                <Button
                  type="submit"
                  text="Salvar"
                  block
                  size="small"
                  skin="success"
                  disabled={!isValid || isSubmitting}
                />
              </FormActions>
            </Form>
          </MatchForm>
        )}
      </Formik>
    </Fragment>
  );

  onDeleteMatch = index => {
    const matchesArr = Array.from(this.props.field.value);

    matchesArr.splice(index, 1);

    this.props.form.setFieldValue('matches', matchesArr);
  };

  onEditMatch = (data, index) => {
    this.setState({
      isMatchFormHidden: false,
      editMatch: {
        index,
        data,
      },
    });
  };

  render() {
    const { isMatchFormHidden, editMatch } = this.state;
    return (
      <Fragment>
        {this.props.field.value.map((match, index) => (
          <Fragment key={match.matchId}>
            <Match>
              <MatchCard match={match} />
              <MatchActions>
                <DeleteAction
                  name="minus-circle"
                  onClick={() => {
                    this.onDeleteMatch(index);
                  }}
                />
              </MatchActions>
            </Match>
          </Fragment>
        ))}

        {!isMatchFormHidden &&
          this.renderMatchForm(editMatch.data, editMatch.index)}

        {isMatchFormHidden && (
          <Button
            text="Associar nova partida"
            block
            size="small"
            skin="accent"
            onClick={() => {
              this.setState({ isMatchFormHidden: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

const MatchForm = styled(Card)`
  margin: 10px 0;
  border: 1px solid var(--accent);
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  & > * {
    flex-basis: 100%;
  }
`;

const MatchActions = styled.div`
  flex: 1;
  text-align: right;
  opacity: 0;
  transition: all 0.2s;
`;

const Match = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  small {
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
    color: var(--gray);
  }

  span {
    font-size: 14px;
  }

  &:hover {
    ${MatchActions} {
      opacity: 1;
    }
  }
`;

const DeleteAction = styled(FontAwesome)`
  color: var(--error);
  padding-left: 12px;
  cursor: pointer;
`;

const FormActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;
