import React, { Component } from 'react';

// 3rd party components
import { Container, Row, Col } from 'reactstrap';

// css
import './styles.css';

export default class Tabs extends Component {
  render() {
    return (
      <div className={`Tabs ${this.props.className || ''}`}>
        <Container fluid>
          <Row>
            <Col lg={12}>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
