import React, { useState, Fragment, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from '../../../components/Card';
import styled from 'styled-components';
import BracketEditor from '../components/BracketEditor';
import Button from '../../../components/Button';
import EditBracketSlotForm from '../components/BracketEditor/EditBracketSlotForm';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@material-ui/core';
import TournamentsService from '../../../services/tournaments';
import * as Yup from 'yup';
import LevelTitlesEditor from '../components/LevelTitlesEditor';
import { semiFinals, upperLower } from './bracketPresets';

const BracketForm = props => {
  const { matches, selectedSubstage, tournamentId, onSubmit } = props;

  const [activeBracketSlot, setActiveBracketSlot] = useState(null);
  const [data, setSubstageData] = useState(null);

  const formEmptyState = {
    substageName: '',
    substageData: {
      levelTitles: [],
      matches: [],
    },
  };

  const baseHiddenMatch = (substageId, index, matchesData) => ({
    substageId,
    binaryTreeIndex: index,
    isLowerBracket: isFatherLower(matchesData, index),
    fatherLower: isFatherLower(matchesData, index),
    isHidden: true,
    winnerAdvances: false,
    loserEliminated: false,
    seedDefiner: false,
    isFinished: false,
    bestOf: null,
    matchId: null,
    matchDate: null,
    matchScores: { matchScoreA: null, matchScoreB: null },
    series: { scoreA: null, scoreB: null },
    teamA: { id: null, name: null, country: null, logo: null },
    teamB: { id: null, name: null, country: null, logo: null },
  });

  const matchesByLevel = levelCount => {
    return 2 ** levelCount - 1;
  };

  const levelsByMatches = matchesArray => {
    const totalLevels = Math.floor(Math.log2(matchesArray.length + 1));

    return totalLevels > 0 ? totalLevels : 1;
  };

  const isFatherLower = (matchesData, index) => {
    const fatherIndex = Math.floor((index - 1) / 2);
    if (fatherIndex >= 0 && matchesData[fatherIndex]) {
      return matchesData[fatherIndex].isLowerBracket;
    }
    return false;
  };

  const initialMatchData = (matchesData, levelCount) => {
    const totalNodes = matchesByLevel(levelCount);
    const nodes = [];
    for (let count = 0; count < totalNodes; count += 1) {
      if (matchesData[count]) {
        nodes.push(matchesData[count]);
      } else {
        const hiddenMatch = baseHiddenMatch(
          selectedSubstage.substageId,
          count,
          matchesData
        );

        nodes.push(hiddenMatch);
      }
    }
    return nodes;
  };

  useEffect(() => {
    // reset previous set substage
    setSubstageData(null);

    // add new substage
    if (!selectedSubstage.substageId) {
      setSubstageData(formEmptyState);

      return;
    }

    TournamentsService.getSingleSubstage(
      tournamentId,
      selectedSubstage.stageId,
      selectedSubstage.substageId
    ).then(response => {
      const substage = response.data.data;
      setSubstageData(substage);
    });
  }, [selectedSubstage.substageId]);

  if (!data) return '';

  const submit = values => {
    onSubmit(values, 'Brackets', selectedSubstage.substageId);
  };

  return (
    <BracketFormContainer fluid>
      <Formik
        initialValues={{
          substagePosition: data.substagePosition || 0,
          substageName: data.substageName || '',
          levelTitles: data.substageData.levelTitles || [],
          matches:
            initialMatchData(
              data.substageData.matches,
              levelsByMatches(data.substageData.matches)
            ) || [],
          levelCount: levelsByMatches(data.substageData.matches),
        }}
        validationSchema={Yup.object().shape({
          substageName: Yup.string().required('Nome do stage é obrigatório'),
        })}
        onSubmit={values => submit(values)}
        enableReinitialize
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <Form>
            <Row>
              <Col lg={3}>
                <StyledCard title="Nome">
                  <TextField
                    name="substageName"
                    label="Nome do Stage"
                    onChange={handleChange}
                    value={values.substageName}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.substageName && touched.substageName}
                    helperText={errors.substageName}
                  />
                </StyledCard>
              </Col>

              <Col lg={3}>
                <StyledCard title="Posição do stage">
                  <TextField
                    name="substagePosition"
                    label="Posição do Stage"
                    onChange={handleChange}
                    value={values.substagePosition}
                    variant="outlined"
                    type="number"
                    size="small"
                    fullWidth
                    error={errors.substagePosition && touched.substagePosition}
                    helperText={errors.substagePosition}
                  />
                </StyledCard>
              </Col>
              <Col lg={3}>
                <StyledCard title="Presets">
                  <PresetButton
                    size="micro"
                    skin="accent"
                    type="button"
                    text="Semifinais"
                    onClick={() => {
                      const preset = semiFinals(selectedSubstage.substageId);
                      setFieldValue('matches', preset.matches);
                      setFieldValue('levelCount', preset.levelCount);
                      setFieldValue('levelTitles', preset.levelTitles);
                    }}
                  />
                  <PresetButton
                    size="micro"
                    skin="accent"
                    type="button"
                    text="UpperLower"
                    onClick={() => {
                      const preset = upperLower(selectedSubstage.substageId);
                      setFieldValue('matches', preset.matches);
                      setFieldValue('levelCount', preset.levelCount);
                      setFieldValue('levelTitles', preset.levelTitles);
                    }}
                  />
                </StyledCard>
              </Col>
              <Col lg={3}>
                {activeBracketSlot !== null && (
                  <FloatingCard
                    title={`Editar Partida - Index ${activeBracketSlot}`}
                  >
                    <Field
                      component={EditBracketSlotForm}
                      activeBracketSlot={activeBracketSlot}
                      values={values.matches}
                      selectedMatch={
                        values.matches.filter(
                          match => match.binaryTreeIndex === activeBracketSlot
                        )[0]
                      }
                      availableMatches={matches}
                      onMatchSubmit={() => {
                        setActiveBracketSlot(null);
                      }}
                      onMatchEditCancel={() => {
                        setActiveBracketSlot(null);
                      }}
                    />
                  </FloatingCard>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Fragment>
                  <Card title="Bracket">
                    <div style={{ overflowX: 'scroll' }}>
                      <Field name="levelTitles" component={LevelTitlesEditor} />
                      <Field
                        name="matches"
                        component={BracketEditor}
                        matchesByLevel={matchesByLevel}
                        selectedSubstageId={selectedSubstage.substageId}
                        baseHiddenMatch={baseHiddenMatch}
                        onMatchSelect={setActiveBracketSlot}
                        activeMatch={activeBracketSlot}
                      />
                    </div>
                  </Card>
                  <Button
                    text="Salvar Bracket"
                    skin="accent"
                    block
                    disabled={!isValid}
                    type="submit"
                  />
                </Fragment>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </BracketFormContainer>
  );
};

const BracketFormContainer = styled(Container)`
  margin-top: 16px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const PresetButton = styled(Button)`
  margin-bottom: 5px;
`;

const FloatingCard = styled(Card)`
  position: fixed;
  bottom: 15px;
  right: 15px;
  border: 1px solid var(--accent);
  z-index: 999;
  width: 300px;
`;

export default BracketForm;
