import React from 'react';

// 3rd party components
import { Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import Button from '../../../components/Button';
import Card from '../../../components/Card';

// css
import styled from 'styled-components';

import {
  Checkbox,
  InputLabel,
  TextField,
  Chip,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  TextareaAutosize
} from '@material-ui/core';

import SelectFormik from '../../../components/SelectFormik';

import TournamentServices from '../../../services/tournaments';

import { toast } from 'react-toastify';
import moment from 'moment';
import CampaignsService from '../../../services/campaigns';

const HubForm = ({ initialData, saveFuncion, onSubmitSuccess }) => {
  const [allTournaments, setAllTournaments] = React.useState([])
  const [spots, setSpots] = React.useState([]);


  React.useEffect(() => {
    loadExtraData();
  }, [])

  const loadExtraData = async () => {
    try {
      const tournamentsReponse = await TournamentServices.getTournaments(1, 999999, false);
      setAllTournaments(tournamentsReponse.data.data)

      const spotsResponse = await CampaignsService.getSpots();
      const _spots = spotsResponse.data.data;
      _spots.sort((s1, s2) => s1.campaignSpotKey.localeCompare(s2.campaignSpotKey))
      setSpots(_spots);
    } catch (error) {
      toast(error.message, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }
  }

  const addTournament = (tournamentId, tournaments = [], setFieldValue) => {

    if (tournaments.find(t => +t.tournamentId === +tournamentId)) return;

    const tournament = allTournaments.find(t => +t.tournamentId === +tournamentId);

    if (tournament) {
      tournaments.push(tournament);
      if (setFieldValue) setFieldValue('tournaments', tournaments);
    }

  };

  const removeTournament = (index, tournaments = [], setFieldValue) => {

    tournaments.splice(index, 1);
    if (setFieldValue) setFieldValue('tournaments', tournaments);
  };

  const transformStringDateOnUnixTimeStamp = (strDate) => {
    if (!strDate) return null;

    return moment(strDate, "YYYY-MM-DD", false).unix()
  }

  const unixTimeToStrString = (unixTime) => {
    if (!unixTime) return "";

    return moment(unixTime * 1000).format("YYYY-MM-DD")
  }

  const save = async (values) => {
    const startsAt = transformStringDateOnUnixTimeStamp(values.startsAtDate)
    const endsAt = transformStringDateOnUnixTimeStamp(values.endsAtDate)
    try {
      const response = await saveFuncion({ ...values, startsAt, endsAt })
      onSubmitSuccess(response);
    } catch (error) {
      console.error(error);
      const errorMessage = error.response ? error.response.data.error : error.message;
      toast(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }
  }

  const renderSpot = ({ name, values, setFieldValue, title }) => {
    return (
      <div style={{ width: "300px" }}>
        <FormControl>
          <InputLabel style={{ width: "450px" }}>{title}</InputLabel>
          <Select
            name={name}
            value={values[name]}
            onChange={ev => {
              const { value } = ev.target;
              setFieldValue(name, value);
            }}
            input={
              <OutlinedInput style={{ width: "100%" }} label="Tag" />
            }
            style={{ width: "700px" }}
          >
            {spots.map(spot => (
              <MenuItem
                key={spot.campaignSpotKey}
                value={spot.campaignSpotKey}
              >
                {spot.campaignSpotKey} -{' '}
                {spot.campaignSpotDescription}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  const renderImageField = ({ name, values, title, handleChange, errors, touched }) => {
    const actualValue = values[name];
    return (
      <Row>
        <Col>
          <CustomFormInput
            name={name}
            value={actualValue}
            fullWidth
            label={title}
            onChange={handleChange}
            className="HubForm__input"
            error={errors[name] && touched[name]}
            helperText={errors[name]}
          />
        </Col>
        <Col md={2}>
          {!!actualValue && <img src={actualValue} alt={title} style={{ maxHeight: "60px" }} />}
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData ? {
          isActive: initialData.isActive,
          isCtaActive: initialData.isCtaActive,
          slug: initialData.slug,
          primaryColor: initialData.primaryColor,
          secondaryColor: initialData.secondaryColor,
          tertiaryColor: initialData.tertiaryColor,
          bannerUrl: initialData.bannerUrl,
          logoHeaderUrl: initialData.logoHeaderUrl,
          logoSponsorUrl: initialData.logoSponsorUrl,
          logoFooterUrl: initialData.logoFooterUrl,
          logoMatchCardUrl: initialData.logoMatchCardUrl,
          ctaMatchCard: initialData.ctaMatchCard,
          adBackgroundRigth: initialData.adBackgroundRigth,
          adBackgroundLeft: initialData.adBackgroundLeft,
          adSuperbanner: initialData.adSuperbanner,
          adSuperbannerSecondary: initialData.adSuperbannerSecondary,
          adNextMatches: initialData.adNextMatches,
          adResults: initialData.adResults,
          tournaments: initialData.tournaments,
          startsAt: initialData.startsAt,
          endsAt: initialData.endsAt,
          startsAtDate: unixTimeToStrString(initialData.startsAt),
          endsAtDate: unixTimeToStrString(initialData.endsAt),
          faq: initialData.faq,
          title: initialData.title,
          description: initialData.description,
          liveOddProviderSlug: initialData.liveOddProviderSlug,
          sponsorLink: initialData.sponsorLink
        } : {
          isActive: true,
          isCtaActive: true,
          slug: "",
          primaryColor: "",
          secondaryColor: "",
          tertiaryColor: "",
          bannerUrl: "",
          logoHeaderUrl: "",
          logoSponsorUrl: "",
          logoFooterUrl: "",
          logoMatchCardUrl: "",
          ctaMatchCard: "",
          adBackgroundRigth: "",
          adBackgroundLeft: "",
          adSuperbanner: "",
          adSuperbannerSecondary: "",
          adNextMatches: "",
          adResults: "",
          tournaments: [],
          startsAt: null,
          endsAt: null,
          startsAtDate: null,
          endsAtDate: null,
          faq: null,
          title: "",
          description: "",
          liveOddProviderSlug: null,
          sponsorLink: null
        }}
        validationSchema={Yup.object().shape({
          slug: Yup.string()
            .min(3, 'Slug muito curto')
            .max(100, 'Slug muito longo')
            .required('Campo obrigatório'),
          title: Yup.string()
            .min(3, 'Título muito curto')
            .max(255, 'Título muito longo')
            .required('Campo obrigatório'),
          description: Yup.string()
            .max(255, 'Descrição muito longa'),
          primaryColor: Yup.string()
            .required('Campo obrigatório'),
          secondaryColor: Yup.string()
            .required('Campo obrigatório'),
          tertiaryColor: Yup.string()
            .required('Campo obrigatório'),
          bannerUrl: Yup.string()
            .max(250, 'Url do banner muito longa'),
        })}
        className="HubForm"
        onSubmit={(values) => {
          save(values)
        }}
      >
        {({ values, handleChange, setFieldValue, handleBlur, errors, touched, isValid }) => (
          <Form>
            <Card>
              <Row>
                <Col md={2}>
                  <InputLabel>
                    <Checkbox
                      name="isActive"
                      checked={values.isActive}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      color="primary"
                    />
                    Está ativa?
                  </InputLabel>
                </Col>
                <Col md={2}>
                  <InputLabel>
                    <Checkbox
                      name="isCtaActive"
                      checked={values.isCtaActive}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      color="primary"
                    />
                    CTA ativo?
                  </InputLabel>
                </Col>

                <Col md={8}>
                  <CustomFormInput
                    name="slug"
                    value={values.slug}
                    fullWidth
                    label="Slug *"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.slug && touched.slug}
                    helperText={errors.slug}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <CustomFormInput
                    name="title"
                    value={values.title}
                    fullWidth
                    label="Título *"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.title && touched.title}
                    helperText={errors.slug}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <CustomFormInput
                    name="description"
                    value={values.description}
                    fullWidth
                    label="Descrição (Meta)"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.description && touched.description}
                    helperText={errors.description}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CustomFormInput
                    type="date"
                    name="startsAtDate"
                    value={values.startsAtDate}
                    fullWidth
                    label="Data de Início"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.startsAtDate && touched.startsAtDate}
                    helperText={errors.startsAtDate}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col>
                  <CustomFormInput
                    type="date"
                    name="endsAtDate"
                    value={values.endsAtDate}
                    fullWidth
                    label="Data de Fim"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.endsAtDate && touched.endsAtDate}
                    helperText={errors.endsAtDate}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
                <Col>
                  <CustomFormInput
                    name="liveOddProviderSlug"
                    value={values.liveOddProviderSlug}
                    fullWidth
                    label="Slug Live Odds (Separar p/ virgula caso mais de um)"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.liveOddProviderSlug && touched.liveOddProviderSlug}
                    helperText={errors.liveOddProviderSlug}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <CustomFormInput
                    name="ctaMatchCard"
                    value={values.ctaMatchCard}
                    fullWidth
                    label="Descrição curta para card de partida"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.ctaMatchCard && touched.ctaMatchCard}
                    helperText={errors.ctaMatchCard}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <CustomFormInput
                    name="sponsorLink"
                    value={values.sponsorLink}
                    fullWidth
                    label="Link de redirecionamento do patrocinador"
                    onChange={handleChange}
                    className="HubForm__input"
                    error={errors.sponsorLink && touched.sponsorLink}
                    helperText={errors.sponsorLink}
                    InputLabelProps={{ shrink: true }}
                  />
                </Col>
              </Row>
            </Card>
            <div style={{ height: "10px" }} />
            <Card title="Cores">

              <Row>
                <Col>
                  <CustomFormInput
                    type="color"
                    name="primaryColor"
                    value={values.primaryColor}
                    fullWidth
                    label="Cor Primária *"
                    onChange={handleChange}
                    className="BetHouseForm__input"
                    error={errors.primaryColor && touched.primaryColor}
                    helperText={errors.primaryColor}
                  />
                </Col>
                <Col>
                  <CustomFormInput
                    type="color"
                    name="secondaryColor"
                    value={values.secondaryColor}
                    fullWidth
                    label="Cor Secundária *"
                    onChange={handleChange}
                    className="BetHouseForm__input"
                    error={errors.secondaryColor && touched.secondaryColor}
                    helperText={errors.secondaryColor}
                  />
                </Col>
                <Col>
                  <CustomFormInput
                    type="color"
                    name="tertiaryColor"
                    value={values.tertiaryColor}
                    fullWidth
                    label="Cor Terciária *"
                    onChange={handleChange}
                    className="BetHouseForm__input"
                    error={errors.tertiaryColor && touched.tertiaryColor}
                    helperText={errors.tertiaryColor}
                  />
                </Col>
              </Row>
            </Card>
            <div style={{ height: "10px" }} />
            <Card title="Torneios">

              <Row>
                <Col>
                  <SelectFormik
                    name="tournament"
                    label="Torneios"
                    onChange={e => {
                      const tournamentId = e.target.value;
                      addTournament(
                        tournamentId,
                        values.tournaments,
                        setFieldValue
                      );
                    }}
                    value={values.tournament}
                    className="HubForm__text-field"
                    onBlur={handleBlur}
                    error={
                      touched.tournament && Boolean(errors.tournament)
                    }
                    helperText={touched.tournament && errors.tournament}
                  >
                    <option value="" selected />
                    {allTournaments &&
                      allTournaments.length > 0 &&
                      allTournaments.map(tournament => (
                        <option
                          key={tournament.tournamentId}
                          value={tournament.tournamentId}
                        >
                          {tournament.tournamentName}
                        </option>
                      ))}
                  </SelectFormik>
                </Col>
                <Col>
                  <div>
                    Torneios Selecionados
                  </div>

                  {!!values.tournaments && values.tournaments.map((tournament, index) => (
                    <Chip
                      key={tournament.tournamentId}
                      title={tournament.tournamentName}
                      label={tournament.tournamentName}
                      onDelete={() => { removeTournament(index, values.tournaments, setFieldValue) }}
                    />
                  ))}
                </Col>
              </Row>
            </Card>
            <div style={{ height: "10px" }} />
            <Card title="Imagens">
              {renderImageField({ name: "bannerUrl", title: "Link da imagem do banner *", values, handleChange, errors, touched })}
              {renderImageField({ name: "logoHeaderUrl", title: "Link da imagem do logo do cabeçalho", values, handleChange, errors, touched })}
              {renderImageField({ name: "logoSponsorUrl", title: "Link da imagem do logo do patrocinador", values, handleChange, errors, touched })}
              {renderImageField({ name: "logoFooterUrl", title: "Link da imagem do rodapé do patrocinador", values, handleChange, errors, touched })}
              {renderImageField({ name: "logoMatchCardUrl", title: "Link da imagem do card de partida", values, handleChange, errors, touched })}

            </Card>
            <div style={{ height: "10px" }} />
            <Card title="Anúncios" >
              {renderSpot({ name: "adSuperbanner", values, setFieldValue, title: "Chave do spot do super banner" })}
              {renderSpot({ name: "adSuperbannerSecondary", values, setFieldValue, title: "Chave do spot do super banner secundário" })}
              {renderSpot({ name: "adBackgroundRigth", values, setFieldValue, title: "Chave do spot do background da direita" })}
              {renderSpot({ name: "adBackgroundLeft", values, setFieldValue, title: "Chave do spot do background da esquerda" })}
              {renderSpot({ name: "adNextMatches", values, setFieldValue, title: "Chave do spot página de próximas partidas" })}
              {renderSpot({ name: "adResults", values, setFieldValue, title: "Chave do spot da página de resultados" })}
            </Card>

            <div style={{ height: "10px" }} />
            <Card title="FAQ">
              <CustomTextArea
                name="faq"
                value={values.faq}
                fullWidth
                label="FAQ"
                onChange={handleChange}
                className="HubForm__input"
                error={errors.faq && touched.faq}
                helperText={errors.faq}
                minRows={10}
                maxRows={10}
              />
            </Card>

            <Card>
              <FormSubmitButton
                size="small"
                className="BetHouseForm__submit"
                text="Enviar"
                disabled={!isValid}
                block
                skin="accent"
                type="submit"
              />
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );

}

export default HubForm;

const CustomFormInput = styled(TextField)`
  margin-bottom: 12px;
  width: 100% !important;
`
const CustomTextArea = styled(TextareaAutosize)`
  margin-bottom: 12px;
  width: 100% !important;
`

const FormSubmitButton = styled(Button)`
  margin-top: 16px;
`