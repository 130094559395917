import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import CampaignsService from '../../services/campaigns';
import SpotsForm from './SpotsForm';

class EditSpot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      initialData: props.location.spot,
    };
  }

  componentDidMount() {
    if (!this.props.location.spot) {
      this.fetchInitialData();
    }
  }

  fetchInitialData = () => {
    const { spotId } = this.props.match.params;

    this.setState({ isLoading: true });

    CampaignsService.getSingleSpot(spotId).then(response => {
      this.setState({
        initialData: response.data.data,
        isLoading: false,
      });
    });
  };

  onSubmit = values => {
    const {
      history,
      match: {
        params: { spotId },
      },
    } = this.props;

    this.setState({ isLoading: true });
    CampaignsService.editSpot(spotId, values)
      .then(() => {
        toast.success('Spot editado com sucesso');
        this.setState({ isLoading: false });

        history.push(`${process.env.PUBLIC_URL}/u/spots`);
      })
      .catch(err => {
        toast.error(err.response.data.message || err.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, initialData } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Editar Spot" />
        <div className="PageContainer">
          <SpotsForm onSubmit={this.onSubmit} initialData={initialData} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditSpot);
