import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import LoadMask from '../../components/LoadMask';
import PlayersService from '../../services/players';
import Flag from '../../components/Flag';
import PageNavigator from '../../components/PageNavigator';
import Search from '../../components/Search';
import { toast } from 'react-toastify';
import styled from 'styled-components';

class PlayersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Nome do Jogador' },
        { title: 'Nickname' },
        { title: 'País do Jogador' },
        { title: 'Data de Nascimento' },
        { title: 'SteamId' },
        { title: 'Tem Foto?' },
        { title: 'Creditos da Foto' },
        { title: 'Ações' },
        { title: '' },
      ],
      currentPage: 1,
      currentAmount: 25,
      totalItems:0,
      data: [],
      isLoading: false,
      deleteModalIsVisible: false,
      deleteId: null,
    };
  }

  componentDidMount() {
    const { currentPage, currentAmount } = this.state;
    const { isArchived } = this.props;

    this.getPlayers(currentPage, currentAmount, isArchived);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isArchived !== prevProps.isArchived) {
      this.getPlayers(1, 25, this.props.isArchived);
      this.resetPagination();
    }
  }

  getPlayers = (page, amount, query) => {
    this.setState({ isLoading: true });

    PlayersService.getPlayers(page, amount, query)
      .then(({ data }) => {
        this.setState({
          totalItems: data.data.totalItems,
          data: this.formatData(data.data),
          isLoading: false,
        });
      });
  }

  resetPagination = () => {
    this.setState({
      currentPage: 1,
      currentAmount: 25,
    });
  }

  deletePlayer = (Id) => {
    this.setState({ isLoading: true });

    PlayersService.deletePlayer(Id)
      .then(() => {
        toast('Player excluido com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
        this.getPlayers(this.state.currentPage, this.state.currentAmount);
      })
      .catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
      });
  }

  playerName = (first, last) => {
    if(first === '-' && last === '-') return '-'
    if(first === '-') return last
    if(last === '-') return first
    return `${first} ${last}`
  }

  formatData = (data) => {
    return data.list
    .map((player) => {
      const {
        playerId,
        playerFirstName,
        playerLastName,
        playerNickname,
        playerCountry,
        birthdate,
        steamId,
        playerPhoto,
        photoCredits,
      } = player;
      return [
        this.playerName(playerFirstName, playerLastName),
        playerNickname,
        <div>
          <Flag country={playerCountry} size={15} /> <span>{playerCountry}</span>
        </div>,
        this.parseStringDate(birthdate),
        steamId || "-",
        <span>{playerPhoto ? 'Sim' : '-'}</span>,
        photoCredits || "-",
        <NavLink to={`${process.env.PUBLIC_URL}/u/players/editar/${playerId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
        <Button size="small" onClick={() => { this.promptDelete(playerId); }} text="Excluir" block skin="error" />,
      ];
    });
  }

  parseStringDate = (stringDate) => {
    if (!stringDate) return "-"
    const shortDate = stringDate.split("T")[0]
    const date = shortDate.split("-")
    return `${date[2]}/${date[1]}/${date[0]}`
  }

  onAmountChange = (newAmount) => {
    const { currentPage } = this.state;

    this.getPlayers(currentPage, newAmount);

    this.setState({
      currentAmount: newAmount,
    });
  }

  onPageChange = (newPage) => {
    const { currentAmount } = this.state;

    if (newPage < 1) return;

    this.getPlayers(newPage, currentAmount);

    this.setState({
      currentPage: newPage,
    });
  }

  onSearch = (searchQuery) => {
    const { currentAmount } = this.state;

    this.setState({
      currentPage: 1,
      isLoading: true
    });

    PlayersService.getPlayers(1, currentAmount, searchQuery).then(({ data }) => {
      this.setState({
        totalItems: data.data.totalItems,
        data: this.formatData(data.data),
        isLoading: false,
      });
    }).catch(error=> {
      console.log(error)
      this.setState({
        isLoading: false
      });
    })
  }


  promptDelete = (Id) => {
    this.setState({
      deleteId: Id,
      deleteModalIsVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteId: null,
    });
  }

  render() {
    const {
      data,
      isLoading,
      deleteModalIsVisible,
      deleteId,
    } = this.state;

    const {
      pageTitle
    } = this.props;

    return (
      <Fragment>
        <Header title={pageTitle} />

        {isLoading && <LoadMask />}

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Player"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text="Excluir Player"
                  skin="error"
                  onClick={() => {
                    this.deletePlayer(deleteId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir este Player?
          </Modal>
        }


        <div className="PlayersList PageContainer">
          <Menu>
            <Search onSearch={this.onSearch} />
            <NavLink to={`${process.env.PUBLIC_URL}/u/players/novo`}><Button size="small" text="+ Adicionar Player" block skin="success" /></NavLink>
          </Menu>


          <Table
            headers={this.state.headers}
            data={data}
          />

          {
            !isLoading &&
            <PageNavigator
              currentPage={this.state.currentPage}
              currentAmount={this.state.currentAmount}
              totalItems={this.state.totalItems}
              onAmountChange={this.onAmountChange}
              onPageChange={this.onPageChange}
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default PlayersList;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`
