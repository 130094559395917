import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rdparty
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';
import StreamForm from '../shared/StreamForm';

// Redux
import { getStreamDetail, editStream } from '../../../redux/stream-detail';
import { getStreamDetailUi } from '../../../redux/selectors/stream-detail';

class EditStream extends Component {
  componentDidMount() {
    const { dispatch, match: { params: { streamId } } } = this.props;

    dispatch(getStreamDetail(streamId));
  }

  _onStreamEdit = (values) => {
    const { dispatch, history, match: { params: { streamId } } } = this.props;

    dispatch(editStream(values, streamId))
      .then(
        // Success
        () => {
          toast('Stream editada com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/streams`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const {
      streamDetailUi,
      streamDetail,
    } = this.props;

    return (
      <Fragment>
        { streamDetailUi.isFetching && <LoadMask /> }
        <Header title="Editar Stream" />
        <div className="EditStream PageContainer">
          <StreamForm
            data={streamDetail}
            enableReinitialize
            onSubmit={this._onStreamEdit}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    streamDetailUi: getStreamDetailUi(state),
    streamDetail: state.streamDetail.streamDetail.data,
  };
}

export default withRouter(connect(mapStateToProps)(EditStream));
