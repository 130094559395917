import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import LoadMask from '../../components/LoadMask';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import moment from 'moment/moment';
import HubsService from '../../services/hubs';

class ListHub extends Component {

  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Nome do Hub'},
        { title: 'Slug' },
        { title: 'Status' },
        { title: 'CTA' },
        { title: 'Inicia em' },
        { title: 'Termina em' },
        { title: 'Data de Cadastro' },
        { title: 'Ações' },
        { title: '' },
      ],
      data: [],
      isLoading: false,
      deleteModalIsVisible: false,
      deleteId: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (search = "") => {
    this.setState({ isLoading: true });

    HubsService.getAll({ page: 1, amount: 500, search })
      .then(({ data }) => {
        this.setState({
          data: this.formatData(data.data),
          isLoading: false,
        });
      });
  }

  deleteRow = (Id) => {
    this.setState({ isLoading: true });

    HubsService.delete(Id)
      .then(() => {
        toast('Hub excluído com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
        this.loadData();
      })
      .catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
      });
  }

  formatData = (data) => {
    return data
      .map((row) => {
        const {
          title,
          hubId,
          slug,
          startsAt,
          endsAt,
          isActive,
          isCtaActive,
          createdDate,
          logoSponsorUrl
        } = row;
        return [
          title,
          slug,
          isActive ? "Ativo" : "Inativo",
          isCtaActive ? "Ativo" : "Inativo",
          startsAt ? moment(startsAt * 1000).format("DD/MM/YYYY") : "Não Informada",
          endsAt ? moment(endsAt * 1000).format("DD/MM/YYYY") : "Não informada",
          moment(createdDate).format("DD/MM/YYYY HH:mm"),
          <NavLink to={`${process.env.PUBLIC_URL}/u/hubs/editar/${hubId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
          <Button size="small" onClick={() => { this.promptDelete(hubId); }} text="Excluir" block skin="error" />,
        ];
      });
  }


  onSearch = (search) => {
    this.loadData(search);
  }


  promptDelete = (Id) => {
    this.setState({
      deleteId: Id,
      deleteModalIsVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteId: null,
    });
  }

  render() {
    const {
      data,
      isLoading,
      deleteModalIsVisible,
      deleteId,
    } = this.state;

    return (
      <Fragment>
        <Header title="Lista de Hubs" />

        {isLoading && <LoadMask />}

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text="Excluir"
                  skin="error"
                  onClick={() => {
                    this.deleteRow(deleteId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir este Hub?
          </Modal>
        }


        <div className="HubList PageContainer">
          <Menu>
            <NavLink to={`${process.env.PUBLIC_URL}/u/hubs/novo`}><Button size="small" text="+ Adicionar Hub" block skin="success" /></NavLink>
          </Menu>


          <Table
            headers={this.state.headers}
            data={data}
            keyExtractor={(row) => `KEY_${row[1]}` /* Get slug to use with key */}
          />
        </div>
      </Fragment>
    );
  }
}

export default ListHub;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`
