import React, { Component } from "react";
import styled from "styled-components";
import StreamItem from './StreamItem';
import FontAwesome from 'react-fontawesome';

export default class ListItem extends Component {
  render() {
    const {
      value,
      streamData,
      mainStream,
      removeStream,
      selectMainStream,
    } = this.props;

    return (
      <Container>
        {
          value.length > 0 && value.map(streamId => {
              const stream = streamData(streamId)
              return (
                <StreamItem
                  key={streamId}
                  isMain={streamId === mainStream}
                  label={<FontAwesome name="external-link-alt" />}
                  onClick={(event) => {event.preventDefault(); selectMainStream(streamId, event)}}
                  onRemove={() => {
                    removeStream(streamId)
                  }}
                >
                  { stream &&
                    (
                      stream.streamInternalName ?
                      `${stream.streamInternalName} - ${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}` :
                      `${stream.streamName} - ${stream.streamPlatform}/${stream.streamChannel}`
                    )
                  }
                </StreamItem>
              )
            }
          )
        }
      </Container>
    );
  }
}

const Container = styled.div`
`;
