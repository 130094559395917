import axios from 'axios';
import moment from 'moment';
import { API_TS_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class CampaignsService {
  static getCampaigns() {
    const url = `${API_TS_BASE_URL}/campaigns`;

    return axios.get(url);
  }

  static getSingleCampaign(campaignId) {
    const url = `${API_TS_BASE_URL}/campaigns/${campaignId}`;

    return axios.get(url);
  }

  static getSpots() {
    const url = `${API_TS_BASE_URL}/campaigns/spots`;

    return axios.get(url);
  }

  static getSingleSpot(spotId) {
    const url = `${API_TS_BASE_URL}/campaigns/spots/${spotId}`;

    return axios.get(url);
  }

  static addNewSpot(data) {
    const url = `${API_TS_BASE_URL}/campaigns/spots`;

    return axios.post(
      url,
      {
        ...data,
        adManagerSizeArray: JSON.stringify(data.adManagerSizeArray),
        siprocalSizeArray: JSON.stringify(data.siprocalSizeArray),
      },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static editSpot(spotId, data) {
    const url = `${API_TS_BASE_URL}/campaigns/spots/${spotId}`;

    return axios.put(
      url,
      {
        ...data,
        adManagerSizeArray: JSON.stringify(data.adManagerSizeArray),
        siprocalSizeArray: JSON.stringify(data.siprocalSizeArray),
      },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static deleteSpot(spotId) {
    const url = `${API_TS_BASE_URL}/campaigns/spots/${spotId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addNewCampaign(values) {
    const url = `${API_TS_BASE_URL}/campaigns`;

    return axios.post(
      url,
      {
        ...values,
        campaignStartDate: moment(
          `${values.campaignStartDate} ${values.campaignStartTime}`
        ).format('X'),
        campaignEndDate: moment(
          `${values.campaignEndDate} ${values.campaignEndTime}`
        ).format('X'),
      },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static editCampaign(campaignId, values) {
    const url = `${API_TS_BASE_URL}/campaigns/${campaignId}`;

    return axios.put(
      url,
      {
        ...values,
        campaignStartDate: moment(
          `${values.campaignStartDate} ${values.campaignStartTime}`
        ).format('X'),
        campaignEndDate: moment(
          `${values.campaignEndDate} ${values.campaignEndTime}`
        ).format('X'),
      },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static deleteCampaign(campaignId) {
    const url = `${API_TS_BASE_URL}/campaigns/${campaignId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
