import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rdparty
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';
import MapForm from '../shared/MapForm';

// redux
import { getMapDetail, editMap } from '../../../redux/map-detail';
import { getMapDetailUi } from '../../../redux/selectors/map-detail';

class EditMap extends Component {
  componentDidMount() {
    const { dispatch, match: { params: { mapId } } } = this.props;

    dispatch(getMapDetail(mapId));
  }

  _onMapEdit = (values) => {
    const { dispatch, history, match: { params: { mapId } } } = this.props;

    dispatch(editMap(values, mapId))
      .then(
        // Success
        () => {
          toast('Mapa editado com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/mapas`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const {
      mapDetail,
      mapDetailUi,
    } = this.props;

    return (
      <Fragment>
        { mapDetailUi.isFetching && <LoadMask /> }
        <Header title="Editar Mapa" />
        <div className="EditMap PageContainer">
          <MapForm
            data={mapDetail}
            enableReinitialize
            onSubmit={this._onMapEdit}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapDetailUi: getMapDetailUi(state),
    mapDetail: state.mapDetail.mapDetail.data,
  };
}

export default withRouter(connect(mapStateToProps)(EditMap));
