import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FontAwesome from 'react-fontawesome';

export default class Ranges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRangeFormHidden: true,
      editRange: {
        index: null,
        data: null,
      },
    };
  }

  renderRangeForm = (initialValues = null, editIndex = null) => (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          description: initialValues ? initialValues.description : '',
          fromPosition: initialValues ? initialValues.fromPosition : '',
          toPosition: initialValues ? initialValues.toPosition : '',
          color: initialValues ? initialValues.color : '',
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string()
            .min(2, 'Descrição muito curta')
            .max(50, 'Descrição muito longa')
            .required('Campo obrigatório'),
          fromPosition: Yup.number()
            .positive('Posição deve ser positiva')
            .min(1, 'Posição deve ser maior que 1')
            .required('Campo Obrigatório'),
          toPosition: Yup.number()
            .positive('Posição deve ser positiva')
            .min(1, 'Posição deve ser maior que 1')
            .required('Campo Obrigatório'),
          color: Yup.string().required('Campo Obrigatório'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const ranges = Array.from(this.props.field.value);

          if (initialValues !== null && editIndex !== null) {
            const newRanges = ranges.filter(
              (range, index) => index !== editIndex
            );

            newRanges.push(values);

            this.setState(
              {
                isRangeFormHidden: true,
                editRange: {
                  index: null,
                  data: null,
                },
              },
              () => {
                setSubmitting(false);

                this.props.form.setFieldValue('ranges', newRanges);
              }
            );

            return;
          }

          this.setState(
            {
              ranges: [...ranges, values],
              isRangeFormHidden: true,
            },
            () => {
              setSubmitting(false);

              this.props.form.setFieldValue('ranges', this.state.ranges);
            }
          );
        }}
      >
        {({ values, handleChange, errors, touched, isSubmitting, isValid }) => (
          <RangeForm>
            <Form>
              <TextField
                name="description"
                label="Descrição"
                onChange={handleChange}
                value={values.label}
                variant="outlined"
                size="small"
                fullWidth
                error={errors.label && touched.label}
                helperText={errors.label}
              />

              <FieldContainer>
                <TextField
                  name="fromPosition"
                  label="De #"
                  type="number"
                  onChange={handleChange}
                  value={values.fromPosition}
                  variant="outlined"
                  size="small"
                  error={errors.fromPosition && touched.fromPosition}
                  helperText={errors.fromPosition}
                />
                <TextField
                  name="toPosition"
                  label="Para #"
                  type="number"
                  onChange={handleChange}
                  value={values.toPosition}
                  variant="outlined"
                  size="small"
                  error={errors.toPosition && touched.toPosition}
                  helperText={errors.toPosition}
                />

                <FormControl
                  variant="outlined"
                  size="small"
                  error={errors.color && touched.color}
                >
                  <InputLabel>Cor</InputLabel>
                  <Select
                    id="color"
                    name="color"
                    value={values.color}
                    onChange={handleChange}
                    label="Cor"
                    fullWidth
                  >
                    <MenuItem value="#549F23">
                      <ColorCircle color="#549F23" />
                    </MenuItem>
                    <MenuItem value="#069BB2">
                      <ColorCircle color="#069BB2" />
                    </MenuItem>
                    <MenuItem value="#FABB0F">
                      <ColorCircle color="#FABB0F" />
                    </MenuItem>
                    <MenuItem value="#FF781F">
                      <ColorCircle color="#FF781F" />
                    </MenuItem>
                    <MenuItem value="#DD2C2C">
                      <ColorCircle color="#DD2C2C" />
                    </MenuItem>
                  </Select>
                  <FormHelperText>{errors.color}</FormHelperText>
                </FormControl>
              </FieldContainer>

              <FormActions>
                <Button
                  text="Cancelar"
                  block
                  outline
                  size="small"
                  skin="error"
                  onClick={() => {
                    this.setState({
                      isRangeFormHidden: true,
                      editRange: {
                        index: null,
                        data: null,
                      },
                    });
                  }}
                />
                <Button
                  type="submit"
                  text="Salvar"
                  block
                  size="small"
                  skin="success"
                  disabled={!isValid || isSubmitting}
                />
              </FormActions>
            </Form>
          </RangeForm>
        )}
      </Formik>
    </Fragment>
  );

  onDeleteRange = index => {
    const rangesArr = Array.from(this.props.field.value);
    rangesArr.splice(index, 1);

    this.props.form.setFieldValue('ranges', rangesArr);
  };

  onEditRange = (data, index) => {
    this.setState({
      isRangeFormHidden: false,
      editRange: {
        index,
        data,
      },
    });
  };

  render() {
    const { isRangeFormHidden, editRange } = this.state;

    return (
      <Fragment>
        {this.props.field.value.map((range, index) => (
          <Fragment>
            <Range>
              <ColorCircle color={range.color} />
              <small>
                #{range.fromPosition} a #{range.toPosition}
              </small>
              <span>{range.description}</span>

              <RangeActions>
                <EditAction
                  name="pencil"
                  onClick={() => {
                    this.onEditRange(range, index);
                  }}
                />
                <DeleteAction
                  name="minus-circle"
                  onClick={() => {
                    this.onDeleteRange(index);
                  }}
                />
              </RangeActions>
            </Range>
          </Fragment>
        ))}

        {!isRangeFormHidden &&
          this.renderRangeForm(editRange.data, editRange.index)}

        {isRangeFormHidden && (
          <Button
            text="Novo Range de Posições"
            block
            size="small"
            skin="accent"
            onClick={() => {
              this.setState({ isRangeFormHidden: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

const RangeForm = styled(Card)`
  margin: 10px 0;
  border: 1px solid var(--accent);
`;

const ColorCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-right: 8px;
  background: ${props => props.color};
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  & > * {
    flex-basis: 30%;
  }
`;

const RangeActions = styled.div`
  flex: 1;
  text-align: right;
  opacity: 0;
  transition: all 0.2s;
`;

const Range = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  small {
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
    color: var(--gray);
  }

  span {
    font-size: 14px;
  }

  &:hover {
    ${RangeActions} {
      opacity: 1;
    }
  }
`;

const EditAction = styled(FontAwesome)`
  color: var(--success);
  padding-left: 12px;
  cursor: pointer;
`;

const DeleteAction = styled(FontAwesome)`
  color: var(--error);
  padding-left: 12px;
  cursor: pointer;
`;

const FormActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;
