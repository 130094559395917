import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Header from '../../components/Header';
import useRankingEdition from './hooks/useRankingEdition';

const RankingCalculatorDetail = props => {
  const [ranking, isLoading, error] = useRankingEdition(
    props.match.params.rankingId
  );

  return (
    <>
      <Header title="Detalhe Ranking" />
      <div className="PageContainer">
        {!isLoading &&
          ranking.teams.map((rankingTeam, index) => (
            <Accordion>
              <AccordionSummary
                expandIcon="▼"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  #{index + 1} - {rankingTeam.team.teamName} -{' '}
                  {rankingTeam.totalPoints} <br />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <small>
                    {rankingTeam.points.map(point => (
                      <>
                        {point.pointsDescription} - {point.pointsDate} (
                        {point.points})
                        <br />
                      </>
                    ))}
                  </small>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </>
  );
};

export default RankingCalculatorDetail;
