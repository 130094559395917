import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_RANKING_LIST = 'draft5-admin/matches/GET_RANKING_LIST';
const GET_RANKING_LIST_SUCCESS = 'draft5-admin/matches/GET_RANKING_LIST_SUCCESS';
const GET_RANKING_LIST_FAILURE = 'draft5-admin/matches/GET_RANKING_LIST_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  isFetching: false,
  error: '',
  data: [],
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RANKING_LIST: {
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    }

    case GET_RANKING_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    }

    case GET_RANKING_LIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _getRankingList() {
  return {
    type: GET_RANKING_LIST,
  };
}

function _getRankingListSuccess(data) {
  return {
    type: GET_RANKING_LIST_SUCCESS,
    data,
  };
}

function _getRankingListFailure(error) {
  return {
    type: GET_RANKING_LIST_FAILURE,
    error,
  };
}

// ========================
// Thunks
// ========================
export function getRankingList(page, amount) {
  return function (dispatch) {
    dispatch(_getRankingList());

    (async () => {
      try {
        const params = {
          page,
          amount,
        };

        const url = `${API_BASE_URL}/rankings`;
        const response = await axios.get(url, { params });

        dispatch(_getRankingListSuccess(response.data.data));
      } catch (error) {
        dispatch(_getRankingListFailure(error.response.data.message));
      }
    })();
  };
}
