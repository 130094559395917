import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';
import parseMatchObject from './utils/parse-match-object';

// ========================
// Enums
// ========================
export const VETO_ACTIONS = {
  PICK: 0,
  BAN: 1,
  LEFT: 2,
  RANDOM: 3,
};

// ========================
// Actions
// ========================
const ADD_NEW_MATCH = 'draft5-admin/matches/ADD_NEW_MATCH';
const ADD_NEW_MATCH_SUCCESS = 'draft5-admin/matches/ADD_NEW_MATCH_SUCCESS';
const ADD_NEW_MATCH_FAILURE = 'draft5-admin/matches/ADD_NEW_MATCH_FAILURE';

const EDIT_MATCH = 'draft5-admin/matches/EDIT_MATCH';
const EDIT_MATCH_SUCCESS = 'draft5-admin/matches/EDIT_MATCH_SUCCESS';
const EDIT_MATCH_FAILURE = 'draft5-admin/matches/EDIT_MATCH_FAILURE';

const DELETE_MATCH = 'draft5-admin/matches/DELETE_MATCH';
const DELETE_MATCH_SUCCESS = 'draft5-admin/matches/DELETE_MATCH_SUCCESS';
const DELETE_MATCH_FAILURE = 'draft5-admin/matches/DELETE_MATCH_FAILURE';

const GET_MATCH_DETAIL = 'draft5-admin/matches/GET_MATCH_DETAIL';
const GET_MATCH_DETAIL_SUCCESS = 'draft5-admin/matches/GET_MATCH_DETAIL_SUCCESS';
const GET_MATCH_DETAIL_FAILURE = 'draft5-admin/matches/GET_MATCH_DETAIL_FAILURE';

const CLEAR_MATCH_DETAIL = 'draft5-admin/matches/CLEAR_MATCH_DETAIL';

// ========================
// initial state
// ========================
const initialState = {
  addNewMatch: {
    isFetching: false,
    error: '',
  },
  editMatch: {
    isFetching: false,
    error: '',
  },
  deleteMatch: {
    isFetching: false,
    error: '',
  },
  matchDetail: {
    isFetching: false,
    error: '',
    data: {},
  },
};

// ========================
// reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD NEW MATCH
    case ADD_NEW_MATCH: {
      return {
        ...state,
        addNewMatch: {
          ...state.addNewMatch,
          isFetching: true,
          error: '',
        },
      };
    }

    case ADD_NEW_MATCH_SUCCESS: {
      return {
        ...state,
        addNewMatch: {
          ...state.addNewMatch,
          isFetching: false,
        },
      };
    }

    case ADD_NEW_MATCH_FAILURE: {
      return {
        ...state,
        addNewMatch: {
          ...state.addNewMatch,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT MATCH
    case EDIT_MATCH: {
      return {
        ...state,
        editMatch: {
          ...state.editMatch,
          isFetching: true,
          error: '',
        },
      };
    }

    case EDIT_MATCH_SUCCESS: {
      return {
        ...state,
        editMatch: {
          ...state.editMatch,
          isFetching: false,
        },
      };
    }

    case EDIT_MATCH_FAILURE: {
      return {
        ...state,
        editMatch: {
          ...state.editMatch,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // EDIT MATCH
    case DELETE_MATCH: {
      return {
        ...state,
        deleteMatch: {
          ...state.deleteMatch,
          isFetching: true,
          error: '',
        },
      };
    }

    case DELETE_MATCH_SUCCESS: {
      return {
        ...state,
        deleteMatch: {
          ...state.deleteMatch,
          isFetching: false,
        },
      };
    }

    case DELETE_MATCH_FAILURE: {
      return {
        ...state,
        deleteMatch: {
          ...state.deleteMatch,
          isFetching: false,
          error: action.error,
        },
      };
    }

    // GET MATCH DETAIL
    case GET_MATCH_DETAIL: {
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_MATCH_DETAIL_SUCCESS: {
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_MATCH_DETAIL_FAILURE: {
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          isFetching: false,
          error: action.error,
        },
      };
    }

    case CLEAR_MATCH_DETAIL: {
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          data: {},
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// action creators
// ========================
function _addNewMatch() {
  return { type: ADD_NEW_MATCH };
}

function _addNewMatchSuccess() {
  return { type: ADD_NEW_MATCH_SUCCESS };
}

function _addNewMatchFailure(error) {
  return {
    type: ADD_NEW_MATCH_FAILURE,
    error,
  };
}

function _editMatch() {
  return { type: EDIT_MATCH };
}

function _editMatchSuccess() {
  return { type: EDIT_MATCH_SUCCESS };
}

function _editMatchFailure(error) {
  return {
    type: EDIT_MATCH_FAILURE,
    error,
  };
}

function _deleteMatch() {
  return { type: DELETE_MATCH };
}

function _deleteMatchSuccess() {
  return { type: DELETE_MATCH_SUCCESS };
}

function _deleteMatchFailure(error) {
  return {
    type: DELETE_MATCH_FAILURE,
    error,
  };
}

function _getMatchDetail() {
  return { type: GET_MATCH_DETAIL };
}

function _getMatchDetailSuccess(data) {
  return { type: GET_MATCH_DETAIL_SUCCESS, data };
}

function _getMatchDetailFailure(error) {
  return {
    type: GET_MATCH_DETAIL_FAILURE,
    error,
  };
}

// ========================
// public action creators
// ========================
export function clearMatchDetail() {
  return { type: CLEAR_MATCH_DETAIL };
}

// ========================
// thunks
// ========================
export function addNewMatch(values) {
  return function (dispatch) {
    return (async () => {
      dispatch(_addNewMatch());

      const formValues = Object.assign({}, values);
      const matchObj = parseMatchObject(formValues);

      try {
        const url = `${API_BASE_URL}/matches`;
        await axios.post(url, matchObj, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_addNewMatchSuccess());
        return true;
      } catch (error) {
        dispatch(_addNewMatchFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function editMatch(values, matchId) {
  return function (dispatch) {
    return (async () => {
      dispatch(_editMatch());

      const formValues = Object.assign({}, values);
      const matchObj = parseMatchObject(formValues);
      try {
        const url = `${API_BASE_URL}/matches/${matchId}`;
        await axios.put(url, matchObj, {
          headers: {
            Authorization: getAuthToken(),
          },
        });

        dispatch(_editMatchSuccess());
        return true;
      } catch (error) {
        dispatch(_editMatchFailure(error.response.data.message));
        throw new Error(error.response.data.message);
      }
    })();
  };
}

export function getMatchDetail(matchId) {
  return async function (dispatch) {
    dispatch(_getMatchDetail());

    try {
      const url = `${API_BASE_URL}/matches/${matchId}`;
      const response = await axios.get(url);
      dispatch(_getMatchDetailSuccess(response.data.data));
    } catch (error) {
      dispatch(_getMatchDetailFailure(error.response.data.message));
    }
  };
}

export function deleteMatch(matchId) {
  return async function (dispatch) {
    dispatch(_deleteMatch());

    try {
      const url = `${API_BASE_URL}/matches/${matchId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: getAuthToken(),
        },
      });

      dispatch(_deleteMatchSuccess(response.data.data));
    } catch (error) {
      dispatch(_deleteMatchFailure(error.response.data.message));
    }
  };
}
