import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import Flag from '../../../../components/Flag';
import { TextField } from '@material-ui/core';

export default class TableTeam extends Component {
  render() {
    const { team, index, activeRange, onChangeTotalScore } = this.props;
    return (
      <Draggable
        key={team.teamData.teamId}
        draggableId={`draggable-${team.teamData.teamId}`}
        index={index}
      >
        {provided => (
          <TeamLine
            ref={provided.innerRef}
            {...provided.draggableProps}
            rangeColor={activeRange ? activeRange.color : '#FFF'}
          >
            <DragHandler name="bars" {...provided.dragHandleProps} />

            <Position>{index + 1}</Position>

            <TeamInfo>
              <Flag country={team.teamData.teamCountry} size="15" />
              {team.teamData.teamName}
            </TeamInfo>
            <TeamStat>{this.props.team.matches || 0}</TeamStat>
            <TeamStat>{this.props.team.wins || 0}</TeamStat>
            <TeamStat>{this.props.team.ties || 0}</TeamStat>
            <TeamStat>{this.props.team.losses || 0}</TeamStat>
            <TeamStat>{this.props.team.roundDiff || 0}</TeamStat>
            <TeamStat>
              {
                this.props.automaticPoints ? (
                  <TeamStat>
                    {
                      (this.props.team.totalPoints + this.props.team.totalExtra) || 0
                    }
                  </TeamStat>
                ) : (
                  <PointsInput
                    type="number"
                    value={this.props.team.totalScore}
                    onChange={teste =>
                      onChangeTotalScore(index, teste.target.value)
                    }
                  />
                )
              }

            </TeamStat>

            <DeleteIcon
              name="minus-circle"
              onClick={() => {
                this.props.onTeamRemove(team, index);
              }}
            />
          </TeamLine>
        )}
      </Draggable>
    );
  }
}

const DeleteIcon = styled(FontAwesome)`
  color: #dd2c2c;
  opacity: 0.1;
  transition: all 0.2s;
  cursor: pointer;
`;

const TeamLine = styled.div`
  padding: 16px 16px 16px 8px;
  border-bottom: 1px solid var(--gray-lightest);
  border-left: 4px solid ${props => props.rangeColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.rangeColor}19;

  &:hover {
    ${DeleteIcon} {
      opacity: 1;
    }
  }
`;

const DragHandler = styled(FontAwesome)`
  color: var(--gray-lighter);
`;

const Position = styled.small`
  padding: 0 16px;
  color: var(--gray);
  font-size: 12px;
`;

const TeamInfo = styled.div`
  flex: 1;

  & img {
    margin-right: 12px;
  }
`;

const TeamStat = styled.span`
  flex-basis: 10%;
  text-align: center;
`;

const PointsInput = styled(TextField)`
  border: none;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid var(--gray);
  width: 5ch;
  text-align: center;
  color: var(--gray);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
`;
