const sentences = [
  'Posso sin Mas não agora, estou prestes a dormir Boa noite bro',
  'SATISFEITO COM A MINHA PERFORMANCE COM O MOUSE LOBO',
  'Como eu ganho drop?',
  'Quando a LG joga?',
  'Taco BOT',
  'We smart we loyal we frinedly WE ARE FROM BRAZIL',
  'Com o Taco nunca vãoo ganhar um Major',
  'Passador fala mau',
  'Pra quem não sabe, eu sou o coach da G3X',
  'Que ganhar 1850 dolares em 15min?',
  'Bida, vai ter drop?',
  'senario brasileiro de cs:go ta ruin porqqe naum ten antehack igual do pointblank, grande fps do mundo que tem campeonatos maiores que o senario csgo, que naum ajuda os players, e precisa das armas do crosfire, outro grande fps do mundo que tem graficu melho que csgo, obs: o fallen é do pb sim',
  'FALLEN VEIO DO CROSSFIRE',
  'Taco GOD',
  'Relaxa, nosso tr é forte',
  '7x1 bronzil 4Head',
  'Spray bom é na GAMERS CLUB',
  'Nice to meet you',
  '#LGLOYAL',
  'Gosto mais que lasanha',
  'Tirou um coelho da manga',
  'Fallen quando vai ser o proximo jogo a Luminosity? na Elegue',
  'Fallen Ainda sai o respect ep 3 agora que voces sao da SK??',
  'Só preciso de 2 dm p aquecer a mira',
  'Se fosse SK Palmeiras nunca mais o fallen ia ganhar mundial',
  'EU JOGO NA GAMERS CLUB, NAO TEM COMO, NICE TO MEET YOU',
  'Que ser o melhor do global, vem pra gamersclub',
  'Troca tiro com nós',
  'entrega newba',
  'ASPX é inocente',
  'Pula ni mim jacaré',
  'Rusha B, confia',
  'Chora na minha varaaa',
  'Tá saindo da Jaula o monstro',
  'TA EM CHOQUE PEDE PAUSE',
  'FALLEN VETA CACHE',
  'Rush fundo, entra pulando',
  'felps, o fer brasileiro ',
  'BRIGA BIRGA MESMO FECHA O CLÃ',
  'Ta miado com 100',
  'E AI REDATOR TA EM CHOQUE',
  'SÓ PRA AVISAR QUE O CLÃ ACABOU NESSA PORRA',
  'Capturei o pokémon',
  'NO ECO NOIS É SUECO',
  'M A L A N D R A M E N T E',
  'Tu é player ou ward?',
  'A verdade tem que ser dita',
  'Mapa é ct , confia',
  'LG SEDEX , perdeu no eco pro armado',
  'B1 é deles, caraio, B1 é deles',
  'Deus perdoa taco não',
  'Scream seu filha da puta, chora na mira vara, porra!',
  'r u mad? cuz im not',
  'kid with mental problems',
  'Fallen, stop blowing my mind!',
  'MF é do FerGOD, carvena do Lincoln Lau. Se nóis perder esse porra tu vai chupar meu pau',
  'Sou prata elite mas jogo como aguia',
  'cade a Keyd Stars?',
  'to prata 1.. mas rlx.. sou smurf...',
  'AFF, KEYDZOU',
  'AFF MEU É O LORE QUE VAI NARRAR AFF CADE O BIDA',
  'MORRE LIXOO',
  'SMOKE da GA',
  'FAZE VAI COMPRAR SK PORQUE O DONO É BRASILEIRO',
  '16x14 EZ GAME',
  'STEWIE2K QUE JAPONES MARAVILHOSO',
  'FERNOMENAL',
  'pula na porra da piscina',
  'fui scammado trocando minha faca',
  'eae guys essa troca aqui é W/L',
  'Seu ridiculo que fake é esse?',
  'Sem a bomba, sem a bomba',
  'Cogu > Fallen',
  'cogu ta formatando o pc dele faz 5 anos ja',
  'Gala Falera',
  'FACE TO FACE PARCEIRO',
  'AQUI NOIS RESOLVE NA BALA',
  'meu nome é fernando alvarenga, eu tenho 22 anos, meu nick é fer e eu jogo crossfire há 2 meses',
  'mostra tua força kabum e amarra o hen na bananeira',
  'Tabriel Goledo aquele que veio do senario do pb a 1 ano atraz pro cs esse gou e agora ta jongando na fanatique',
  'Hora de separar os homens do garotos',
  'Dont be a looser buy a defuser',
  'Pros dont fake',
  'Estão guardando tática pro major',
  'olha esse cara mano, não acredito nisso',
  'o ousado chegou',
  'give me your demos if you hax I will find you',
  'Pode vir que a pica ta durona',
  'Vai lá irmão, acredito, faz uma pra Deus vê',
  'Smoke nao é parede',
  'o time que eu estiver vai ser o melhor',
  'Ahh nao meu, de novo nao...mais uma vez prejudicados',
  'Estudo nao leva a nada, CS leva a dallas',
  '- Fala galera fallenzão aqui! - Pau no seu cu',
  'Se eu quisesse pão de ontem, tinha comprado ontem',
  'Não tem uma aranha descendo de cordinha no seu teclado',
  'Dedetize sua casa, antes que o homem aranha visite seu teclado e acabe com seu comp',
  'pare, mire e atire você vai acertar pode confiar',
  'Só eu acho o Lucas1 parecido com o hen1?',
  'a aranha desceu de cordinha e os caraio tio',
  'Tu é entryfragger, vai lá TACO',
  'I cry now bit - i\'m not speek english very well',
  'O Diabo veste Prata',
];

export default function getRandomSentence() {
  const sentencesLength = sentences.length;
  return sentences[Math.floor(Math.random() * sentencesLength)];
}
