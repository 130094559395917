import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class PlayersService {
  static convertToFormData(player, image) {
    const {
      playerFirstName,
      playerLastName,
      playerNickname,
      playerCountry,
      birthdate,
      steamId,
      photoCredits,
    } = player;
    const data = new FormData();
    data.append('playerFirstName', playerFirstName || '-');
    data.append('playerLastName', playerLastName || '-');
    data.append('playerNickname', playerNickname);
    data.append('playerCountry', playerCountry);
    data.append('steamId', steamId || '');
    data.append('photoCredits', photoCredits || '');
    if (birthdate) data.append('birthdate', birthdate || '');
    if (image) data.append('playerPhoto', image);

    return data;
  }

  static addPlayer(player, image) {
    const url = `${API_TS_BASE_URL}/players`;

    const body = this.convertToFormData(player, image);
    return axios.post(url, body, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static editPlayer(player, playerId, image) {
    const url = `${API_TS_BASE_URL}/players/${playerId}`;

    const body = this.convertToFormData(player, image);
    return axios.put(url, body, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addPlayerHistory(history, playerId) {
    const url = `${API_TS_BASE_URL}/players/${playerId}/history`;

    return axios.post(
      url,
      { ...history, playerId },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static editPlayerHistory(history, playerId, historyId) {
    const url = `${API_TS_BASE_URL}/players/${playerId}/history/${historyId}`;

    return axios.put(
      url,
      { ...history, playerId, id: historyId },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static deletePlayerHistory(playerId, historyId) {
    const url = `${API_TS_BASE_URL}/players/${playerId}/history/${historyId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static getPlayers(page, amount, query = '') {
    const baseUrl = `${API_TS_BASE_URL}/players?page=${page}&amount=${amount}`;

    const url = query ? `${baseUrl}&query=${query}` : baseUrl;

    return axios.get(url);
  }

  static getPlayer(playerId) {
    const url = `${API_TS_BASE_URL}/players/${playerId}`;

    return axios.get(url);
  }

  static deletePlayer(playerId) {
    const url = `${API_TS_BASE_URL}/players/${playerId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}
