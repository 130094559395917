import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_TEAM_LIST = 'draft5-admin/teams/GET_TEAM_LIST';
const GET_TEAM_LIST_SUCCESS = 'draft5-admin/teams/GET_TEAM_LIST_SUCCESS';
const GET_TEAM_LIST_FAILURE = 'draft5-admin/teams/GET_TEAM_LIST_FAILURE';

// ========================
// initial state
// ========================
const initialState = {
  isFetching: false,
  error: '',
  data: [],
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_LIST: {
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    }

    case GET_TEAM_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    }

    case GET_TEAM_LIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _getTeamList() {
  return {
    type: GET_TEAM_LIST,
  };
}

function _getTeamListSuccess(data) {
  return {
    type: GET_TEAM_LIST_SUCCESS,
    data,
  };
}

function _getTeamListFailure(error) {
  return {
    type: GET_TEAM_LIST_FAILURE,
    error,
  };
}

// ========================
// Thunks
// ========================
export function getTeamList(page = 1, amount = 50, searchQuery = null) {
  return function (dispatch) {
    dispatch(_getTeamList());

    (async () => {
      try {
        const url = `${API_BASE_URL}/teams?page=${page}&amount=${amount}${searchQuery ? `&searchQuery=${searchQuery}` : ''}`;
        const response = await axios.get(url);

        dispatch(_getTeamListSuccess(response.data.data));
      } catch (error) {
        dispatch(_getTeamListFailure(error.response.data.message));
      }
    })();
  };
}
