import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_MATCH_LIST = 'draft5-admin/matches/GET_MATCH_LIST';
const GET_MATCH_LIST_SUCCESS = 'draft5-admin/matches/GET_MATCH_LIST_SUCCESS';
const GET_MATCH_LIST_FAILURE = 'draft5-admin/matches/GET_MATCH_LIST_FAILURE';

// =======================
// Enums
// ========================
export const matchTypes = {
  NEXT: 'nextMatches',
  FINISHED: 'finishedMatches',
};

// ========================
// initial state
// ========================
const initialState = {
  nextMatches: {
    isFetching: false,
    error: '',
    data: [],
  },
  finishedMatches: {
    isFetching: false,
    error: '',
    data: [],
  },
};

// ========================
// Reducer
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MATCH_LIST: {
      return {
        ...state,
        [action.matchType]: {
          ...state[action.matchType],
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_MATCH_LIST_SUCCESS: {
      return {
        ...state,
        [action.matchType]: {
          ...state[action.matchType],
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_MATCH_LIST_FAILURE: {
      return {
        ...state,
        [action.matchType]: {
          ...state[action.matchType],
          isFetching: false,
          error: action.error,
        },
      };
    }

    default:
      return state;
  }
}

// ========================
// Action Creators
// ========================
function _getMatchList(matchType) {
  return {
    type: GET_MATCH_LIST,
    matchType,
  };
}

function _getMatchListSuccess(data, matchType) {
  return {
    type: GET_MATCH_LIST_SUCCESS,
    data,
    matchType,
  };
}

function _getMatchListFailure(error, matchType) {
  return {
    type: GET_MATCH_LIST_FAILURE,
    error,
    matchType,
  };
}

// ========================
// Thunks
// ========================
export function getMatchList(
  page = 1,
  amount = 50,
  matchType,
  team = null,
  tournament = null
) {
  return function(dispatch) {
    dispatch(_getMatchList(matchType));

    (async () => {
      try {
        const finished = matchType === matchTypes.FINISHED ? '1' : '0';
        const featured = matchType === matchTypes.FEATURED ? '1' : '0';

        const params = {
          page,
          amount,
          finished,
          featured,
          team,
          tournament,
        };

        const url = `${API_TS_BASE_URL}/matches`;
        const response = await axios.get(url, { params });
        dispatch(_getMatchListSuccess(response.data.data.list, matchType));
      } catch (error) {
        dispatch(_getMatchListFailure(error.response.data.message, matchType));
      }
    })();
  };
}
