import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import LoadMask from '../../components/LoadMask';
import Search from '../../components/Search';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BetHousesService from '../../services/bet-houses';
import moment from 'moment/moment';

class BetHouseList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: '' },
        { title: 'Posição' },
        { title: 'Nome' },
        { title: 'Slug' },
        { title: 'Data de Cadastro' },
        { title: 'Ações' },
        { title: '' },
      ],
      data: [],
      isLoading: false,
      deleteModalIsVisible: false,
      deleteId: null,
    };
  }

  componentDidMount() {

    this.getBetHouses();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.getPlayers();
    }
  }

  getBetHouses = (search = "") => {
    this.setState({ isLoading: true });

    BetHousesService.getAll({ page: 1, amount: 500, search })
      .then(({ data }) => {
        this.setState({
          data: this.formatData(data.data),
          isLoading: false,
        });
      });
  }

  deleteBetHouse = (Id) => {
    this.setState({ isLoading: true });

    BetHousesService.delete(Id)
      .then(() => {
        toast('Casa de Apostas excluida com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
        this.getBetHouses();
      })
      .catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
      });
  }

  formatData = (data) => {
    return data
      .map((betHouse) => {
        const {
          betHouseId,
          betHouseViewOrder,
          betHouseName,
          betHouseSlug,
          betHouseLogoImageUrl,
          // betHouseShortCallRegister,
          betHouseCreatedDate,
          // betHouseUpdatedAt,
        } = betHouse;
        return [
          <div>
            <img src={betHouseLogoImageUrl} alt={betHouseName} />
          </div>,
          betHouseViewOrder,
          betHouseName,
          betHouseSlug,
          moment(betHouseCreatedDate).format("DD/MM/YYYY HH:mm"),
          <NavLink to={`${process.env.PUBLIC_URL}/u/bet-houses/editar/${betHouseId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
          <Button size="small" onClick={() => { this.promptDelete(betHouseId); }} text="Excluir" block skin="error" />,
        ];
      });
  }


  onSearch = (search) => {
    this.getBetHouses(search);
  }


  promptDelete = (Id) => {
    this.setState({
      deleteId: Id,
      deleteModalIsVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteId: null,
    });
  }

  render() {
    const {
      data,
      isLoading,
      deleteModalIsVisible,
      deleteId,
    } = this.state;

    return (
      <Fragment>
        <Header title="Lista de Casas de Apostas" />

        {isLoading && <LoadMask />}

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text="Excluir"
                  skin="error"
                  onClick={() => {
                    this.deleteBetHouse(deleteId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir esta Casa de Apostas?
          </Modal>
        }


        <div className="BetHouseList PageContainer">
          <Menu>
            <Search onSearch={this.onSearch} />
            <NavLink to={`${process.env.PUBLIC_URL}/u/bet-houses/ordenacao`}><Button size="small" text="Ordenar Casas de Apostas" block skin="gray" /></NavLink>
            <NavLink to={`${process.env.PUBLIC_URL}/u/bet-houses/novo`}><Button size="small" text="+ Adicionar Casa de Apostas" block skin="success" /></NavLink>
          </Menu>


          <Table
            headers={this.state.headers}
            data={data}
            keyExtractor={(row) => `KEY_${row[4]}` /* Get slug to use with key */}
          />
        </div>
      </Fragment>
    );
  }
}

export default BetHouseList;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`
