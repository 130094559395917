import React from 'react';
import moment from 'moment';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
} from '@material-ui/core';

import Button from '../../../components/Button';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const TournamentTable = props => (
  <TableContainer elevation={1}>
    {props.isLoading && <LinearProgress />}

    <TableStyle>
      <TableHeadStyle position="static" color="secondary">
        <TableRow>
          <TournamentTitleCellStyle>
            <strong>ID</strong>
          </TournamentTitleCellStyle>
          <TournamentTitleCellStyle>
            <strong>Evento</strong>
          </TournamentTitleCellStyle>
          <TournamentTitleCellStyle>
            <strong>Data</strong>
          </TournamentTitleCellStyle>
          <TournamentTitleCellStyle>
            <strong>Status</strong>
          </TournamentTitleCellStyle>
          <TournamentTitleCellStyle>
            <strong>Ações</strong>
          </TournamentTitleCellStyle>
          <TournamentTitleCellStyle></TournamentTitleCellStyle>
        </TableRow>
      </TableHeadStyle>
      <TableBody>
        <TableRow>
          {props.error && <TableCell colSpan={4}>{props.error}</TableCell>}
        </TableRow>
        {props.data.map(tournament => (
          <TableRow>
            <TableCellStyle>{tournament.tournamentId}</TableCellStyle>
            <TableCellStyle>{tournament.tournamentName}</TableCellStyle>
            <TableCellStyle>
              {moment(tournament.tournamentStart, 'X').format('DD/MM')} à{' '}
              {moment(tournament.tournamentEnd, 'X').format('DD/MM')}
            </TableCellStyle>
            <TableCellStyle>
              {!tournament.isFinished && (
                <span className="MatchList__label MatchList__nostreamed-label">
                  Ativo
                </span>
              )}

              {!!tournament.isFinished && (
                <span className="MatchList__label MatchList__finished-label">
                  Finalizado
                </span>
              )}

              {!!tournament.isFeatured && (
                <span className="MatchList__label MatchList__featured-label">
                  Destaque
                </span>
              )}

              {!!tournament.isTournamentHided && (
                <span className="MatchList__label MatchList__hidded-label">
                  Escondido
                </span>
              )}
            </TableCellStyle>
            <TableCellStyle>
              <Link
                to={`${process.env.PUBLIC_URL}/u/campeonatos/editar/${tournament.tournamentId}`}
              >
                <Button size="small" block skin="accent" text="Editar" />
              </Link>
            </TableCellStyle>
            <TableCellStyle>
              <Button
                size="small"
                onClick={() => {
                  props.onDeleteRequest(tournament);
                }}
                block
                skin="error"
                text="Excluir"
              />
            </TableCellStyle>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 75, 100, 200, 300]}
            count={-1}
            rowsPerPage={props.count}
            page={props.page - 1}
            labelRowsPerPage="Itens por página"
            labelDisplayedRows={({ from, to }) =>
              `Exibindo itens ${from}-${to}`
            }
            onChangePage={props.onChangePage}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </TableStyle>
  </TableContainer>
);

const TableStyle = styled(Table)``;

const TableHeadStyle = styled(TableHead)`
  background-color: var(--gray-lighter);
  text-transform: uppercase;
  font-weight: bold;
`;

const TournamentTitleCellStyle = styled(TableCell)`
  font-size: 12px;
  line-height: 18px;
  padding: 5px;
  font-family: 'Lato', sans-serif;
`;

const TableCellStyle = styled(TableCell)`
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  background-color: #fff;
  font-family: 'Lato', sans-serif;
`;

export default TournamentTable;
