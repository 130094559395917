import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

// 3rd
import { formValueSelector } from 'redux-form';

// components
import Card from '../../../../components/Card';
import { S3_BASE_URL } from '../../../../utils/constants';

class ArticlePreview extends Component {
  render() {
    const { previewValues } = this.props;

    return (
      <Card title="Preview">
        <h1>{previewValues.articleTitle}</h1>
        <h4>
          <i>{previewValues.articleExcerpt}</i>
        </h4>

        <img
          src={`${S3_BASE_URL}/${previewValues.articleImage}`}
          width="100%"
          alt=""
        />
        <small>{previewValues.articleImageCredit}</small>

        {previewValues.blocks &&
          previewValues.blocks.map(block => (
            <Fragment key={block.title}>
              <h3>{block.title}</h3>
              <ReactMarkdown source={block.text} />
              {block.highlight && (
                <div className="Article__block-highlight">
                  <iframe
                    title={block.highlight}
                    src={`https://clips.twitch.tv/embed?clip=${
                      block.highlight.split('.tv/')[1]
                    }&autoplay=false&parent=eatclean.draft5.gg`}
                    frameBorder="0"
                    allowFullScreen="true"
                    height="300"
                    width="100%"
                  />
                </div>
              )}
            </Fragment>
          ))}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    previewValues: {
      articleTitle: formValueSelector('articleForm')(state, 'articleTitle'),
      articleExcerpt: formValueSelector('articleForm')(state, 'articleExcerpt'),
      articleImage: formValueSelector('articleForm')(state, 'articleImage'),
      articleImageCredit: formValueSelector('articleForm')(
        state,
        'articleImageCredit'
      ),
      blocks: formValueSelector('articleForm')(state, 'blocks'),
    },
  };
}

export default connect(mapStateToProps)(ArticlePreview);
