import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

export default class AuthService {
  static getCookies() {
    const cookies = {};
    document.cookie.split(';').forEach(cookie => {
      const [key, value] = cookie.split('=');

      cookies[key.trim()] = decodeURIComponent(value);
    });

    return cookies;
  }

  static getAuthToken() {
    return this.getCookies().d5_session;
  }

  static validateToken() {
    const url = `${API_BASE_URL}/legacy-validate`;

    return axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getAuthToken()}`,
        },
      }
    );
  }

  static logout() {
    document.cookie =
      'd5_session=; domain=.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'displayName=; domain=.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'userPhoto=; domain=.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    document.cookie =
      'd5_session=; domain=eatclean.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'displayName=; domain=eatclean.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'userPhoto=; domain=eatclean.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    document.cookie =
      'd5_session=; domain=streamops-dashboard.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'displayName=; domain=streamops-dashboard.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      'userPhoto=; domain=streamops-dashboard.draft5.gg; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
