import styled from 'styled-components';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import CampaignsService from '../../services/campaigns';
import DeleteCampaign from './DeleteCampaign';
import LoadMask from '../../components/LoadMask';
import { toast } from 'react-toastify';

export default class Campaigns extends Component {
  headers = [
    { title: 'Nome da Campanha' },
    { title: 'Data de Início' },
    { title: 'Data de Término' },
    { title: 'Status' },
    { title: 'Ações' },
    { title: '' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      campaigns: [],
      deleteCampaign: {
        isModalOpen: false,
        deletedCampaign: null,
      },
    };
  }

  componentDidMount() {
    this.getCampaigns();
  }

  getCampaigns() {
    CampaignsService.getCampaigns().then(response => {
      this.setState({
        isLoading: false,
        campaigns: response.data.data,
      });
    });
  }

  formatCampaignsArray() {
    return this.state.campaigns.map(campaign => [
      campaign.campaignName,
      moment(campaign.campaignStartDate, 'X').format('L'),
      moment(campaign.campaignEndDate, 'X').format('L'),
      <div>
            {campaign.campaignStatus ?
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__actived-label">Ativa</span>
              ) :
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__deactived-label">Desativada</span>
              )}
          </div>,
      <NavLink
        to={{
          pathname: `${process.env.PUBLIC_URL}/u/campanhas/editar/${campaign.campaignId}`,
          campaign,
        }}
      >
        <Button size="small" text="Editar" block skin="accent" />
      </NavLink>,
      <Button
        size="small"
        text="Excluir"
        block
        skin="error"
        onClick={() => {
          this.setState({
            deleteCampaign: {
              isModalOpen: true,
              deletedCampaign: campaign,
            },
          });
        }}
      />,
    ]);
  }

  handleModalClose = () => {
    this.setState({
      deleteCampaign: {
        isModalOpen: false,
        deletedCampaign: null,
      },
    });
  };

  onDelete = campaignId => {
    this.setState({ isLoading: true });
    CampaignsService.deleteCampaign(campaignId)
      .then(() => {
        toast.success('Campanha deletada com sucesso');
        this.setState({
          deleteCampaign: {
            isModalOpen: false,
            deletedCampaign: null,
          },
        });

        this.getCampaigns();
      })
      .catch(err => {
        toast.error(err.response.data.error || err.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { deleteCampaign, isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Campanhas" />
        <div className="PageContainer">
          <Menu>
            <NavLink to={`${process.env.PUBLIC_URL}/u/campanhas/novo`}>
              <Button
                size="small"
                text="+ Adicionar Campanha"
                block
                skin="success"
              />
            </NavLink>
          </Menu>
          <Table headers={this.headers} data={this.formatCampaignsArray()} />

          <DeleteCampaign
            open={deleteCampaign.isModalOpen}
            deletedCampaign={deleteCampaign.deletedCampaign}
            handleClose={this.handleModalClose}
            onDelete={this.onDelete}
          />
        </div>
      </Fragment>
    );
  }
}

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;
