import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import FontAwesome from 'react-fontawesome';

const Stage = props => {
  const {selectedStageId, stage, onStageSelect, onStageEdit, onStageDelete} = props
  return (
    <ListItem
      button
      selected={selectedStageId === stage.stageId}
      onClick={() => {
        onStageSelect(stage.stageId);
      }}
    >
      <ListItemAvatar>
        <Avatar>{stage.stageName[0].toUpperCase()}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={stage.stageName}
        secondary={`${stage.substageData.length} substages - posição ${stage.stagePosition}`}
      />
      <ListItemSecondaryAction>
        <ButtonGroup size="small">
          <Button
            onClick={() => {
              onStageEdit(stage);
            }}
          >
            <FontAwesome name="pencil" />
          </Button>
          <Button
            onClick={() => {
              onStageDelete(stage);
            }}
          >
            <FontAwesome name="trash" />
          </Button>
        </ButtonGroup>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default Stage;
