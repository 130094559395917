import React from 'react';
import styled from 'styled-components';


const BracketMatch = props => {
  const teamAName = props.matchData.teamA.name || props.matchData.teamA.teamName
  const teamBName = props.matchData.teamB.name || props.matchData.teamB.teamName
  const teamAScore = props.matchData.series ? props.matchData.series.scoreA : props.matchData.seriesScoreA
  const teamBScore = props.matchData.series ? props.matchData.series.scoreB : props.matchData.seriesScoreB
  return (
    <BracketMatchContainer
      containerHeight={props.containerHeight}
      onClick={() => props.onEdit(props.index)}
    >
      {props.matchData.isHidden && (
        <TeamsContainer isActive={props.isActive}>
          <EmptySlot>Espaço Vazio</EmptySlot>
        </TeamsContainer>
      )}

      {!props.matchData.isHidden && (
        <TeamsContainer isActive={props.isActive}>
          <TeamContainer
            hasTeamB={
              teamBName && teamBScore !== null
            }
          >
            <span>{teamAName}</span>
            <small>{teamAScore}</small>
          </TeamContainer>

          {teamBName && teamBScore !== null && (
            <TeamContainer>
              <span>{teamBName}</span>
              <small>{teamBScore || 0}</small>
            </TeamContainer>
          )}
        </TeamsContainer>
      )}

      {props.index !== 0 && (
        <BracketLineContainer containerHeight={props.containerHeight}>
          <BracketLine containerHeight={props.containerHeight} first />
          <BracketLine
            containerHeight={props.containerHeight}
            second
            isOdd={props.isOdd}
          />
          <BracketLine containerHeight={props.containerHeight} third />
          <BracketLine
            containerHeight={props.containerHeight}
            fourth
            isOdd={props.isOdd}
          />
        </BracketLineContainer>
      )}
    </BracketMatchContainer>
)};

const BracketMatchContainer = styled.div`
  height: ${props => props.containerHeight}px;
  display: flex;
  align-items: center;
  position: relative;
`;

const TeamsContainer = styled.div`
  cursor: pointer;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  border: 1px solid
    ${props => (props.isActive ? 'var(--accent)' : 'var(--gray-lighter)')};
  border-radius: 4px;
  flex: 1;
  transition: all 0.2s;

  color: ${props => (props.isActive ? 'var(--accent)' : 'inherit')};

  &:hover {
    border: 1px solid var(--accent);
  }
`;

const TeamContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--gray-lighter);
  font-size: 13px;
  font-weight: bold;

  &:first-child {
    ${props => (props.hasTeamB ? 'border-top: 0px' : '')}
  }

  span {
    padding: 10px;
    flex: 1;
  }

  small {
    display: inline-block;
    width: 5ch;
    background: #f0f0f0;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    border-left: 1px solid var(--gray-lighter);
  }
`;

const BracketLineContainer = styled.div`
  width: 61px;
  display: flex;
  flex-wrap: wrap;
  height: ${props => props.containerHeight}px;
`;

const BracketLine = styled.div`
  ${props =>
    props.first
      ? `
    border-bottom: 1px solid #3f3f3f;
    `
      : ''}

  ${props =>
    props.second
      ? `
    border-left: 1px solid #3f3f3f;
    top: 1px;
    position: relative;

    ${props.isOdd ? 'border-left-color: transparent;' : ''}
    `
      : ''}

  ${props =>
    props.fourth
      ? `
    border-left: 1px solid #3f3f3f;
    border-bottom: 1px solid #3f3f3f;
    position: relative;
    top: -1px;
    ${props.isOdd ? '' : 'border-color: transparent;'}
  `
      : ''}


  ${props =>
    props.third
      ? `
    border-width: 0px;
  `
      : ''}

  flex-basis: 30px;
  width: 30px;
  height: 30px;
  height: ${props => props.containerHeight / 2}px;
`;

const EmptySlot = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 5px;
  color: var(--gray-light);
`;

export default BracketMatch;
