import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TableTeam from './TableTeam';
import styled from 'styled-components';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FontAwesome from 'react-fontawesome';

export default class TableEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // update availableTeams array to match API format
      // filter for existing teams
      availableTeams: props.availableTeams
        .filter(availableTeam => {
          const { teamId, teamCountry } = availableTeam;

          if (teamCountry === 'TBA') return true;


          const filteredTeams = this.props.field.value.filter(
            team => team.teamData.teamId === teamId
          );

          return filteredTeams.length === 0;
        })
        .map(team => ({
          teamData: team,
        })),
    };
  }

  onDragEnd = result => {
    const { destination, source } = result;
    const { field } = this.props;

    // if no change or no destination
    if (!destination) return;
    if (destination.index === source.index) return;

    // reorder state
    const newList = Array.from(field.value);
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, field.value[source.index]);

    this.props.form.setFieldValue('lineData', newList);
  };

  addTeamToTable = (values) => {
    const { field } = this.props;

    const teamsArr = Array.from(field.value);
    const availableTeamsArr = Array.from(this.state.availableTeams);

    teamsArr.push(
      availableTeamsArr.filter(
        team => team.teamData.teamId === values.target.value
      )[0]
    );

    this.setState({
      availableTeams: availableTeamsArr.filter(
        team => team.teamData.teamCountry === 'TBA' || team.teamData.teamId !== values.target.value
      ),
    });

    this.props.form.setFieldValue('lineData', teamsArr);
  };

  removeTeamFromTable = (selectedTeam, indexToRemove) => {
    const { field } = this.props;

    const teamsArr = Array.from(field.value);
    const availableTeamsArr = Array.from(this.state.availableTeams);

    if (selectedTeam.teamData.teamCountry !== 'TBA') {
      availableTeamsArr.push(
        teamsArr.filter(
          team => team.teamData.teamId === selectedTeam.teamData.teamId
        )[0]
      );
    }

    this.setState({
      availableTeams: availableTeamsArr,
    });

    this.props.form.setFieldValue(
      'lineData',
      teamsArr.filter(
        (_team, index) => index !== indexToRemove
      )
    );
  };

  onChangeTotalScore = (index, total) => {
    const { field } = this.props;

    const teamsArr = Array.from(field.value);
    teamsArr[index].totalScore = total;

    this.props.form.setFieldValue(
      'lineData',
      teamsArr
    );
  }

  render() {
    const { availableTeams } = this.state;

    const { ranges } = this.props.form.values;

    const {
      matches,
      selectedMatches,
      automaticPoints
    } = this.props;

    return (
      <Fragment>
        <TableHeader>
          <FontAwesome name="bars" />
          <Position>#</Position>
          <THead stretch>Equipe</THead>
          <THead title="Jogos Participados">J</THead>
          <THead title="Vitórias">V</THead>
          <THead title="Empates">E</THead>
          <THead title="Derrotas">D</THead>
          <THead title="Saldo de Rounds">SR</THead>
          <THead title="Pontos">P</THead>
          <FontAwesome name="minus-circle" />
        </TableHeader>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppableId">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.props.field.value.map((team, index) => {
                  const activeRange = ranges.filter(
                    range =>
                      range.fromPosition <= index + 1 &&
                      range.toPosition >= index + 1
                  );

                  return (
                    <TableTeam
                      team={team}
                      index={index}
                      automaticPoints={automaticPoints}
                      activeRange={activeRange[0]}
                      onTeamRemove={this.removeTeamFromTable}
                      onChangeTotalScore={this.onChangeTotalScore}
                      teamMatches={matches.filter(
                        match =>
                          match.teamA.teamId === team.teamData.teamId ||
                          match.teamB.teamId === team.teamData.teamId
                      )}
                      selectedMatches={selectedMatches}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <TeamSelect>
          <Formik
            initialValues={{ teamId: '' }}
          >
            {({ values }) => (
              <Form>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Equipe</InputLabel>
                  <StyledSelect
                    name="teamId"
                    onChange={this.addTeamToTable}
                    label="Equipe"
                    value={values.teamId}
                  >
                    {availableTeams.map(team => (
                      <MenuItem value={team.teamData.teamId}>
                        {team.teamData.teamName}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Form>
            )}
          </Formik>
        </TeamSelect>
        <InputLabel>
          <input
            type="checkbox"
            value={automaticPoints}
            checked={automaticPoints}
            onClick={() => { this.props.setFieldValue("automaticPoints", !automaticPoints) }}
            className="NewMatchForm__checkbox"
          />
          Calculo automatico de pontos
        </InputLabel>
      </Fragment>
    );
  }
}

const TeamSelect = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  margin-right: 12px;
  min-width: 120px;
`;

const TableHeader = styled.div`
  display: flex;

  padding: 16px 16px 16px 8px;
  border-bottom: 1px solid var(--gray-lightest);
  border-left: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const THead = styled.div`
  font-weight: bold;
  flex-basis: 10%;
  text-align: center;

  ${props => (props.stretch ? 'flex: 1' : '')};
  ${props => (props.stretch ? 'text-align: left' : '')}
`;

const Position = styled.div`
  padding: 0 16px;
  color: var(--gray);
  font-size: 12px;
`;