import React, { Component, Fragment } from 'react';
import moment from 'moment';

// 3rd party
import { NavLink } from 'react-router-dom';

// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import LoadMask from '../../components/LoadMask';
import PlayerTransfersService from '../../services/playerTransfers';
import Flag from '../../components/Flag';
import PageNavigator from '../../components/PageNavigator';
import { toast } from 'react-toastify';

class TransfersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Data' },
        { title: 'Nome do Jogador' },
        { title: 'Função' },
        { title: 'País do Jogador' },
        { title: 'Origem' },
        { title: 'Destino' },
        { title: 'Status' },
        { title: 'Free Agent?' },
        { title: 'Aposentando?' },
        { title: 'Reserva?' },
        { title: 'Inativo?' },
        { title: 'Ações' },
        { title: '' },
      ],
      currentPage: 1,
      currentAmount: 25,
      data: [],
      isLoading: false,
      deleteModalIsVisible: false,
      deleteId: null,
    };
  }

  componentDidMount() {
    const { currentPage, currentAmount } = this.state;
    const { isArchived } = this.props;

    this.getPlayerTransfers(currentPage, currentAmount, isArchived);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isArchived !== prevProps.isArchived) {
      this.getPlayerTransfers(1, 25, this.props.isArchived);
      this.resetPagination();
    }
  }

  getPlayerTransfers = (page, amount, archived) => {
    this.setState({ isLoading: true });

    PlayerTransfersService.getPlayerTransfers(page, amount, archived)
      .then(({ data }) => {
        this.setState({
          data: this.formatData(data.data),
          isLoading: false,
        });
      });
  }

  resetPagination = () => {
    this.setState({
      currentPage: 1,
      currentAmount: 25,
    });
  }

  deletePlayerTransfer = (transferId) => {
    this.setState({ isLoading: true });

    PlayerTransfersService.deletePlayerTransfer(transferId)
      .then(() => {
        toast('Transferência excluída com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
        this.getPlayerTransfers(this.state.currentPage, this.state.currentAmount);
      })
      .catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
      });
  }

  formatData = (data) => {
    return data
    .sort((a,b) =>(b.transferDate + b.transferId/1000) - (a.transferDate + a.transferId/1000))
    .map((transfer) => {
      const {
        transferId,
        transferDate,
        playerName,
        role,
        playerCountry,
        originTeam,
        destinationTeam,
        transferStatus,
        isFreeAgent,
        isRetiring,
        isBenched,
        isInactive,
      } = transfer;
      return [
        moment(transferDate * 1000).format('DD/MM'),
        playerName,
        role,
        <div>
          <Flag country={playerCountry} size={15} /> <span>{playerCountry}</span>
        </div>,
        originTeam || 'N/A',
        destinationTeam || 'N/A',
        transferStatus,
        <span>{isFreeAgent ? 'Sim' : '-'}</span>,
        <span>{isRetiring ? 'Sim' : '-'}</span>,
        <span>{isBenched ? 'Sim' : '-'}</span>,
        <span>{isInactive ? 'Sim' : '-'}</span>,
        <NavLink to={`${process.env.PUBLIC_URL}/u/transferencias/editar/${transferId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
        <Button size="small" onClick={() => { this.promptDeleteTransfer(transferId); }} text="Excluir" block skin="error" />,
      ];
    });
  }

  onAmountChange = (newAmount) => {
    const { currentPage } = this.state;

    this.getPlayerTransfers(currentPage, newAmount);

    this.setState({
      currentAmount: newAmount,
    });
  }

  onPageChange = (newPage) => {
    const { currentAmount } = this.state;

    if (newPage < 1) return;

    this.getPlayerTransfers(newPage, currentAmount);

    this.setState({
      currentPage: newPage,
    });
  }

  promptDeleteTransfer = (transferId) => {
    this.setState({
      deleteId: transferId,
      deleteModalIsVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteId: null,
    });
  }

  render() {
    const {
      data,
      isLoading,
      deleteModalIsVisible,
      deleteId,
    } = this.state;

    const {
      pageTitle
    } = this.props;

    return (
      <Fragment>
        <Header title={pageTitle} />

        {isLoading && <LoadMask />}

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Ranking"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text="Excluir Transferência"
                  skin="error"
                  onClick={() => {
                    this.deletePlayerTransfer(deleteId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir esta transferência??
          </Modal>
        }


        <div className="TransfersList PageContainer">
          <Table
            headers={this.state.headers}
            data={data}
          />

          {
            !isLoading &&
            <PageNavigator
              currentPage={this.state.currentPage}
              currentAmount={this.state.currentAmount}
              onAmountChange={this.onAmountChange}
              onPageChange={this.onPageChange}
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default TransfersList;
