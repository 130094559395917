import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { NavLink } from 'react-router-dom';

// components
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import Modal from '../../../components/Modal';
import LoadMask from '../../../components/LoadMask';

// Redux
import { getMapList } from '../../../redux/map-list';
import { deleteMap } from '../../../redux/map-detail';
import { toast } from '../../../../node_modules/react-toastify';
import { getDeleteMapUi } from '../../../redux/selectors/map-detail';
import { getMapListUi } from '../../../redux/selectors/map-list';

class MapList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'Mapa' },
        { title: 'Ações' },
        { title: '' },
      ],
      deleteModalIsVisible: false,
      deleteModalMapObject: {},
    };
  }

  componentDidMount() {
    this._getMapList();
  }

  _getMapList = () => {
    this.props.dispatch(getMapList());
  }

  _formatMapListArray = () => (
    this.props.mapList.map((map) => {
      const { mapName, mapId } = map;

      return [
        mapName,
        <NavLink to={`${process.env.PUBLIC_URL}/u/mapas/editar/${mapId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
        <Button size="small" onClick={() => { this._promptDeleteMap(map); }} text="Excluir" block skin="error" />,
      ];
    })
  );

  _promptDeleteMap = (map) => {
    this.setState({
      deleteModalMapObject: map,
      deleteModalIsVisible: true,
    });
  }

  _closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteModalMapObject: {},
    });
  }

  _deleteMap = (mapId) => {
    const { dispatch } = this.props;

    dispatch(deleteMap(mapId))
      .then(
        // Success
        () => {
          toast('Mapa excluído com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          this._closeModal();
          this._getMapList();
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });

          this._closeModal();
        },
      );
  }

  render() {
    const {
      deleteModalIsVisible,
      deleteModalMapObject,
    } = this.state;

    const {
      deleteMapUi,
      ui,
    } = this.props;

    return (
      <Fragment>
        <Header title="Mapas" />

        { deleteMapUi.isFetching && <LoadMask /> }
        { ui.isFetching && <LoadMask /> }

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Equipe"
            onBackdropClick={this._closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeModal}
                />
                <Button
                  size="small"
                  text={`Excluir ${deleteModalMapObject.mapName}`}
                  skin="error"
                  onClick={() => {
                    this._deleteMap(deleteModalMapObject.mapId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir o mapa <strong>{deleteModalMapObject.mapName}</strong>?
          </Modal>
        }

        {/* =================================== */}
        {/* ============ TEAM LIST ============ */}
        {/* =================================== */}
        <div className="MapList PageContainer">
          <Table
            headers={this.state.headers}
            data={this._formatMapListArray()}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapList: state.maps.data,
    ui: getMapListUi(state),
    deleteMapUi: getDeleteMapUi(state),
  };
}

export default connect(mapStateToProps)(MapList);
