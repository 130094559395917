import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';
import LiveOddProviderForm from './form/LiveOddProviderForm';
import LiveOddProvidersService from '../../services/live-odd-providers';

class AddLiveOddProvider extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
  }
  onSubmit = (values) => {
    this.setState({ loading: true })

    LiveOddProvidersService.create(values).then(
      // Success
      (resp) => {
        const { liveOddProviderId } = resp.data.data
        toast('Provedor de probabilidade criado com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });
        // Ver forma de utilizar o router
        window.location.replace(`${process.env.PUBLIC_URL}/u/live-odd-providers/editar/${liveOddProviderId}`);
      },
      // Error
      (error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });
        this.setState({ loading: false })
      },
    );
  }

  render() {
    const { loading } = this.state
    return (
      <Fragment>
        <Header title="Adicionar Novo Provedor de Probabilidade" />
        {
          loading ?
            (<LoadIndicator />) :
            (
              <div className="AddLiveOddProvider PageContainer">
                <LiveOddProviderForm onSubmit={this.onSubmit} />
              </div>
            )
        }

      </Fragment>
    );
  }
}

export default AddLiveOddProvider
