import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { API_BASE_URL } from '../../../utils/constants';

// 3rd
import { NavLink, Route } from 'react-router-dom';

// pages
import Broadcast from './Broadcast';
import BroadcastArticle from './Article';

// components
import Header from '../../../components/Header';
import Tabs from '../../../components/Tabs';
import MatchSummary from './components/MatchSummary';

// services
import MatchesService from '../../../services/matches';
import Card from '../../../components/Card';

class BroadcastPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matchData: null,
    };

    this.socket = io(`${API_BASE_URL}`, {
      query: `id=${this.props.match.params.matchId}`,
    });
  }

  componentDidMount() {
    this.getMatchDetail();
  }

  componentWillUnmount() {
    this.socket.close();
  }

  getMatchDetail = () => {
    const { matchId } = this.props.match.params;

    MatchesService.getMatchDetail(matchId)
      .then(({ data }) => {
        this.setState({ matchData: data.data });
      });
  }

  render() {
    const {
      matchData,
    } = this.state;

    const { match: { url, params: { matchId } } } = this.props;

    return (
      <Fragment>
        <Header title="Play By Play" />
        <Tabs>
          <NavLink
            exact
            to={url}
            activeClassName="Tabs__tab-item--active"
            className="Tabs__tab-item"
          >
            Transmissão
          </NavLink>
          <NavLink
            exact
            to={`${url}/artigo`}
            activeClassName="Tabs__tab-item--active"
            className="Tabs__tab-item"
          >
            Artigo
          </NavLink>
        </Tabs>

        {/* ============================ */}
        {/* == NEXT MATCHES AND NEWS === */}
        {/* ============================ */}
        {
          matchData &&
          <div className="PageContainer">
            <MatchSummary
              data={matchData}
              socket={this.socket}
              onPlayByPlayStatusChange={this.getMatchDetail}
            />
          </div>
        }

        {
          matchData && matchData.isPlayByPlay &&
          <Fragment>
            <Route
              exact
              path={url}
              render={props => (
                <Broadcast
                  {...props}
                  matchData={matchData}
                  matchId={matchId}
                  socket={this.socket}
                />
              )}
            />

            <Route
              exact
              path={`${url}/artigo`}
              render={props => (
                <BroadcastArticle
                  {...props}
                  matchData={matchData}
                  matchId={matchId}
                />
              )}
            />
          </Fragment>
        }

        {
          matchData && !matchData.isPlayByPlay &&
          <div className="PageContainer">
            <Card>
              Habilite a transmissão da partida para cadastrar eventos e artigo
            </Card>
          </div>
        }

      </Fragment>
    );
  }
}

export default connect(null)(BroadcastPage);
