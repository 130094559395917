import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';
import LiveOddProvidersService from '../../services/live-odd-providers';
import LiveOddProviderForm from './form/LiveOddProviderForm';

class EditLiveOddProvider extends Component {
  constructor() {
    super()
    this.state = {
      liveOddProviderId: 0,
      liveOddProvider: null,
      loading: true,
    }
  }
  componentDidMount() {
    const liveOddProviderId = +this.props.match.params.id
    this.loadBetHouse(liveOddProviderId)
  }

  loadBetHouse(liveOddProviderId) {
    LiveOddProvidersService.getById(liveOddProviderId)
      .then((response) => {
        const liveOddProvider = response.data.data
        this.setState({ liveOddProviderId, liveOddProvider, loading: false })
      }).catch(error => {
        console.error(error)
      });
  }

  onPlayerEdit = async (values) => {
    this.setState({ loading: true })
    try {
      const response = await LiveOddProvidersService.edit(this.state.liveOddProviderId, values);

      this.setState({ liveOddProvider: response.data.data })

      toast('provedor de probabilidade editado com sucesso', {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: true,
      });

    } catch (error) {
      toast(error.message, {
        position: 'bottom-center',
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
      });
    }

    this.setState({ loading: false })
  }

  render() {
    const { liveOddProvider, loading } = this.state;
    return (
      <Fragment>
        <Header title="Editar Provedor de Probabilidade" />
        {
          loading && <LoadIndicator />
        }
        {
          !loading && !!liveOddProvider &&
          (
            <div className="EditBetHouse PageContainer">
              <LiveOddProviderForm
                initialData={liveOddProvider}
                onSubmit={this.onPlayerEdit}
              />
            </div>
          )
        }
      </Fragment >
    );
  }
}

export default EditLiveOddProvider