import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import CampaignsService from '../../services/campaigns';
import CampaignForm from './CampaignForm';

class EditCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      initialData: props.location.campaign,
    };
  }

  componentDidMount() {
    if (!this.props.location.campaign) {
      this.fetchInitialData();
    }
  }

  fetchInitialData = () => {
    const { campaignId } = this.props.match.params;

    this.setState({ isLoading: true });

    CampaignsService.getSingleCampaign(campaignId).then(response => {
      this.setState({
        initialData: response.data.data,
        isLoading: false,
      });
    });
  };

  onSubmit = values => {
    const {
      history,
      match: {
        params: { campaignId },
      },
    } = this.props;

    this.setState({ isLoading: true });
    CampaignsService.editCampaign(campaignId, values)
      .then(() => {
        toast.success('Campanha editada com sucesso');
        this.setState({ isLoading: false });

        history.push(`${process.env.PUBLIC_URL}/u/campanhas`);
      })
      .catch(err => {
        toast.error(err.response.data.error || err.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, initialData } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Editar Campanha" />
        <div className="PageContainer">
          <CampaignForm onSubmit={this.onSubmit} initialData={initialData} />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditCampaign);
