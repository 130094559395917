import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import LoadMask from '../../components/LoadMask';
import FeatureFlagsService from '../../services/feature-flags';

export default class FeatureFlags extends Component {
  headers = [{ title: 'Flag' }, { title: 'Valor' }, { title: 'Ações' }];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      featureFlags: [],
    };
  }

  componentDidMount() {
    this.getCampaigns();
  }

  getCampaigns() {
    FeatureFlagsService.getFeatureFlags().then(response => {
      this.setState({
        isLoading: false,
        featureFlags: response.data.data,
      });
    });
  }

  formatArray() {
    return this.state.featureFlags.map(flag => [
      flag.name,
      flag.value || '--',
      <NavLink
        to={{
          pathname: `${process.env.PUBLIC_URL}/u/feature-flags/editar/${flag.id}`,
          flag,
        }}
      >
        <Button size="small" text="Editar" block skin="accent" />
      </NavLink>,
    ]);
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Feature Flags" />
        <div className="PageContainer">
          <Table headers={this.headers} data={this.formatArray()} />
        </div>
      </Fragment>
    );
  }
}
