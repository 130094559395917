import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

// 3rd party components
import { TextField } from 'redux-form-material-ui';
import { Col, Row } from 'reactstrap';

// components
import Card from '../../../components/Card';
import Button from '../../../components/Button';

// Utils
import { required } from '../../../utils/form-validators';
import countries from '../../../utils/countries';
import SelectField from '../../../components/SelectField';

class NewStreamForm extends Component {
  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <form className="NewTeamForm" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>
            <Card>
              <Field
                name="streamName"
                fullWidth
                floatingLabelText="Nome da Stream*"
                validate={required}
                component={TextField}
                floatingLabelFixed
              />

              <Field
                name="streamInternalName"
                fullWidth
                floatingLabelText="Nome interno da Stream"
                component={TextField}
                floatingLabelFixed
              />

              <Field
                name="streamPlatform"
                component={SelectField}
                label="Plataforma da Stream*"
                validate={required}
                fullWidth
              >
                <option value="YouTubeGaming">YouTube Gaming</option>
                <option value="YouTubeEmbed">YouTube Embed</option>
                <option value="Twitch">Twitch</option>
                <option value="Kick">Kick</option>
                <option value="FacebookLive">Facebook</option>
                <option value="Smashcast">Smashcast</option>
                <option value="Mixer">Mixer</option>
              </Field>

              <Field
                name="streamChannel"
                fullWidth
                floatingLabelText="Canal da Stream*"
                component={TextField}
                validate={required}
                floatingLabelFixed
              />

              <Field
                name="streamLanguage"
                component={SelectField}
                validate={required}
                label="Idioma da Stream"
              >
                {countries.map(country => (
                  <option value={country.label}>{country.fullName}</option>
                ))}
              </Field>

              <hr />

              <Button
                text="Enviar"
                skin="accent"
                size="small"
                block
                className="NewTeamForm__submit"
                disabled={!valid}
              />
            </Card>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { data } = ownProps;

  return data
    ? {
        initialValues: {
          streamName: data.streamName,
          streamInternalName: data.streamInternalName,
          streamPlatform: data.streamPlatform,
          streamChannel: data.streamChannel,
          streamLanguage: data.streamLanguage,
        },
      }
    : {
        initialValues: {
          streamName: '',
          streamInternalName: '',
          streamPlatform: 'Twitch',
          streamChannel: '',
          streamLanguage: '',
        },
      };
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'newStreamForm' })(NewStreamForm)
);
