import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import RankingsService from '../../services/rankings';

export default class Ranking extends Component {
  headers = [
    { title: 'ID' },
    { title: 'Data' },
    { title: 'Ações' },
    { title: '' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      rankings: [],
      deleteModalIsVisible: false,
      deleteRankingId: null,
    };
  }

  componentDidMount() {
    this.getRankings();
  }

  getRankings() {
    RankingsService.getRankings()
      .then(response => {
        this.setState({
          isLoading: false,
          rankings: response.data.data,
        });
      })
      .catch(err => {
        toast.error(err.message);

        this.setState({
          isLoading: false,
        });
      });
  }

  deleteRanking = rankingId => {
    this.setState({ isLoading: true });

    RankingsService.deleteRanking(rankingId)
      .then(() => {
        toast.success('Cálculo deletado com sucesso');
      })
      .catch(err => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          deleteModalIsVisible: false,
          deleteRankingId: null,
        });

        this.getRankings();
      });
  };

  prompDeleteRanking = rankingId => {
    this.setState({
      deleteModalIsVisible: true,
      deleteRankingId: rankingId,
    });
  };

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteRankingId: null,
    });
  };

  formatRankingsArray = () => {
    const { rankings } = this.state;

    return rankings.map(ranking => [
      ranking.id,
      ranking.rankingDate,
      <NavLink
        to={`${process.env.PUBLIC_URL}/u/ranking-calculator/editar/${ranking.id}`}
      >
        <Button size="small" text="Extrato" block skin="accent" />
      </NavLink>,
      <Button
        size="small"
        text="Excluir"
        block
        skin="error"
        onClick={() => this.prompDeleteRanking(ranking.id)}
      />,
    ]);
  };

  render() {
    const { isLoading, deleteModalIsVisible, deleteRankingId } = this.state;

    return (
      <>
        {isLoading && <LoadMask />}
        <Header title="Ranking" />
        <div className="PageContainer">
          <Table headers={this.headers} data={this.formatRankingsArray()} />
        </div>

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {deleteModalIsVisible && (
          <Modal
            title="Excluir Ranking"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text={`Excluir ${deleteRankingId}`}
                  skin="error"
                  onClick={() => {
                    this.deleteRanking(deleteRankingId);
                  }}
                />
              </>
            )}
          >
            Deseja excluir o ranking <strong>{deleteRankingId}</strong>?
          </Modal>
        )}
      </>
    );
  }
}
