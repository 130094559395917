import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// 3rd party
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

// Components
import StreamForm from '../shared/StreamForm';
import Header from '../../../components/Header';
import LoadMask from '../../../components/LoadMask';

// redux
import { addNewStream } from '../../../redux/stream-detail';
import { getAddNewStreamUi } from '../../../redux/selectors/stream-detail';

class AddNewStream extends Component {
  _onStreamSubmit = (res) => {
    const { dispatch, history } = this.props;

    dispatch(addNewStream(res))
      .then(
        // Success
        () => {
          toast('Stream cadastrada com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          history.push(`${process.env.PUBLIC_URL}/u/streams`);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });
        },
      );
  }

  render() {
    const { addNewStreamUI } = this.props;

    return (
      <Fragment>
        { addNewStreamUI.isFetching && <LoadMask /> }
        <Header title="Adicionar Nova Stream" />
        <div className="AddNewStream PageContainer">
          <StreamForm onSubmit={this._onStreamSubmit} />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    addNewStreamUI: getAddNewStreamUi(state),
  };
}

export default withRouter(connect(mapStateToProps)(AddNewStream));
