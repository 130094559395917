import axios from 'axios';
import { API_TS_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class RankingsService {
  static getRankings() {
    const url = `${API_TS_BASE_URL}/ranking-calculator`;

    return axios.get(url);
  }

  static addNewRanking(values) {
    const formattedValues = {
      ...values,
      bonusAndPenalties: values.bonusAndPenalties.map(bNp => ({
        ...bNp,
        percent: bNp.percent / 100,
      })),
    };

    const url = `${API_TS_BASE_URL}/ranking-calculator`;

    return axios.post(url, formattedValues, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static deleteRanking(rankingId) {
    const url = `${API_TS_BASE_URL}/ranking-calculator/${rankingId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static getSingleRanking(rankingId) {
    const url = `${API_TS_BASE_URL}/ranking-calculator/${rankingId}`;

    return axios.get(url);
  }
}
