import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';
import moment from 'moment';
import parseTwitchClipUrl from '../utils/parse-twitch-clip-url';

export default class PlayByPlayService {
  static formatEventData(values, matchData, eventDate = null) {
    let data = {
      ...values,
      eventDate: eventDate || moment().format('X'),
    };

    if (values.featuredTeam) {
      data.featuredTeam =
        parseInt(values.featuredTeam, 10) === matchData.teamA.teamId
          ? matchData.teamA
          : matchData.teamB;
    }

    if (values.eventHighlight) {
      data.eventHighlight = parseTwitchClipUrl(values.eventHighlight);
    }

    if (!values.hasCustomLabel) {
      data = {
        ...data,
        map: {
          name: matchData.scores.filter(map => map.mapId === values.map)[0].map
            .mapName,
          id: matchData.scores.filter(map => map.mapId === values.map)[0].mapId,
        },
      };
    }

    return data;
  }

  static addEvent(matchId, values, matchData) {
    const url = `${API_BASE_URL}/matches/${matchId}/live`;

    const data = this.formatEventData(values, matchData);

    return axios.post(
      url,
      { data },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static editEvent(matchId, values, matchData, eventId, eventDate) {
    const url = `${API_BASE_URL}/matches/${matchId}/live/${eventId}`;

    const data = this.formatEventData(values, matchData, eventDate);

    return axios.put(
      url,
      { data },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static getPreviousEvents(matchId) {
    const url = `${API_BASE_URL}/matches/${matchId}/live`;

    return axios.get(url);
  }

  static deleteEvent(matchId, eventId) {
    const url = `${API_BASE_URL}/matches/${matchId}/live/${eventId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addArticle(
    data,
    matchId,
    isPublished,
    shouldSendNotification,
    shouldTweet,
    tweetText
  ) {
    const url = `${API_BASE_URL}/matches/${matchId}/article`;

    const blockData = ((data.blocks && data.blocks.length) > 0
      ? data.blocks
      : []
    ).map(block => {
      const highlight = block.highlight
        ? parseTwitchClipUrl(block.highlight)
        : null;

      return {
        ...block,
        highlight,
      };
    });

    const config = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(
      url,
      {
        ...data,
        blocks: blockData,
        isPublished,
        shouldSendNotification,
        shouldTweet,
        tweetText,
      },
      config
    );
  }

  static getArticle(matchId) {
    const url = `${API_BASE_URL}/matches/${matchId}/article`;

    return axios.get(url);
  }

  static togglePlayByPlayStatus(matchId, userId, isOn) {
    const url = `${API_BASE_URL}/matches/${matchId}/live/status`;

    return axios.post(
      url,
      {
        isOn,
        userId: parseInt(userId, 10),
      },
      {
        headers: {
          Authorization: getAuthToken(),
        },
      }
    );
  }

  static uploadMatchImage(values, matchId) {
    const url = `${API_BASE_URL}/matches/${matchId}/article/image`;

    const formData = new FormData();

    formData.append('image', values.newMatchImage);

    const options = {
      headers: {
        Authorization: getAuthToken(),
      },
    };

    return axios.post(url, formData, options);
  }
}
