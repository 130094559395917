import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// components
import Button from '../../components/Button';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import LoadMask from '../../components/LoadMask';
import Search from '../../components/Search';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LiveOddProvidersService from '../../services/live-odd-providers';
import moment from 'moment/moment';

import './styles.css';

class ListLiveOddProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: '' },
        { title: 'Nome' },
        { title: 'Slug' },
        { title: 'Data de Cadastro' },
        { title: 'Situação' },
        { title: 'Ações' },
        { title: '' },
      ],
      data: [],
      isLoading: false,
      deleteModalIsVisible: false,
      deleteId: null,
    };
  }

  componentDidMount() {

    this.getRows();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.getRows();
    }
  }

  getRows = (search = "") => {
    this.setState({ isLoading: true });

    LiveOddProvidersService.getAll({ page: 1, amount: 500, search })
      .then(({ data }) => {
        this.setState({
          data: this.formatData(data.data),
          isLoading: false,
        });
      });
  }

  deleteLiveOddProvider = (Id) => {
    this.setState({ isLoading: true });

    LiveOddProvidersService.delete(Id)
      .then(() => {
        toast('Provedor de probabilidade excluído com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
        this.getRows();
      })
      .catch((error) => {
        toast(error.message, {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          hideProgressBar: true,
        });

        this.setState({ isLoading: false });
        this.closeModal();
      });
  }

  formatData = (data) => {
    return data
      .map((row) => {
        const {
          liveOddProviderId,
          liveOddProviderName,
          liveOddProviderSlug,
          liveOddProviderImageUrl,
          liveOddProviderIsActive,
          liveOddProviderCreatedDate,
        } = row;
        return [
          <div>
            <img src={liveOddProviderImageUrl} alt={liveOddProviderName} />
          </div>,
          liveOddProviderName,
          liveOddProviderSlug,
          moment(liveOddProviderCreatedDate).format("DD/MM/YYYY HH:mm"),
          <div>
            {liveOddProviderIsActive ?
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__actived-label">Ativo</span>
              ) :
              (
                <span className="ListLiveOddProvider__label ListLiveOddProvider__deactived-label">Inativo</span>
              )
            }
          </div>,
          <NavLink to={`${process.env.PUBLIC_URL}/u/live-odd-providers/editar/${liveOddProviderId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
          <Button size="small" onClick={() => { this.promptDelete(liveOddProviderId); }} text="Excluir" block skin="error" />,
        ];
      });
  }

  promptDelete = (Id) => {
    this.setState({
      deleteId: Id,
      deleteModalIsVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteId: null,
    });
  }

  render() {
    const {
      data,
      isLoading,
      deleteModalIsVisible,
      deleteId,
    } = this.state;

    return (
      <Fragment>
        <Header title="Lista de Provedores de Probabilidade" />

        {isLoading && <LoadMask />}

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir"
            onBackdropClick={this.closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  onClick={this.closeModal}
                />
                <Button
                  size="small"
                  text="Excluir"
                  skin="error"
                  onClick={() => {
                    this.deleteLiveOddProvider(deleteId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir o provedor de probalidade?
          </Modal>
        }


        <div className="ListLiveOddProvider PageContainer">
          <Menu>
            <NavLink to={`${process.env.PUBLIC_URL}/u/live-odd-providers/novo`}><Button size="small" text="+ Adicionar Provedor de Probabilidade" block skin="success" /></NavLink>
          </Menu>


          <Table
            headers={this.state.headers}
            data={data}
            keyExtractor={(row) => `KEY_${row[4]}` /* Get slug to use with key */}
          />
        </div>
      </Fragment>
    );
  }
}

export default ListLiveOddProvider;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`
