import { createSelector } from 'reselect';
import extractRequestUI from '../../utils/extract-request-ui';

// =============
// Selectors
// =============
const teamDetailSelector = state => state.teamDetail;

// =============
// Selector
// =============
export const getAddNewTeamUi = createSelector(
  teamDetailSelector,
  teamDetail => extractRequestUI(teamDetail.addNewTeam),
);

export const getEditTeamUi = createSelector(
  teamDetailSelector,
  teamDetail => extractRequestUI(teamDetail.editTeam),
);

export const getTeamDetailUi = createSelector(
  teamDetailSelector,
  teamDetail => extractRequestUI(teamDetail.teamDetail),
);

export const getDeleteTeamUi = createSelector(
  teamDetailSelector,
  teamDetail => extractRequestUI(teamDetail.deleteTeam),
);
