import React, { Component, Fragment } from 'react';

// 3rd
import FontAwesome from 'react-fontawesome';

// css
import './styles.css';

class TwitchClip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  toggleClip = () => {
    this.setState({
      isVisible: true,
    });
  }

  render() {
    const {
      height,
      width,
      clipName,
      title,
      thumb,
    } = this.props;

    const {
      isVisible,
      clipData,
    } = this.state;

    return (
      <Fragment>
        {
          !isVisible &&
          <div
            className="TwitchClip__not-visible"
            style={{
              height: `${height}px`,
              width: `${width}px`,
              backgroundImage: `
                linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.6)),
                url(${thumb})
              `,
            }}
            onClick={this.toggleClip}
            onKeyPress={this.toggleClip}
          >
            <FontAwesome name="play" />
          </div>
        }
        {
          isVisible &&
          <iframe
            title={title}
            src={`https://clips.twitch.tv/embed?clip=${clipName}&autoplay=true`}
            frameBorder="0"
            allowFullScreen="true"
            height={height}
            width={width}
          />
        }
      </Fragment>
    );
  }
}

export default TwitchClip;
