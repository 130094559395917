import React, { Component, Fragment } from 'react';

// Components
import Header from '../../components/Header';
import LoadIndicator from "../../components/LoadingIndicator"

// Services
import { toast } from 'react-toastify';
import HubForm from './form/HubForm';

import HubsService from '../../services/hubs';

class AddHub extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
  }

  saveFuncion = async (values) => {
    return HubsService.create(values)
  }

  onSubmitSuccess = () => {
    // const { hubId } = resp.data.data
    toast('Hub criado com sucesso', {
      position: 'bottom-center',
      autoClose: 5000,
      type: toast.TYPE.SUCCESS,
      hideProgressBar: true,
    });
    // Ver forma de utilizar o router
    window.location.replace(`${process.env.PUBLIC_URL}/u/hubs`);
  }

  render() {
    const { loading } = this.state
    return (
      <Fragment>
        <Header title="Adicionar Novo Hub" />
        {
          loading ?
            (<LoadIndicator />) :
            (
              <div className="AddHub PageContainer">
                <HubForm saveFuncion={this.saveFuncion} onSubmitSuccess={this.onSubmitSuccess} />
              </div>
            )
        }

      </Fragment>
    );
  }
}

export default AddHub
