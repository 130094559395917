import axios from 'axios';
import moment from 'moment';
import { API_BASE_URL } from '../utils/constants';
import getAuthToken from '../utils/get-auth-token';

export default class PlayerTransfersService {
  static getPlayerTransfers(page, amount, archived = 0) {
    const url = `${API_BASE_URL}/transfers`;

    return axios.get(
      url,
      { params: { page, amount, archived: archived ? 1 : 0 } },
    );
  }

  static getSinglePlayerTransfer(transferId) {
    const url = `${API_BASE_URL}/transfers/${transferId}`;

    return axios.get(url);
  }

  static deletePlayerTransfer(transferId) {
    const url = `${API_BASE_URL}/transfers/${transferId}`;

    return axios.delete(url, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }

  static addNewTransfer(
    playerName,
    playerCountry,
    originTeam,
    destinationTeam,
    transferStatus,
    transferDate,
    relatedNewsUrl,
    isFreeAgent,
    isRetiring,
    isArchived,
    isBenched,
    isInactive,
    role,
  ) {
    const url = `${API_BASE_URL}/transfers`;

    const data = {
      playerName,
      playerCountry,
      originTeam: parseInt(originTeam, 10) || null,
      destinationTeam: (!isFreeAgent && !isRetiring) ? parseInt(destinationTeam, 10) : null,
      transferStatus: parseInt(transferStatus, 10),
      transferDate: moment(transferDate).format(),
      relatedNewsUrl: relatedNewsUrl || '',
      isFreeAgent: !!isFreeAgent,
      isRetiring: !!isRetiring,
      isArchived: !!isArchived,
      isBenched: !!isBenched,
      isInactive: !!isInactive,
      role: role || '',
    };

    return axios.post(
      url,
      data,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      },
    );
  }

  static editTransfer(transferId, transferObj) {
    const url = `${API_BASE_URL}/transfers/${transferId}`;

    const {
      playerName,
      playerCountry,
      originTeam,
      destinationTeam,
      transferStatus,
      transferDate,
      relatedNewsUrl,
      isFreeAgent,
      isRetiring,
      isArchived,
      isBenched,
      isInactive,
      role,
    } = transferObj;

    const data = {
      playerName,
      playerCountry,
      originTeam: parseInt(originTeam, 10) || null,
      destinationTeam: (!isFreeAgent && !isRetiring) ? parseInt(destinationTeam, 10) : null,
      transferStatus: parseInt(transferStatus, 10),
      transferDate: moment(transferDate).format(),
      relatedNewsUrl: relatedNewsUrl || '',
      isFreeAgent: !!isFreeAgent,
      isRetiring: !!isRetiring,
      isArchived: !!isArchived,
      isBenched: !!isBenched,
      isInactive: !!isInactive,
      role: role || '',
    };

    return axios.put(
      url,
      data,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      },
    );
  }
}
