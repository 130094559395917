import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';


// components
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import TransferForm from './TransferForm';
import PlayerTransfersService from '../../services/playerTransfers';
import { toast } from 'react-toastify';

class AddNewTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: {},
    };
  }

  componentDidMount() {
    const { transferId } = this.props.match.params;

    PlayerTransfersService.getSinglePlayerTransfer(transferId)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          data: data.data[0],
        });
      });
  }

  onSubmit = ({
    playerName,
    playerCountry,
    originTeam,
    destinationTeam,
    transferStatus,
    transferDate,
    relatedNewsUrl,
    isFreeAgent,
    isRetiring,
    isArchived,
    isBenched,
    isInactive,
    role,
  }) => {
    const { history } = this.props;
    const { transferId } = this.props.match.params;

    this.setState({ isLoading: true });

    PlayerTransfersService.editTransfer(transferId, {
      playerName,
      playerCountry,
      originTeam,
      destinationTeam,
      transferStatus,
      transferDate,
      relatedNewsUrl,
      isFreeAgent,
      isRetiring,
      isArchived,
      isBenched,
      isInactive,
      role,
    })
      .then(() => {
        this.setState({ isLoading: true });

        toast('Transferência editada com sucesso', {
          position: 'bottom-center',
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: true,
        });

        history.push(`${process.env.PUBLIC_URL}/u/transferencias`);
      });
  }

  render() {
    const {
      isLoading,
      data,
    } = this.state;

    return (
      <Fragment>
        {isLoading && <LoadMask />}
        <Header title="Editar Transferência" />
        <div className="EditTransfer PageContainer">
          {
            Object.keys(data).length > 0 &&
            <TransferForm
              onSubmit={this.onSubmit}
              data={data}
              enableReinitialize
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddNewTransfer);
