import React, { Component } from 'react';

// 3rd
import ReactMde, { commands } from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';

// css
import './styles.css';

class MarkdownInput extends Component {
  render() {
    const { input: { onChange, value } } = this.props;

    const listCommands = [{
      commands: [
        commands.boldCommand,
        commands.italicCommand,
        commands.linkCommand,
        commands.unorderedListCommand,
        commands.strikeThroughCommand,
      ],
    }];

    return (
      <ReactMde
        value={value}
        onChange={onChange}
        commands={listCommands}
      />
    );
  }
}

export default MarkdownInput;
