import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

// Pages
import Home from '../Home';
import MatchList from '../Matches/MatchList';
import AddNewMatch from '../Matches/AddNewMatch';
import EditMatch from '../Matches/EditMatch';
import BroadcastPage from '../PlayByPlay/Broadcast';

import AdminTools from '../AdminTools';

import AddNewTournament from '../Tournaments/AddNewTournament';
import TournamentList from '../Tournaments/TournamentList';
import TournamentListFinished from '../Tournaments/TournamentListFinished';
import EditTournament from '../Tournaments/EditTournament';

import TeamList from '../Teams/TeamList';
import AddNewTeam from '../Teams/AddNewTeam';
import EditTeam from '../Teams/EditTeam';

import StreamList from '../Streams/StreamList';
import AddNewStream from '../Streams/AddNewStream';
import EditStream from '../Streams/EditStream';

import MapList from '../Maps/MapList';
import AddNewMap from '../Maps/AddNewMap';
import EditMap from '../Maps/EditMap';

import AddPlayer from '../Players/AddPlayer';
import EditPlayer from '../Players/EditPlayer';
import PlayersList from '../Players/PlayersList';

import TransfersList from '../Transfers/TransfersList';
import AddNewTransfer from '../Transfers/AddNewTransfer';
import EditTransfer from '../Transfers/EditTransfer';

import Spots from '../Spots';
import AddNewSpot from '../Spots/AddNewSpot';
import EditSpot from '../Spots/EditSpot';
import AddNewCampaign from '../Campaigns/AddNewCampaign';
import EditCampaign from '../Campaigns/EditCampaign';
import FeatureFlags from '../FeatureFlags';
import EditFeatureFlag from '../FeatureFlags/EditFeatureFlag';

import RankingCalculator from '../RankingCalculator';
import AddNewRankingCalculation from '../RankingCalculator/AddNewRanking';
import RankingCalculatorDetail from '../RankingCalculator/RankingCalculatorDetail';

import AddNewRanking from '../Ranking/AddNewRanking';
import RankingList from '../Ranking/RankingList';
import EditRanking from '../Ranking/EditRanking';

import Campaigns from '../Campaigns';

import ListBetHouse from '../BetHouses/ListBetHouse';

// Components
import Sidebar from '../../components/Sidebar';
import LoadMask from '../../components/LoadMask';

// Services
import AuthService from '../../services/auth';

// redux
import { matchTypes } from '../../redux/match-list';

// css
import './styles.css';
import AddBetHouse from '../BetHouses/AddBetHouse';
import EditBetHouse from '../BetHouses/EditBetHouse';
import OrderBetHouses from '../BetHouses/OrderBetHouses';
import { BYPASS } from '../../utils/constants';
import ListLiveOddProvider from '../LiveOddProviders/ListLiveOddProvider';
import AddLiveOddProvider from '../LiveOddProviders/AddLiveOddProvider';
import EditLiveOddProvider from '../LiveOddProviders/EditLiveOddProvider';
import ListHub from '../Hubs/ListHub';
import AddHub from '../Hubs/AddHub';
import EditHub from '../Hubs/EditHub';

class Logged extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidToken: true,
      isLoading: true,
    };
  }

  componentWillMount() {
    AuthService.validateToken()
      .then(() => this.setState({ isLoading: false, isValidToken: true }))
      .catch(() => this.setState({ isLoading: false, isValidToken: false }));
  }

  render() {
    const { match } = this.props;

    const { isValidToken, isLoading } = this.state;

    if (!isValidToken) return <Redirect to="/" />;

    return (
      <Router>
        <div className="Logged">
          {isLoading && <LoadMask />}
          <Sidebar />
          <div className="Logged__container">
            <ToastContainer />
            <Switch>
              {/* =============================== */}
              {/* ============= TEAMS =========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/equipes`}
                component={TeamList}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/equipes/novo`}
                component={AddNewTeam}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/equipes/editar/:teamId`}
                component={EditTeam}
              />

              {/* =============================== */}
              {/* ============ STREAMS ========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/streams`}
                component={StreamList}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/streams/novo`}
                component={AddNewStream}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/streams/editar/:streamId`}
                component={EditStream}
              />

              {/* =============================== */}
              {/* ============ MATCHES ========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/partidas`}
                render={props => (
                  <MatchList
                    {...props}
                    matchType={matchTypes.NEXT}
                    pageTitle="Próximas Partidas"
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/partidas/passadas`}
                render={props => (
                  <MatchList
                    {...props}
                    matchType={matchTypes.FINISHED}
                    pageTitle="Resultados"
                  />
                )}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/partidas/novo`}
                component={AddNewMatch}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/partidas/editar/:matchId`}
                component={EditMatch}
              />

              {/* =============================== */}
              {/* ========= PLAY BY PLAY ======== */}
              {/* =============================== */}
              <Route
                path={`${process.env.PUBLIC_URL}${match.url}/partidas/play-by-play/:matchId`}
                component={BroadcastPage}
              />

              {/* =============================== */}
              {/* ========= TOURNAMENTS ========= */}
              {/* =============================== */}
              <Route
                path={`${process.env.PUBLIC_URL}${match.url}/campeonatos/novo`}
                component={AddNewTournament}
              />
              <Route
                path={`${process.env.PUBLIC_URL}${match.url}/campeonatos/editar/:tournamentId`}
                component={EditTournament}
              />
              <Route
                path={`${process.env.PUBLIC_URL}${match.url}/campeonatos/passados`}
                component={TournamentListFinished}
              />
              <Route
                path={`${process.env.PUBLIC_URL}${match.url}/campeonatos`}
                component={TournamentList}
              />

              {/* =============================== */}
              {/* ============== MAP ============ */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/mapas`}
                component={MapList}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/mapas/novo`}
                component={AddNewMap}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/mapas/editar/:mapId`}
                component={EditMap}
              />

              {/* =============================== */}
              {/* ======== RANKING CALC ========= */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking-calculator`}
                component={RankingCalculator}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking-calculator/editar/:rankingId`}
                component={RankingCalculatorDetail}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking-calculator/novo`}
                component={AddNewRankingCalculation}
              />

              {/* =============================== */}
              {/* =========== RANKING =========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking`}
                component={RankingList}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking/novo`}
                component={AddNewRanking}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/ranking/editar/:rankingId`}
                component={EditRanking}
              />

              {/* =============================== */}
              {/* ========== PLAYERS ========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/players`}
                render={props => <PlayersList {...props} pageTitle="Players" />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/players/novo`}
                component={AddPlayer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/players/editar/:playerId`}
                component={EditPlayer}
              />

              {/* =============================== */}
              {/* ========== TRANSFERS ========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/transferencias/novo`}
                component={AddNewTransfer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/transferencias/editar/:transferId`}
                component={EditTransfer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/transferencias`}
                render={props => (
                  <TransfersList {...props} pageTitle="Transferências" />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/transferencias/arquivadas`}
                render={props => (
                  <TransfersList
                    {...props}
                    isArchived
                    pageTitle="Transferências Arquivadas"
                  />
                )}
              />

              {/* =============================== */}
              {/* ========== CAMPAIGNS ========== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/campanhas`}
                component={Campaigns}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/campanhas/novo`}
                component={AddNewCampaign}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/campanhas/editar/:campaignId`}
                component={EditCampaign}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/spots`}
                component={Spots}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/spots/novo`}
                component={AddNewSpot}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/spots/editar/:spotId`}
                component={EditSpot}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/home`}
                component={Home}
              />

              {/* =============================== */}
              {/* ========== FEAT FLAGS ========= */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/feature-flags`}
                component={FeatureFlags}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/feature-flags/editar/:featureFlagId`}
                component={EditFeatureFlag}
              />

              {/* =============================== */}
              {/* ========== ADMIN TOOLS ======== */}
              {/* =============================== */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/admin-tools`}
                component={AdminTools}
              />

              {/* =============================== */}
              {/* ========== BET HOUSES ========= */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/bet-houses`}
                component={ListBetHouse}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/bet-houses/ordenacao`}
                component={OrderBetHouses}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/bet-houses/novo`}
                component={AddBetHouse}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/bet-houses/editar/:id`}
                component={EditBetHouse}
              />

              {/* =============================== */}
              {/* ====== LIVE ODD PROVIDER ====== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/live-odd-providers`}
                component={ListLiveOddProvider}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/live-odd-providers/novo`}
                component={AddLiveOddProvider}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/live-odd-providers/editar/:id`}
                component={EditLiveOddProvider}
              />

              {/* =============================== */}
              {/* ============ HUB ============== */}
              {/* =============================== */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/hubs`}
                component={ListHub}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/hubs/novo`}
                component={AddHub}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}${match.url}/hubs/editar/:id`}
                component={EditHub}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default Logged;
