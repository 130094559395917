import React, { Component } from 'react';
import moment from 'moment';

// 3rd party
import Switch from '@material-ui/core/Switch';

// components
import Card from '../../../../../components/Card';
import LoadMask from '../../../../../components/LoadMask';

// components
import PlayByPlayService from '../../../../../services/playByPlay';
import AuthService from '../../../../../services/auth';

// css
import './styles.css';

class MatchSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socketMessage: 'Não Conectado...',
      isLoading: false,
    };
  }

  componentWillMount() {
    const { socket } = this.props;

    if (socket.connected) {
      this.setState({
        socketMessage: 'Conectado',
      });
    }

    socket.on('connect', () => {
      this.setState({ socketMessage: 'Conectado' });
    });
  }

  onPlayByPlayStatusChange = (val) => {
    const { data } = this.props;
    const { userId } = AuthService.getCookies();

    this.setState({ isLoading: true });

    PlayByPlayService.togglePlayByPlayStatus(data.matchId, userId, val.target.checked)
      .then(() => {
        this.setState({
          isLoading: false,
        });

        this.props.onPlayByPlayStatusChange();
      });
  }


  render() {
    const {
      data,
    } = this.props;

    const {
      socketMessage,
      isLoading,
    } = this.state;

    const matchIsLive = moment(data.matchDate * 1000).diff(moment(new Date())) < 0;

    return (
      <Card className="MatchSummary">
        {isLoading && <LoadMask />}

        <div className="MatchSummary__match-info">
          <strong>{data.teamA.teamName} vs {data.teamB.teamName}</strong>
          <small>{data.tournament.tournamentName}</small>
        </div>

        <div className="MatchStatus">
          {
            ((!data.isOver && !data.isFinished) && !matchIsLive) &&
            <strong className="MatchStatus__status">
              Começa {moment(data.matchDate * 1000).fromNow()}
            </strong>
          }

          {
            ((!data.isOver && !data.isFinished) && matchIsLive) &&
            <strong className="MatchStatus__status MatchStatus__status--live">Ao Vivo</strong>
          }

          {
            (data.isOver === 1 || data.isFinished === 1) &&
            <strong className="MatchStatus__status">Finalizado</strong>
          }
          <small>Status da Partida</small>
        </div>

        <div className="MatchStatus MatchStatus--live">
          {
            !data.playByPlayAuthor ?
              (<strong className="MatchStatus__status">-</strong>) :
              (<strong className="MatchStatus__status">{data.playByPlayAuthor.displayName} (@{data.playByPlayAuthor.twitterHandle})</strong>)
          }
          <small>Autor Associado</small>
        </div>

        <div className="MatchSummary__block">
          <strong className="MatchStatus__status">{socketMessage}</strong>
          <small>Conexão com Socket</small>
        </div>

        <div className="MatchSummary__toggle">
          <Switch
            checked={data.isPlayByPlay}
            color="primary"
            onChange={this.onPlayByPlayStatusChange}
          />
          <small>
            {data.isPlayByPlay && 'Desabilitar Play By Play'}
            {!data.isPlayByPlay && 'Habilitar Play By Play e iniciar transmissão'}
          </small>
        </div>
      </Card>
    );
  }
}

export default MatchSummary;
