import React, { Component } from 'react';
import { connect } from 'react-redux';

// 3rd party
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';

// images
import logo from '../../assets/logo.png';

// css
import './styles.css';
import { APP_VERSION } from '../../utils/constants';
import { toggleSidebar } from '../../redux/ui';

class Sidebar extends Component {
  _toggleSidebar = () => {
    this.props.dispatch(toggleSidebar());
  };

  menus = {
    ' ': [
      {
        label: 'Home',
        icon: 'home',
        url: '/u/home',
      },
    ],
    'Midia & Ferramentas': [
      {
        label: 'Campanhas',
        icon: 'bullhorn',
        url: '/u/campanhas',
      },
      {
        label: 'Spots',
        icon: 'map-marker',
        url: '/u/spots',
      },
      {
        label: 'Ferramentas',
        icon: 'gear',
        url: '/u/admin-tools',
      },
      {
        label: 'Feature Flags',
        icon: 'flag',
        url: '/u/feature-flags',
      },
    ],
    Campeonatos: [
      {
        label: 'Novo Campeonato',
        icon: 'plus',
        url: '/u/campeonatos/novo',
      },
      {
        label: 'Próximos Campeonatos',
        icon: 'calendar',
        url: '/u/campeonatos',
      },
      {
        label: 'Campeonatos Passados',
        icon: 'calendar-check-o',
        url: '/u/campeonatos/passados',
      },
    ],
    Partidas: [
      {
        label: 'Nova Partida',
        icon: 'plus',
        url: '/u/partidas/novo',
      },
      {
        label: 'Próximas Partidas',
        icon: 'calendar',
        url: '/u/partidas',
      },
      {
        label: 'Partidas Passadas',
        icon: 'calendar-check-o',
        url: '/u/partidas/passadas',
      },
    ],
    'Equipes & Jogadores': [
      {
        label: 'Players',
        icon: 'users',
        url: '/u/players',
      },
      {
        label: 'Nova Equipe',
        icon: 'plus',
        url: '/u/equipes/novo',
      },
      {
        label: 'Listar Equipes',
        icon: 'list',
        url: '/u/equipes',
      },
    ],
    'Streams & Mapas': [
      {
        label: 'Nova Stream',
        icon: 'plus',
        url: '/u/streams/novo',
      },
      {
        label: 'Listar Streams',
        icon: 'list',
        url: '/u/streams',
      },
      {
        label: 'Novo Mapa',
        icon: 'plus',
        url: '/u/mapas/novo',
      },
      {
        label: 'Listar Mapas',
        icon: 'list',
        url: '/u/mapas',
      },
    ],
    'Ranking & Calculadora': [
      {
        label: 'Nova Edição',
        icon: 'plus',
        url: '/u/ranking/novo',
      },
      {
        label: 'Listar Edições',
        icon: 'list',
        url: '/u/ranking',
      },
      {
        label: 'Novo Cálculo',
        icon: 'plus',
        url: '/u/ranking-calculator/novo',
      },
      {
        label: 'Listar Cálculos',
        icon: 'list',
        url: '/u/ranking-calculator',
      },
    ],
    // 'Transferências e Rumores': [
    //   {
    //     label: 'Nova Transferência',
    //     icon: 'plus',
    //     url: '/u/transferencias/novo',
    //   },
    //   {
    //     label: 'Listar Transferências',
    //     icon: 'list',
    //     url: '/u/transferencias',
    //   },
    //   {
    //     label: 'Arquivadas',
    //     icon: 'archive',
    //     url: '/u/transferencias/arquivadas',
    //   },
    // ],
    Hubs: [
      {
        label: 'Novo Hub',
        icon: 'plus',
        url: '/u/hubs/novo',
      },
      {
        label: 'Listar Hubs',
        icon: 'list',
        url: '/u/hubs',
      },
    ],
    'Casas & Provadores de Odd': [
      {
        label: 'Nova Casa',
        icon: 'plus',
        url: '/u/bet-houses/novo',
      },
      {
        label: 'Listar Casas',
        icon: 'list',
        url: '/u/bet-houses',
      },
      {
        label: 'Ordenar Casas',
        icon: 'sort',
        url: '/u/bet-houses/ordenacao',
      },
      {
        label: 'Novo Provedor',
        icon: 'plus',
        url: '/u/live-odd-providers/novo',
      },
      {
        label: 'Listar Provedores',
        icon: 'list',
        url: '/u/live-odd-providers',
      },
    ],
  };

  render() {
    const { sidebarIsVisible } = this.props;

    return (
      <div className={`Sidebar ${!sidebarIsVisible ? 'Sidebar--hidden' : ''}`}>
        <div className="Sidebar__logo">
          <a href="https://draft5.gg" target="_blank" rel="noopener noreferrer">
            <img src={logo} className="Sidebar__logo-img" alt="" />
          </a>
          <small className="Sidebar__title">EatClean {APP_VERSION}</small>
        </div>

        <div
          role="button"
          className="Sidebar__nav Sidebar__nav--mobile-only"
          onClick={this._toggleSidebar}
        >
          <FontAwesome name="close" className="Sidebar__nav-icon" />
          Fechar Menu
        </div>

        {Object.keys(this.menus).map((menu, index) => (
          <div key={menu} className="Sidebar__menu">
            <span className="Sidebar__nav-divider">{menu}</span>
            {this.menus[menu].map((item, index) => (
              <NavLink
                exact
                key={item.label}
                onClick={this._toggleSidebar}
                to={`${process.env.PUBLIC_URL}${item.url}`}
                activeClassName="Sidebar__nav--active"
                className="Sidebar__nav"
              >
                <FontAwesome name={item.icon} className="Sidebar__nav-icon" />
                {item.label}
              </NavLink>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebarIsVisible: state.ui.sidebarIsVisible,
  };
}

export default connect(mapStateToProps)(Sidebar);
