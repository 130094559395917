import React, { Component } from "react";
import styled from "styled-components";
import { S3_BASE_URL } from '../../../../utils/constants';
import moment from "moment";

export default class MatchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match: props.match,
      date: moment(props.match.matchDate, "X"),
    };
  }

  render() {
    const { match, date } = this.state;
    return (
      <>
        <CardContainer noPadding={this.props.noPadding}>
          <DateContainer>
            <HourInfo>{date.format("LT")}</HourInfo>
            <DayInfo>{date.format("DD MMMM")}</DayInfo>
          </DateContainer>
          <MatchContainer>
            <TeamsInfo>
              {match.teamA.name || match.teamA.teamName}{" "}
              <img
                src={`${S3_BASE_URL}/assets/flags/${match.teamA.country || match.teamA.teamCountry}.svg`}
                alt={match.teamA.Country || match.teamA.teamCountry}
                width="14px"
              />
            </TeamsInfo>
            <ScoreInfo>
              {match.series ? match.series.scoreA : match.seriesScoreA} : {match.series ? match.series.scoreB : match.seriesScoreB}
            </ScoreInfo>
            <TeamsInfo>
              <img
                src={`${S3_BASE_URL}/assets/flags/${match.teamB.country || match.teamB.teamCountry}.svg`}
                alt={match.teamB.Country || match.teamB.teamCountry}
                width="14px"
              />
              {" "}
              {match.teamB.name || match.teamB.teamName}
            </TeamsInfo>
          </MatchContainer>
        </CardContainer>
      </>
    );
  }
}

const CardContainer = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.04), 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

const DateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => (props.noPadding ? "0px" : "10px")};
  padding-right: 0px;
  @media screen and (max-width: 992px) {
    padding: 20px 0px;
  }
`;

const HourInfo = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #565656;
`;

const DayInfo = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #959595;
`;

const MatchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 5;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    flex: 1;
    margin: 20px;
  }

  padding: ${(props) => (props.noPadding ? "0px" : "10px")};
  padding-right: 0px;
`;

const TeamsInfo = styled.div`
  flex: 1;
  color: #565656;
  font-size: 14px;
  line-height: 16px;
`;

const ScoreInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5ch;
  width: 5ch;
  margin: 10px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  color: #565656;
  font-size: 14px;
  line-height: 16px;
`;