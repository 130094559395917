import React, { Component, Fragment } from 'react';

// components
import LoadingIndicator from '../LoadingIndicator';
import Card from '../Card';

// css
import './styles.css';

export default class Table extends Component {

  selection = {};

  getRowKey(row, rowIndex) {
    if (this.props.keyExtractor)
      return this.props.keyExtractor(row)

    return rowIndex;
  }

  fireChangeEvent = (event) => {
    this.selection[event.target.value] = event.target.checked;
    if (this.props.onSelect) {
      const listSelection = Object.keys(this.selection).filter(key => this.selection[key])
      this.props.onSelect(listSelection)
    }
  }

  clearSelection = () => {
    this.selection = {};
    const checkBoxes = document.getElementsByName("table_selection");
    if (checkBoxes) {
      for (let index = 0; index < checkBoxes.length; index += 1)
        checkBoxes[index].checked = false;
    }

    if (this.props.onSelect) {
      this.props.onSelect([])
    }
  }

  render() {
    return (
      <Fragment>
        {
          this.props.isFetching &&
          <LoadingIndicator />
        }

        {
          !this.props.isFetching &&
          this.props.data.length === 0 &&
          <Card>
            <small>Nenhum resultado encontrado</small>
          </Card>
        }

        {
          !this.props.isFetching &&
          this.props.data.length > 0 &&
          <table className="Table">
            <thead className="Table__thead">
              <tr>
                {
                  this.props.showSelect && (<td>#</td>)
                }
                {
                  this.props.headers.map((head, index) => <td key={`${index}_${head.title}`}>{head.title}</td>)
                }
              </tr>
            </thead>

            <tbody className="Table__tbody">
              {
                this.props.data.map((row, index) => (
                  <tr key={this.getRowKey(row, index)}>
                    {
                      this.props.showSelect && (
                        <td><input type='checkbox' id="table_selection" name='table_selection' value={`${index}`} onChange={this.fireChangeEvent} /></td>
                      )
                    }


                    {row.map((cell, indexCol) => <td key={`${this.getRowKey(row, index)}_${indexCol}`}>{cell}</td>)}
                  </tr>
                ))
              }
            </tbody>
          </table>
        }
      </Fragment>
    );
  }
}
