import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// 3rd party
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import Modal from '../../../components/Modal';
import LoadMask from '../../../components/LoadMask';
import PageNavigator from '../../../components/PageNavigator';

// Redux
import { getRankingList } from '../../../redux/ranking-list';
import { deleteRanking } from '../../../redux/ranking-detail';
// import { deleteMap } from '../../../redux/map-detail';

class MapList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        { title: 'ID do Ranking' },
        { title: 'Data do Ranking' },
        { title: 'Ações' },
        { title: '' },
      ],
      currentPage: 1,
      currentAmount: 25,
      deleteModalIsVisible: false,
      deleteModalRankingObject: {},
    };
  }

  componentDidMount() {
    const { currentPage, currentAmount } = this.state;

    this._getRankingList(currentPage, currentAmount);
  }

  _getRankingList = (page, amount) => {
    this.props.dispatch(getRankingList(page, amount));
  }

  _onAmountChange = (newAmount) => {
    const { currentPage } = this.state;

    this._getRankingList(currentPage, newAmount);

    this.setState({
      currentAmount: newAmount,
    });
  }

  _onPageChange = (newPage) => {
    const { currentAmount } = this.state;

    if (newPage < 1) return;

    this._getRankingList(newPage, currentAmount);

    this.setState({
      currentPage: newPage,
    });
  }

  _formatRankingListArray = () => (
    this.props.rankingList.map((ranking) => {
      const { rankingDate, rankingId } = ranking;

      return [
        rankingId,
        moment(rankingDate * 1000).format('D [de] MMMM [de] YYYY'),
        <NavLink to={`${process.env.PUBLIC_URL}/u/ranking/editar/${rankingId}`}><Button size="small" text="Editar" block skin="accent" /></NavLink>,
        <Button size="small" onClick={() => { this._promptDeleteRanking(ranking); }} text="Excluir" block skin="error" />,
      ];
    })
  );

  _promptDeleteRanking = (ranking) => {
    this.setState({
      deleteModalRankingObject: ranking,
      deleteModalIsVisible: true,
    });
  }

  _closeModal = () => {
    this.setState({
      deleteModalIsVisible: false,
      deleteModalRankingObject: {},
    });
  }

  _deleteRanking = (rankingId) => {
    const { dispatch } = this.props;
    const { currentPage, currentAmount } = this.state;

    dispatch(deleteRanking(rankingId))
      .then(
        // Success
        () => {
          toast('Ranking excluído com sucesso', {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
          });

          this._closeModal();
          this._getRankingList(currentPage, currentAmount);
        },
        // Error
        (error) => {
          toast(error.message, {
            position: 'bottom-center',
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
          });

          this._closeModal();
        },
      );
  }

  render() {
    const {
      deleteModalIsVisible,
      deleteModalRankingObject,
    } = this.state;

    const {
      ui,
    } = this.props;

    return (
      <Fragment>
        <Header title="Rankings" />

        {/* { deleteMapUi.isFetching && <LoadMask /> } */}
        { ui.isFetching && <LoadMask /> }

        {/* =================================== */}
        {/* =========== DELETE MAP ============ */}
        {/* =================================== */}
        {
          deleteModalIsVisible &&
          <Modal
            title="Excluir Ranking"
            onBackdropClick={this._closeModal}
            renderFooter={() => (
              <Fragment>
                <Button
                  size="small"
                  text="Cancelar"
                  skin="gray"
                  className="TeamList__cancel-delete-team"
                  onClick={this._closeModal}
                />
                <Button
                  size="small"
                  text="Excluir Ranking"
                  skin="error"
                  onClick={() => {
                    this._deleteRanking(deleteModalRankingObject.rankingId);
                  }}
                />
              </Fragment>
            )}
          >
            Deseja excluir o ranking de <srong>{moment(deleteModalRankingObject.rankingDate * 1000).format('D [de] MMMM [de] YYYY')}</srong>?
          </Modal>
        }

        {/* =================================== */}
        {/* ============ TEAM LIST ============ */}
        {/* =================================== */}
        <div className="RankingList PageContainer">
          <Table
            headers={this.state.headers}
            data={this._formatRankingListArray()}
          />

          {
            !ui.isFetching &&
            <PageNavigator
              currentPage={this.state.currentPage}
              currentAmount={this.state.currentAmount}
              onAmountChange={this._onAmountChange}
              onPageChange={this._onPageChange}
            />
          }
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    rankingList: state.rankings.data,
    ui: {
      isFetching: state.rankings.isFetching,
      error: state.rankings.error,
    }
    // deleteMapUi: getDeleteMapUi(state),
  };
}

export default connect(mapStateToProps)(MapList);
