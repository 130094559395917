import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import React from 'react';

const DeleteCampaign = props => (
  <Dialog open={props.open} onClose={props.handleClose}>
    <DialogTitle id="alert-dialog-title">
      Deseja deletar esta campanha?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {props.deletedCampaign ? `${props.deletedCampaign.campaignName}` : ''}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={props.handleClose}>
        Cancelar
      </Button>
      <Button
        color="primary"
        onClick={() => {
          props.onDelete(props.deletedCampaign.campaignId);
        }}
        variant="text"
        autoFocus
      >
        Excluir
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteCampaign;
