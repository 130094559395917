import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

// ========================
// Actions
// ========================
const GET_TOURNAMENTS = 'draft5/tournaments/GET_TOURNAMENTS';
const GET_TOURNAMENTS_SUCCESS = 'draft5/tournaments/GET_TOURNAMENTS_SUCCESS';
const GET_TOURNAMENTS_FAILURE = 'draft5/tournaments/GET_TOURNAMENTS_FAILURE';

const CLEAR_TOURNAMENTS = 'draft5/tournaments/CLEAR_TOURNAMENTS';

// ========================
// Enums
// ========================
export const tournamentTypes = {
  NEXT: 'nextTournaments',
  FINISHED: 'pastTournaments',
  FEATURED: 'featuredTournaments',
};

// ========================
// initial state
// ========================
const initialState = {
  nextTournaments: {
    isFetching: false,
    error: '',
    data: [],
  },
  pastTournaments: {
    isFetching: false,
    error: '',
    data: [],
  },
  featuredTournaments: {
    isFetching: false,
    error: '',
    data: [],
  },
};

// ========================
// REDUCER
// ========================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOURNAMENTS: {
      return {
        ...state,
        [action.tournamentType]: {
          ...state[action.tournamentType],
          isFetching: true,
          error: '',
        },
      };
    }

    case GET_TOURNAMENTS_SUCCESS: {
      return {
        ...state,
        [action.tournamentType]: {
          ...state[action.tournamentType],
          isFetching: false,
          data: action.data,
        },
      };
    }

    case GET_TOURNAMENTS_FAILURE: {
      return {
        ...state,
        [action.tournamentType]: {
          ...state[action.tournamentType],
          isFetching: false,
          error: action.error,
        },
      };
    }

    case CLEAR_TOURNAMENTS: {
      return {
        ...state,
        nextTournaments: {
          ...state.nextTournaments,
          data: [],
        },
        pastTournaments: {
          ...state.pastTournaments,
          data: [],
        },
        featuredTournaments: {
          ...state.featuredTournaments,
          data: [],
        },
      };
    }

    default: {
      return state;
    }
  }
}

// ========================
// ACTION CREATORS
// ========================
function _getTournaments(tournamentType) {
  return {
    type: GET_TOURNAMENTS,
    tournamentType,
  };
}

function _getTournamentsSuccess(data, tournamentType) {
  return {
    type: GET_TOURNAMENTS_SUCCESS,
    data,
    tournamentType,
  };
}

function _getTournamentsFailure(error, tournamentType) {
  return {
    type: GET_TOURNAMENTS_FAILURE,
    error,
    tournamentType,
  };
}

// ========================
// public action creators
// ========================
export function clearTournaments() {
  return { type: CLEAR_TOURNAMENTS };
}

// ========================
// THUNKS
// ========================
export function getTournaments(page = 1, amount = 10, tournamentType, team = null) {
  return async function (dispatch) {
    dispatch(_getTournaments(tournamentType));

    try {
      const finished = (tournamentType === tournamentTypes.FINISHED ? '1' : '0');
      const featured = (tournamentType === tournamentTypes.FEATURED ? '1' : '0');

      const response = await axios.get(`${API_BASE_URL}/tournaments?featured=${featured}&finished=${finished}&page=${page}&amount=${amount}`);

      dispatch(_getTournamentsSuccess(response.data.data, tournamentType));
    } catch (error) {
      dispatch(_getTournamentsFailure(error, tournamentType));
    }
  };
}
