import {
  FormControl,
  FormHelperText,
  Select,
  Card,
  CardContent,
  Divider,
  Button as MUIBUtton,
} from '@material-ui/core';
import { CardActions } from 'material-ui/Card';

import React, { useState } from 'react';
import Button from '../../../components/Button';
import useTournaments from '../hooks/useTournaments';
import SelectFormik from '../../../components/SelectFormik';

const SelectedTournamentsFieldArray = ({ arrayHelpers, handleChange }) => {
  const [data, tournamentsIsLoading, tournamentsError] = useTournaments();
  const [selectedTournament, setSelectedTournament] = useState(null);

  const { push, form, remove } = arrayHelpers;
  const { values } = form;

  const getTournamentDataById = tournamentId => {
    return data.find(
      tournament => tournament.tournamentId === parseInt(tournamentId, 10)
    );
  };

  return (
    <div>
      <FormControl fullWidth>
        <Select
          native
          fullWidth
          value={selectedTournament}
          onChange={ev => {
            setSelectedTournament(ev.target.value);
          }}
          disabled={tournamentsIsLoading}
        >
          <option selected value="" />
          {data.map(tournament => (
            <option
              key={tournament.tournamentId}
              value={tournament.tournamentId}
            >
              {tournament.tournamentName}
              {tournament.isFinished ? ' - Finalizado' : ''}
            </option>
          ))}
        </Select>
        <FormHelperText>
          Mostrando os campeonatos em andamento e os últimos 100 campeonatos
          finalizados
        </FormHelperText>
      </FormControl>

      <Button
        text="Adicionar"
        skin="accent"
        size="small"
        block
        disabled={!selectedTournament}
        onClick={() => {
          push({
            id: selectedTournament,
          });

          setSelectedTournament(null);
        }}
      />

      <hr />

      {values.tournaments &&
        values.tournaments.length > 0 &&
        values.tournaments.map((tournament, index) => {
          const tournamentData = getTournamentDataById(tournament.id);

          if (!tournamentData) return '';

          return (
            <>
              <Card variant="outlined" style={{ borderColor: 'var(--accent)' }}>
                <CardContent>{tournamentData.tournamentName}</CardContent>
                <Divider />
                <CardContent>
                  <SelectFormik
                    name={`tournaments.${index}.distribution`}
                    label="Tipo"
                    onChange={handleChange}
                    value={values.tournaments[index].distribution}
                  >
                    <option value="ThreeEqualsFour" selected>
                      3=4
                    </option>
                    <option value="Normal">Normal</option>
                    <option value="Qualifier">Qualifier</option>
                    <option value="OneAndTwo">Um e Dois</option>
                    <option value="Equal">Igual</option>
                  </SelectFormik>
                </CardContent>
                <Divider />
                <CardActions>
                  <MUIBUtton onClick={() => remove(index)} size="small">
                    Excluir
                  </MUIBUtton>
                </CardActions>
              </Card>
              <br />
            </>
          );
        })}
    </div>
  );
};

export default SelectedTournamentsFieldArray;
