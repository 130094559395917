import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import { Container, Row, Col } from 'reactstrap';

const SubstageTypeSelectionModal = props => {
  return (
    <Dialog open={props.open} onClose={props.onModalClose}>
      <DialogTitle>Novo Substage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Container fluid>
            <Row>
              <Col lg={6}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography color="textSecondary">Tipo</Typography>
                    <Typography variant="h5" component="h2">
                      Bracket
                    </Typography>
                    <Typography variant="body2" component="p">
                      Para playoffs e chaves de campeonato.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        props.onSubstageTypeSelect("Brackets");
                      }}
                    >
                      Selecionar
                    </Button>
                  </CardActions>
                </Card>
              </Col>
              <Col lg={6}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography color="textSecondary">Tipo</Typography>
                    <Typography variant="h5" component="h2">
                      Tabela
                    </Typography>
                    <Typography variant="body2" component="p">
                      Para fase de grupos e tabelas com pontuação
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        props.onSubstageTypeSelect("Groups");
                      }}
                    >
                      Selecionar
                    </Button>
                  </CardActions>
                </Card>
              </Col>
            </Row>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SubstageTypeSelectionModal;
