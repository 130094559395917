import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const DeleteSubstageModal = props => {
  return (
    <Dialog open={props.open} onClose={props.onModalClose}>
      <DialogTitle>Deletar Stage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deseja deletar o substage{' '}
          {props.substageData && props.substageData.stageName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onModalClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onDeleteSubstage(
              props.substageData.substageId,
              props.substageData.stageId
            );
          }}
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSubstageModal;
