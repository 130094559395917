import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import './styles.css';

export default class Modal extends Component {

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
    document.getElementsByTagName('html')[0].style.overflow = 'initial';
  }

  _onBackdropClick = () => {
    const { onBackdropClick } = this.props;
    if (onBackdropClick) onBackdropClick();
  };

  render() {
    const {
      title,
      children,
      className,
      renderFooter,
    } = this.props;

    return (
      <CSSTransition
        classNames="Modal"
        in
        appear
        timeout={400}
      >
        <div
          className="Modal__backdrop"
          onClick={this._onBackdropClick}
          role="dialog"
        >
          <div
            className={`Modal__window ${className || ''}`}
            onClick={e => e.stopPropagation()}
            style={this.props.layoutConfig}
            role="dialog"
          >
            {
              title &&
              <div className="Modal__title">{title}</div>
            }
            <div className="Modal__content">
              {children}
            </div>

            {
              renderFooter &&
              <div className="Modal__footer">
                {renderFooter()}
              </div>
            }
          </div>
        </div>
      </CSSTransition>
    )
  }
}
