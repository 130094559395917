import React from 'react';
import './style.css';

const Group = () => {
  return (
    <svg id="Agrupar_1" className="substage-icon" data-name="Agrupar 1" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <rect id="Retângulo_1" data-name="Retângulo 1" className="cls-1" width="48" height="14" />
      <rect id="Retângulo_1_copiar" data-name="Retângulo 1 copiar" className="cls-1" y="17" width="48" height="14" />
      <rect id="Retângulo_1_copiar_2" data-name="Retângulo 1 copiar 2" className="cls-1" y="34" width="48" height="14" />
    </svg>
  );
}

export default Group;