import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Card from '../../../components/Card';
import Ranges from '../components/TableEditor/Ranges';
import Matches from '../components/TableEditor/Matches';
import ExtraPoints from '../components/TableEditor/ExtraPoints';
import Table from '../components/TableEditor';
import { Formik, Field, Form } from 'formik';
import { TextField } from '@material-ui/core';
import Button from '../../../components/Button';
import * as Yup from 'yup';
import TournamentsService from '../../../services/tournaments';

const GroupForm = props => {
  const {
    availableTeams,
    matches,
    selectedSubstage,
    tournamentId,
    onSubmit,
  } = props;

  const [data, setSubstageData] = useState(null);

  const formEmptyState = {
    substageName: '',
    substageData: {
      ranges: [],
      matches: [],
      lineData: [],
    },
  };

  useEffect(() => {
    // reset previous set substage
    setSubstageData(null);

    // add new substage
    if (!selectedSubstage.substageId) {
      setSubstageData(formEmptyState);

      return;
    }

    TournamentsService.getSingleSubstage(
      tournamentId,
      selectedSubstage.stageId,
      selectedSubstage.substageId
    ).then(response => {
      setSubstageData(response.data.data);
    });
  }, [props.substageId]);

  if (!data) return '';

  return (
    <GroupFormContainer fluid>
      <Formik
        initialValues={{
          substageName: data.substageName || '',
          substagePosition: data.substagePosition || 0,
          extraPoints: data.substageData.extraPoints || [],
          matches: data.substageData.matches || [],
          ranges: data.substageData.ranges || [],
          lineData: data.substageData.lineData || [],
          automaticPoints: typeof data.automaticPoints !== "undefined" || true,
        }}
        validationSchema={Yup.object().shape({
          substageName: Yup.string().required('Nome do stage é obrigatório'),
          ranges: Yup.array()
            .min(1)
            .required('Preencha pelo menos um range'),
          lineData: Yup.array()
            .min(2)
            .required('Preencha pelo menos duas equipes'),
        })}
        onSubmit={values => {
          onSubmit(values, 'Groups', selectedSubstage.substageId);
        }}
        enableReinitialize
      >
        {({ values, handleChange, errors, touched, isValid, isSubmitting, setFieldValue }) => (
          <Form>
            <Row>
              <Col lg={3}>
                <StyledCard title="Nome">
                  <TextField
                    name="substageName"
                    label="Nome do Substage"
                    onChange={handleChange}
                    value={values.substageName}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.substageName && touched.substageName}
                    helperText={errors.substageName}
                  />
                </StyledCard>

                <StyledCard title="Posição do Stage">
                  <TextField
                    name="substagePosition"
                    label="Posição do Stage"
                    onChange={handleChange}
                    value={values.substagePosition}
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.substagePosition && touched.substagePosition}
                    helperText={errors.substagePosition}
                  />
                </StyledCard>

                <StyledCard title="Ranges">
                  <Field
                    name="ranges"
                    component={Ranges}
                    substageId={selectedSubstage.substageId}
                    value={values.ranges}
                  />
                </StyledCard>

                <Card title="Partidas do Torneio">
                  {matches.map(match => (
                    <span>
                      {match.matchId} - {match.teamA.teamName} vs {match.teamB.teamName}{' '}
                      <br />
                    </span>
                  ))}
                </Card>
              </Col>

              <Col lg={9}>
                <StyledCard title="Tabela">
                  <Field
                    name="lineData"
                    component={Table}
                    automaticPoints={values.automaticPoints}
                    setFieldValue={setFieldValue}
                    availableTeams={availableTeams}
                    matches={matches}
                    value={values.lineData}
                    selectedMatches={data.substageData.matches}
                  />
                </StyledCard>
                <StyledCard title="Pontos Extra">
                  <Field
                    name="extraPoints"
                    component={ExtraPoints}
                    value={values.extraPoints}
                    teams={values.lineData}
                  />
                </StyledCard>
                <StyledCard title="Partidas Associadas">
                  <Field
                    name="matches"
                    component={Matches}
                    value={values.matches}
                    availableMatches={matches}
                    selectedTeams={values.lineData}
                  />
                </StyledCard>

                <Button
                  type="submit"
                  text="Salvar Tabela"
                  skin="accent"
                  disabled={!isValid && isSubmitting}
                  block
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </GroupFormContainer>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;

const GroupFormContainer = styled(Container)`
  margin-top: 16px;
`;

export default GroupForm;
