import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';

// 3rd party components
import { TextField, DatePicker, Checkbox } from 'redux-form-material-ui';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { Select } from '@material-ui/core/';

// components
import Card from '../../../components/Card';

// utils
import { required } from '../../../utils/form-validators';
import Button from '../../../components/Button';

// redux
import { getTeamList } from '../../../redux/team-list';

// css
import './styles.css';

class RankingForm extends Component {
  componentDidMount() {
    this.props.dispatch(getTeamList(1, 10000));
  }

  _renderTeamDropdown = ({ input }) => {
    const {
      teamList,
    } = this.props;

    const {
      onChange,
      value,
    } = input;

    return (
      <div className="RankingForm__team-dropdown">
        <label>Equipe</label>
        <Select
          fullWidth
          native
          onChange={onChange}
          value={value}
        >
          <option value="">Selecione</option>
          {
            teamList.length > 0 &&
            teamList
              .sort((a, b) => {
                if (a.teamName > b.teamName) return 1;
                else if (a.teamName < b.teamName) return -1;
                return 0;
              })
              .map(team => (
                <option
                  value={team.teamId}
                >
                  {team.teamName}
                </option>
              ))
          }
        </Select>
      </div>
    );
  }

  _renderPositions = ({ fields }) => {
    return (
      <Fragment>
        {
          fields.map((member, index) => (
            <Card className="RankingForm__team-container">
              <a
                className="RankingForm__remove-field"
                onClick={() => fields.remove(index)}
                role="button"
              >
                <FontAwesome name="times" />
              </a>
              <div className="RankingForm__team">
                <span className="RankingForm__team-position">
                  #{index + 1}
                </span>

                <Field
                  name={`${member}.team`}
                  validate={required}
                  component={this._renderTeamDropdown}
                />

                <Field
                  name={`${member}.isNew`}
                  label="Novo?"
                  className="RankingForm__team-checkbox"
                  component={Checkbox}
                />
              </div>

              <div className="RankingForm__meta">
                <Field
                  name={`${member}.points`}
                  floatingLabelText="Pontos"
                  validate={required}
                  className="RankingForm__meta-field"
                  type="number"
                  component={TextField}
                />

                <Field
                  name={`${member}.previousPosition`}
                  floatingLabelText="Posição Anterior"
                  className="RankingForm__meta-field"
                  type="number"
                  component={TextField}
                />
              </div>

              <div className="RankingForm__meta">
                <Field
                  name={`${member}.observation`}
                  floatingLabelText="Observação"
                  component={TextField}
                  className="RankingForm__observation"
                />
              </div>

            </Card>
          ))
        }

        <Button
          skin="accent"
          size="small"
          block
          type="button"
          text="Adicionar time"
          onClick={() => fields.push({})}
        />
      </Fragment>
    );
  }

  render() {
    const {
      valid,
      positions,
      handleSubmit,
    } = this.props;

    return (
      <form className="RankingForm" onSubmit={handleSubmit}>
        <Row>
          <Col lg={3} xs={12}>
            <Card>
              <Field
                name="rankingDate"
                fullWidth
                autoOk
                floatingLabelText="Data do Ranking"
                validate={required}
                component={DatePicker}
                floatingLabelFixed
              />
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <FieldArray name="positions" component={this._renderPositions} />
            <Button
              text="Enviar"
              skin="accent"
              className="RankingForm__submit"
              block
              type="submit"
              disabled={!valid || positions.length === 0}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { data } = ownProps;

  return data ?
    {
      teamList: state.teams.data,
      positions: formValueSelector('rankingForm')(state, 'positions') || [],
      initialValues: {
        rankingDate: data.rankingDate ? new Date(data.rankingDate * 1000) : '',
        positions: data.positions ? data.positions.map(position => ({
          ...position,
          team: position.team.id,
        })) : [],
      },
    } :
    {
      teamList: state.teams.data,
      positions: formValueSelector('rankingForm')(state, 'positions') || [],
    };
}


export default connect(mapStateToProps)(reduxForm({ form: 'rankingForm' })(RankingForm));
