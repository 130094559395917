import React, { Component, Fragment } from 'react';

// 3rd party components
import { TextField } from '@material-ui/core';

// components
import Button from '../../../../components/Button';

class MatchParticularity extends Component {
  presets = [
    'Abertura grupo A/B <br>',
    'Ganhadores grupo A/B.<br>Ganhador estará classificado para os playoffs.',
    'Perdedores grupo A/B.<br>Perdedor estará eliminado.',
    'Decisão grupo A/B.<br>Ganhador estará classificado para os playoffs, perdedor estará eliminado.',
    'XX de final tabela winner.<br>',
    'XX de final tabela lower.<br>Perdedor estará eliminado.',
    'Rodada #',
    'XX de final',
  ];

  render() {
    const { field, setFieldValue, form } = this.props;

    return (
      <Fragment>
        <TextField
          hintText="Particularidade"
          floatingLabelText="Particularidade"
          className="NewMatchForm__text-field"
          multiline
          rows={3}
          name="particularity"
          value={form.values.particularity}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={
            form.touched.particularity && Boolean(form.errors.particularity)
          }
          helperText={form.touched.particularity && form.errors.particularity}
        />

        <br />

        <small>HTML</small>

        <div className="NewMatchForm__particularity-presets">
          {this.presets.map(preset => (
            <Button
              key={preset}
              outline
              size="micro"
              type="button"
              className="NewMatchForm__particularity-preset-btn"
              text={preset}
              name="particularity"
              value={preset}
              onClick={() => form.setFieldValue('particularity', preset)}
            />
          ))}
        </div>
      </Fragment>
    );
  }
}

export default MatchParticularity;
