import React, { Component, useState } from 'react';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import LoadMask from '../../components/LoadMask';
import RankingsService from '../../services/rankings';
import useLocalStorageState from './hooks/useLocalStorageState';
import RankingForm from './RankingForm';

const AddNewRanking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [localStorageState, updateLocalStorageState] = useLocalStorageState(
    'rankingCalculationForm',
    {}
  );

  const addNewRanking = values => {
    setIsLoading(true);

    RankingsService.addNewRanking(values)
      .then(() => {
        toast.success('Cálculo criado com sucesso');
      })
      .catch(err => {
        toast.error(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header title="Novo Ranking" />
      {isLoading && <LoadMask />}
      <div className="PageContainer">
        <RankingForm
          saveFormState={updateLocalStorageState}
          initialData={localStorageState}
          onSubmit={addNewRanking}
        />
      </div>
    </>
  );
};

export default AddNewRanking;
