import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const StageFormModal = props => {
  const {stageData, onStageAdd, onStageEdit, onModalClose, open } = props
  return (
    <Formik
      initialValues={{
        stageName: stageData ? stageData.stageName : '',
        stagePosition: stageData ? stageData.stagePosition : 0,
      }}
      validationSchema={Yup.object().shape({
        stageName: Yup.string().required('Nome do stage é obrigatório'),
        stagePosition: Yup.string().required('Posição do stage é obrigatório'),
      })}
      onSubmit={values => {
        if (stageData) {
          onStageEdit(values, stageData.stageId);
          return;
        }
        onStageAdd(values);
      }}
      enableReinitialize
    >
      {({ handleChange, values, errors, touched, isValid }) => (
        <Dialog open={open} onClose={onModalClose}>
          <Form>
            <DialogTitle>
              {stageData
                ? `Editando ${stageData.stageName}`
                : 'Adicionar Stage'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <TextField
                  name="stageName"
                  label="Nome do Stage"
                  onChange={handleChange}
                  value={values.stageName}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={errors.stageName && touched.stageName}
                  helperText={errors.stageName}
                />
                
              </DialogContentText>

              <DialogContentText>
                <TextField
                  name="stagePosition"
                  label="Posição do Stage"
                  onChange={handleChange}
                  value={values.stagePosition}
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={errors.stagePosition && touched.stagePosition}
                  helperText={errors.stagePosition}
                />
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={onModalClose}>
                Cancelar
              </Button>
              <Button
                component="button"
                color="primary"
                type="submit"
                disabled={!isValid}
              >
                {stageData ? 'Salvar' : 'Adicionar'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default StageFormModal;
