import React, { Component } from 'react';

// 3rd party
import FontAwesome from 'react-fontawesome';

// Components
import Card from '../Card';
import Button from '../Button';

// css
import './styles.css';

export default class PageNavigator extends Component {
  render() {
    const {
      currentAmount,
      currentPage,
      onAmountChange,
      onPageChange,
      totalItems
    } = this.props

    const currentItems = currentPage * currentAmount
    
    return (
      <div className="PageNavigator">
        <Card className="PageNavigator__itens-per-page" noPadding>
          <span className="PageNavigator__itens-per-page-label">Itens por página</span>

          <a
            className={`
              PageNavigator__itens-per-page-link
              ${currentAmount === 10 ? ' PageNavigator__itens-per-page-link--active' : ''}
            `}
            role="Button"
            onClick={() => { onAmountChange(10) } }
          >
            10
          </a>

          <a
            className={`
              PageNavigator__itens-per-page-link
              ${currentAmount === 25 ? ' PageNavigator__itens-per-page-link--active' : ''}
            `}
            role="Button"
            onClick={() => { onAmountChange(25) } }
          >
            25
          </a>

          <a
            className={`
              PageNavigator__itens-per-page-link
              ${currentAmount === 50 ? ' PageNavigator__itens-per-page-link--active' : ''}
            `}
            role="Button"
            onClick={() => { onAmountChange(50) } }
          >
            50
          </a>

          <a
            className={`
              PageNavigator__itens-per-page-link
              ${currentAmount === 100 ? ' PageNavigator__itens-per-page-link--active' : ''}
            `}
            role="Button"
            onClick={() => { onAmountChange(100) } }
          >
            100
          </a>
        </Card>

        <div className="PageNavigator__page-btns">
          <Button
            className="PageNavigator__page-btns-btn"
            skin="accent"
            size="small"
            disabled={currentPage === 1}
            onClick={() => { onPageChange(currentPage - 1) }}
            text={<FontAwesome name="arrow-left" />}
          />
          <Button
            className="PageNavigator__page-btns-btn"
            skin="accent"
            size="small"
            disabled={totalItems <= currentItems}
            onClick={() => { onPageChange(currentPage + 1) }}
            text={<FontAwesome name="arrow-right" />}
          />
        </div>

      </div>
    );
  }
}
